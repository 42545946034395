import React, { useEffect, useId } from "react";
import { Card, CardHeader, CardBody, CardFooter, Button } from "reactstrap";
import { Chart } from "chart.js/auto";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { convertPenniesToDecimals } from "src/utils/money";

const TrafficDougnut = ({ data, loading, chartFilter, setChartFilter }) => {
  const id = useId();

  useEffect(() => {
    if (loading === false) {
      var config = {
        type: "bar",
        data: {
          labels: data.by_month.map((item) => moment(item.date_from).format("MMM YY")),
          legend: false,
          datasets: [
            {
              borderColor: "#fff",
              label: 'Supplier Paid',
              backgroundColor: data.by_month.map(() => '#798bff'),
              data: data.by_month.map((item) => Math.floor(convertPenniesToDecimals(item[chartFilter]) * 0.75)),
            },
            {
              borderColor: "#fff",
              label: 'Held By Agent',
              backgroundColor: data.by_month.map(() => '#4b127c'),
              data: data.by_month.map((item) => Math.floor(convertPenniesToDecimals(item[chartFilter]) * 0.8)),
            }
          ],
        },
        options: {
          legend: {
            display: true,
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                maxTicksLimit: 6,
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return '£' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return '£' + value;
                  }
                }
              }
            }]
          },
          rotation: -1.5,
          cutoutPercentage: 70,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            backgroundColor: "#fff",
            borderColor: "#eff6ff",
            borderWidth: 2,
            titleFontSize: 13,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
        },
      };

      var ctx = document.getElementById(id).getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <Card className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">
        <CardHeader className="bg-gray-50 mb-2 text-purple-900">
          <h2 className="text-xl text-purple-900 font-bold">
            Forward Looking Untraveled Bookings
          </h2>
          <p className="text-xs">
            Un-travelled bookings by month, grouped by whether supplier has been paid. {" "}
          </p>
        </CardHeader>
        <CardBody className="card-inner pt-0">
          <div className="traffic-channel">
            <Skeleton count={1} height="250px" />
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">
      <CardHeader className="bg-gray-50 mb-2 text-purple-900">
        <h2 className="text-xl text-purple-900 font-bold">
          Forward Looking Untraveled Bookings
        </h2>
        <p className="text-xs">
          Un-travelled bookings by month, grouped by whether supplier has been paid. {" "}
        </p>
      </CardHeader>
      <CardBody className="card-inner pt-0">
        <div className="pt-4 grow max-h-[250px]">
          <canvas id={id}></canvas>
        </div>
      </CardBody>
      <CardFooter className="bg-light">
        <Button
          className={`float-right ml-2 ${chartFilter === 'total' ? 'bg-slate-800' : 'bg-purple-800'}`}
          onClick={() => setChartFilter('total')}
        >
          All Card Types
        </Button>
        <Button
          className={`float-right ml-2 ${chartFilter === 'credit' ? 'bg-slate-800' : 'bg-purple-800'}`}
          onClick={() => setChartFilter('credit')}
        >
          Credit Cards
        </Button>
        <Button
          className={`float-right ml-2 ${chartFilter === 'debit' ? 'bg-slate-800' : 'bg-purple-800'}`}
          onClick={() => setChartFilter('debit')}
        >
          Debit Cards
        </Button>
      </CardFooter>
    </Card>
  );
};
export default TrafficDougnut;
