import React, {useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block, BlockTitle,
  Icon,
} from "src/components/Component";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import CurrencyFormat from "../../../../../../transformer/currency-format";
import {Link} from "react-router-dom";
import {convertPenniesToDecimals} from "../../../../../../utils/money";

const Payouts = ({ data, loading }) => {
  const [batches, setBatches] = useState([])

  useEffect(() => {
    const cleanedBatches = [];
    if (typeof data.transactions !== 'undefined' && data.transactions.length >= 1) {
      data.transactions.forEach((transaction) => {
        if (transaction.batch[0].completed_at) {
          cleanedBatches.push({
            disbursal_id: transaction.batch[0].disbursal.id,
            amount: transaction.amount,
            disbursal_amount: transaction.batch[0].disbursal.amount,
            completed_at: transaction.batch[0].completed_at,
            iban: transaction.batch[0].disbursal.iban,
            bank_reference: transaction.batch[0].disbursal.bank_reference,
          })
        }
      });

      setBatches(cleanedBatches);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Head title="Payouts" />

      <div className="mb-3">
        <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payout Batches</BlockTitle>
        <p className="text-secondary text-sm">Payment Batches to your bank / trust account for this booking.</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
            <tr className='tb-tnx-item'>
              <th rowSpan={2} scope="col">Time</th>
              <th rowSpan={2} scope="col" className="text-center border-left">Paid Out Amount</th>
              <th colSpan={4} scope="col" className="text-center border-left">Batch</th>
              <th rowSpan={2} scope="col" className="border-left">{ ' ' }</th>
            </tr>
            <tr className='tb-tnx-item'>
              <th scope="col" className="text-center border-left">Amount</th>
              <th scope="col" className="text-center">Bank Reference</th>
              <th scope="col" className="text-center">Account Number</th>
              <th scope="col" className="text-center">Sort Code</th>
            </tr>
          </thead>
          <tbody>
          { loading === true && [0, 1, 2, 3].map((key) => (
            <tr className='tb-tnx-item' key={key}>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && batches.length === 0 && (
            <tr className='tb-tnx-item'>
              <td colSpan={6} className="text-center">
                <strong>No payouts have been disbursed on this booking</strong>
              </td>
            </tr>
          )}
          { loading === false && batches.map((batch, key) => (
            <tr className='tb-tnx-item' key={key}>
              <td>{ moment(batch.completed_at).format('Do MMMM YYYY, h:mm:ss a') }</td>
              <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals( batch.amount) ) }</td>
              <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals( batch.disbursal_amount) ) }</td>
              <td className="text-center">{ batch.bank_reference }</td>
              <td className="text-center">{ batch.iban.account_number }</td>
              <td className="text-center">{ batch.iban.sort_code }</td>
              <td className="border-left text-center" style={{ width: '75px' }}>
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <Link
                      to={`/batch/?disbursement_id=${batch.disbursal_id}`}
                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                    >
                      <Icon name="info" />
                    </Link>
                  </li>
                </ul>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </Block>

    </React.Fragment>
  );
};
export default Payouts;
