export const AUDIT = 'AUDIT';
export const COMPONENT_LIBRARY = 'COMPONENT_LIBRARY';
export const ORGANISATIONS = 'ORGANISATIONS';
export const RISK_ANALYSIS = 'RISK_ANALYSIS';
export const SUPPLIERS = 'SUPPLIERS';

export const features = {
  [AUDIT]: false,
  [COMPONENT_LIBRARY]: false,
  [ORGANISATIONS]: false,
  [RISK_ANALYSIS]: false,
  [SUPPLIERS]: true,
};

export const isEnabled = (name, organisation) => {
  if (organisation === 'X1234') {
    return true;
  }

  return false;
}

export default isEnabled();
