import React, {useCallback, useEffect, useState} from 'react';
import Head from 'src/layout/head/Head';
import Content from 'src/layout/content/Content';
import moment from 'moment';

import {
  Block,
  Row,
  Col, BlockHead, BlockBetween, BlockHeadContent, BlockTitle,
} from "src/components/Component";
import BankAccounts from "./components/accounts";
import Connections from "./components/connections";
import StatBox from "../dashboard/pages/main/components/statistics/stat-box";
import AddBankButton from "./components/add-bank-button";
import {useSelector} from "react-redux";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../config/api";
import {useHistory} from "react-router-dom";

const Dashboard = () => {
  const { organisation } = useSelector((state) => state);

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState({});
  const [reload, setReload] = useState(0);

  const triggerReload = () => {
    setReload(reload + 1);
  };

  const refreshAccounts = useCallback(async (connectionID) => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.saltedge['customer:refresh'](),
      {
        method: 'post',
        data: {
          organisation: organisation.organisation,
          connection_id: connectionID,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    getData();
  });

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.bank['statistics:get'](),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        params: {
          organisation: organisation.organisation
        }
      }
    );

    setStatistics(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    const connectionID = urlParams.get('connection_id');

    if (connectionID) {
      refreshAccounts(connectionID);
    } else {
      getData();
    }
  }, [organisation.organisation, reload]);

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-primary" page>Bank <span className="fw-normal">Accounts</span></BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <AddBankButton />
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="mt-4">
          <Row className="g-gs">
            <Col size="4">
              <StatBox
                title={ <React.Fragment>Active <span className="fw-normal">Accounts</span></React.Fragment> }
                figure={statistics?.accounts?.count}
                loading={loading}
                popoverTitle="Active Accounts"
                popoverDescription="Account that you have activated and are synchronizing with Felloh"
              />
            </Col>
            <Col size="4">
              <StatBox
                title={ <React.Fragment>Expired <span className="fw-normal">Accounts</span></React.Fragment> }
                figure={0}
                loading={loading}
                popoverTitle="Expired Accounts"
                popoverDescription="Accounts that have expired and are no longer synchronizing with Felloh"
              />
            </Col>
            <Col size="4">
              <StatBox
                title={ <React.Fragment>Last <span className="fw-normal">Synchronisation</span></React.Fragment> }
                figure={moment(statistics?.accounts?.last_sync).fromNow()}
                loading={loading}
                popoverTitle="Last Synchronisation"
                popoverDescription="When the last time your accounts were synchronised with Felloh"
              />
            </Col>
          </Row>
        </Block>
        <Block>
          <Row className="g-gs">
            <Col size="12">
              <Connections data={statistics} loading={loading} />
              <BankAccounts data={statistics} loading={loading} triggerReload={triggerReload} />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
