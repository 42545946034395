export const buildProviderGroups = (providers) => {
  const groups = {};

  providers.forEach((provider) => {
    const paymentMethod = provider.payment_provider.payment_method;
    const transactionMethods = provider.transaction_methods;
    transactionMethods.forEach((item) => {
      const transactionMethod = item.name;

      const key = `${paymentMethod} - ${transactionMethod}`;

      const group = {
        key,
        paymentMethod,
        transactionMethod,
      };

      groups[key] = group;
    });
  });

  // Return groups as array of objects
  return Object.values(groups);
};

export const providersInGroup = (providers, group) => {
  const filtered = providers.filter(
    (provider) => (
      provider.payment_provider.payment_method === group.paymentMethod
      && (provider.transaction_methods.filter((item) => (item.name === group.transactionMethod))).length
    )
  );
  return filtered;
};

export const getInitialWeights = (providers, transactionMethod) => {
  const initialWeights = [];
  providers.forEach((provider) => {
    let weight = provider.transaction_methods.reduce((prev, item) => ((item.name === transactionMethod) ? prev + item.weight : prev), 0);
    if (weight < 1) {
      weight = 1;
    }
    initialWeights.push(weight);
  });
  return initialWeights;
};

export const normaliseWeights = (initialWeights) => {
  // Calculate sum+ratio to normalise values to a total sum of 100
  const sum = initialWeights.reduce((totalWeight, weight) => totalWeight + weight, 0);
  const ratio = sum / 100;

  let runningTotal = 0;
  const weights = initialWeights.map((weight, i) => {
    let normalised = Math.floor(weight / ratio);
    // Ensure each value is a minimum of 1 (no zero values)
    if (normalised < 1) {
      normalised = 1;
    }
    runningTotal += normalised;
    return normalised;
  });

  // Check if we have a difference against our target of 100 and fudge a change if needed
  const diff = runningTotal - 100;
  if (diff !== 0) {
    let target;
    if (diff > 0) {
      target = Math.max(...weights);
    } else if (diff < 0) {
      target = Math.min(...weights);
    }
    const index = weights.indexOf(target);
    weights[index] -= diff;
  }

  return weights;
};

export const calcInitialPoints = (weights) => {
  let initialPoints = [];
  let totalPoints = 0;
  weights.forEach((weight) => {
    totalPoints += weight;
    initialPoints.push(totalPoints);
  });

  initialPoints.pop(); // remove the final 100 value

  return initialPoints;
};

export const getWeight = (points, index) => {
  let startPoint = 0;
  if (index >= 1) {
    startPoint = points[index - 1];
  }

  let endPoint = 100;
  if (index < points.length) {
    endPoint = points[index];
  }

  const weight = endPoint - startPoint;
  return weight;
};

export const indexLabel = (index) => {
  return (index+1);
}
