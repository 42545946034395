import React, {useEffect, useState} from 'react';
import { faker } from '@faker-js/faker';
import { RSelect } from '../../Component';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganisation } from 'src/actions/organisationActions';
import {useHistory} from "react-router-dom";

const OrganisationSelector = ({ width, showWithNoDefault = true }) => {
  const history = useHistory();

  const { piiObfuscated } = useSelector((state) => state.account);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownOptionsDefault, setDropdownOptionsDefault] = useState([]);

  const dispatch = useDispatch();

  // Fetch Organisations
  const organisation = useSelector((state) => state.organisation);

  useEffect(() => {
    const organisations = organisation.organisations;

    let options = organisations.map((item) => ({ value: item.id, label: `${piiObfuscated ? faker.company.name() : item.name} | ${item.id}` }));
    options.sort((a, b) => a.label.localeCompare(b.label));

    options.forEach(item => {
      if (item.value === organisation.organisation) {
        setDropdownOptionsDefault(item);
      }
    });

    setDropdownOptions(options)
  }, [organisation.organisations, organisation.organisation, piiObfuscated]);

  // Handle Organisation Changes
  const changeOrganisation = async({ value }) => {
    dispatch(setOrganisation(value));
    
    history.push('/');
  };

  const classes = width === 100 ? 'w-100 h-60 border border-purple-800': 'w-300px ml-2 border-purple-800';

  if (organisation.organisation === null && showWithNoDefault === false) {
    return <React.Fragment />
  }

  return (
    <RSelect
      options={dropdownOptions}
      value={dropdownOptionsDefault}
      onChange={changeOrganisation}
      className={classes}
      placeholder='Select Organisation'
    />
  )
}

export default OrganisationSelector;
