import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import { Block, BlockTitle } from "src/components/block/Block";
import { Card, Col, Row } from "reactstrap";
import Head from "src/layout/head/Head";
import { Button } from "src/components/Component";
import Skeleton from "react-loading-skeleton";
import AuthoriseButton from "./components/authorise-button";
import DeleteButton from "./components/delete-button";
import Field from "../../../bookings/pages/booking/views/booking-information/components/field";
import CurrencyFormat from "src/transformer/currency-format";
import { convertPenniesToDecimals } from "src/utils/money";
import { useSelector } from "react-redux";
import moment from "moment";
import { ChevronRightIcon } from '@heroicons/react/24/outline';

const RefundDetail = ({ data, loading }) => {
  const { enums } = useSelector((state) => state.enums);
  const history = useHistory();

  const triggerReload = () => {
    history.push(`/payments/refunds`);
  };

  if (loading === true) {
    return (
      <Block>
        <Card className="card-bordered">
          <div className="card-inner card-inner-lg pt-4">
            <Row>
              <Col xl="9">
                <Block>
                  <Skeleton count={4} height="40px" className="mb-2" />
                </Block>
              </Col>
              <Col xl="3" className="pl-xl-4 bl-xl">

                <div className="divider-xl-down" />

                <div className="w-100 mb-4">
                  <div className="mb-3 mt-4 mt-xl-1">
                    <div className="mb-3 mt-4 mt-xl-1">
                      <BlockTitle tag="h4" className="text-primary mb-0">Actions</BlockTitle>
                    </div>

                    <Block>
                      <Skeleton count={1} height="40px" className="mb-2" />
                    </Block>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Block>
    )
  }

  return (
    <Block>
      <Card className="card-bordered">
        <div className="card-inner card-inner-lg pt-4">
          <Row>
            <Col xl="9">
              <Block>
                <div className="row g-3">
                  <Field
                    name="Refund Amount"
                    description="The amount that is being requested to be refunded"
                    itemWidth={4}
                    data={CurrencyFormat(enums.currency, data.transaction.currency).format(convertPenniesToDecimals(data.amount))} />

                  <Field
                    name="Transaction Amount"
                    description="The amount that was originally charged"
                    itemWidth={4}
                    data={CurrencyFormat(enums.currency, data.transaction.currency).format(convertPenniesToDecimals(data.transaction.amount))} />

                  <Field
                    name="Refund %"
                    description="The percentage of the transaction that is being refunded"
                    itemWidth={4}
                    data={`${((data.amount / data.transaction.amount) * 100).toFixed(2)}%`} />

                  <Field
                    name="Transaction Date"
                    description="The date that the transaction was made"
                    itemWidth={4}
                    data={moment(data.transaction.created_at).format('Do MMMM YYYY')} />

                  <Field
                    name="Date Requested"
                    description="The date that this refund was requested"
                    itemWidth={4}
                    data={moment(data.created_at).format('Do MMMM YYYY')} />

                  <Field
                    name="Requested By"
                    description="The user that requested this refund"
                    itemWidth={4}
                    data={data.requesting_user?.email} />

                  <Field
                    name="Transaction ID"
                    description="The transaction ID that this refund is associated with"
                    itemWidth={6}
                    data={
                      <Link
                        to={`/transaction/${data.transaction.id}`}
                      >
                        {data.transaction.id}
                      </Link>
                    } />

                  <Field
                    name="Refund Description"
                    description="The reason for the refund"
                    itemWidth={12}
                    data={data.description} />
                </div>
              </Block>
            </Col>
            <Col xl="3" className="pl-xl-4 bl-xl">

              <div className="divider-xl-down" />

              <div className="w-100 mb-4">
                <div className="mb-3 mt-4 mt-xl-1">
                  <BlockTitle tag="h4" className="text-primary mb-0">Actions</BlockTitle>
                </div>
                {
                  data.status.id === 'PENDING_AUTHORISATION' && (
                    <React.Fragment>
                      <AuthoriseButton item={data} triggerReload={triggerReload} />
                      <DeleteButton item={data} triggerReload={triggerReload} />
                    </React.Fragment>
                  )
                }
                {
                  data.status.id !== 'PENDING_AUTHORISATION' && (
                    <React.Fragment>
                      <strong>No actions available - This refund request has already been actioned</strong>
                    </React.Fragment>
                  )
                }
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </Block>
  );
}

const Refund = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getRefund = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent["refund:get"](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    getRefund();
  }, []);

  return (
    <React.Fragment>
      <Head title="Refund Request" />

      <div className="flex flex-col rounded-md border border-slate-200 nimate-in fade-in-50 mb-4 bg-gray-50">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title w-100">
              <div className="float-right">
                <Link to={'/payments/refunds'}>
                  <Button color="light" outline className="btn-white" id="button__back">
                    <span>Back to all refunds</span>
                    <ChevronRightIcon className="w-4 h-4 ml-1" />
                  </Button>
                </Link>
              </div>
              <h2 className="text-xl font-normal text-purple-900">
                Recent <span className="font-bold">Payments</span>
              </h2>
            </div>
          </div>
        </div>
        <RefundDetail data={data} loading={loading} />
      </div>

    </React.Fragment>
  )
}

export default Refund;
