/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  FETCHING_ORGANISATIONS,
  SET_ORGANISATIONS,
  SET_ORGANISATION,
  SET_ORGANISATION_FEATURES,
  SET_ORGANISATION_CURRENCIES,
  SET_FILTERS_SHOW_ALL_CHILD_ORGANISATIONS,
  SET_ORGANISATION_PAYMENT_METHODS,
  SET_ORGANISATION_UNPAID,
  SET_ORGANISATION_INTEGRATIONS,
} from 'src/actions/organisationActions';

const initialState = {
  organisation: null,
  organisations: [],
  fetching_organisations: false,
  features: [],
  currencies: [],
  payment_methods: [],
  filtering: {
    show_all_child_organisations: false,
  },
  integrations: [],
  unpaid_invoice: false
};

export const FELLOH_ID = 'X1234';

const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATION_UNPAID: {
      return produce(state, (draft) => {
        draft.unpaid_invoice = action.payload;
      });
    }

    case FETCHING_ORGANISATIONS: {
      return produce(state, (draft) => {
        draft.fetching_organisations = action.payload;
      });
    }

    case SET_ORGANISATIONS: {
      return produce(state, (draft) => {
        draft.organisations = action.payload;
      });
    }

    case SET_ORGANISATION: {
      return produce(state, (draft) => {
        draft.organisation = action.payload;
      });
    }

    case SET_ORGANISATION_FEATURES: {
      return produce(state, (draft) => {
        draft.features = action.payload;
      });
    }

    case SET_ORGANISATION_CURRENCIES: {
      return produce(state, (draft) => {
        draft.currencies = action.payload;
      });
    }

    case SET_ORGANISATION_PAYMENT_METHODS: {
      return produce(state, (draft) => {
        draft.payment_methods = action.payload;
      });
    }

    case SET_FILTERS_SHOW_ALL_CHILD_ORGANISATIONS: {
      return produce(state, (draft) => {
        draft.filtering.child_organisations_filtered = action.payload;
      });
    }

    case SET_ORGANISATION_INTEGRATIONS: {
      return produce(state, (draft) => {
        draft.integrations = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default organisationReducer;
