import React from "react";
import { Route, withRouter, useLocation } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";

import Layout from "./layout";

import Error401Modern from "src/pages/error/401-modern";
import Error404Modern from "src/pages/error/404-modern";
import Error504Modern from "src/pages/error/504-modern";

import Login from "src/pages/auth";
import FragmentSupportingSwitch from "src/components/fragment-supporting-switch";
import {useSelector} from "react-redux";

import { VIEWS as LOGIN_VIEWS } from "src/pages/auth";
import ErrorBanner from "./components/error-banner";
import posthog from 'posthog-js'

const RenderHomepage = (user, organisation) => {
  if (user === null || typeof user === 'undefined' || organisation.organisation === null) {
    return (
      <React.Fragment>
        <Route exact path={`/link/:id`} component={() => <Login page={LOGIN_VIEWS.MAGIC_LINK} />} />
        <Route exact path={`/password-reset`} component={() => <Login page={LOGIN_VIEWS.FORGOT_PASSWORD} />} />
        <Route exact path={`/`} component={() => <Login page={LOGIN_VIEWS.LOGIN} />} />
      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

const RenderRedirect = (authChecked) => {
  if (authChecked === true) {
    return <Route component={RedirectAs404} />;
  }

  return <React.Fragment />;
}

const App = () => {
  const { account: { authChecked, user }, organisation } = useSelector((state) => state);
  let location = useLocation(); // new

  React.useEffect(() => {
    if (posthog.__loaded) {
      posthog.capture('$pageview')
    }
  }, [location]);

  return (
    <React.Fragment>
      <ErrorBanner />

      <FragmentSupportingSwitch>
        {/* Auth Pages */}
        { RenderHomepage(user, organisation) }

        {/*Error Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/errors/401`} component={Error401Modern} />
        <Route exact path={`${process.env.PUBLIC_URL}/errors/504`} component={Error504Modern} />
        <Route exact path={`${process.env.PUBLIC_URL}/errors/404`} component={Error404Modern} />

        {/*Main Routes*/}
        <PrivateRoute exact path="" component={Layout} />
        { RenderRedirect(authChecked) }
      </FragmentSupportingSwitch>
    </React.Fragment>
  );
};
export default withRouter(App);
