import React, { useCallback, useState } from 'react';
import { Row, Col, FormGroup, Card, Form, Spinner } from 'reactstrap';

import Content from 'src/layout/content/Content';
import Head from 'src/layout/head/Head';
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button, Icon,
} from 'src/components/Component';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import AvailablePayoutValue from "../../utils/available-payout-value";
import CurrencyFormat from "../../../../../../transformer/currency-format";
import AsyncSelect from "react-select/async";
import Dropzone from 'react-dropzone-uploader';
import { convertPenniesToDecimals, roundDecimalMoney } from "../../../../../../utils/money";

const PayoutCreate = ({ ...props }) => {
  const history = useHistory();
  const { organisation } = useSelector((state) => state);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    amount: '',
  });
  const [touched, setTouched] = useState({});
  const data = history.location.state.data;

  const availableToBePaidOut = AvailablePayoutValue(data);

  const updateFormField = ((e) => {
    let value = e.target.value;

    if (e.target.id === 'amount' && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      value = roundDecimalMoney(e.target.value);
    }

    setTouched({
      ...touched,
      [e.target.id]: true,
    });

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  const updateSupplier = (field) => {
    setTouched({
      ...touched,
      supplier: true,
    });
    setFormValues({
      ...formValues,
      supplier_field: field,
    });
  };

  const findSuppliers = useCallback(async (value) => {
    if (data.components.length >= 1) {
      return data.components
        .map((item) => item.supplier)
        .sort(function (a, b) {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();

          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['suppliers:get'](),
      {
        method: 'post',
        data: {
          take: 25,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    return result.data.data;
  }, []);

  const balance = convertPenniesToDecimals(availableToBePaidOut) - formValues.amount;

  const isValid = () => {
    if (typeof formValues.supplier_field === 'undefined' || formValues.supplier_field === null) return false;
    if (balance < 0) return false;
    if (formValues.amount === '' || formValues.amount === 0 || formValues.amount < 1) return false;

    return true;
  };

  const formValid = isValid();

  const assignFullBalance = (event) => {
    event.preventDefault();

    setFormValues({
      ...formValues,
      amount: convertPenniesToDecimals(availableToBePaidOut),
    })
  };

  return (
    <React.Fragment>
      <Head title="Form Layouts" />
      <Content>
        <BlockHead size="sm" className="mb-2">
          <BlockHeadContent>
            <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal py-2" page>
              Create Supplier Payout
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Card className="card-bordered p-5">

          <Form className="gy-3">

            <Block size="lg">

              <div className="nk-data data-list data-list-s2">
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Booking reference</strong>
                      <br />
                      <small>Your booking reference for this booking</small>
                    </span>
                    <span className="data-value">{data.booking_reference}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="lock-alt" />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Available to payout</strong>
                      <br />
                      <small>The amount available to pay out</small>
                    </span>
                    <span className="data-value">{CurrencyFormat().format(convertPenniesToDecimals(availableToBePaidOut))}</span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="lock-alt" />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Supplier</strong>
                      <br />
                      <small>The supplier that will be paid out</small>
                    </span>
                    <span className="data-value w-100">
                      <FormGroup>
                        <div className="form-control-wrap">
                          <AsyncSelect
                            isClearable
                            className={`form-control form-control-xl p-0 border-0 indicator-hidden`}
                            value={formValues.supplier_field}
                            isMulti={false}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e.id}
                            loadOptions={findSuppliers}
                            onChange={(item) => updateSupplier(item)}
                            cacheOptions
                            defaultOptions
                            placeholder=""
                            classNamePrefix="react-select"
                          />
                        </div>
                      </FormGroup>
                      {formValues.supplier_field && (
                        <React.Fragment>
                          <strong>IBAN: </strong>GB74SRLG60837188343667
                          <br />
                          <strong>Account Number: </strong>88343667
                          <br />
                          <strong>Sort Code: </strong>608371
                        </React.Fragment>
                      )}
                    </span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="pen2" />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Payout amount</strong>
                      <br />
                      <small>The amount to be paid to the supplier</small>
                    </span>
                    <span className="data-value w-100">
                      <FormGroup>
                        <div className={`form-control-wrap input-group`}>
                          <div className="input-group-addon pl-3 pr-3">£</div>
                          <input
                            type="number"
                            id="amount"
                            disabled={isSubmitting}
                            value={formValues.amount}
                            onChange={updateFormField}
                            className={`form-control form-control-xl`}
                            min={0}
                            step={0.01}
                          />
                        </div>
                        {formValues.amount <= 0 && touched.amount === true && (
                          <React.Fragment>
                            <small className="text-danger"><strong>Amount must not be negative or zero</strong></small> |{` `}
                          </React.Fragment>
                        )}
                        {convertPenniesToDecimals(availableToBePaidOut) !== formValues.amount && (
                          <small><a href="/" onClick={assignFullBalance}>Assign full balance for payout</a></small>
                        )}
                      </FormGroup>
                    </span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="pen2" />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className={`data-label${balance >= 0 ? '' : ' text-danger'}`}>
                      <strong>Booking balance after payout</strong>
                      <br />
                      <small>The amount available to payout</small>
                    </span>
                    <span className={`data-value${balance >= 0 ? '' : ' text-danger'}`}>
                      {CurrencyFormat().format(balance)}
                      {(balance < 0) && (
                        <React.Fragment>
                          <br />
                          <small><strong>Balance cannot be negative or zero</strong></small>
                        </React.Fragment>
                      )}
                    </span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className={`data-more disable`}>
                      <Icon name={balance >= 0 ? 'lock-alt' : 'cross'} className={`${balance >= 0 ? '' : ' text-danger'}`} />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Reason for payout</strong>
                      <br />
                      <small>A description of the reason for the payout</small>
                    </span>
                    <span className="data-value w-100">
                      <FormGroup>
                        <div className={`form-control-wrap`}>
                          <textarea
                            id="description"
                            name="description"
                            className={`form-control form-control-xl`}
                          />
                        </div>
                      </FormGroup>
                    </span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="pen2" />
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      <strong>Supporting documents</strong>
                      <br />
                      <small>Documentation to support reason for release</small>
                    </span>
                    <span className="data-value w-100">
                      <Dropzone
                        dropzoneDisabled
                        accept="image/*"
                        maxFiles={1}
                        PreviewComponent={null}
                        multiple={false}
                        submitButtonContent="Save new image"
                        styles={{ dropzone: { minHeight: 100, height: 150 } }}
                      />
                    </span>
                  </div>
                  <div className="data-col data-col-end">
                    <span className="data-more disable">
                      <Icon name="pen2" />
                    </span>
                  </div>
                </div>

                <Row className="g-3 mt-5">
                  <Col lg="7" className="offset-lg-5 mt-5">
                    <FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        size="lg"
                        className="float-right"
                        disabled={formValid === false}
                        onClick={() => setIsSubmitting(true)}
                      >
                        {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : 'Create Payout'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Block>
          </Form>
        </Card>
      </Content>
    </React.Fragment>

  );
};

export default PayoutCreate;
