import React, { useState, useCallback } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader, ModalFooter, Spinner,
} from "reactstrap";
import {
  Icon,
  Button,
} from "src/components/Component";
import { Auth } from 'aws-amplify';
import { API_ROUTES } from 'src/config/api';
import Axios from 'axios';
import {useHistory} from "react-router-dom";

const DeleteModal = ({ organisation }) => {
  const [modal, setModal] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const history = useHistory();

  const onFormSubmit = useCallback(async () => {
    setModalFormProcessing(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios.get(
      API_ROUTES.user['organisation:archive'](organisation.id),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    setModal(false);
    history.push('/organisations');
  }, [organisation.organisation]);

  return (
    <React.Fragment>
      <Button color="white" onClick={() => setModal(true)}>
        <span>Archive Organisation</span>
      </Button>

      <Modal isOpen={modal} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
        <ModalHeader
          className="bg-danger text-white"
          close={
            <button className="close" onClick={() => setModal(false)}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Archive Organisation
        </ModalHeader>
        <ModalBody>
          <p className="pt-4 pb-4">Are you sure that you would like to archive this organisation?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" className="bg-red-400" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                onFormSubmit();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  );
};
export default DeleteModal;
