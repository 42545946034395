import {
  COMPONENT_LIBRARY,
  ORGANISATIONS,
} from 'src/config/feature';

import HasRole from '../../utils/has-role';
import HasFeature from '../../utils/has-feature';

const FetchMenu = (organisation, features, account) => {
  if (!account?.roles) {
    return [];
  }

  return [
    {
      icon: "dashboard",
      text: "Dashboard",
      link: "/",
    },
    {
      icon: "tranx-fill",
      text: "Create Payment Link",
      enabled: HasRole(account, ['booking-and-payment:create:payment']) && HasFeature(features, ['booking-and-payment']),
      link: "/payment-link/create",
    },
    {
      heading: "Customer",
      enabled: HasFeature(features, ['booking-and-payment']),
    },
    {
      icon: "clipboard",
      text: "Bookings",
      link: "/bookings",
      enabled: HasFeature(features, ['booking-and-payment']),
    },
    {
      icon: "blank",
      text: "Credit Notes",
      link: "/credit-notes",
      enabled: HasRole(account, ['credit-notes:view']) && HasFeature(features,['credit-notes']),
      active: false,
    },
    {
      icon: "server",
      text: "Ecommerce Sessions",
      link: "/ecommerce",
      enabled: HasRole(account, ['booking-and-payment:organisation-access']) && HasFeature(features,['ecommerce']),
      active: false,
    },
    {
      icon: "cc",
      text: "Transactions",
      link: "/transactions",
      enabled: HasFeature(features, ['booking-and-payment']),
    },
    {
      icon: "link-alt",
      text: "Payment Links",
      link: "/payment-links",
      enabled: HasFeature(features, ['booking-and-payment']),
    },
    {
      icon: "cc-off",
      text: "Refunds",
      enabled: HasRole(account, ['refund:approve']) && HasFeature(features,['refunds']),
      link: "/refunds",
    },
    {
      heading: "Reporting",
      enabled: HasRole(account,
        [
          'chargebacks:view',
          'payouts:view',
          'payouts:create',
          'ledger:view',
          'organisation:risk:statistics'
        ],
        true
      ) && HasFeature(features,
        [
          'payouts',
          'ledger',
          'risk',
          'chargebacks'
        ],
        true
      ),
    },
    {
      icon: "wallet",
      text: "Bank Accounts",
      link: "/bank-account",
      enabled: HasRole(account, ['open-banking:manage']) && HasFeature(features,['aisp']),
      active: false,
    },
    {
      icon: "shield-star",
      text: "Chargebacks",
      link: "/chargebacks",
      enabled: HasRole(account, ['chargebacks:view']) && HasFeature(features,['chargebacks']),
      active: false,
    },
    {
      icon: "cc-off",
      text: "Decline Analysis",
      link: "/declines",
      enabled: HasRole(account, ['acquirer:reporting']) && HasFeature(features,['acquirer-reporting']),
      active: false,
    },
    {
      icon: "sign-gbp",
      text: "Disbursements",
      link: "/disbursements",
      enabled: HasRole(account, ['payouts:view', 'payouts:create']) && HasFeature(features,['payouts']),
      active: false,
    },
    {
      icon: "view-list-fill",
      text: "Ledger",
      link: "/ledger",
      active: false,
      enabled: HasRole(account, ['ledger:view']) && HasFeature(features,['ledger']),
    },
    {
      icon: "brick",
      text: "Merchant Acquirer",
      enabled: HasRole(account, ['acquirer:reporting']) && HasFeature(features,['acquirer-reporting']),
      link: "/batches",
    },
    {
      icon: "alert",
      text: "Risk Analysis",
      active: false,
      enabled: HasRole(account, ['organisation:risk:statistics']) && HasFeature(features,['risk']),
      subMenu: [
        {
          icon: "brick",
          text: "Overview",
          link: "/risk",
        },
        {
          text: "Financial",
          link: "/risk/abta-reporting",
        },
        {
          text: "Exposure",
          link: "/risk/rolling-reserve",
        },
      ]
    },
    {
      heading: "Account",
      enabled: HasRole(account,
        [
          'beneficiaries:manage',
          'developers:manage:keys',
          'developers:manage:webhooks',
          'organisation:management',
          'suppliers:manage',
          'users:manage'
        ],
        true
      ) && HasFeature(features,
        [
          'audit',
          'payouts',
          'developers',
          'organisation',
          'suppliers',
          'users'
        ],
        true
      ),
    },
    {
      icon: "crosshair",
      text: "Audit",
      link: "/audit",
      active: false,
      enabled: HasRole(account, ['organisation:audit']) && HasFeature(features, ['audit']),
    },
    {
      icon: "briefcase",
      text: "Beneficiaries",
      link: "/beneficiaries",
      active: false,
      enabled: HasRole(account, ['beneficiaries:manage']) && HasFeature(features,['payouts']),
    },
    {
      icon: "report-profit",
      text: "Billing & Subscription",
      link: "/billing",
      active: false,
      enabled: HasRole(account, ['billing:view'], true),
    },
    {
      icon: "code",
      text: "Developers",
      link: "/developers",
      active: false,
      enabled: HasRole(account, ['developers:manage:keys', 'developers:manage:webhook'], true) && HasFeature(features,['developers']),
    },
    {
      icon: "swap",
      text: "Integrations",
      link: "/integrations",
      active: false,
      enabled: HasRole(account,['organisation:integrations']),
    },
    {
      icon: "network",
      text: "Suppliers",
      link: "/suppliers",
      active: false,
      enabled: HasRole(account, ['suppliers:manage']) && HasFeature(features,['suppliers']),
    },
    {
      icon: "users",
      text: "Users",
      link: "/users",
      active: false,
      enabled: HasRole(account, ['users:manage']) && HasFeature(features,['users']),
    },
    {
      heading: "Felloh Admin",
      feature: COMPONENT_LIBRARY,
    },
    {
      icon: "react",
      text: "Component Library",
      link: "/components",
      feature: COMPONENT_LIBRARY,
      panel: true,
      subPanel: [
        {
          icon: "layers",
          text: "Ui Elements",
          active: false,
          subMenu: [
            {
              text: "Components",
              link: "/components",
            },
            {
              text: "Alerts",
              link: "/components/alerts",
            },
            {
              text: "Accordions",
              link: "/components/accordions",
            },
            {
              text: "Badges",
              link: "/components/badges",
            },
            {
              text: "Buttons",
              link: "/components/buttons",
            },
            {
              text: "Button Group",
              link: "/components/button-group",
            },
            {
              text: "Breadcrumbs",
              link: "/components/breadcrumbs",
            },
            {
              text: "Cards",
              link: "/components/cards",
            },
            {
              text: "Carousel",
              link: "/components/carousel",
            },
            {
              text: "Dropdowns",
              link: "/components/dropdowns",
            },
            {
              text: "Modals",
              link: "/components/modals",
            },
            {
              text: "Pagination",
              link: "/components/pagination",
            },
            {
              text: "Popovers",
              link: "/components/popovers",
            },
            {
              text: "Progress",
              link: "/components/progress",
            },
            {
              text: "Spinner",
              link: "/components/spinner",
            },
            {
              text: "Tabs",
              link: "/components/tabs",
            },
            {
              text: "Toast",
              link: "/components/toast",
            },
            {
              text: "Tooltips",
              link: "/components/tooltips",
            },
            {
              text: "Utilities",
              active: false,
              subMenu: [
                {
                  text: "Borders",
                  link: "/components/util-border",
                },
                {
                  text: "Colors",
                  link: "/components/util-colors",
                },
                {
                  text: "Display",
                  link: "/components/util-display",
                },
                {
                  text: "Embeded",
                  link: "/components/util-embeded",
                },
                {
                  text: "Flex",
                  link: "/components/util-flex",
                },
                {
                  text: "Text",
                  link: "/components/util-text",
                },
                {
                  text: "Sizing",
                  link: "/components/util-sizing",
                },
                {
                  text: "Spacing",
                  link: "/components/util-spacing",
                },
                {
                  text: "Others",
                  link: "/components/util-others",
                },
              ],
            },
          ],
        },
        {
          icon: "dot-box",
          text: "Crafted Icons",
          active: false,
          subMenu: [
            {
              text: "SVG Icon-Exclusive",
              link: "/svg-icons",
            },
            {
              text: "Nioicon - HandCrafted",
              link: "/nioicon",
            },
          ],
        },
        {
          icon: "table-view",
          text: "Tables",
          active: false,
          subMenu: [
            {
              text: "Basic Tables",
              link: "/table-basic",
            },
            {
              text: "Special Tables",
              link: "/table-special",
            },
          ],
        },
        {
          icon: "card-view",
          text: "Forms",
          active: false,
          subMenu: [
            {
              text: "Form Elements",
              link: "/components/form-elements",
            },
            {
              text: "Form Layouts",
              link: "/components/form-layouts",
            },
            {
              text: "Form Validation",
              link: "/components/form-validation",
            },
            {
              text: "Date Time Picker",
              link: "/components/datetime-picker",
            },
          ],
        },
        {
          icon: "pie",
          text: "Charts",
          active: false,
          subMenu: [
            {
              text: "Chart Js",
              link: "/charts/chartjs",
            },
          ],
        },
      ],
    },
    {
      icon: "building",
      text: "Organisations",
      link: "/organisations",
      feature: ORGANISATIONS,
      active: false,
      enabled: HasRole(account, ['organisation:management']) && HasFeature(features,['organisation']),
    },
  ]
}

export default FetchMenu;
