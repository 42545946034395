import React, {useState} from 'react';
import {Button, Icon} from "src/components/Component";
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import {Auth} from "aws-amplify";
import {useSelector} from "react-redux";

const ActivateUser = ({ user }) => {
  const history = useHistory();
  const { organisation } = useSelector((state) => state);

  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);

  const toggleForm = () => setModalForm(!modalForm);

  const activateUser = async () => {
    setModalFormProcessing(true);
    const authUser = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.user['organisation:users:activate'](organisation.organisation, user.id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push(`/users/${user.id}/roles`);
  };

  return (
    <React.Fragment>
      <Button
        id="create-payout-button"
        size="sm"
        color="white"
        outline
        className="btn-primary float-right"
        onClick={toggleForm}
      >
        <span>Activate User</span>
      </Button>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-primary text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Activate User
        </ModalHeader>
        <ModalBody>
          <p>Are you sure that you would like to activate this user?</p>
          <p>An email will be sent to <strong>{user.email}</strong> with a link to create their new password.</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="primary" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                activateUser();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ActivateUser;
