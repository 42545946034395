import React, {useState} from 'react';
import { Button, Icon } from "src/components/Component";
import {Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap"
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import PiiObfuscator from "src/components/pii-obfuscator";
import Select from 'react-select';

const FieldEdit = ({
  data,
  name,
  description,
  bookingID,
  fieldName,
  triggerReload,
  inputSelectOptions,
  inputSelectValue,
  displayValue,
  fieldType,
  itemWidth,
}) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const [popoverOpen, togglePopover] = useState(false);

  const [ formValues, setFormValues ] = useState({
    data: data,
  });

  const toggleForm = () => setModalForm(!modalForm);

  const updateFormValue = (e) => {
    setFormValues({
      data: e.target.value,
    });
  };

  const updateFieldRequest = async() => {
    setModalFormProcessing(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['booking:get'](bookingID),
      {
        method: 'post',
        data: {
          [fieldName]: formValues.data
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalFormProcessing(false);
    toggleForm();
    triggerReload();
  };

  return (
    <React.Fragment>
      <Col xl={ itemWidth ? itemWidth : 6 }>
        <div>
          <div className="pr-2 pt-1 form-banner font-normal">
            { name }
            <Icon
              name="info"
              className="text-primary pl-1 pointer-event"
              style={{ cursor: 'pointer', display: popoverOpen ? 'none' : 'inline' }}
              onClick={() => togglePopover(popoverOpen === false)}
            />
            <div className="text-secondary" style={{ display: popoverOpen ? 'block' : 'none' }}>
              <small>{ description }</small>
            </div>
          </div>
          <div className="pr-2 pt-1">
            <div className="float-right">
              <Button
                id="create-payout-button"
                size="sm"
                color="white"
                outline
                className="btn-green text-white ml-2"
                onClick={toggleForm}
                style={{ height: '25px', borderRadius: '20px' }}
              >
                <Icon
                  name="edit"
                  className="text-white" />
              </Button>
            </div>
            <span className="data-value">
              <PiiObfuscator className="data-value" type="customer_name">{ displayValue ? displayValue : data }</PiiObfuscator>
            </span>
          </div>
        </div>
      </Col>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-secondary text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Update { name }
        </ModalHeader>
        <ModalBody>
          <p>Please enter the new { name.toLowerCase() }</p>
          <FormGroup>
            <div className="form-control-wrap">
              {typeof inputSelectOptions === 'undefined' ? (
                <input
                  type={ fieldType ? fieldType : 'text' }
                  id="name"
                  name="name"
                  value={formValues.data}
                  onChange={updateFormValue}
                  className={`form-control form-control-xl`}
                />)
                :
                (<Select name='package_type'
                  onChange={(val) => setFormValues({ data: val.value })}
                  defaultValue={inputSelectValue}
                  className="form-control form-control-xl p-0 border-0 indicator-hidden"
                  options={inputSelectOptions}
                  classNamePrefix="react-select"

                />)}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" type="submit" disabled={modalFormProcessing || formValues.data === ''} onClick={
              (ev) => {
                ev.preventDefault();
                updateFieldRequest();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default FieldEdit;
