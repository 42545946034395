import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Axios from 'axios';
import {
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { Button, Icon } from '../../../../../../components/Component';
import API_ROUTES from '../../../../../../config/api';

const Options = ({ organisation, item, reload }) => {
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const toggleConfirmation = () => setModalConfirmation(!modalConfirmation);

  /**
   * Change organisation payment provider active state
   */
  const changePaymentProviderActiveState = async(state) => {
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.payment['organisation:provider:active:change'](organisation.id, item.id, state),
      {
        method: 'put',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    reload();
  };

  /**
   * Delete an organisation payment provider
   */
  const deletePaymentProvider = async() => {
    const user = await Auth.currentAuthenticatedUser();
    setDisabled(true);

    await Axios(
      API_ROUTES.payment['organisation:provider:delete'](organisation.id, item.id),
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalConfirmation(false);

    reload();
  };

  return (
    <React.Fragment>
      <DropdownMenu end>
        <ul className="link-list-plain">
          <li>
            <Link to={`/organisations/${organisation.id}/payment-config/${item.id}/edit`}>Edit Provider</Link>
          </li>
          { item.payment_provider.is_active && !item.is_active && (
          <li>
            <DropdownItem
              tag="a"
              href="#view"
              onClick={(ev) => {
                ev.preventDefault();
                changePaymentProviderActiveState('true');
              }}
            >
              Activate Provider
            </DropdownItem>
          </li>
          )}
          { item.payment_provider.is_active && item.is_active && (
          <li>
            <DropdownItem
              tag="a"
              href="#view"
              onClick={(ev) => {
                ev.preventDefault();
                changePaymentProviderActiveState('false');
              }}
            >
              Deactivate Provider
            </DropdownItem>
          </li>
          )}
          <li>
            <DropdownItem
              tag="a"
              href="#view"
              onClick={(ev) => {
                ev.preventDefault();
                toggleConfirmation();
              }}
            >
              Delete Provider
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>

      <Modal isOpen={modalConfirmation} toggle={toggleConfirmation}>
        <ModalHeader
          toggle={toggleConfirmation}
          className="bg-danger text-white"
          close={
            <button className="close" onClick={toggleConfirmation}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Delete Payment Provider
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you would like to delete the <strong>"{ item.name }"</strong> payment provider?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" type="submit" disabled={disabled} onClick={
              (ev) => {
                ev.preventDefault();
                deletePaymentProvider(item);
              }
            } size="lg">
              Continue
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default Options;
