const LOCALE = 'en-GB';
const DEFAULT_CURRENCY = 'GBP';

class IntlCurrencyFormat extends Intl.NumberFormat {
  getCurrencySymbol() {
    return this.formatToParts(0)[0]?.value;
  }
}

const CurrencyFormat = (currencyEnums={}, currency=null) => {
  let initCurrency = DEFAULT_CURRENCY;
  if (currency !== null) {
    const currencyMeta = currencyEnums[currency];
    initCurrency = currencyMeta?.major_unit;
  }

  return new IntlCurrencyFormat(LOCALE, {
    style: 'currency',
    currency: initCurrency,
    currencyDisplay: 'symbol', // 'narrowSymbol' preferred, but not used due to old Safari compatibility
  });
}

export default CurrencyFormat;
