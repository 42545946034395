import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, Link, useParams, useHistory } from 'react-router-dom';
import { Card, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

import Content from 'src/layout/content/Content';

import HasRole from '../../../../utils/has-role';
import HasFeature from "../../../../utils/has-feature";

import BookingInformation from './views/booking-information';
import Elements from './views/elements';
import Transactions from './views/transactions';
import Payouts from './views/payouts';

import { Tooltip } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Button, Icon } from "src/components/Component";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from "src/config/api";
import CompletedTransactionCount from './utils/transaction-completed-count';
import AvailablePayoutValue from "./utils/available-payout-value";
import AllTransactionCount from "./utils/transaction-all-count";
import AllPaymentLinkCount from './utils/payment-link-all-count';
import PaidOutCount from "./utils/paid-out-count";
import ElementsCount from "./utils/elements-count";
import CreditNotes from "./views/credit-notes";
import SupplierPayments from "./views/supplier-payments";

const Booking = () => {
  const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
  const [createPayoutTooltipOpen, setCreatePayoutTooltipOpen] = useState(false);
  const [deleteModalForm, setDeleteModalForm] = useState(false);
  const [deleteModalFormProcessing, setDeleteModalFormProcessing] = useState(false);
  const [supplierTransactionsCount, setSupplierTransactionsCount] = useState(0);

  const { account } = useSelector((state) => state.account);
  const { organisation } = useSelector((state) => state);

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['booking:get'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    // Update supplier transaction count
    let supplierTXCount = 0;
    if (result.data?.data?.bank_transactions && result.data?.data?.bank_transactions.length >= 1) {
      result.data.data.bank_transactions.forEach((item) => {
        if (item.is_debit) {
          supplierTXCount += 1;
        }
      });
      setSupplierTransactionsCount(supplierTXCount);
    }

    setData({
      ...data,
      ...result.data.data
    });
    setLoading(false);

  }, [organisation.organisation], id);

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  const completedTransactionCount = CompletedTransactionCount(data);
  const availableToBePaidOut = AvailablePayoutValue(data);
  const allTransactionCount = AllTransactionCount(data);
  const allPaymentLinkCount = AllPaymentLinkCount(data);
  const paidOutCount = PaidOutCount(data);
  const elementsCount = ElementsCount(data);

  const createPayout = () => {
    if (availableToBePaidOut < 1 || loading === true) return null;

    history.push({
      pathname: '/payout/create',
      state: { data }
    });
  }

  const toggleDeleteForm = () => setDeleteModalForm(!deleteModalForm);
  const deleteBooking = async () => {
    if (completedTransactionCount >= 1 || deleteModalFormProcessing === true) return null;

    setDeleteModalFormProcessing(true);
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['booking:delete'](id),
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push({
      pathname: '/bookings',
    });
  };

  return (
    <React.Fragment>
      <Content>

        <Block>
          <Card className="border my-4">
            <BlockHead className="px-6 pt-4 pb-4 bg-gray-50">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className="page-title text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal">
                    <strong>Booking |</strong> {data.booking_reference ? data.booking_reference : id}
                  </BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-expand-content" style={{ display: "block" }}>
                    <ul className="nk-block-tools g-3">
                      {HasRole(account, ['booking-and-payment:create:payment']) && HasFeature(organisation.features, ['booking-and-payment']) && (
                        <li>
                          <Link to={`/payment-link/create?booking=${id}`}>
                            <Button
                              className="bg-violet-800 text-white border-none disabled:bg-gray-50 disabler:border-grey-50 disabled:text-slate-800"
                            >
                              <Icon name="plus" />
                              <span>Create Payment Link</span>
                            </Button>
                          </Link>
                        </li>
                      )}
                      {HasRole(account, ['booking-and-payment:delete:booking']) && (
                        <li>
                          <Button
                            id="delete-booking-button"
                            className="bg-red-500 text-white border-none disabled:bg-red-50 disabler:border-red-50 disabled:text-slate-800"
                            onClick={toggleDeleteForm}
                            disabled={completedTransactionCount >= 1 || loading === true}
                          >
                            <Icon name="trash" />
                            <span>Delete</span>
                          </Button>
                          {(completedTransactionCount >= 1 || loading === true) && (
                            <Tooltip placement="bottom" isOpen={deleteTooltipOpen} target="delete-booking-button" toggle={() => setDeleteTooltipOpen(!deleteTooltipOpen)}>
                              Payments have already been made on booking
                            </Tooltip>
                          )}
                        </li>
                      )}
                      {HasRole(account, ['payouts:create']) && HasFeature(organisation.features, ['supplier-payouts']) && (
                        <li>
                          <Button
                            id="create-payout-button"
                            className="bg-violet-800 text-white border-none disabled:bg-gray-50 disabler:border-grey-50 disabled:text-slate-800"
                            onClick={() => createPayout()}
                            disabled={availableToBePaidOut < 1 || loading === true}
                          >
                            <Icon name="wallet-out" />
                            <span>Create Payout</span>
                          </Button>

                          {(availableToBePaidOut < 1 || loading === true) && (
                            <Tooltip placement="bottom" isOpen={createPayoutTooltipOpen} target="create-payout-button" toggle={() => setCreatePayoutTooltipOpen(!createPayoutTooltipOpen)}>
                              Total value of transactions has been paid out
                            </Tooltip>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card pt-3">
              <li
                className={`nav-item ${window.location.pathname === `/booking/${id}`
                    ? "active current-page"
                    : ""
                  } `}
              >
                <Link
                  to={`/booking/${id}`}
                  className={`nav-link
                    ${window.location.pathname === `/booking/${id}` ? "active" : ""}
                  `}
                >
                  <Icon name="info-i" />
                  <span>Booking Information</span>
                </Link>
              </li>
              {data.components && data.components.length >= 1 && (
                <li
                  className={`nav-item ${window.location.pathname === `/booking/${id}/elements` ? "active" : ""
                    }`}
                >
                  <Link
                    to={`/booking/${id}/elements`}
                    className={`nav-link ${window.location.pathname === `/booking/${id}/elements` ? "active" : ""
                      }`}
                  >
                    <Icon name="view-row-fill" />
                    <span>Elements<small className="text-primary">{loading === false ? ` (${elementsCount})` : ''}</small></span>
                  </Link>
                </li>
              )}
              <li
                className={`nav-item ${window.location.pathname === `/booking/${id}/transactions` ? "active" : ""
                  }`}
              >
                <Link
                  to={`/booking/${id}/transactions`}
                  className={`nav-link ${window.location.pathname === `/booking/${id}/transactions` ? "active" : ""
                    }`}
                >
                  <Icon name="wallet-in" />
                  <span>Payments <small className="text-primary">{loading === false ? ` (${allPaymentLinkCount}/${allTransactionCount})` : ''}</small></span>
                </Link>
              </li>
              {data.applied_credit_notes && data.applied_credit_notes.length >= 1 && (
                <li
                  className={`nav-item ${window.location.pathname === `/booking/${id}/credit-notes` ? "active" : ""
                    }`}
                >
                  <Link
                    to={`/booking/${id}/credit-notes`}
                    className={`nav-link ${window.location.pathname === `/booking/${id}/credit-notes` ? "active" : ""
                      }`}
                  >
                    <Icon name="blank" />
                    <span>Applied Credit Notes<small className="text-primary">{loading === false ? ` (${data.applied_credit_notes.length})` : ''}</small></span>
                  </Link>
                </li>
              )}
              <li
                className={`nav-item ${window.location.pathname === `/booking/${id}/payouts` ? "active" : ""
                  }`}
              >
                {HasRole(account, ['payouts:view']) && HasFeature(organisation.features, ['payouts']) && (
                  <Link
                    to={`/booking/${id}/payouts`}
                    className={`nav-link ${window.location.pathname === `/booking/${id}/payouts` ? "active" : ""
                      }`}
                  >
                    <Icon name="wallet-out" />
                    <span>Payout Batches<small className="text-primary pb-2">{loading === false ? ` (${paidOutCount})` : ''}</small></span>
                  </Link>
                )}
              </li>
              <li
                className={`nav-item ${window.location.pathname === `/booking/${id}/supplier-payments` ? "active" : ""
                  }`}
              >
                {HasFeature(organisation.features, ['aisp']) && (
                  <Link
                    to={`/booking/${id}/supplier-payments`}
                    className={`nav-link ${window.location.pathname === `/booking/${id}/supplier-payments` ? "active" : ""
                      }`}
                  >
                    <Icon name="wallet-saving" />
                    <span>Supplier Payments<small className="text-primary pb-2">{loading === false ? ` (${supplierTransactionsCount})` : ''}</small></span>
                  </Link>
                )}
              </li>
            </ul>

            <div className="card-inner card-inner-lg pt-4">
              <Switch>
                <Route
                  exact
                  path={`/booking/:id/`}
                  render={() => <BookingInformation data={data} loading={loading} triggerReload={getData} />}
                />
                <Route
                  exact
                  path={`/booking/:id/elements`}
                  render={() => <Elements data={data} loading={loading} />}
                />
                <Route
                  exact
                  path={`/booking/:id/transactions`}
                  render={() => <Transactions data={data} loading={loading} triggerReload={getData} />}
                />
                <Route
                  exact
                  path={`/booking/:id/credit-notes`}
                  render={() => <CreditNotes data={data} loading={loading} />}
                />
                {HasRole(account, ['payouts:view']) && HasFeature(organisation.features, ['payouts']) && (
                  <Route
                    exact
                    path={`/booking/:id/payouts`}
                    render={() => <Payouts data={data} loading={loading} />}
                  />
                )}
                {HasFeature(organisation.features, ['aisp']) && (
                  <Route
                    exact
                    path={`/booking/${id}/supplier-payments`}
                    render={() => <SupplierPayments data={data} loading={loading} triggerReload={getData} />}
                  />
                )}
              </Switch>
            </div>
          </Card>
        </Block>
      </Content>

      <Modal isOpen={deleteModalForm} toggle={toggleDeleteForm}>
        <ModalHeader
          toggle={toggleDeleteForm}
          className="bg-red-500 text-white"
          close={
            <button className="close" onClick={toggleDeleteForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Delete Booking
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you would like to delete this Booking?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-500 hover:bg-red-700" color="danger" type="submit" disabled={deleteModalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                deleteBooking();
              }
            } size="lg">
              {deleteModalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Booking;
