// Set keys to exclude from persistent state
const EXCLUDED_KEYS = ['application'];

/**
 * Save State to session storage
 * @param state
 */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors
  }
};

/**
 * Load state from session storage
 * @return {*}
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const loadedState = JSON.parse(serializedState);

    // Remove excluded keys from loaded state
    EXCLUDED_KEYS.forEach(key => {
      if (key in loadedState) {
        delete(loadedState[key]);
      }
    });

    return loadedState;
  } catch (err) {
    return undefined;
  }
};
