const TransactionFromTransformer = (paymentLink = null) => {
  if (paymentLink) {
    return {
      icon: 'ni-link-alt',
      label: 'Payment Link',
    }
  }

  return {
    icon: 'ni-server',
    label: 'Ecommerce'
  };
}

export default TransactionFromTransformer;
