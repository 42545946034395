const LedgerSourceTransformer = (source) => {
  if (source === 'disbursal') {
    return {
      icon: 'ni-list',
      label: 'Disbursal',
    };
  }

  if (source === 'settled transaction') {
    return {
      icon: 'ni-inbox',
      label: 'Settled Transaction',
    }
  }

  if (source === 'amendment') {
    return {
      icon: 'ni-pen2',
      label: 'Amendment',
    }
  }

  if (source === 'adjustment') {
    return {
      icon: 'ni-notice',
      label: 'Adjustment',
    }
  }

  return {
    icon: 'ni-question',
    label: source
  };
}

export default LedgerSourceTransformer;
