const HasRole = (account, roles = [], onlyOneOf = false) => {
  let roleMatchingCount = 0;
  for (const role of roles) {
    if (account.roles.indexOf(role) !== -1) {
      roleMatchingCount += 1;
    }
  }

  if (onlyOneOf === true) {
    return roleMatchingCount >= 1;
  }

  return roles.length === roleMatchingCount;
}

export default HasRole;
