/**
 * Calculate the total count of pay outs
 * @param data
 * @returns {number}
 * @constructor
 */
const PaidOutCount = (data) => {
  const transactions = data.transactions;

  if (typeof transactions === 'undefined' || transactions.length === 0) return 0;

  let count = 0;

  transactions.forEach((transaction) => {
    if (transaction.batch[0].completed_at) {
      count += 1;
    }
  });

  return count;
};

export default PaidOutCount;
