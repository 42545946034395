import React from 'react';

import { useLocation, Link } from 'react-router-dom'

function GroupMenu({ items, title }) {
  const { pathname } = useLocation();

  const baseClass = 'text-lg hs-tab-active:font-extrabold hs-tab-active:border-purple-900 hs-tab-active:text-purple-800 pt-2 pb-[10px] mr-4 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-purple-800 hover:text-purple-800 disabled:opacity-50 disabled:pointer-events-none';
  const activeClass = `${baseClass} active border-b-1`;

  let count = 0;
  for (const item of items) {
    if (item.check) {
      count += 1;
    }
  }

  let activeTitle = null;
  for (const item of items) {
    if (item.check && pathname === item.path) {
      activeTitle = item;
    }
  }

  return (
    <div className="my-3 pt-1 border-b-2 border-gray-200 position-relative">

      <h2
        className='text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal'>
          { activeTitle?.icon && <activeTitle.icon className="w-7 h-7 text-purple-900 inline-block -mt-2 mr-1.5 " />}
          { activeTitle?.name || title }
      </h2>

      <nav id="page-tabs-bar" className={`ml-0.5 block lg:flex lg:space-x-4 -mb-0.5 ${items.length === 0 || count < 2 ? 'pb-2' : ''} `} aria-label="Tabs" role="tablist">

        { count > 1 && items.map((item, index) => {
          if (item.check) {
            return (
              <Link
                to={item.path}
                key={index}
                className="w-100 lg:w-auto block lg:inline-grid"
              >
                <button
                  type="button"
                  className={pathname === item.path ? activeClass : baseClass}
                  id={`basic-tabs-item-${index + 1}`}
                  data-hs-tab={`#basic-tabs-item-${index + 1}`}
                  aria-controls={`basic-tabs-item-${index + 1}`}
                  role="tab"
                >
                  {item.name}
                </button>
              </Link>
            )
          }

          return <React.Fragment key={index} />
        })}

      </nav>
    </div>
  );
}
export default GroupMenu;
