import { all } from 'redux-saga/effects';

import Account from './account';
import Application from './application';
import Enums from './enums';
import Organisation from './organisation';

/**
 * Saga Reducer Hooks
 */
export default function* sagas() {
  yield all([
    Account(),
    Application(),
    Enums(),
    Organisation(),
  ]);
}
