import React, {useState, useEffect, useCallback} from 'react';

import {
  Button, Col, Row,
  Icon,
} from 'src/components/Component';
import { Link } from 'react-router-dom';
import DataTable from 'src/components/data-table';
import {useSelector} from "react-redux";
import API_ROUTES from "src/config/api";
import LedgerSourceTransformer from "src/transformer/ledger-source";
import {Auth} from "aws-amplify";
import axios from "axios";
import {Spinner} from "reactstrap";
import Axios from "axios";
import StatBox from "../../../dashboard/pages/main/components/statistics/stat-box";
import CurrencyFormat from 'src/transformer/currency-format';
import TransactionFlow from "./components/transaction-flow";
import {convertPenniesToDecimals} from "src/utils/money";
import HasFeature from "src/utils/has-feature";

const fieldFormatter = (item) => {
  if (item?.transaction?.id) {
    return <td>
      <Link
        className="text-violet-800 underline"
        to={`/transaction/${item.transaction.id}`}
      >
        { item.transaction.id }
      </Link>
    </td>;
  }

  if (item?.disbursal?.id) {
    return <td>
      <Link 
        className="text-violet-800 underline"
        to={`/batch/?disbursement_id=${item.disbursal.id}`}
      >
        {item.disbursal.id}
      </Link>
    </td>;
  }

  if (item?.amendment?.id) {
    return <td>
      {item.amendment.id}
    </td>;
  }

  if (item?.adjustment?.id) {
    return <td>
      {item.adjustment.id}
    </td>;
  }

  return <td />;
}

const RowFormatter = ({item}) => {
  const source = LedgerSourceTransformer(item.source);

  return (
    <React.Fragment key={item.id}>
      <tr className="tb-tnx-item">
        <td className="text-l" rowSpan={item.source === 'amendment' || item.source === 'adjustment' ? 2 : 1}>
          <Icon name={`ni text-primary icon ${source.icon} pr-1`} />
          <span className="date">{ source.label }</span>
        </td>
        { fieldFormatter(item) }
        <td className="border-left text-center" rowSpan={item.source === 'amendment' || item.source === 'adjustment' ? 2 : 1}>
          <span className="date">{ item.type === 'credit' ? CurrencyFormat().format( convertPenniesToDecimals(item.amount) ) : '£0' }</span>
        </td>
        <td className="border-left text-center" rowSpan={item.source === 'amendment' || item.source === 'adjustment' ? 2 : 1}>
          <span className="date">{ item.type === 'debit' ? CurrencyFormat().format( convertPenniesToDecimals(item.amount) ) : '£0' }</span>
        </td>
      </tr>
      {
        item.source === 'amendment' && (
          <tr className="tb-tnx-item">
            <td className="border-top-0 pl-2 pt-0 text-danger">
              <span className="date">{ item.amendment.description }</span>
            </td>
          </tr>
        )
      }
      {
        item.source === 'adjustment' && (
          <tr className="tb-tnx-item">
            <td className="border-top-0 pl-2 pt-0 text-danger">
              <span className="date">{ item.adjustment.description }</span>
            </td>
          </tr>
        )
      }
    </React.Fragment>
  );
}

const PageOptions = ({requestParams}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.ledger['ledger:get'](),
      { ...requestParams, type: 'csv' },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFetchingCSV(false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button color="light" disabled={fetchingCSV} outline className="btn-white" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Type</span>
    </th>
    <th className='text-left'>
      <span>Reference</span>
    </th>
    <th className="border-left text-center">
      <span>Credit</span>
    </th>
    <th className="border-left text-center">
      <span>Debit</span>
    </th>
  </tr>
);

const TopBlock = () => {
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [ledgerData, setLedgerData] = useState({});

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.ledger['statistics:get'](),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        data: {
          organisation: organisation.organisation
        }
      }
    );

    setLedgerData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  const hasRefunds = HasFeature(organisation.features, ['refunds']);

  return (
    <div>

      <Row className="mt-4 mb-2">
        <Col md="12" xl={ hasRefunds === true ? 3 : 4 }>
          <StatBox
            title={ <React.Fragment>Funds <span className="fw-normal">Settled</span></React.Fragment> }
            period="This week"
            figure={CurrencyFormat().format( convertPenniesToDecimals(ledgerData?.settled?.week?.amount) )}
            loading={loading}
            popoverTitle="Funds Settled"
            popoverDescription="Transactions that have been settled by the merchant acquirer"
          />
        </Col>
        <Col md="12" xl={ hasRefunds === true ? 3 : 4 }>
          <StatBox
            title={ <React.Fragment>Funds <span className="fw-normal">Disbursed</span></React.Fragment> }
            period="This week"
            figure={CurrencyFormat().format( convertPenniesToDecimals(ledgerData?.disbursed?.week?.amount) )}
            loading={loading}
            popoverTitle="Funds Disbursed"
            popoverDescription="Funds that have been disbursed to your bank account"
          />
        </Col>

        <Col md="12" xl={ hasRefunds === true ? 3 : 4 }>
          <StatBox
            title={ <React.Fragment>Ledger <span  className="fw-normal">Balance</span></React.Fragment> }
            period="Current"
            figure={CurrencyFormat().format( convertPenniesToDecimals(ledgerData?.balance) )}
            loading={loading}
            popoverTitle="Ledger Balance"
            popoverDescription="This is the balance of your virtual ledger. This is made up transactions in, disbursements out and any adjustments."
          />
        </Col>

        { hasRefunds === true && (
          <Col md="12" xl={ hasRefunds === true ? 3 : 4 }>
            <StatBox
              title={ <React.Fragment>Pending <span  className="fw-normal">Refunds</span></React.Fragment> }
              period="Current"
              figure={CurrencyFormat().format( convertPenniesToDecimals(ledgerData?.refunds?.pending_amount) )}
              loading={loading}
              popoverTitle="Pending Refunds"
              popoverDescription="Total value of refunds that are currently awaiting approval."
            />
          </Col>
        )}


      </Row>

      <TransactionFlow loading={loading} ledgerData={ledgerData} />

    </div>
  );
};

const Bookings = () => {
  const { organisation } = useSelector((state) => state);
  const [ endpoint, setEndpoint ] = useState(API_ROUTES.ledger['ledger:get']());
  const [ reloadTrigger, setReloadTrigger ] = useState(0);

  useEffect(() => {
    setEndpoint(API_ROUTES.ledger['ledger:get']());
    setReloadTrigger(reloadTrigger + 1)
  }, [organisation.organisation]);

  return (
    <DataTable
      title="Ledger Breakdown"
      TopBlock={TopBlock}
      columnCount={9}
      itemTitle="ledger entries"
      dateGroupingField="created_at"
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={endpoint}
      reloadTrigger={reloadTrigger}
      childOrganisationsOption={false}
    />
  );
};

export default Bookings;
