import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import moment from "moment";
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import { Auth } from "aws-amplify";
import axios from "axios";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import PaymentLinkStatusTransformer from 'src/transformer/payment-link-status';
import ParseName from 'parse-full-name';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { toast, ToastContainer } from 'react-toastify';

const RowFormatter = ({item, childFilterState, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount));
  let transactionId = null;
  let status = PaymentLinkStatusTransformer(item.status);
  const history = useHistory();

  const rowClick = (event, url) => {
  if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'INPUT' && item.status !== 'expired') {
      history.push(url)
    }
  }

  item.transactions.forEach((transaction) => {
    if (transaction.completed_at && transaction.status === 'COMPLETE') {
      transactionId = transaction.id;
    }
  })

  return (
    <tr
      key={item.id}
      className={`tb-tnx-item ${item.status !== 'expired' ? 'cursor-pointer hover:bg-gray-50' : ''}`}
      onClick={(event) => rowClick(event, item.status === 'paid' ? `/transaction/${transactionId}` : `/payment-links/${item.id}`)}
    >
      <td className='border-l text-left'>
        <PiiObfuscator className="title" type="customer_name">
          <strong>{ParseName.parseFullName(item.customer_name).first} {ParseName.parseFullName(item.customer_name).last}</strong>
          <br />
          {item.email.toLowerCase()}
        </PiiObfuscator>
      </td>
      <td className='text-left'>
        <span className="title">
          {item.booking && (
            <Link to={`/booking/${item.booking.id}`} className="text-violet-800 underline">{item.booking.booking_reference}</Link>
          )}
          {!item.booking && (
            <Link to={`/payment-links/${item.id}/assign`}>
              <Button color="dark" className="bg-violet-800 text-white border-none">Assign to Booking</Button>
            </Link>
          )}
        </span>
      </td>
      {childFilterState === true && (
        <td className="text-center border-left">
          <PiiObfuscator className="title" type="organisation">{item.organisation.name}</PiiObfuscator>
        </td>
      )}
      <td className='text-center border-l'>
        <span className="date">{total}</span>
      </td>
      <td className="text-center border-l w-100px">
        <Icon name={`ni text-primary icon ${item.methods.open_banking ? 'ni-check' : 'ni-cross'} pr-1`} />
      </td>
      <td className="text-center w-100px">
        <Icon name={`ni text-primary icon ${item.methods.card ? 'ni-check' : 'ni-cross'} pr-1`} />
      </td>
      <td className="text-center border-l">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className='text-center'>
        <span className="title">{moment(item.expires_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="text-center w-120px border-l px-1">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-light text-white ${status.color}`}>
          <Icon name={`ni text-white icon ${status.icon}`} />
          <span className="date">{status.label}</span>
        </span>
      </td>
    </tr>
  );
}

const PageOptions = (requestParams, triggerReload, loading) => {
  const { account } = useSelector((state) => state.account);
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    await axios.post(
      API_ROUTES.agent['csv:generate']('payment-link'),
      { ...requestParams },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    toast.info(`Your export will be ready shortly. A link to download it will be sent to ${account.email}.`, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

    await new Promise(resolve => setTimeout(resolve, 10000));

    setFetchingCSV(false);
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
      <li>
        <Button disabled={fetchingCSV} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = ({childFilterState}) => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Customer</span>
    </th>
    <th className='text-left'>
      <span>Booking Ref</span>
    </th>
    {childFilterState === true && (
      <th className="text-center border-left">
        <span>Organisation</span>
      </th>
    )}
    <th className='text-center border-l'>
      <span>Amount</span>
    </th>


    <th className="border-l text-center">
      <span>Open Banking</span>
    </th>
    <th className="text-center">
      <span>Card Payments</span>
    </th>
    <th className='text-center border-l'>
      <span>Created</span>
    </th>
    <th className='text-center'>
      <span>Expires</span>
    </th>
    <th className="text-center border-l border-r">
      <span>Status</span>
    </th>
  </tr>
);

const PaymentLinks = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const pathFilters = {};
  let pageTitle = 'Payment Links';

  if (urlParams.get('has_booking_reference')) {
    pathFilters.filter_booking_exists = urlParams.get('has_booking_reference') === '1' ? true : false;
  }

  if (urlParams.get('status')) {
    pathFilters.filter_status = urlParams.get('status');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <DataTable
        title={pageTitle}
        largeHeading={true}
        columnCount={10}
        TableHeaders={TableHeaders}
        RowFormatter={RowFormatter}
        pageOptions={PageOptions}
        childOrganisationsOption={true}
        dateFilterOption={true}
        dateFilterLabel="Created Date"
        endpoint={API_ROUTES.agent['payment-links:get']()}
        searchPlaceholder="Booking Ref"
        pathFilters={pathFilters}
        noHeader={true}
        totalFilterOption={true}
        pathFiltersTranslations={{
          filter_booking_exists: {
            name: 'Has Booking',
            filterName: 'Unassigned Only',
            selectedValue: false,
            removeIfNotSelected: true,
          },
        }}
      />
    </React.Fragment>
  );
}

export default PaymentLinks;
