import React, {useCallback, useEffect, useState} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import { Card } from 'reactstrap';

import Content from 'src/layout/content/Content';

import Information from './information';
import {Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Button, Icon} from "src/components/Component";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../config/api";
import {useSelector} from "react-redux";

const Payment = () => {
  const { id } = useParams();
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(true);

  const { organisation } = useSelector((state) => state);

  const getPayment = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent["credit-note:get"](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);
    setLoading(false);
  }, [organisation.organisation]);

  useEffect(() => {
    getPayment();
  }, [organisation.organisation]);

  return (
      <React.Fragment>
        <Content>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className="text-primary"><strong>Credit Note |</strong> { data.short_id ? data.short_id : id }</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/credit-notes'}>
                        <Button color="light" outline className="btn-white" id="button__back">
                          <Icon name="back-ios" />
                          <span>Back</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Card className="card-bordered">
              <Switch>
                <Route
                  exact
                  path={`/customers/credit-note/:id/`}
                  render={() => <Information data={data} loading={loading} />}
                />
              </Switch>
            </Card>
          </Block>
        </Content>
      </React.Fragment>
  );
};

export default Payment;
