import React, {useState} from 'react';
import {Button, Icon} from "src/components/Component";
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import {Auth} from "aws-amplify";

const CompleteButton = ({ triggerReload, item }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);

  const toggleForm = () => setModalForm(!modalForm);

  const createRefund = async () => {
    setModalFormProcessing(true);
    const authUser = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['payment:complete'](item.id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
    toggleForm();
    setModalFormProcessing(false);
  };

  return (
    <React.Fragment>
      <div className="w-100 mt-1">
        <div className="w-100">
          <Button className="btn-green text-white w-100 text-center"
                  onClick={toggleForm}
                  style={{ display: 'block' }}>Complete Transaction</Button>
        </div>
      </div>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-green text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Complete Transaction
        </ModalHeader>
        <ModalBody>
          <p>Are you sure that you would like to complete the transaction and charge the customer?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="btn-green text-white" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                createRefund();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default CompleteButton;
