import { useSelector } from "react-redux";
import { faker } from '@faker-js/faker';

function ucwords (str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
  });
}

const selectType = (piiObfuscated, type, children) => {
  if (piiObfuscated !== true) {
    if (type === 'customer_name' && typeof children === 'string') {
      return ucwords(children);
    }

    return null;
  }

  switch(type) {

    case 'account_number':
      return faker.finance.account();
    case 'company':
      return faker.company.name();
    case 'customer_name':
      return `${faker.name.firstName()} ${faker.name.lastName()}`;
    case 'email':
      return faker.internet.email().toLowerCase();
    case 'iban':
      return faker.finance.iban();
    case 'bank_description':
      return faker.finance.transactionDescription().split('using card')[0];
    case 'sort_code':
      return faker.finance.account(6);
    default:
      return 'PII Redacted';
  }

}

const PiiObfuscator = ({ type, ...props }) => {
  const { piiObfuscated } = useSelector((state) => state.account);
  const value = selectType(piiObfuscated, type, props.children);

  return (
    <span {...props}>
      { value !== null ? value : props.children }
    </span>
  )
}

export default PiiObfuscator;
