import React from 'react';
import Head from 'src/layout/head/Head';
import TransactionTable from 'src/components/partials/default/transaction/Transaction';

import DashboardStatistics from "./components/statistics";

const Dashboard = () => (
  <React.Fragment>
    <Head title="Dashboard" />
    <main className="flex w-full flex-1 flex-col">

      <div className="mt-1">
        <div className="grid grid-cols-6 gap-4">
          <DashboardStatistics />
          <div className='col-span-6'>
            <TransactionTable />
          </div>
        </div>
      </div>

    </main>
  </React.Fragment>
);
export default Dashboard;
