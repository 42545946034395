/**
 * Calculate the total value of booking elements
 * @param data
 * @returns {number}
 * @constructor
 */
const ElementsTotalValue = (data) => {
  const components = data.components;

  if (typeof components === 'undefined' || components.length === 0) return 0;

  let amount = 0;

  components.forEach((component) => {
    amount += component.amount;
  });

  return amount;
};

export default ElementsTotalValue;
