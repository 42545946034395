import React from 'react'
import Head from "src/layout/head/Head";

import {
  Block,
} from "src/components/Component";
import ImageUpload from "./image-upload";

const OrganisationTheming = ({ organisation }) => {
  return (
    <div className="card-inner card-inner-lg">
      <Head title="Theming" />

      <Block>
        <div className="nk-data data-list data-list-s2">

          <ImageUpload organisation={organisation} />

        </div>
      </Block>
    </div>
  );
};
export default OrganisationTheming;
