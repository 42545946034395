import React from "react";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import {useSelector} from "react-redux";

const UserProfileRegularPage = () => {
  const { account } = useSelector((state) => state.account);

  return (
    <React.Fragment>
      <Head title="User Profile" />
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Account Detail</BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list data-list-s2">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Full Name</span>
              <span className="data-value">{`${account.first_name} ${account.last_name}`}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{ account.email }</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
            </div>
          </div>
        </div>
        <div className="nk-data data-list data-list-s2">
          <div className="data-head">
            <h6 className="overline-title">Preferences</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Date Format</span>
              <span className="data-value">DD/MM/YYYY</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
            </div>
          </div>
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
