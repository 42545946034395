/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  AUTH_CHECKED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_ACCOUNT_DATA,
  SILENT_LOGIN,
  OBFUSCATE_PII,
} from 'src/actions/accountActions';

const initialState = {
  authChecked: false,
  account: null,
  user: null,
  userModified: false,
  piiObfuscated: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case OBFUSCATE_PII: {
      const status = action.payload;

      return produce(state, (draft) => {
        draft.piiObfuscated = status;
      });
    }

    case AUTH_CHECKED: {
      const status = action.payload;

      return produce(state, (draft) => {
        draft.authChecked = status;
      });
    }

    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SET_ACCOUNT_DATA: {
      return produce(state, (draft) => {
        draft.account = action.payload;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.userModified = true;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
