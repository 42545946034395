import CompletedTransactionValue from "../transaction-completed-total-value";
import PaidOutTotal from "../paid-out-total-value";

const AvailablePayoutValue = (data) => {
  const totalValue = CompletedTransactionValue(data, false);
  const paidOutValue = PaidOutTotal(data);

  return totalValue - paidOutValue;
}

export default AvailablePayoutValue;
