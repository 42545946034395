import React, {useEffect, useState} from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import { Block, BlockContent, Button } from "../../components/Component";

const Error401Modern = () => {
  const [countdownClock, setCountdownClock] = useState(10);

  const clearSession = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  }

  useEffect(() => {
    const countdown = (countdownClock) => {
       setInterval(() => {
         if (countdownClock === 0) {
           clearSession()
         } else {
           setCountdownClock(countdownClock - 1);
           countdown(countdownClock - 1);
         }
      }, 1000);
    }

    countdown(10);
  }, []);

  return (
    <PageContainer>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <div className="pb-5" style={{ width: '200px', textAlign: 'center', maxWidth: '80%', margin: '0 auto' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
              <rect
                width="68"
                height="66"
                x="13"
                y="16"
                fill="#fff"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                rx="6"
                ry="6"
              ></rect>
              <path
                fill="#fff"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M24 82H35V87H24z"
              ></path>
              <path
                fill="#fff"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M60 82H71V87H60z"
              ></path>
              <path
                fill="#eff1ff"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M47 70.15S61.89 62.49 61.89 51V37.6L47 31.85 32.11 37.6V51C32.11 62.49 47 70.15 47 70.15z"
              ></path>
              <path
                fill="#3d0f66"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M41.56 48h10.88A1.6 1.6 0 0154 49.59v5.73A1.6 1.6 0 0152.44 57H41.56A1.6 1.6 0 0140 55.32v-5.73A1.6 1.6 0 0141.56 48z"
              ></path>
              <path
                fill="none"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M43 48v-3a4 4 0 018 0v3"
              ></path>
              <circle
                cx="46.67"
                cy="52.79"
                r="0.91"
                fill="#fff"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></circle>
              <circle
                cx="23"
                cy="17"
                r="14"
                fill="#fff"
                stroke="#3d0f66"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              ></circle>
              <circle cx="23" cy="17" r="10.5" fill="#e3e7fe"></circle>
              <path
                fill="#3d0f66"
                d="M28.46 20.47l-4.41-4.41a3.4 3.4 0 00.26-1.31A3.34 3.34 0 1021 18.1a3.41 3.41 0 001.31-.27l1.44 1.45a.33.33 0 00.23.09h.79v.79a.32.32 0 00.09.23.27.27 0 00.23.08h.79v.79a.31.31 0 00.09.22.29.29 0 00.22.09h.79v.79a.3.3 0 00.09.24.32.32 0 00.21.08l1.21-.14a.3.3 0 00.27-.33l-.13-1.48z"
              ></path>
              <path
                fill="#fff"
                d="M20.56 14.09a1 1 0 01-1.34 0 1 1 0 010-1.35 1 1 0 111.34 1.35zM23.72 16.39l3.79 3.79a.22.22 0 010 .32.24.24 0 01-.32 0l-3.75-3.76z"
              ></path>
              <path fill="none" d="M17.32 11.6H28.32V22.6H17.32z"></path>
            </svg>
          </div>
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Access Denied</h3>
            <p className="nk-error-text">
              You do not have the correct permissions to access this page. If you feel this incorrect, please contact your
              account owner or our support team.
            </p>
            <Button color="primary" disabled size="lg" className="mt-2" onClick={() => clearSession()}>
              Redirecting in {countdownClock} seconds
            </Button>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};

export default Error401Modern;
