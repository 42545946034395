/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  SET_ENUMS_DATA,
} from 'src/actions/enumsActions';

const initialState = {
  enums: null,
};

const enumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENUMS_DATA: {
      return produce(state, (draft) => {
        draft.enums = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default enumsReducer;
