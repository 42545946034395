import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Auth } from "aws-amplify";
import Axios from "axios";
import Select from 'react-select';
import Validator from 'validator';
import { Row, Col, FormGroup, Card, Spinner, Alert, Label } from "reactstrap";
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block, BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button, Icon,
} from "src/components/Component";
import API_ROUTES from "../../../../config/api";
import CurrencyFormat from "../../../../transformer/currency-format";
import { convertNumberToPennies, roundDecimalMoney } from "../../../../utils/money";
import { DEFAULT_CURRENCY, mapCurrency } from "../../../../utils/currency";
import { camelCase, startCase } from 'lodash';

/**
 * Booking Create Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreateBooking = ({ ...props }) => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const [errors, setErrors] = useState({});
  const [apiErrors, setAPIErrors] = useState([]);
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayCurrency, setDisplayCurrency] = useState(mapCurrency(enums.currency[DEFAULT_CURRENCY]));

  const currencies = organisation.currencies.map(item => {
    const currency = enums.currency[item];
    return mapCurrency(currency);
  });

  const packageTypes = enums.booking_Package_type?.map(item => ({
    value: item,
    label: startCase(camelCase(item)),
  }));

  const formDefaults = {
    amount: '',
    currency: DEFAULT_CURRENCY,
    booking_id: '',
    name: '',
    email: '',
    departure_date: '',
    departure_date_pretty: '',
    return_date: '',
    return_date_pretty: '',
    package_type: '',
  };

  const [formValues, setFormValues] = useState(formDefaults);

  const history = useHistory();

  /**
   * Constantly check form field validity
   */
  useEffect(() => {
    checkFormValidity();
  }, [formValues]);

  /**
   * Update a form value
   * @type {updateFormField}
   */
  const updateFormField = ((e) => {
    let value = e.target.value;

    setTouched({ ...touched, [e.target.id]: true });

    if (e.target.id === 'amount' && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      value = roundDecimalMoney(e.target.value);
    }

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  /**
   * Update date fields
   * @param date
   * @param field
   */
  const updateDateField = (date, field) => {
    setTouched({
      ...touched,
      [field]: true,
    });
    const dateTime = new Date(date);
    setFormValues({
      ...formValues,
      [field]: new Date(dateTime.getTime() - (dateTime.getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      [`${field}_pretty`]: dateTime.toDateString(),
    });
  };

  /**
   * Set currency (advanced option)
   * @param event
   */
  const setCurrency = (event) => {
    setDisplayCurrency(event);
    return setFormValues({
      ...formValues,
      currency: event.id,
    });
  };

  /**
   * Check the validity of the form and update state
   * @returns {{}}
   */
  const checkFormValidity = () => {
    const errorObj = {};

    if (formValues.amount === '') errorObj.amount = 'Amount cannot be empty';
    if (formValues.amount < 0.01 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be greater than zero';
    if (formValues.amount > 100000 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be less than or equal to 100,000';

    if (formValues.currency === '') errorObj.currency = 'Currency cannot be empty';

    if (formValues.name === '') errorObj.name = 'Name cannot be empty';

    if (formValues.email === '') errorObj.email = 'Email cannot be empty';

    if (!Validator.isEmail(formValues.email) && typeof errorObj.email === 'undefined') errorObj.email = 'Email is Invalid';

    if (formValues.booking_id === '') errorObj.booking_id = 'Booking reference cannot be empty';

    if (formValues.departure_date !== ''
      && formValues.return_date !== ''
      && new Date(formValues.return_date).getTime() < new Date(formValues.departure_date).getTime()
    ) {
      errorObj.return_date = 'Return date must be greater than or equal to departure date';
    }

    if (formValues.package_type === '') errorObj.package_type = 'Package type cannot be empty';

    setErrors(errorObj);

    return errorObj;
  };

  /**
   * Submit the form
   * @param e
   * @returns {Promise<boolean>}
   */
  const formSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      ...touched,
      name: true,
      email: true,
      amount: true,
      currency: true,
      booking_id: true,
      departure_date: true,
      return_date: true,
      package_type: true,
    });

    const errorCheck = checkFormValidity();

    if (Object.keys(errorCheck).length >= 1) {
      return false;
    }

    setIsSubmitting(true);

    const user = await Auth.currentAuthenticatedUser();

    try {
      const requestData = {
        organisation: organisation.organisation,
        customer_name: formValues.name,
        email: formValues.email,
        booking_reference: formValues.booking_id,
        gross_amount: convertNumberToPennies(formValues.amount),
        currency: formValues.currency,
        package_type: formValues.package_type.value,
      };

      if (formValues.departure_date !== '') {
        requestData.departure_date = formValues.departure_date
      }

      if (formValues.return_date !== '') {
        requestData.return_date = formValues.return_date
      }

      const bookingSearch = await Axios(
        API_ROUTES.agent['bookings:get'](organisation.organisation),
        {
          method: 'post',
          data: {
            take: 10,
            keyword: formValues.booking_id,
            skip: 0,
            organisation: organisation.organisation,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      if (bookingSearch.data.data.length >= 1) {
        setAPIErrors([{
          title: 'Booking already exists',
          message: (
            <React.Fragment>
              A booking with this reference already exists. <Link className="text-white" style={{ textDecoration: 'underline' }} to={`/booking/${bookingSearch.data.data[0].id}`}>View booking</Link>
            </React.Fragment>
          ),
        }]);
        setIsSubmitting(false);

        return null;
      }

      const booking = await Axios(
        API_ROUTES.agent['bookings:create'](),
        {
          method: 'put',
          data: requestData,
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      return history.push(`/booking/${booking.data.data.id}`);
    } catch (error) {
      setIsSubmitting(false);
      setAPIErrors(error.response.data.errors);
    }
  };

  return (
    <React.Fragment>
      <Head title="New Booking" />
      <Content>
      <Card className="card-bordered px-4 py-4 rounded-xl my-4">
          <BlockHead size="sm" className="pb-0">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
                  Create a Booking
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/bookings'}>
                      <Button color="dark" outline className="border border-violet-800 text-violet-800 hover:bg-slate-700 hover:text-white rounded-md" id="button__back">
                      <ArrowLeftIcon className='h-3.5 w-3.5 inline mr-1' /> 
                          <span>Bookings</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <div className="nk-divider divider md pb-2" />

          {apiErrors.map((error) => (
            <Alert color="danger" className="alert-pro text-white bg-danger">
              <Icon name="cross-circle" className="text-white mr-2 fs-22px pos-abs pt-1" />
              <p className="pl-5"><strong>{error.title}</strong><br />{error.message}</p>
            </Alert>
          ))}
          <Block size="lg">
            <form className="gy-3">
              <Row className="g-3 align-center">

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Booking Reference<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="booking_id"
                        name="booking_id"
                        value={formValues.booking_id}
                        onChange={updateFormField}
                        placeholder="e.g. TEST-123"
                        className={`form-control form-control-xl ${typeof errors.booking_id !== 'undefined' && typeof touched.booking_id !== 'undefined' ? 'error' : ''}`}
                        disabled={isSubmitting === true}
                      />
                    </div>
                    {typeof errors.booking_id !== 'undefined' && typeof touched.booking_id !== 'undefined' && (
                      <small className="text-danger">Booking reference cannot be empty</small>
                    )}
                    {(typeof errors.booking_id === 'undefined' || typeof touched.booking_id === 'undefined') && (
                      <span className="form-note">The customers reference for their booking</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Customer Name<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formValues.name}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' ? 'error' : ''}`}
                        disabled={isSubmitting === true}
                        placeholder="e.g. James Bond"
                      />
                    </div>
                    {typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' && (
                      <small className="text-danger">Customer Name cannot be empty</small>
                    )}
                    {(typeof errors.name === 'undefined' || typeof touched.name === 'undefined') && (
                      <span className="form-note">The customers first and last name</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Customer Email<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={formValues.email}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' ? 'error' : ''}`}
                        disabled={isSubmitting === true}
                        placeholder="e.g. james.bond@mi6.gov.uk"
                      />
                    </div>
                    {typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                    {(typeof errors.email === 'undefined' || typeof touched.email === 'undefined') && (
                      <span className="form-note">The customers email address</span>
                    )}
                  </FormGroup>
                </Col>

                </Row>

<Row className="g-3 align-center">

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Booking Value
                    </Label>
                    <div className="form-control-wrap input-group">
                      <div className="input-group-addon pl-3 pr-3">{CurrencyFormat(enums.currency, formValues.currency).getCurrencySymbol()}</div>
                      <input
                        type="number"
                        id="amount"
                        placeholder="e.g. 200.00"
                        disabled={isSubmitting}
                        value={formValues.amount}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' ? 'error' : ''}`}
                        min={0}
                        step={0.01}
                      />
                    </div>
                    {typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' && (
                      <small className="text-danger">{errors.amount}</small>
                    )}
                    {(typeof errors.amount === 'undefined' || typeof touched.amount === 'undefined') && (
                      <span className="form-note">The total value of the booking</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Booking Currency
                    </Label>
                    <div className="form-control-wrap input-group">
                      <Select
                        className="form-control form-control-xl p-0 border-0 indicator-hidden"
                        value={displayCurrency}
                        getOptionLabel={item => item.label}
                        getOptionValue={item => item.id}
                        options={currencies}
                        onChange={setCurrency}
                        isDisabled={currencies.length <= 1}
                        classNamePrefix="react-select"
                      />
                    </div>
                    {typeof errors.currency !== 'undefined' && typeof touched.currency !== 'undefined' && (
                      <small className="text-danger">{errors.currency}</small>
                    )}
                    {currencies.length <= 1 && (typeof errors.currency === 'undefined' || typeof touched.currency === 'undefined') && (
                      <span className="form-note">Multi currency is not available, contact your account manager to enable.</span>
                    )}
                    {currencies.length > 1 && (typeof errors.currency === 'undefined' || typeof touched.currency === 'undefined') && (
                      <span className="form-note">The currency that the customer will pay in</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Booking Package Type<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <Select
                        name='package_type'
                        onChange={(val) => { setFormValues({ ...formValues, package_type: val }); setTouched({ ...touched, package_type: true }) }}
                        value={formValues.package_type}
                        className="form-control form-control-xl p-0 border-0 indicator-hidden"
                        options={packageTypes}
                        classNamePrefix="react-select"
                      />
                    </div>
                    {typeof errors.package_type !== 'undefined' && typeof touched.package_type !== 'undefined' && (
                      <small className="text-danger">{errors.package_type}</small>
                    )}
                    {(typeof touched.package_type == 'undefined' || typeof errors.package_type === 'undefined') && (
                      <span className="form-note">The booking package type.</span>
                    )}
                  </FormGroup>
                </Col>

              </Row>

              <div className="nk-divider divider md" />

              <Row className="g-3 align-center">

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Departure Date
                    </Label>
                    <div className="form-control-wrap input-group">
                      <DatePicker
                        id="departure_date"
                        name="departure_date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        value={formValues.departure_date_pretty}
                        onChange={(value) => updateDateField(value, 'departure_date')}
                        disabled={isSubmitting === true}
                        placehholder="click to select date"
                        className={`form-control form-control-xl date-picker ${typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' ? 'error' : ''}`}
                      />
                    </div>
                    {typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' && (
                      <small className="text-danger">{errors.departure_date}</small>
                    )}
                    {(typeof errors.departure_date === 'undefined' || typeof touched.departure_date === 'undefined') && (
                      <span className="form-note">The date that the customers holiday departs</span>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Return Date
                    </Label>
                    <div className="form-control-wrap input-group">
                      <DatePicker
                        id="return_date"
                        name="return_date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        value={formValues.return_date_pretty}
                        onChange={(value) => updateDateField(value, 'return_date')}
                        disabled={isSubmitting}
                        placehholder="click to select date"
                        className={`form-control form-control-xl date-picker ${typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' ? 'error' : ''}`}
                      />
                    </div>
                    {typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' && (
                      <small className="text-danger">{errors.departure_date}</small>
                    )}
                    {(typeof errors.return_date === 'undefined' || typeof touched.return_date === 'undefined') && (
                      <span className="form-note">The date that the customers holiday returns</span>
                    )}
                  </FormGroup>
                </Col>

              </Row>

              <div className="nk-divider divider md" />

              <Row className="g-3">
                <Col lg="7" className="offset-lg-5">
                  <FormGroup>
                    <Button color="primary" size="lg" onClick={formSubmit} disabled={isSubmitting} className="float-right">
                      {isSubmitting === true ? (
                        <React.Fragment>
                          Creating Booking <Spinner style={{ marginLeft: '1em' }} size="sm"> </Spinner>
                        </React.Fragment>
                      ) : (
                        <span>Create Booking</span>
                      )}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </Block>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default CreateBooking;
