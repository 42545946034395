const HasFeature = (allFeatures, features, onlyOneOf = false) => {
  let featureMatches = 0;

  // Check to see if the organisations features is not null and is iterable
  if (allFeatures == null || typeof allFeatures[Symbol.iterator] !== 'function') {
    return false;
  }

  for (const feature of allFeatures) {
    if (features.indexOf(feature.machine_name) !== -1) {
      featureMatches += 1;
    }
  }

  if (onlyOneOf === true) {
    return featureMatches >= 1;
  }

  return features.length === featureMatches;
}

export default HasFeature;
