/**
 * Calculate the completed transaction count
 * @param data
 * @returns {number}
 * @constructor
 */
const TransactionCompletedCount = (data) => {
  let completedTransactionCount = 0;
  if (typeof data.transactions !== 'undefined') {
    for (const transaction of data.transactions) {
      if (transaction.status.id === 'COMPLETE') {
        completedTransactionCount += 1;
      }
    }
  }

  return completedTransactionCount
}

export default TransactionCompletedCount;
