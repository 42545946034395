import { takeLatest, put, select } from 'redux-saga/effects';
import Axios from 'axios';
import * as Sentry from '@sentry/browser';

import {
  LOAD,
  SET_RUNNING_VERSION,
  SET_LATEST_VERSION,
  CHECK_NEW_VERSION,
  POLL_LATEST_VERSION,
} from 'src/actions/applicationActions';
import { getApplication } from '../selectors';

function* setRunningVersion() {
  try {
    const result = require('src/version.json');
    yield put({ type: SET_RUNNING_VERSION, payload: result?.version });

    // Set the sentry version tag
    Sentry.setTag('version', result?.version);
  } catch (err) {
    // Ignore file not found errors
    if (err?.code !== 'MODULE_NOT_FOUND') {
      throw err;
    }
  }
}

function* setLatestVersion() {
  const cacheBuster = Date.now();
  const url = `/version.json?buster=${cacheBuster}`;

  try {
    const result = yield Axios(
      url,
      { bubbleErrors: [404] }
    );
    yield put({ type: SET_LATEST_VERSION, payload: result?.data?.version });
  } catch (err) {
    // Ignore 404 errors
    if (err?.response?.status !== 404) {
      throw err;
    }
  }
}

function* checkNewVersion() {
  let application = yield select(getApplication);
  if (application?.running_version && application?.latest_version && application?.running_version !== application?.latest_version) {
    yield put({ type: CHECK_NEW_VERSION, payload: true });
    // Log on sentry that new version is detected
    Sentry.captureMessage('New version detected');
  } else {
    yield put({ type: CHECK_NEW_VERSION, payload: false });
  }
}

export default function* sagas() {
  yield takeLatest(LOAD, setRunningVersion);
  yield takeLatest(LOAD, setLatestVersion);
  yield takeLatest(POLL_LATEST_VERSION, setLatestVersion);
  yield takeLatest(SET_RUNNING_VERSION, checkNewVersion);
  yield takeLatest(SET_LATEST_VERSION, checkNewVersion);
}
