const PROVIDER_FIELDS = {
  'NUVEI': ['merchantID', 'merchantSiteID', 'googlePayMerchantId', 'googlePayMerchantName', 'googlePayAllowedCardNetworks', 'applePayMerchantName'],
}

const PUBLIC_CREDS_ID_PREFIX = 'public_creds_';

const PUBLIC_CREDS_LABEL_PREFIX = 'Public Credentials: ';

export const GOOGLEPAY_ALLOWED_CARD_NETWORKS_OPTIONS = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];

export const getProviderFields = (paymentProviderName) => {
  if (!(paymentProviderName in PROVIDER_FIELDS)) {
    return [];
  }

  return PROVIDER_FIELDS[paymentProviderName];
}

export const providerFieldLabel = (field) => ( `${PUBLIC_CREDS_LABEL_PREFIX}${field}` );

export const providerFieldId = (field) => ( `${PUBLIC_CREDS_ID_PREFIX}${field}` );

export const buildFormData = (values) => {
  const data = {
    name: values.name,
    payment_provider_id: values.paymentProvider,
    transaction_methods: (values.transactionMethods || []).map((item) => (item.id)),
    currencies: (values.currencies || []).map((item) => (item.id)),
    public_key: values.publicKey,
    public_creds: {},
    secret_key: values.secretKey,
    googlepay_enabled: values.googlepay_enabled,
    applepay_enabled: values.applepay_enabled,
  };

  for (const [key, value] of Object.entries(values)) {
    if (key.indexOf(PUBLIC_CREDS_ID_PREFIX) === -1) {
      continue;
    }
    const field = key.replace(PUBLIC_CREDS_ID_PREFIX, '');
    data.public_creds[field] = value;
  }

  return data;
}

export const buildInitialValues = (data) => {
  const values = {
    name: data.name,
    paymentProvider: data.payment_provider.id,
    paymentProviderName: data.payment_provider.name,
    transactionMethods: data.transaction_methods.map((value) => ({ id: value.name })),
    currencies: data.currencies.map((value) => ({ id: value })),
    publicKey: data.public_key,
    secretKey: '',
    googlepay_enabled: data.googlepay_enabled,
    applepay_enabled: data.applepay_enabled,
  };

  if (data.public_creds) {
    for (const [field, value] of Object.entries(data.public_creds)) {
      const id = providerFieldId(field);
      values[id] = value;
    }
  }

  return values;
}
