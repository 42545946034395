import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { Row, Col, FormGroup, Card, Spinner, Label, CardHeader, CardFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import AsyncSelect from 'react-select/async-creatable';
import Select from 'react-select';

import Head from "src/layout/head/Head";
import {
  Block,
  Button,
} from "src/components/Component";
import API_ROUTES from "src/config/api";
import CurrencyFormat from "src/transformer/currency-format";
import randomstring from "randomstring";
import { convertNumberToPennies, roundDecimalMoney } from "src/utils/money";

const DEFAULT_CURRENCY = 'GBX';

const formDefaults = {
  short_id: '',
  amount: '',
  bookingField: null,
  booking: '',
  booking_id: null,
  description: '',
  customer_name: '',
  currency: DEFAULT_CURRENCY,
};

const mapCurrency = (item) => (
  {
    id: item.minor_unit,
    label: item.major_unit,
  }
);

/**
 * Credit Note Create Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CreditNoteCreate = ({ ...props }) => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    ...formDefaults,
    short_id: randomstring.generate({
      length: 10,
      charset: 'alphanumeric',
      capitalization: 'uppercase',
    }),
  });
  const [displayCurrency, setDisplayCurrency] = useState(mapCurrency(enums.currency[DEFAULT_CURRENCY]));

  const currencies = organisation.currencies.map(item => {
    const currency = enums.currency[item];
    return mapCurrency(currency);
  });

  const [formState, setFormState] = useState({
    showSelectDropdown: false,
    asyncSelectLoaded: false,
  });

  /**
   * Constantly check form field validity
   */
  useEffect(() => {
    checkFormValidity();
  }, [formValues]);

  /**
   * Update a form value
   * @type {updateFormField}
   */
  const updateFormField = ((e) => {
    let value = e.target.value;

    setTouched({ ...touched, [e.target.id]: true });

    if (e.target.id === 'amount' && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      value = roundDecimalMoney(e.target.value);
    }

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  /**
   * Set currency (advanced option)
   * @param e
   */
  const setCurrency = (e) => {
    setDisplayCurrency(e);
    return setFormValues({
      ...formValues,
      currency: e.id,
    });
  };

  /**
   * Check the validity of the form and update state
   * @returns {{}}
   */
  const checkFormValidity = () => {
    const errorObj = {};

    if (formValues.amount === '') errorObj.amount = 'Amount cannot be empty';
    if (formValues.amount < 0.01 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be greater than zero';
    if (formValues.amount > 100000 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be less than or equal to 100,000';

    if (formValues.customer_name === '') errorObj.customer_name = 'Customer Name cannot be empty';

    setErrors(errorObj);

    return errorObj;
  };

  /**
   * Submit the form
   * @param e
   * @returns {Promise<boolean>}
   */
  const formSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      ...touched,
      amount: true,
      booking: true,
      customer_name: true,
    });

    const errorCheck = checkFormValidity();

    if (Object.keys(errorCheck).length >= 1) {
      return false;
    }

    setIsSubmitting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();

      // Create the payment link
      await Axios(
        API_ROUTES.agent['credit-notes:put'](),
        {
          method: 'put',
          data: {
            customer_name: formValues.customer_name,
            booking_id: formValues.booking_id,
            amount: convertNumberToPennies(formValues.amount),
            currency: formValues.currency,
            organisation: organisation.organisation,
            description: formValues.description,
            short_id: formValues.short_id
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      return history.push(`/credit-notes`);
    } catch (error) {
      return history.push(`/errors/504`);
    }
  };

  /**
   * Find bookings using the API
   * @type {function(*): *}
   */
  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFormState({ ...formState, asyncSelectLoaded: true });
    return result.data.data;
  }, []);

  /**
   * Populate the booking related fields on booking select
   * @param value
   * @returns {Promise<void>}
   */
  const populateBookingDetails = async (value) => {
    if (value !== null) {
      setFormState({ ...formState, asyncSelectLoaded: false });

      const valuesToSet = {
        ...formValues,
        bookingField: value,
        booking: value.booking_reference,
        booking_id: value.id,
      };

      setFormValues(valuesToSet);
    }
  }

  return (
    <React.Fragment>
      <Head title="New Credit Note" />

      <div className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">
        <CardHeader className="bg-gray-50 mb-2 text-purple-900">
          <h2 className="text-xl text-purple-900">
            Create <span className="font-bold">Credit Note</span>
          </h2>
        </CardHeader>

        <form className="gy-3">
        <Card>
          <Block size="lg" className="p-5"> 
              <Row className="g-3 align-center">
                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Credit Note Amount
                    </Label>
                    <div className="form-control-wrap input-group">
                      <div className="input-group-addon pl-3 pr-3">{CurrencyFormat(enums.currency, formValues.currency).getCurrencySymbol()}</div>
                      <input
                        type="number"
                        id="amount"
                        disabled={isSubmitting}
                        value={formValues.amount}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' ? 'error' : ''}`}
                        min={0}
                        step={0.01}
                      />
                    </div>
                    {typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' && (
                      <small className="text-danger">{errors.amount}</small>
                    )}
                    {(typeof errors.amount === 'undefined' || typeof touched.amount === 'undefined') && (
                      <span className="form-note">The amount that the credit note is for</span>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Currency
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <Select
                          className="form-control form-control-xl p-0 border-0 indicator-hidden"
                          value={displayCurrency}
                          getOptionLabel={item => item.label}
                          getOptionValue={item => item.id}
                          options={currencies}
                          onChange={setCurrency}
                          isDisabled={currencies.length <= 1 || isSubmitting}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    {currencies.length <= 1 && (
                      <span className="form-note">Multi currency is not available, contact your account manager to enable.</span>
                    )}
                    {currencies.length > 1 && (
                      <span className="form-note">The currency that the credit note is for</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row className="g-3 align-center">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label text-primary" htmlFor="site-name">
                      Customer Name
                    </label>
                    <span className="form-note">The name of the customer that the credit note is for</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  <FormGroup>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="customer_name"
                        name="customer_name"
                        value={formValues.customer_name}
                        disabled={isSubmitting}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.customer_name !== 'undefined' && typeof touched.customer_name !== 'undefined' ? 'error' : ''}`}
                      />
                      {typeof errors.customer_name !== 'undefined' && typeof touched.customer_name !== 'undefined' && (
                        <small className="text-danger">{errors.customer_name}</small>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="g-3 align-center">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label text-primary" htmlFor="site-name">
                      Description
                    </label>
                    <span className="form-note">Description of what the credit note is for</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  <FormGroup>
                    <div className="form-control-wrap">
                      <textarea
                        id="description"
                        name="description"
                        disabled={isSubmitting}
                        value={formValues.description}
                        onChange={updateFormField}
                        className={`form-control form-control-xl`}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="g-3 align-center">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label text-primary">Booking Reference</label>
                    <span className="form-note">The booking that the credit note was created against</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  <FormGroup>
                    <div className="form-control-wrap">
                      <AsyncSelect
                        isClearable
                        isValidNewOption={() => false}
                        className={`form-control form-control-xl p-0 border-0 indicator-hidden ${typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' ? 'error' : ''}`}
                        allowCreateWhileLoading={false}
                        value={formValues.bookingField}
                        isMulti={false}
                        getOptionLabel={e => {
                          return `${e.booking_reference} | ${e.customer_name}`;
                        }}
                        getOptionValue={e => e.id}
                        loadOptions={findBookings}
                        onChange={populateBookingDetails}
                        menuIsOpen={formState.asyncSelectLoaded === true}
                        placeholder=""
                        classNamePrefix="react-select"
                        createOptionPosition="first"
                        isDisabled={isSubmitting}
                      />
                    </div>
                    {typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' && (
                      <small className="text-danger">{errors.booking}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <div className="nk-divider divider md" />

              <Row className="g-3 align-center">
                <Col lg="5">
                  <FormGroup>
                    <label className="form-label text-primary" htmlFor="site-name">
                      Short ID
                    </label>
                    <span className="form-note">An identifier that can be given to a customer to reference the credit note</span>
                  </FormGroup>
                </Col>
                <Col lg="7">
                  <FormGroup>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="short_id"
                        name="short_id"
                        value={formValues.short_id}
                        className={`form-control form-control-xl`}
                        disabled={true}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
  

          </Block>
          <CardFooter className="bg-gray-200">
              <FormGroup>
                    <Button color="primary" size="lg" onClick={formSubmit} disabled={isSubmitting} className="float-right">
                      {isSubmitting === true ? (
                        <React.Fragment>
                          Generating Credit Note <Spinner style={{ marginLeft: '1em' }} size="sm"> </Spinner>
                        </React.Fragment>
                      ) : (
                        <span>Create Credit Note</span>
                      )}
                    </Button>
                  </FormGroup>
              </CardFooter>
        </Card>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CreditNoteCreate;
