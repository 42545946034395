import React, { useCallback, useEffect, useState } from "react";
import Head from "src/layout/head/Head";
import {
  Block, Col, Row, Icon
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import { Spinner, Card, CardHeader, CardBody, Badge, Alert } from "reactstrap";
import { BeakerIcon } from "@heroicons/react/20/solid";

const UserFeatures = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const getRoles = useCallback(async (triggerLoading = true) => {
    if (triggerLoading || roles.length === 0) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user['organisation:feature'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setRoles(result.data.data.map((item) => ({
      ...item,
      changing: false,
    })));
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const changeStatus = async (organisationID, key, status) => {
    let data = [...roles];
    data[key].changing = true;
    setRoles(data);

    const user = await Auth.currentAuthenticatedUser();
    const action = status === true ? 'add' : 'remove';

    await Axios.post(
      API_ROUTES.user["organisation:feature:change"](organisationID, action),
      JSON.stringify({
        'feature-id': data[key].id,
      }),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    data = [...roles];
    data[key].enabled = status;
    data[key].changing = false;
    setRoles(data);
  }

  return (
    <Row className="card-inner card-inner-lg">
      <Head title="Organisation Features" />
      <Block className="w-100">
        <Row className="g-gs" style={{ width: '100%' }}>
          {
            loading === true && (
              <Col size={12} style={{ width: '100%' }}>
                <Skeleton count={5} height="50px" className="mb-4" style={{
                  width: '100%',
                }} />
              </Col>
            )
          }

          {loading === false && (
            <div className="bg-violet-100 border-t-4 border-violet-500 rounded-b text-violet-900 px-4 py-3 my-2 w-100 mx-2" role="alert">
              <div className="flex">
                <div className="py-1">
                  <BeakerIcon className="h-6 w-6 text-violet-500 mr-4 mt-1" aria-hidden="true" />
                </div>
                <div>
                  <p className="font-bold">Beta Feature Warning</p>
                  <p className="text-sm">
                    Beta features are either incomplete or may only be available for some acquirers.
                  </p>
                </div>
              </div>
            </div>
          )}

          {loading === false && roles.map((item, key) => {
            return (
              <Col size="4" key={key}>
                <Card className="card-bordered mb-0 pb-o">
                  <CardHeader className="bg-light text-primary fw-bolder pr-0">
                    <div className="custom-control custom-switch float-right  pr-0 mr-0">
                      <span>{item.changing && <Spinner size="sm" className="mt-1 mr-3"> </Spinner>}</span>
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        checked={item.enabled}
                        disabled={item.changing}
                        onChange={() => changeStatus(id, key, item.enabled === false)}
                        id={`feature-switch-${item.id}`}
                      />
                      <label className="custom-control-label" htmlFor={`feature-switch-${item.id}`} />
                    </div>
                    {item.name}
                    {
                      item.is_beta === true && (
                        <Badge color="primary" className="ml-1 text-white">Beta</Badge>
                      )
                    }
                  </CardHeader>
                  <CardBody className="card-inner pt-2 pb-2">
                    {item.description}
                  </CardBody>
                </Card>
              </Col>
            );
          })}

        </Row>
      </Block>
    </Row>
  );
};
export default UserFeatures;
