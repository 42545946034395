import React, { useState } from 'react';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import moment from "moment";
import API_ROUTES from "src/config/api";
import { Link } from "react-router-dom";
import CurrencyFormat from 'src/transformer/currency-format';
import { Auth } from "aws-amplify";
import axios from "axios";
import { Spinner } from "reactstrap";
import { convertPenniesToDecimals } from "src/utils/money";
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const RowFormatter = ({item, childFilterState}) => {

  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <span className="title">
          <Link
            to={`/transaction/${item.transaction.id}`}
            className="text-left underline text-violet-800"
          >
            {item.transaction.id}
          </Link>
        </span>
      </td>
      {childFilterState === true && (
        <td className="text-center border-left">
          <span className="title">{item.transaction.organisation.name}</span>
        </td>
      )}
      <td className="border-left text-center">
        <span className="title">{moment(item.posting_date).format('Do MMMM YYYY')}</span>
      </td>
      <td className="text-center">
        <span className="title">{moment(item.transaction.completed_at).format('Do MMMM YYYY')}</span>
      </td>
      <td className="text-center border-left">
        {CurrencyFormat().format(convertPenniesToDecimals(item.amount))}
      </td>
      <td className="border-left pl-3">
        {item.reason}
      </td>
    </tr>
  );
}

const PageOptions = ({requestParams, triggerReload, loading}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.agent['chargebacks:get'](),
      { ...requestParams, type: 'csv' },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFetchingCSV(false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
      <li>
        <Button disabled={fetchingCSV} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = ({childFilterState}) => (
  <tr className="text-left tb-tnx-head">
    <th>
      <span>Transaction</span>
    </th>
    {childFilterState === true && (
      <th className="text-center border-left">
        <span>Organisation</span>
      </th>
    )}
    <th className="border-left text-center">
      <span>Received Date</span>
    </th>
    <th className="text-center">
      <span>Transaction Date</span>
    </th>
    <th className="text-center border-left">
      <span>Chargeback Amount</span>
    </th>
    <th className="border-left pl-3">
      <span>Reason</span>
    </th>
  </tr>
);

const Chargebacks = () => {
  const endpoint = API_ROUTES.agent['chargebacks:get']();

  return (
    <DataTable
      title="Chargebacks"
      largeHeading={true}
      columnCount={9}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      childOrganisationsOption={true}
      endpoint={endpoint}
      noHeader={true}
    />
  );
};

export default Chargebacks;
