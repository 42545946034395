import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from "src/config/api";
import StatBox from "src/pages/dashboard/pages/main/components/statistics/stat-box";
import CurrencyFormat from 'src/transformer/currency-format';
import { convertPenniesToDecimals } from "src/utils/money";

import Head from 'src/layout/head/Head';
import CustomerTable from './components/customer-table';
import TopCustomerTable from './components/top-customer-table';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    thirty_day_payments: [],
  });

  const { enums } = useSelector((state) => state.enums);
  const { organisation } = useSelector((state) => state);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.reporting['customers'](organisation.organisation),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setData(result.data.data);

      setLoading(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [organisation.organisation]);

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <React.Fragment>
      <Head title="Dashboard" />
      <main className="flex w-full flex-col">

        <div className="mb-3">

          <div className="grid grid-cols-1 gap-y-2 lg:gap-y-0 lg:grid-cols-3 lg:space-x-4">

            <StatBox
              title={<React.Fragment>New <span className="font-bold">Customers</span></React.Fragment>}
              period="Last 7 Days"
              figure={`
                ${data?.totals?.new_count} (${data?.totals?.new_count + data?.totals?.returning_count > 1 ? Math.round((data?.totals?.new_count / (data?.totals?.new_count + data?.totals?.returning_count)) * 100) : 0}%)
              `}
              loading={loading}
              popoverTitle="New Customers"
              popoverDescription="The percentage of declined transactions vs succesfull transactions in the last 7 days"
            />
            <StatBox
              title={<React.Fragment>Returning <span className="font-bold">Customers</span></React.Fragment>}
              period="Last 7 Days"
              figure={`
                ${data?.totals?.returning_count} (${data?.totals?.new_count + data?.totals?.returning_count > 1 ? Math.round((data?.totals?.returning_count / (data?.totals?.new_count + data?.totals?.returning_count)) * 100) : 0}%)
              `}
              loading={loading}
              popoverTitle="Returning Customers"
              popoverDescription="Payment links that have been paid vs those which have not, that were created in the last 7 days"
            />
            <StatBox
              title={<React.Fragment>Average <span className="font-bold">Spend</span></React.Fragment>}
              period="Last 7 Days"
              figure={CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(data?.totals?.average_spend || 0))}
              loading={loading}
              popoverTitle="Returning Customers"
              popoverDescription="Payment links that have been paid vs those which have not, that were created in the last 7 days"
            />

          </div>

        </div>

        <div className="grid grid-cols-6 gap-4">


          <div className='col-span-6 xl:col-span-3'>
            <TopCustomerTable type="last_7" />
          </div>

          <div className='col-span-6 xl:col-span-3'>
            <TopCustomerTable type="all"  />
          </div>

          <div className='col-span-6'>
            <CustomerTable />
          </div>

        </div>


      </main>
    </React.Fragment>
  );
}

export default Dashboard;
