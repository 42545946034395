export const ACTIVE = 'ACTIVE';
export const PENDING = 'PENDING';
export const DISABLED = 'DISABLED';
export const DELETED = 'DELETED';
export const IMPORTED = 'IMPORTED';

export const STATUS_TYPES = [
  { value: ACTIVE, label: "Active" },
  { value: PENDING, label: "Pending" },
  { value: DISABLED, label: "Disabled" },
  { value: DELETED, label: "Deleted" },
  { value: IMPORTED, label: "Imported" }
];

let statusTypesObject = {};

STATUS_TYPES.forEach((item) => {
  statusTypesObject[item.value] = item.label;
})


export const STATUS_TYPES_OBJECT = statusTypesObject;

export default STATUS_TYPES;
