import React from 'react';
import {Col} from "reactstrap";
import {Icon} from "src/components/Component";
import Tippy from '@tippyjs/react';

const Field = ({ description, name, data, itemWidth }) => {
  return (
    <Col xl={ itemWidth ? itemWidth : 6 }>
      <div>
        <div className="pr-2 pt-1 form-banner">
          <Tippy content={<span>{description}</span>}>
            <span className="font-light">
              { name }
              <Icon
                name="info"
                className="text-primary pl-1 pointer-event"
              />
            </span>
          </Tippy>
        </div>
        <div className="pr-2 pt-1">
        <span className="data-value">
            { data }
        </span>
        </div>
      </div>
    </Col>
  );
}

export default Field;
