import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Pages from "../route";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import FetchMenu from "../config/menu";
import NewVersionBanner from "src/components/new-version-banner";
import UnpaidInvoiceBanner from "../components/unpaid-invoice-banner";

const Layout = () => {
  const organisationObject = useSelector((state) => state.organisation);
  const { organisation, features } = useSelector((state) => state.organisation);
  const { account } = useSelector((state) => state.account);

  //Sidebar
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    main: "default",
    headerOption: "fixed",
    header: "white",
    skin: "light",
  });
  const history = useHistory();

  // Hide the menu when the location changes
  history.listen((location, action) => {
    if (visibility === true && action === 'PUSH') {
      setVisibility(false);
    }
  });

  useEffect(() => {
    const menu = FetchMenu(organisation, features, account);

    document.body.className = `nk-body bg-white npc-default has-aside no-touch nk-nio-theme ${themeState.skin === "dark" ? "dark-mode" : " "
      }`;
    let apps = menu.find((item) => item.text === "Applications");

    try {
      let matched = apps.subMenu.find((sub) => process.env.PUBLIC_URL + sub.link === window.location.pathname);

      if (matched) {
        document.body.classList.add("apps-only");
      } else {
        document.body.classList.remove("apps-only");
      }
    } catch (e) {
      document.body.classList.remove("apps-only");
    }
  }, [window.location.pathname, organisation, features, account]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to toggle sidebar
  const toggleSidebar = (e) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Loading" />
      <div className="nk-app-root">
        <div className="flex w-screen">
          <Sidebar setVisibility={setVisibility} fixed theme="light" visibility={visibility} />

          <div className="flex-1 lg:ml-[170px]">
            <Header
              sidebarToggle={toggleSidebar}
              fixed={themeState.headerOption === "fixed"}
              theme={themeState.header}
            />
            <div className="mx-4 w-auto relative">
              <NewVersionBanner />
              <UnpaidInvoiceBanner organisation={organisationObject} />
              <Pages />
            </div>

          </div>

        </div>

      </div>
    </React.Fragment>
  );
};
export default Layout;
