import React, {useState} from 'react';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import API_ROUTES from "../../config/api";
import {Auth} from "aws-amplify";
import axios from "axios";
import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import ActivateComponent from './components/activate';
import PiiObfuscator from "../../components/pii-obfuscator";

const RowFormatter = ({item, triggerReload, childFilterState}) => {
  return (
    <tr className="tb-tnx-item">
      { childFilterState === true && (
        <td>
          <span className="title">{item.organisation.name}</span>
        </td>
      )}
      <td>
        <PiiObfuscator className="date" type="iban">{ item.bank.iban }</PiiObfuscator>
      </td>
      <td>
        <PiiObfuscator className="date" type="company">{ item.bank.name }</PiiObfuscator>
      </td>
      <td>
        <PiiObfuscator className="date" type="account_number">{ item.bank.account_number }</PiiObfuscator>
      </td>
      <td>
        <span className="date">{ item.bank.sort_code }</span>
      </td>
      <td className="w-100px text-center">
        { item.is_active && (
          <Icon name={`ni text-primary icon ni-check pr-1`} />
        )}
        { !item.is_active && (
          <ActivateComponent data={item} triggerReload={triggerReload} />
        )}
      </td>
    </tr>
  );
}

const PageOptions = ({requestParams}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.ledger['beneficiaries:get'](),
      { ...requestParams, type: 'csv' },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFetchingCSV(false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Link to={'/beneficiaries/create'} id="link_add_beneficiary">
          <Button color="light" outline className="btn-white">
            <Icon name="plus" />
            <span>Add Beneficiary</span>
          </Button>
        </Link>
      </li>
      <li>
        <Button disabled={fetchingCSV} outline className="btn-white hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};


const TableHeaders = ({childFilterState}) => (
  <tr className="tb-tnx-head">
    { childFilterState === true && (
      <th>
        <span>Organisation</span>
      </th>
    )}
    <th>
      <span>IBAN</span>
    </th>
    <th>
      <span>Account Name</span>
    </th>
    <th>
      <span>Account Number</span>
    </th>
    <th>
      <span>Sort Code</span>
    </th>
    <th className="text-center">
      <span>Enabled</span>
    </th>
  </tr>
);

const Beneficiaries = () => {
  const endpoint = API_ROUTES.ledger['beneficiaries:get']();

  return (
    <DataTable
      title="Beneficiaries"
      searchPlaceholder="Search..."
      columnCount={5}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={endpoint}
      childOrganisationsOption={true}
    />
  );
};

export default Beneficiaries;
