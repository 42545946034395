import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Spinner } from "reactstrap";
import Skeleton from "react-loading-skeleton";

import { API_ROUTES } from 'src/config/api';
import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Button,
  Row,
} from "src/components/Component";

import Group from "./group";
import { buildProviderGroups, providersInGroup } from "./utils";

const OrganisationPaymentConfigWeights = ({ organisation }) => {
  const [providers, setProviders] = useState();
  const [weights, setWeights] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect( () => {
    const action = async () => {
      if (!organisation.id) {
        return;
      }

      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['organisation:providers'](organisation.id),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setProviders(data);
    }

    action();
  }, [organisation.id]);

  const history = useHistory();

  const processForm = async () => {
    setIsSubmitting(true);

    const data = {
      weights: Object.values(weights),
    };

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.payment['organisation:providers:set-weights'](organisation.id),
      {
        method: 'put',
        data,
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push(`/organisations/${organisation.id}/payment-config`);
  };

  if (!providers) {
    return (
      <div className="mt-3">
        <Skeleton count={3} height="150px" className="mb-5" style={{ width: '100%' }}/>
      </div>
    );
  }

  return (
    <>
      <Head title="Set Payment Provider Weighting" />
      <Content>
        <BlockTitle tag="h4" className="text-primary mb-2">Payment Provider Weighting(s)</BlockTitle>

        <div className="nk-divider divider md pb-2" />

        <Row className="g-gs">
          {buildProviderGroups(providers).map((group) => (
            <Group
              key={group.key}
              organisation={organisation}
              providers={providersInGroup(providers, group)}
              group={group}
              setWeights={setWeights}
            />
          ))}
        </Row>

        <div className="nk-divider divider md pb-2" />

        <Block size="lg">
          <Button onClick={(event) => { event.preventDefault(); processForm(); }} type="submit" color="primary" size="lg" className="float-right">
            {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : "Update Weighting(s)"}
          </Button>
        </Block>
      </Content>
    </>
  );
};
export default OrganisationPaymentConfigWeights;
