import React, { useEffect, useId } from "react";
import { CardHeader } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Chart } from "chart.js/auto";

const ChartComponent = ({ data, loading }) => {
  const id = useId();

  useEffect(() => {
    if (loading === false) {
      var config = {
        type: "line",
        data: {
          labels: data.map((item) => item.month),
          datasets: [
            {
              color: "#798bff",
              fill: true,
              backgroundColor: "rgba(74,18,124,0.1)",
              borderColor: "#670199",
              barPercentage: 1,
              categoryPercentage: 0.1,
              borderWidth: 2,
              lineTension: 0.1,
              pointRadius: false,
              data: data.map((item) => item.count),
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            backgroundColor: "#eff6ff",
            titleFontSize: 13,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              display: true,
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontColor: "#9eaecf",
                padding: 10,
                min: 0,
                stepSize: 5000,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
            x: {
              display: true,
              ticks: {
                fontSize: 9,
                fontColor: '#9eaecf',
                source: "auto",
                padding: 10,
                display: true,
                autoSkip: true,
                maxTicksLimit: 4,
                maxRotation: 0
              },
              grid: {
                drawBorder: false,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
          },
        },
      };

      var ctx = document.getElementById(id).getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  }, [loading, data]);

  return (
    <div className="flex grow flex-col">
        <CardHeader className="border-bottom bg-gray-50 mb-2">
          <h2 className="text-xl font-normal text-purple-900">
            Declines <span className="font-bold">By Month</span>
          </h2>
        </CardHeader>
      {loading && (
        <div className="text-center px-6 py-3">
          <Skeleton count={1} height="260px" />
        </div>
      )}
      {!loading && (
        <div className="py-4 px-4 grow">
          <canvas id={id}></canvas>
        </div>
      )}

    </div>
  );
};
export default ChartComponent;
