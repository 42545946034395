const TransactionStatusTransformer = (type) => {
  if (type === 'COMPLETE') {
    return {
      icon: 'ni-check',
      label: 'Complete',
      color: 'bg-emerald-600'
    };
  }

  if (type === 'AUTHORIZED') {
    return {
      icon: 'ni-check-circle-cut',
      label: 'Authorised',
      color: 'bg-emerald-600'
    }
  }

  if (type === 'REFUNDED') {
    return {
      icon: 'ni-rewind-fill',
      label: 'Refunded',
      color: 'bg-gray-600'
    }
  }

  if (type === 'PARTIALLYREFUNDED') {
    return {
      icon: 'ni-rewind-fill',
      label: 'Refunded',
      color: 'bg-sky-600'
    }
  }

  if (type === 'DECLINED' || type === 'REJECTED') {
    return {
      icon: 'ni-cross',
      label: 'Declined',
      color: 'bg-rose-600'
    }
  }

  if (type === 'PENDING') {
    return {
      icon: 'ni-swap-v',
      label: 'Pending',
      color: 'bg-gray-600'
    }
  }

  if (type === 'ABANDONED') {
    return {
      icon: 'ni-archive',
      label: 'Abandoned',
      color: 'bg-rose-600'
    }
  }

  if (type === 'REVERSED' || type === 'PENDINGREVERSAL') {
    return {
      icon: 'ni-chevron-left-circle',
      label: 'Reversed',
      color: 'bg-gray-600'
    }
  }


  if (type === 'PREAUTHORIZED') {
    return {
      icon: 'ni-focus',
      label: 'Preauthorized',
      color: 'bg-sky-600'
    }
  }

  if (type === 'PENDINGCAPTURE') {
    return {
      icon: 'ni-minimize',
      label: 'Pending Capture',
      color: 'bg-sky-600'
    }
  }

  return {
    icon: 'ni-question',
    label: type
  };
}

export default TransactionStatusTransformer;
