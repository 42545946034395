import React, { useCallback, useEffect, useState } from 'react';
import Head from 'src/layout/head/Head';

import {
  Button,
} from "src/components/Component";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import moment from "moment";
import CurrencyFormat from "src/transformer/currency-format";
import { convertPenniesToDecimals } from "src/utils/money";

const Developers = () => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const [loading, setLoading] = useState(true);

  const [invoices, setInvoices] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user['organisation:subscription'](organisation.organisation),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setSubscription(result?.data?.data?.subscription);
    setInvoices(result?.data?.data?.invoices);

    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <React.Fragment>
      <Head title="Billing" />

      <div className="flex-col rounded-md border animate-in fade-in-50">

        <CardHeader className="bg-gray-50 mb-0 text-purple-900">
          <h2 className="text-xl text-purple-900">
            Current <strong>Subscription</strong>
          </h2>
        </CardHeader>

        <Card className="card-stretch">

          <CardBody className="p-0">
            <table className="table table-tranx border-light card-bordered">
              <tbody>
                <tr className="tb-tnx-head">
                  <th className='text-left'><span>Plan ID</span></th>
                  <th className='text-left'><span>Subscribed Since</span></th>
                  <th className='text-left'><span>Plan Interval</span></th>
                  <th className='text-left'><span>Status</span></th>
                </tr>
                {loading === true && Array.from(Array(1).keys()).map((key) => (
                  <tr className="tb-tnx-item" key={key}>
                    <td colSpan={7}>
                      <Skeleton count={1} height="50px" className="m-0 p-1" />
                    </td>
                  </tr>
                ))}
                {loading === false && !subscription && (
                  <tr className="tb-tnx-item">
                    <td colSpan={7} className="text-center">
                      <strong>No subscription found</strong>
                    </td>
                  </tr>
                )}
                {loading === false && subscription && (
                  <tr className="tb-tnx-item">
                    <td>
                      <span className="sub-text">{subscription.plan_code}</span>
                    </td>
                    <td>{moment(subscription.subscription_at).format('Do MMMM YYYY')}</td>
                    <td>
                      monthly
                    </td>
                    <td>
                      {subscription.status}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </CardBody>
        </Card>

      </div>

      <div className="flex-col rounded-md border animate-in fade-in-50 mt-4">

        <CardHeader className="bg-gray-50 mb-0 text-purple-900">
          <h2 className="text-xl text-purple-900">
            Latest <strong>Invoices</strong>
          </h2>
        </CardHeader>


        <Card className="card-stretch">

          <CardBody className="p-0">
            <table className="table table-tranx border-light card-bordered">
              <tbody>
                <tr className="tb-tnx-head">
                  <th className='text-left'><span>Invoice ID</span></th>
                  <th className='text-left'><span>Issuing date</span></th>
                  <th className='text-left'><span>Invoice Amount</span></th>
                  <th><span>Payment Status</span></th>
                  <th><span>Invoice Status</span></th>
                  <th><span>Options</span></th>
                </tr>
                {loading === true && Array.from(Array(5).keys()).map((key) => (
                  <tr className="tb-tnx-item" key={key}>
                    <td colSpan={7}>
                      <Skeleton count={1} height="50px" className="m-0 p-1" />
                    </td>
                  </tr>
                ))
                }
                {loading === false && invoices.length === 0 && (
                  <tr className="tb-tnx-item">
                    <td colSpan={7} className="text-center">
                      <strong>No invoices found</strong>
                    </td>
                  </tr>
                )}
                {loading === false && invoices.map((item, index) => (
                  <tr className="tb-tnx-item" key={index}>
                    <td>
                      <span className="sub-text">{item.id}</span>
                    </td>
                    <td>{moment(item.issuing_date).format('Do MMMM YYYY')}</td>
                    <td style={{ maxWidth: '120px' }}>
                      {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(item.amount))}
                    </td>
                    <td className='text-center'>{item.payment_status}</td>
                    <td className='text-center'>{item.status}</td>
                    <td style={{ width: '100px' }}>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <Button outline className="btn-primary text-white btn-green">
                          <span>Download Invoice</span>
                        </Button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>

      </div>
    </React.Fragment>
  );
}

export default Developers;
