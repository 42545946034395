import React from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Row,
} from "src/components/Component";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { camelCase, startCase }  from 'lodash';
import CurrencyFormat from 'src/transformer/currency-format';
import CompletedTransactionValue  from "../../utils/transaction-completed-total-value";
import PaidOutTotal from "../../utils/paid-out-total-value";
import ElementsTotalValue from "../../utils/elements-total-value";
import AvailablePayoutValue from "../../utils/available-payout-value";
import HasRole from "../../../../../../utils/has-role";
import {useSelector} from "react-redux";
import ReassignButton from "./components/reassign-button";
import PiiObfuscator from "../../../../../../components/pii-obfuscator";
import DateEdit from "./components/date-edit";
import Field from "./components/field";
import FieldEdit from "./components/field-edit";
import {convertPenniesToDecimals} from "../../../../../../utils/money";
import {Col} from "reactstrap";


const BookingInformation = ({ data, loading, triggerReload }) => {
  const { account } = useSelector((state) => state.account);
  const { enums } = useSelector((state) => state.enums);

  const packageTypes = enums.booking_Package_type?.map(item => ({
    value: item,
    label: startCase(camelCase(item)),
  }));
  
  const titleClass = 'text-primary mb-0 text-xl font-bold';
  const descriptionClass = 'text-secondary text-sm';

  if (loading === true) {
    return (
      <React.Fragment>
        <Row>
          <Col xl="9">
            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className={titleClass}>Customer & Booking</BlockTitle>
              <p className={descriptionClass}>Information about the customer and their booking</p>
            </div>

            <Block>
              <Skeleton count={2} height="60px" className="mb-4" />
            </Block>

            <div className="divider"/>

            <div className="mb-3 mt-4">
              <BlockTitle tag="h4" className={titleClass}>Travel Details</BlockTitle>
              <p className={descriptionClass}>Details related to the customers travel information</p>
            </div>

            <Block>
              <Skeleton count={2} height="60px" className="mb-4" />
            </Block>

            <div className="divider"/>

            <div className="mb-3 mt-4">
              <BlockTitle tag="h4" className={titleClass}>Protection</BlockTitle>
              <p className={descriptionClass}>How the booking is protected</p>
            </div>

            <Block>
              <Skeleton count={2} height="60px" className="mb-4" />
            </Block>

            <div className="divider"/>

            <div className="mb-3 mt-4">
              <BlockTitle tag="h4" className={titleClass}>Metadata</BlockTitle>
              <p className={descriptionClass}>Metadata related to the booking</p>
            </div>

            <Block>
              <Skeleton count={2} height="60px" className="mb-4" />
            </Block>

          </Col>

          <Col xl="3" className="pl-xl-4 bl-xl">

            <div className="divider-xl-down"/>

            <div className="mb-3 mt-4 mt-xl-1">
              <BlockTitle tag="h4" className={titleClass}>Financials</BlockTitle>
              <p className={descriptionClass}>Financial breakdown of the booking</p>
            </div>

            <Block>
              <Skeleton count={5} height="60px" className="mb-4" />
            </Block>

          </Col>

        </Row>

      </React.Fragment>
    )
  }

  const totalValue = CompletedTransactionValue(data);
  const paidOutValue = PaidOutTotal(data);
  const elementsValue = ElementsTotalValue(data);
  const availableToBePaidOut = AvailablePayoutValue(data);

  return (
    <React.Fragment>
      <Head title="Booking Detail" />

      <Row>
        <Col xl="9">
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Customer & Booking</BlockTitle>
            <p className={descriptionClass}>Information about the customer and their booking</p>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Booking Reference"
                  description="Your booking reference"
                  data={
                    <React.Fragment>
                      { HasRole(account, ['booking-and-payment:reassign:booking']) && (
                        <ReassignButton data={data} triggerReload={triggerReload} />
                      )}
                      { data.booking_reference }
                    </React.Fragment>
                  } />

                <FieldEdit
                  data={ data.customer_name }
                  bookingID={ data.id }
                  fieldName="customer_name"
                  name="Customer Name"
                  description="The customers first and last name"
                  triggerReload={triggerReload}
                />

                { data.email && (
                  <FieldEdit
                    data={ data.email }
                    bookingID={ data.id }
                    fieldName="email"
                    name="Customer Email"
                    description="The customers email address"
                    triggerReload={triggerReload}
                  />
                )}

              </Row>

            </div>
          </Block>

          <div className="divider"/>

          <div className="mb-3 mt-4">
            <BlockTitle tag="h4" className={titleClass}>Travel Details</BlockTitle>
            <p className={descriptionClass}>Details related to the customers travel information</p>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <DateEdit
                  data={ data.departure_date }
                  bookingID={ data.id }
                  fieldName="departure_date"
                  name="Departure Date"
                  description="The date that the holiday departs"
                  triggerReload={triggerReload}
                />

                <DateEdit
                  data={ data.return_date }
                  bookingID={ data.id }
                  fieldName="return_date"
                  name="Return Date"
                  description="The date that the holiday returns"
                  triggerReload={triggerReload}
                />

              </Row>

            </div>

          </Block>

          <div className="divider"/>

          <div className="mb-3 mt-4">
            <BlockTitle tag="h4" className={titleClass}>Protection</BlockTitle>
            <p className={descriptionClass}>How the booking is protected</p>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <FieldEdit
                  data={startCase(camelCase(data.package_type?.id || 'Not Set'))}
                  bookingID={data.id}
                  fieldName="package_type"
                  name="Package Type"
                  description="The booking package type"
                  triggerReload={triggerReload}
                  inputSelectOptions={packageTypes}
                  inputSelectValue={{value: data.package_type?.id, label: startCase(camelCase(data.package_type?.id))}}
                />

                <FieldEdit
                  displayValue={ data.atol_receipt_number ? data.atol_receipt_number : 'Not Set' }
                  data={ data.atol_receipt_number ? data.atol_receipt_number : '' }
                  bookingID={data.id}
                  fieldName="atol_receipt_number"
                  name="ATOL Receipt Number"
                  description="The receipt number from the ATOL certificate"
                  triggerReload={triggerReload}
                />

              </Row>
            </div>
          </Block>

          <div className="divider"/>

          <div className="mb-3 mt-4">
            <BlockTitle tag="h4" className={titleClass}>Metadata</BlockTitle>
            <p className={descriptionClass}>Metadata related to the booking</p>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Felloh ID"
                  description="The felloh ID for this booking"
                  data={data.id} />

                { data.user && (
                  <Field
                    name="Created By"
                    description="The user that created this booking"
                    data={
                      <PiiObfuscator className="data-value" type="email">{ data.user?.email || 'Not Set' }</PiiObfuscator>
                    } />
                )}

                <Field
                  name="Created On"
                  description="The date that the booking was created with Felloh"
                  data={ moment(data.created_at).format('Do MMMM YYYY, h:mm:ss a') } />

                <Field
                  name="Updated At"
                  description="The date that the booking was last updated with Felloh"
                  data={ moment(data.updated_at).format('Do MMMM YYYY, h:mm:ss a') } />

              </Row>

            </div>
          </Block>
        </Col>

        <Col xl="3" className="pl-xl-4 bl-xl">

          <div className="divider-xl-down"/>

          <div className="mb-3 mt-4 mt-xl-1">
            <BlockTitle tag="h4" className={titleClass}>Financials</BlockTitle>
            <p className={descriptionClass}>Financial breakdown of the booking</p>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <FieldEdit
                  displayValue={ data.gross_amount ? CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(data.gross_amount)) : 'Not Set' }
                  data={ data.gross_amount ? data.gross_amount / 100 : 0 }
                  bookingID={ data.id }
                  fieldType="number"
                  fieldName="gross_amount"
                  name="Total Booking Value"
                  description="The total value of the booking due by the customer"
                  triggerReload={triggerReload}
                  itemWidth={12}
                />

                {
                  elementsValue > 0 && (
                    <Field
                      name="Elements Value"
                      description="The total value of all elements on the booking"
                      itemWidth={12}
                      data={ CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(elementsValue)) } />
                  )
                }

                <Field
                  name="Customer Paid"
                  description="The amount the customer has paid via Felloh"
                  itemWidth={12}
                  data={ CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(totalValue)) } />

                {
                  data.gross_amount && (
                    <Field
                      name="Customer Outstanding"
                      description="The amount the customer still has to pay on the booking"
                      itemWidth={12}
                      data={ CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals((data.gross_amount - totalValue))) } />
                  )
                }

                <Field
                  name="Paid Out"
                  description="The amount that has been paid out on this booking"
                  itemWidth={12}
                  data={ CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(paidOutValue)) } />

                <Field
                  name="Available to Pay Out"
                  description="The amount available to pay out"
                  itemWidth={12}
                  data={ CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(availableToBePaidOut)) } />

              </Row>
            </div>
          </Block>

        </Col>

      </Row>

    </React.Fragment>
  );
};
export default BookingInformation;
