import React, { useCallback } from "react";
import { Row, Col, FormGroup, Card, ButtonGroup } from "reactstrap";
import AsyncSelect from 'react-select/async';
import { Formik } from 'formik';
import { API_ROUTES } from 'src/config/api';

import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button, Icon,
} from "src/components/Component";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { fetchOrganisations } from "../../../../../actions/organisationActions";

const FormLayoutsPage = ({ ...props }) => {
    const { organisation } = useSelector((state) => state);
    const history = useHistory();
    const dispatch = useDispatch();

    const findOrganisations = useCallback(async (value) => {
        const response = [];

        organisation.organisations.forEach((item) => {
            if (item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || item.id.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                response.push(item);
            }
        });

        return response;
    }, [organisation]);

    return (
      <Formik
        initialValues={{
            id: '',
            name: '',
            parent: '',
        }}
        validationSchema={Yup.object().shape({
            id: Yup.string().max(10).required('Organisation ID is required'),
            name: Yup.string().max(50).required('Organisation Name is required'),
            parent: Yup.string().required('Parent organisation is required')
        })}
        onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
        }) => {
            setSubmitting(true);

            const user = await Auth.currentAuthenticatedUser();

            await Axios(
              API_ROUTES.user['organisations:create'](),
              {
                  method: 'put',
                  data: values,
                  headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
              }
            );

            await dispatch(fetchOrganisations());

            history.push(`/organisations/${values.id.toUpperCase()}`);
        }}
        >
          {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
            }) => (
              <React.Fragment>
                  <Head title="New Organisation" />
                  <Content>
                      <Card className="card-bordered p-5">
                          <BlockHead size="lg" wide="sm" className="p-0">
                              <BlockHeadContent>
                                  <BlockTitle className="text-primary" page>
                                      Create an Organisation
                                  </BlockTitle>
                              </BlockHeadContent>
                          </BlockHead>

                          <div className="nk-divider divider md pb-4" />

                          <Block size="lg">
                              <form noValidate className="is-alter" onSubmit={handleSubmit}>
                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label" htmlFor="site-name">
                                                  Organisation ID
                                              </label>
                                              <span className="form-note">The unique ID of the organisation</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="id"
                                                    name="id"
                                                    value={values.id}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.id && errors.id && <span className="invalid">{errors.id}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>

                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label" htmlFor="site-name">
                                                  Organisation Name
                                              </label>
                                              <span className="form-note">The name of the organisation</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.name && errors.name && <span className="invalid">{errors.name}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>

                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label">Parent Organisation</label>
                                              <span className="form-note">The over-arching parent of the organisation</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          { values.parent !== '' && (
                                            <React.Fragment>
                                                <ButtonGroup>
                                                    <Button disabled color="secondary">{ values.parent }</Button>
                                                    <Button onClick={(e) => {
                                                        e.preventDefault();
                                                        setFieldValue("parent", '');
                                                    }} color="danger"><Icon name="cross" /></Button>
                                                </ButtonGroup>
                                            </React.Fragment>
                                          )}
                                          { values.parent === '' && (
                                              <FormGroup>
                                                  <div className="form-control-wrap">
                                                      <AsyncSelect
                                                        isClearable
                                                        name="parent"
                                                        id="parent"
                                                        className="form-control form-control-xl p-0 border-0 indicator-hidden"
                                                        value={values.parent}
                                                        getOptionLabel={e => `${e.name} | ${e.id}`}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={findOrganisations}
                                                        onChange={(opt, e) => {
                                                            setFieldValue("parent", opt.id);
                                                        }}
                                                        placeholder=""
                                                        classNamePrefix="react-select"
                                                      />
                                                      {touched.parent && errors.parent && <span className="invalid">{errors.parent}</span>}
                                                  </div>
                                              </FormGroup>
                                          )}
                                      </Col>
                                  </Row>

                                  <div className="nk-divider divider md" />
                                  <Row className="g-3">
                                      <Col lg="7" className="offset-lg-5">
                                          <FormGroup>
                                              <Button disabled={Object.keys(errors).length > 0 || isSubmitting === true} type="submit" color="primary" size="lg" className="float-right">
                                                  Create Organisation {isSubmitting}
                                              </Button>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </form>
                          </Block>
                      </Card>
                  </Content>
              </React.Fragment>
            )}
      </Formik>
    );
};

export default FormLayoutsPage;
