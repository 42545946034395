import React, {useCallback, useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button, Icon,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import {Auth} from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import {Spinner, Card, CardBody} from "reactstrap";
import {Link, useParams} from "react-router-dom";

const WebhookNotifications = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [raw, setRaw] = useState(null);
  const { id } = useParams();

  const getData = useCallback(async (triggerLoading = true) => {
    if (triggerLoading || data === null) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.webhooks['webhook:get'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setRaw(result.data.data);
    setData(result.data.data.webhook_types.map((item) => ({
      ...item,
      changing: false,
    })));
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const changeStatus = async (organisationID, key, status) => {
    let dataCopy = [...data];
    dataCopy[key].changing = true;
    setData(dataCopy);

    const user = await Auth.currentAuthenticatedUser();
    const action = status === true ? 'add' : 'remove';

    await Axios.post(
      API_ROUTES.webhooks["webhook:notification:update"](id, action),
      JSON.stringify({
        'webhook-type-id': dataCopy[key].id,
      }),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    dataCopy = [...dataCopy];
    dataCopy[key].active = status;
    dataCopy[key].changing = false;
    setData(dataCopy);
  }

  return (
    <React.Fragment>
      <Head title="Webhook Notification Options" />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent className="mb-2">
            <BlockTitle className="text-primary">
              Webhook Management
            </BlockTitle>
          </BlockHeadContent>

          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content">
                <ul className="nk-block-tools g-3">
                  <Link to={'/developers'}>
                    <Button color="light" outline className="btn-white">
                      <Icon name="back-ios" />
                      <span>Back</span>
                    </Button>
                  </Link>
                </ul>
              </div>
            </div>
          </BlockHeadContent>

        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <CardBody className="card-inner card-inner-lg">
            <h5 className="text-secondary mb-4">Webhook Information</h5>
            { loading === true && (
              <div className="mt-5">
                <Skeleton count={1} height="60px" className="mb-4" style={{
                  width: '100%',
                }}/>
              </div>
            )}
            { loading === false && (
              <Block>
                <div className="row g-3">
                  <div className="col-6">
                    <span className="sub-text">Notification URL:</span>
                    <span>{ raw.url }</span>
                  </div>
                  <div className="col-6">
                    <span className="sub-text">Child Organisations:</span>
                    <span>{ raw.child_organisations === true ? 'Enabled' : 'Disabled' }</span>
                  </div>
                </div>
              </Block>
            )}
          </CardBody>
        </Card>

        <Card className="card-bordered">
          <div className="card-inner card-inner-lg">
            <h5 className="text-secondary mb-2">Notification Options</h5>

            { loading === true && (
              <div className="mt-3">
                <Skeleton count={5} height="60px" className="mb-4" style={{
                  width: '100%',
                }}/>
              </div>
            )}

            { loading === false && (
              <div>
                <div className="data-item">
                  <div className="data-col" style={{ width: '25%' }}>
                    <span className="data-label text-primary fs-14px"><strong>Notification Name</strong></span>
                  </div>
                  <div className="data-col" style={{ width: '60%' }}>
                    <span className="data-label text-primary fs-14px"><strong>Notification Description</strong></span>
                  </div>
                  <div className="data-col data-col-end">
                    {` `}
                  </div>
                </div>

                { data.map((item, key) => {
                  return (
                    <div className="data-item" key={item.id}>
                      <div className="data-col" style={{ width: '25%' }}>
                        <span className="data-label text-primary">{ item.machine_name }</span>
                      </div>
                      <div className="data-col" style={{ width: '60%' }}>
                        <span className="data-label text-primary">{ item.description }</span>
                      </div>
                      <div className="data-col data-col-end">
                        <div className="data-col data-col-end">
                          <span>{ item.changing && <Spinner size="sm" className="mt-1 mr-3"> </Spinner> }</span>
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              checked={item.active}
                              disabled={item.changing}
                              onChange={() => changeStatus(id, key, item.active === false)}
                              id={`feature-switch-${item.id}`}
                            />
                            <label className="custom-control-label" htmlFor={`feature-switch-${item.id}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              </div>
            )}
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default WebhookNotifications;
