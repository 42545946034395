import React, {useState} from 'react';
import {Button, Icon} from 'src/components/Component';
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";

const ActivateButton = ({ data, triggerReload }) => {
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);

  const toggleForm = () => setModalForm(!modalForm);

  const reassign = async () => {
    setModalFormProcessing(true);
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Axios(
        API_ROUTES.ledger['beneficiary:activate'](data.id),
        {
          method: 'post',
          data: {},
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setModalFormProcessing(false);
      triggerReload();
      toggleForm();
    } catch(error) {
      setModalFormProcessing(false);
      triggerReload();
      toggleForm();
    }
  };

  return (
    <React.Fragment>
      <Button
        id="create-payout-button"
        size="sm"
        color="white"
        outline
        className="btn-primary"
        onClick={toggleForm}
      >
        <span>Enable</span>
      </Button>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-danger text-white"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Enable Beneficiary
        </ModalHeader>
        <ModalBody>
          <p>Are you sure that you want to enable this beneficiary?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" type="submit" disabled={modalFormProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                reassign();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default ActivateButton;
