import React, { useEffect } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import Content from 'src/layout/content/Content';
import { Block, BlockHead, BlockTitle, BlockHeadContent, BlockBetween, Icon } from "src/components/Component";
import { Card, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { API_ROUTES } from 'src/config/api';
import { useSelector } from "react-redux";
import ParseName from 'parse-full-name';

import { BanknotesIcon, UserCircleIcon, BriefcaseIcon, LinkIcon } from '@heroicons/react/24/outline';

import CustomerInformation from './views/information';
import CustomerPayments from './views/payments';
import CustomerBookings from './views/bookings';
import CustomerPaymentLinks from './views/payment-links';

import Axios from 'axios';

const Customer = () => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});
  const { organisation } = useSelector((state) => state);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.agent['customer:get'](id),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setData(result.data.data);
      setLoading(false);
    };

    fetch();
  }, [organisation.organisation]);

  return (
    <React.Fragment>
      <Content>

        <Block>
          <Card className="border my-4">
            <BlockHead className="px-6 pt-4 pb-4 bg-gray-50">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className="page-title text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal">
                    <strong>Customer | { data.customer_name ? `${ParseName.parseFullName(data.customer_name).first} ${ParseName.parseFullName(data.customer_name).last}` : id }</strong>
                  </BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-expand-content" style={{ display: "block" }}>
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/Customers'}>
                        <Button color="dark" outline className="bg-violet-800 text-white border-none" id="button__back">
                          <Icon name="back-ios" />
                          <span>Back</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card pt-3">
              <li
                className={`nav-item ${window.location.pathname === `/customer/${id}`
                  ? "active current-page"
                  : ""
                  } `}
              >
                <Link
                  to={`/customer/${id}`}
                  className={`nav-link
                    ${window.location.pathname === `/customer/${id}` ? "active" : ""}
                  `}
                >
                  <UserCircleIcon className="h-5 w-5 mr-1" />
                  <span>Customer Information</span>
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname === `/customer/${id}/payment-links`
                  ? "active current-page"
                  : ""
                  } `}
              >
                <Link
                  to={`/customer/${id}/payment-links`}
                  className={`nav-link
                    ${window.location.pathname === `/customer/${id}/payment-links` ? "active" : ""}
                  `}
                >
                  <LinkIcon className="h-5 w-5 mr-1" />
                  <span>Payment Links</span>
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname === `/customer/${id}/payments`
                  ? "active current-page"
                  : ""
                  } `}
              >
                <Link
                  to={`/customer/${id}/payments`}
                  className={`nav-link
                    ${window.location.pathname === `/customer/${id}/payments` ? "active" : ""}
                  `}
                >
                  <BanknotesIcon className="h-5 w-5 mr-1" />
                  <span>Payments</span>
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname === `/customer/${id}/bookings`
                  ? "active current-page"
                  : ""
                  } `}
              >
                <Link
                  to={`/customer/${id}/bookings`}
                  className={`nav-link
                    ${window.location.pathname === `/customer/${id}/boookings` ? "active" : ""}
                  `}
                >
                  <BriefcaseIcon className="h-5 w-5 mr-1" />
                  <span>Bookings</span>
                </Link>
              </li>
            </ul>
            <Switch>
              <Route
                exact
                path={`/customer/:id/`}
                render={() => <CustomerInformation customerID={id} loading={loading} data={data} />}
              />
              <Route
                exact
                path={`/customer/:id/payments`}
                render={() => <CustomerPayments customerID={id} />}
              />
              <Route
                exact
                path={`/customer/:id/bookings`}
                render={() => <CustomerBookings customerID={id} />}
              />
              <Route
                exact
                path={`/customer/:id/payment-links`}
                render={() => <CustomerPaymentLinks customerID={id} />}
              />
            </Switch>
          </Card>
        </Block>
      </Content >
    </React.Fragment >
  );
}

export default Customer;
