import React, { useState, useCallback } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  Form,
} from "reactstrap";
import {
  Icon,
  Col,
  Button,
} from "src/components/Component";
import { useForm } from "react-hook-form";
import { Auth } from 'aws-amplify';
import { API_ROUTES } from 'src/config/api';
import Axios from 'axios';
import {useSelector} from "react-redux";

const defaultState = {
  first_name: "",
  last_name: "",
  email: "",
};

const AddUserModal = ({ refreshUsers }) => {
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const organisation = useSelector((state) => state.organisation);

  const [formData, setFormData] = useState(defaultState);

  const onFormSubmit = useCallback(async (userData) => {
    setFormData(defaultState);
    setModal({ edit: false }, { add: false });
    const user = await Auth.currentAuthenticatedUser(organisation.organisation);

    await Axios.post(
      API_ROUTES.user['organisation:user:add'](organisation.organisation),
      JSON.stringify(userData),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    refreshUsers();
  }, [organisation.organisation]);

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    setFormData(defaultState);
    refreshUsers();
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Button color="primary" className="bg-violet-800" onClick={() => setModal({ add: true })}>
        <span>Add / Invite User</span>
        <Icon name="user-add" />
      </Button>

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm" />
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        defaultValue={formData.first_name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.first_name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        defaultValue={formData.last_name}
                        placeholder="Enter Last name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.last_name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

    </React.Fragment>
  );
};
export default AddUserModal;
