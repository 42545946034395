import React from "react";
import { useRanger } from "react-ranger";

import { indexLabel } from "../utils";

const Ranger = ({ points, setPoints }) => {
  const { getTrackProps, segments, handles } = useRanger({
    min: 0,
    max: 100,
    stepSize: 1,
    values: points,
    onChange: setPoints,
    onDrag: setPoints,
  });

  return (
    <div
      {...getTrackProps({
        style: { height: "20px", borderRadius: "10px", }
      })}
    >
      {segments.map(({ getSegmentProps }, index) => (
        <div {...getSegmentProps({
          style: { height: "100%", borderRadius: "10px", color: "#fff", textAlign: "center", },
          className: ((index % 2 === 0) ? "bg-primary" : "bg-secondary"),
        })} index={index}>{indexLabel(index)}</div>
      ))}
      {handles.map(({ getHandleProps }) => (
        <button
          {...getHandleProps({
            style: {
              width: "20px",
              height: "20px",
              borderRadius: "100%",
              border: "0px none"
            }
          })}
        />
      ))}
    </div>
  );
};

export default Ranger;
