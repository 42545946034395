import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import {useSelector} from 'react-redux';
import API_ROUTES from 'src/config/api';
import UserAddModal from "./modal/add-user";
import moment from "moment";
import {STATUS_TYPES_OBJECT, IMPORTED} from "src/constants/user";
import ActivateUser from "./components/activate-user";
import PiiObfuscator from "src/components/pii-obfuscator";

const RowFormatter = ({item}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <strong className="date"><PiiObfuscator type="customer_name">{ item.first_name } {item.last_name}</PiiObfuscator></strong>
      </td>
      <td>
        <span className="date"><PiiObfuscator type="email">{item.email}</PiiObfuscator></span>
      </td>
      <td>
        <span>{ moment(item.created_at).format('Do MMMM YYYY, h:mm:ss a') }</span>
      </td>
      <td className="text-center">
        <span>{STATUS_TYPES_OBJECT[item.status]}</span>
      </td>
      <td className="text-center">
        { item.status === IMPORTED && (
          <ActivateUser user={item} />
        )}
        { item.status !== IMPORTED && (
          <ul className="nk-tb-actions gx-1">
            <li>
              <Link
                to={`/users/${item.id}`}
                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h" />
              </Link>
            </li>
          </ul>
        )}
      </td>
    </tr>
  );
}

const PageOptions = ({reloadTrigger}) => (
  <ul className="nk-block-tools g-3">
    <UserAddModal refreshUsers={reloadTrigger} />
  </ul>
);

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th>
        <strong>Name</strong>
      </th>
      <th>
        <span>Email</span>
      </th>
      <th>
        <span>User Since</span>
      </th>
      <th className="text-center">
        <span>Status</span>
      </th>
      <th>
        <span>{' '}</span>
      </th>
    </tr>
  </React.Fragment>
);

const Suppliers = () => {
  const { organisation } = useSelector((state) => state);
  const [ endpoint, setEndpoint ] = useState(API_ROUTES.user['organisation:users'](organisation.organisation));
  const [ reloadTrigger, setReloadTrigger ] = useState(0);

  useEffect(() => {
    setEndpoint(API_ROUTES.user['organisation:users']());
    setReloadTrigger(reloadTrigger + 1)
  }, [organisation.organisation]);

  return (
    <DataTable
      title="Users"
      largeHeading={true}
      columnCount={5}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={() => <PageOptions reloadTrigger={setReloadTrigger} /> }
      endpoint={endpoint}
      reloadTrigger={reloadTrigger}
      noHeader={true}
      searchPlaceholder="Name or email"
    />
  );
};

export default Suppliers;
