/**
 * Calculate the payment link count
 * @param data
 * @returns {number}
 * @constructor
 */
const PaymentLinkAllCount = (data) => {
  if (typeof data.payment_links !== 'undefined') {
    return data.payment_links.length
  }

  return 0;
}

export default PaymentLinkAllCount;
