import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button as ButtonShape,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import {
  Icon,
} from "src/components/Component";
import ProviderNameTransformer from "src/transformer/provider-name";
import Options from '../options';

const activeStateLabel = (item) => {
  if (!item.payment_provider.is_active) {
    return 'GLOBALLY INACTIVE';
  }
  if (!item.is_active) {
    return 'INACTIVE';
  }
  return 'ACTIVE';
};

const paymentTypesLabels = (item) => {
  return (
    <>
      <ButtonShape color="primary" disabled className="mr-1" size="sm">{item.payment_provider.payment_method}</ButtonShape>
      {item.googlepay_enabled && <ButtonShape color="primary" disabled className="mr-1" size="sm">GOOGLE PAY</ButtonShape>}
      {item.applepay_enabled && <ButtonShape color="primary" disabled className="mr-1" size="sm">APPLE PAY</ButtonShape>}
    </>
  );
}

const Item = ({ item, organisation, activeState, reload  }) => (
  <Card className="card-bordered mb-2">
    <CardHeader className={(activeState ? "bg-secondary" : "bg-danger")}>
      <UncontrolledDropdown className="float-right">
        <DropdownToggle
          tag="a"
          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
        >
          <Icon name="more-h" className="text-white" />
        </DropdownToggle>
        <Options
          organisation={organisation}
          item={item}
          reload={reload}
        />
      </UncontrolledDropdown>
      <CardTitle tag="h5" className="text-white pt-1">{item.name}</CardTitle>
    </CardHeader>
    <CardBody className="card-inner p-1">
      <table className="table">
        <tbody>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Payment Provider</th>
          <td>{ProviderNameTransformer(item.payment_provider.name)}</td>
        </tr>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Payment Types</th>
          <td>{paymentTypesLabels(item)}</td>
        </tr>
         <tr>
          <th scope="row" className="w-250px w-max-250px">Transaction Methods</th>
          <td>{ item.transaction_methods.map((item, key) => (
            <ButtonShape key={key} color="primary" disabled className="mr-1 mb-1" size="sm">{item.name}</ButtonShape>
          )) }</td>
        </tr>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Currencies</th>
          <td>{ item.currencies.map((item, key) => (
            <ButtonShape key={key} color="primary" disabled className="mr-1 mb-1" size="sm">{item}</ButtonShape>
          )) }</td>
        </tr>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Public Key</th>
          <td>{item.public_key}</td>
        </tr>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Private Key (Last 4 Digits)</th>
          <td>{item.secret_key_last_four}</td>
        </tr>
        <tr>
          <th scope="row" className="w-250px w-max-250px">Status</th>
          <td>{activeStateLabel(item)}</td>
        </tr>
        </tbody>
      </table>
    </CardBody>
  </Card>
);

export default Item;
