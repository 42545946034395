import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';

import Content from 'src/layout/content/Content';

import Information from './views/information';
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Icon, Button } from "src/components/Component";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import { useSelector } from "react-redux";
import HasRole from "src/utils/has-role";
import HasFeature from "src/utils/has-feature";
import Settlement from "./views/settlement";
import Payout from "./views/payout";
import Bank from './views/bank';

const Payment = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [reloadCount, setReloadCount] = useState(0);

  const { organisation } = useSelector((state) => state);
  const { account } = useSelector((state) => state.account);

  const triggerReload = () => setReloadCount(reloadCount + 1);

  const getPayment = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent["payment:get"](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);
    setLoading(false);
  }, [organisation.organisation]);

  useEffect(() => {
    getPayment();
  }, [organisation.organisation, reloadCount]);

  return (
    <React.Fragment>
      <Content>

        <Block>
          <Card className="border my-4">
            <BlockHead className="px-6 pt-4 pb-4 bg-gray-50">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className="page-title text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal"><strong>Payment |</strong> {id}</BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-expand-content" style={{ display: "block" }}>
                    <ul className="nk-block-tools g-3">
                      {data?.booking?.id && (
                        <li>
                          <Link to={`/booking/${data?.booking?.id}`}>
                            <Button color="light" outline className="bg-violet-800 text-xs text-white hover:bg-violet-900 hover:border-violet-900">
                              <Icon name="clipboard" />
                              <span>View Booking</span>
                            </Button>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>


            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card pt-3">
              <li
                className={`nav-item ${window.location.pathname === `/payment/${id}`
                    ? "active current-page"
                    : ""
                  } `}
              >
                <Link
                  to={`/payment/${id}`}
                  className={`nav-link
                    ${window.location.pathname === `/payment/${id}` ? "active" : ""}
                  `}
                >
                  <Icon name="info-i" />
                  <span>Payment Information</span>
                </Link>
              </li>

              {data?.status?.id === 'COMPLETE' && (
                <li
                  className={`nav-item ${window.location.pathname === `/payment/${id}/settlement`
                      ? "active current-page"
                      : ""
                    } `}
                >
                  <Link
                    to={`/payment/${id}/settlement`}
                    className={`nav-link
                    ${window.location.pathname === `/payment/${id}/settlement` ? "active" : ""}
                  `}
                  >
                    <Icon name="money" />
                    <span>Acquirer Reconciliation</span>
                  </Link>
                </li>
              )}

              {HasRole(account, ['payouts:view']) && HasFeature(organisation.features, ['payouts']) && data?.status?.id === 'COMPLETE' && (
                <li
                  className={`nav-item ${window.location.pathname === `/payment/${id}/payout`
                      ? "active current-page"
                      : ""
                    } `}
                >
                  <Link
                    to={`/payment/${id}/payout`}
                    className={`nav-link
                    ${window.location.pathname === `/payment/${id}/payout` ? "active" : ""}
                  `}
                  >
                    <Icon name="wallet-out" />
                    <span>Disbursal Detail</span>
                  </Link>
                </li>
              )}

              {data?.status?.id === 'COMPLETE' && (
                <li
                  className={`nav-item ${window.location.pathname === `/payment/${id}/bank`
                      ? "active current-page"
                      : ""
                    } `}
                >
                  <Link
                    to={`/payment/${id}/bank`}
                    className={`nav-link
                    ${window.location.pathname === `/payment/${id}/bank` ? "active" : ""}
                  `}
                  >
                    <Icon name="wallet" />
                    <span>Bank Reconciliation</span>
                  </Link>
                </li>
              )}

            </ul>

            <div className="card-inner card-inner-lg pt-4">
              <Switch>
                <Route
                  exact
                  path={`/payment/:id/`}
                  render={() => <Information data={data} loading={loading} triggerReload={triggerReload} />}
                />
                <Route
                  exact
                  path={`/payment/:id/settlement`}
                  render={() => <Settlement data={data} loading={loading} triggerReload={triggerReload} />}
                />
                <Route
                  exact
                  path={`/payment/:id/payout`}
                  render={() => <Payout data={data} loading={loading} triggerReload={triggerReload} />}
                />
                <Route
                  exact
                  path={`/payment/:id/bank`}
                  render={() => <Bank data={data} loading={loading} triggerReload={triggerReload} />}
                />
              </Switch>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Payment;
