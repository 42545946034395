import React from 'react';
import {
  Icon,
  Button,
} from 'src/components/Component';
import { Link } from 'react-router-dom';
import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import moment from "moment";
import { convertPenniesToDecimals } from "src/utils/money";
import AuthoriseButton from "../refund/components/authorise-button";
import DeleteButton from "../refund/components/delete-button";
import RefundStatusTransformer from "src/transformer/refund-status";
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const RowFormatter = ({item, triggerReload, enums}) => {
  const total = CurrencyFormat(enums.currency, item.transaction.currency).format(convertPenniesToDecimals(item.amount));
  const transactionTotal = CurrencyFormat(enums.currency, item.transaction.currency).format(convertPenniesToDecimals(item.transaction.amount));
  const status = RefundStatusTransformer(item.status?.id);

  return (
    <React.Fragment>
      <tr key={item.id} className="tb-tnx-item">
        <td className="border-right">
          <strong>Created On:</strong><br />
          <span className="title">{moment(item.created_at).format('Do MMMM YYYY')}</span>
          {
            item.authorised_at && (
              <React.Fragment>
                <br /><br />
                <strong>{item.status.id === 'AUTHORISATION_DECLINED' ? 'Declined On' : 'Authorised On'}:</strong><br />
                <span className="title">{moment(item.authorised_at).format('Do MMMM YYYY')}</span>
              </React.Fragment>
            )
          }
        </td>
        <td className="border-left border-right">
          <strong>Transaction ID:</strong><br />
          <ul>
            <li>
              <Link
                to={`/transaction/${item.transaction.id}`}
              >
                {item.transaction.id}
              </Link>
            </li>
          </ul>
          {item.description && (
            <React.Fragment>
              <br />
              <strong>Description:</strong>
              <br /> <span>{item.description}</span>
            </React.Fragment>
          )}
        </td>
        <td className="text-center border-l w-150px">
          <span className="date">{total}</span>
        </td>
        <td className="text-center w-150px">
          <span className="date">{transactionTotal}</span>
        </td>
        <td className="text-center border-r w-150px">
          <span className="date">{((item.amount / item.transaction.amount) * 100).toFixed(2)}%</span>
        </td>
        <td className="border-left text-center">
          <div className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-white ${status.color}`}>
            <Icon name={`ni text-white icon ${status.icon} pr-1`} />
            <span className="date" style={{ verticalAlign: 'top' }}>{status.label}</span>
          </div>
          {item.error_message && (
            <div className='mt-3'>
              <span>{item.error_message}</span>
            </div>
          )}
        </td>
        <td className="text-center border-l w-150px">
          {item.status.id === 'PENDING_AUTHORISATION' && (
            <React.Fragment>
              <AuthoriseButton item={item} triggerReload={triggerReload} />
              <DeleteButton item={item} triggerReload={triggerReload} />
            </React.Fragment>
          )}
          {item.status.id !== 'PENDING_AUTHORISATION' && (
            <React.Fragment>
              -
            </React.Fragment>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th rowSpan={2} className="border-right text-left">Date</th>
      <th rowSpan={2} className="border-left text-left">
        <span>Transaction</span>
      </th>
      <th colSpan={3} className="text-center border-x">
        <span>Financial</span>
      </th>
      <th rowSpan={2} className="border-l">
        <span>Status</span>
      </th>
      <th rowSpan={2} className="text-center border-l">
        <span>Actions</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="text-center border-l p-2">
        <span>Refund</span><br /><span>Amount</span>
      </th>
      <th className="text-center p-2">
        <span>Transaction</span><br /><span>Amount</span>
      </th>
      <th className="text-center border-r p-2">
        <span>Percentage</span><br /><span>Of Transaction</span>
      </th>
    </tr>
  </React.Fragment>
);

const PageOptions = ({triggerReload, loading}) => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
    </ul>
  );
};

const Declines = () => {
  return (
    <DataTable
      title="Refunds"
      columnCount={9}
      itemTitle="Refunds"
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={API_ROUTES.agent['refunds:get']()}
      childOrganisationsOption={true}
      largeHeading={true}
      noHeader={true}
    />
  );
};

export default Declines;
