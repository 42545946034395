import React from "react";
import Head from "src/layout/head/Head";
import DataTable from "src/components/data-table";
import moment from "moment";
import CurrencyFormat from "src/transformer/currency-format";
import { convertPenniesToDecimals } from "src/utils/money";
import { Link, useHistory } from "react-router-dom";
import { Button, Icon } from "src/components/Component";
import API_ROUTES from "src/config/api";
import PaymentLinkStatusTransformer from "src/transformer/payment-link-status";

const RowFormatter = ({item, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount));
  let transactionId = null;
  let status = PaymentLinkStatusTransformer(item.status);
  const history = useHistory();

  const rowClick = (event, url) => {  
    if (event.target.tagName !== 'A' && item.status !== 'expired') {
      history.push(url)
    }
  }

  item.transactions.forEach((transaction) => {
    if (transaction.completed_at && transaction.status === 'COMPLETE') {
      transactionId = transaction.id;
    }
  })

  return (
    <tr
      key={item.id}
      className={`tb-tnx-item ${item.status !== 'expired' ? 'cursor-pointer hover:bg-gray-50' : ''}`}
      onClick={(event) => rowClick(event, item.status === 'paid' ? `/transaction/${transactionId}` : `/payment-links/${item.id}`)}
    >
      <td className='text-left'>
        <span className="title">
          {item.booking && (
            <Link to={`/booking/${item.booking.id}`} className="text-violet-800 underline">{item.booking.booking_reference}</Link>
          )}
          {!item.booking && (
            <Link to={`/payment-links/${item.id}/assign`}>
              <Button color="dark" className="bg-violet-800 text-white border-none">Assign to Booking</Button>
            </Link>
          )}
        </span>
      </td>
      <td className='text-center border-l'>
        <span className="date">{total}</span>
      </td>
      <td className="text-center border-l w-100px">
        <Icon name={`ni text-primary icon ${item.methods.open_banking ? 'ni-check' : 'ni-cross'} pr-1`} />
      </td>
      <td className="text-center w-100px">
        <Icon name={`ni text-primary icon ${item.methods.card ? 'ni-check' : 'ni-cross'} pr-1`} />
      </td>
      <td className="text-center border-l">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className='text-center'>
        <span className="title">{moment(item.expires_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="text-center w-120px border-l px-1">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-light text-white ${status.color}`}>
          <Icon name={`ni text-white icon ${status.icon}`} />
          <span className="date">{status.label}</span>
        </span>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Booking Ref</span>
    </th>
    <th className='text-center border-l'>
      <span>Amount</span>
    </th>
    <th className="border-l text-center">
      <span>Open Banking</span>
    </th>
    <th className="text-center">
      <span>Card Payments</span>
    </th>
    <th className='text-center border-l'>
      <span>Created</span>
    </th>
    <th className='text-center'>
      <span>Expires</span>
    </th>
    <th className="text-center border-l border-r">
      <span>Status</span>
    </th>
  </tr>
);
const Information = ({ customerID }) => {
  const pathFilters = {
    'customer_id': customerID,
  }

  return (
    <React.Fragment>
      <Head title="Payment Links" />

      <div>

      <DataTable
        title={'Payment Links'}
        largeHeading={true}
        columnCount={10}
        TableHeaders={TableHeaders}
        RowFormatter={RowFormatter}
        childOrganisationsOption={true}
        dateFilterOption={true}
        dateFilterLabel="Created Date"
        endpoint={API_ROUTES.agent['payment-links:get']()}
        searchPlaceholder="Booking Ref"
        pathFilters={pathFilters}
        noHeader={true}
        hideFilters={true}
        cardClass=' '
      />
      </div>

    </React.Fragment >
  )
}

export default Information;