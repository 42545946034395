import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Skeleton from "react-loading-skeleton";

import { API_ROUTES } from 'src/config/api';
import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
} from "src/components/Component";

import { buildFormData, buildInitialValues } from "../utils";
import Form from "../form";

const OrganisationPaymentConfigEdit = ({ organisation }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [paymentProviders, setPaymentProviders] = useState();
  const [transactionMethods, setTransactionMethods] = useState();

  const { enums } = useSelector((state) => state.enums);
  const currencies = Object.values(enums.currency).map((item) => ({ id: item.minor_unit }));

  const { providerId } = useParams();

  const history = useHistory();

  const getProviderData = useCallback(async () => {
    setLoading(true);

    if (typeof organisation.id === 'undefined' || !organisation.id) {
      return null;
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.payment['organisation:provider:get'](organisation.id, providerId),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );
    const data = result.data.data;

    const initialValues = buildInitialValues(data);

    setInitialValues(initialValues);
    setLoading(false);
  }, [organisation.id, providerId]);

  useEffect(() => {
    const action = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['providers:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setPaymentProviders(data);
    };

    action();
  }, []);

  useEffect(() => {
    const action = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['transaction-methods:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setTransactionMethods(data);
    };

    action();
  }, []);

  useEffect(() => {
    getProviderData();
  }, [getProviderData, organisation.id, providerId]);

  const processForm = async (values) => {
      const user = await Auth.currentAuthenticatedUser();

      const data = buildFormData(values);

      await Axios(
        API_ROUTES.payment['organisation:provider:update'](organisation.id, providerId),
        {
            method: 'put',
            data,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      history.push(`/organisations/${organisation.id}/payment-config`);
  };

  if (loading || !initialValues || !paymentProviders || !transactionMethods || !currencies) {
    return (
      <div className="mt-3">
        <Skeleton count={3} height="150px" className="mb-5" style={{ width: '100%' }}/>
      </div>
    );
  }

  return (
      <React.Fragment>
          <Head title="Edit Payment Provider" />
          <div className="p-4">
          <Content>
            <BlockHead size="lg" wide="sm" className="p-0">
              <BlockHeadContent>
                <BlockTitle className="text-primary" page>
                  Update Payment Provider
                </BlockTitle>
              </BlockHeadContent>
            </BlockHead>

            <div className="nk-divider divider md pb-4" />

            <Block size="lg">

              <Form
                organisation={organisation}
                paymentProviders={paymentProviders}
                transactionMethods={transactionMethods}
                currencies={currencies}
                initialValues={initialValues}
                buttonText="Update Payment Provider"
                processForm={processForm}
                secretKeyOptional={true}
              />

            </Block>
          </Content>
          </div>
      </React.Fragment>
  );
};

export default OrganisationPaymentConfigEdit;
