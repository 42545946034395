import React, {useState} from 'react';
import {Spinner} from "reactstrap";
import {Button} from "../../../../components/Component";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../config/api";
import {useSelector} from "react-redux";

const AddBankButton = () => {
  const { organisation } = useSelector((state) => state);

  const [loadingBankAdd, setLoadingBankAdd] = useState(false);

  const handleBankAdd = async () => {
    setLoadingBankAdd(true);

    const user = await Auth.currentAuthenticatedUser();

    // Create the payment link
    const data = await Axios(
      API_ROUTES.saltedge['bank:add'](),
      {
        method: 'post',
        data: {
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    window.location.href = data.data.data.redirect_url;
  };

  return (
    <React.Fragment>
      <Button disabled={loadingBankAdd} outline className="btn-primary text-white" onClick={() => handleBankAdd()}>
        <span>{loadingBankAdd ? <Spinner size="sm" color="light"> </Spinner> : "Add Bank Account"}</span>
      </Button>
    </React.Fragment>
  )
}

export default AddBankButton;
