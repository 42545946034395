import React, { useState } from 'react';
import Skeleton from "react-loading-skeleton";
import TransactionTypeTransformer from "../../../../../transformer/transaction-type";
import TransactionMethodTransformer from "../../../../../transformer/transaction-method";
import {Link} from "react-router-dom";
import CurrencyFormat from "../../../../../transformer/currency-format";
import {Icon} from "../../../../../components/Component";
import {Card, CardHeader, CardBody, CardFooter, Button, ButtonGroup, PopoverHeader, Popover, PopoverBody} from "reactstrap";
import moment from "moment";
import ProviderNameTransformer from "../../../../../transformer/provider-name";
import {convertPenniesToDecimals} from "../../../../../utils/money";

const OPTION_KEYS = {
  SEVEN_DAY: 'SEVEN_DAY',
  THIRTY_DAY: 'THIRTY_DAY',
  NINETY_DAY: 'NINETY_DAY',
};

const OPTIONS = {
  [OPTION_KEYS.SEVEN_DAY]: {
    title: '7 Days',
    data_key: 'seven',
  },
  [OPTION_KEYS.THIRTY_DAY]: {
    title: '30 Days',
    data_key: 'thirty',
  },
  [OPTION_KEYS.NINETY_DAY]: {
    title: '90 Days',
    data_key: 'ninety',
  }
};

const TransactionFlow = ({ loading, ledgerData }) => {
  const [activeView, setActiveView] = useState(OPTION_KEYS.SEVEN_DAY);
  const [popoverOpen, togglePopover] = useState(false);

  return (
    <React.Fragment>
      <Card className="card-stretch">
        <CardHeader className="pt-4 pb-3 card-bordered bg-white border-bottom-0">
          <h5 className="text-primary mb-0">
            Transaction<small> Type Breakdown | {OPTIONS[activeView].title}</small>
          </h5>
        </CardHeader>

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered">
            <tbody>
            <tr className="tb-tnx-head">
              <th className="border-left text-center"><span>Type</span></th>
              <th className="border-left text-center"><span>Credits</span></th>
              <th className="border-left text-center"><span>Debits</span></th>
              <th className="border-left text-center"><span>Variance</span></th>
            </tr>
            { loading && Array.from(Array(2).keys()).map((key) => (
              <tr className="tb-tnx-item" key={key}>
                <td colSpan={6}>
                  <Skeleton count={1} height="50px" className="m-0 p-1" />
                </td>
              </tr>
            ))
            }
            { loading === false && ledgerData.days[OPTIONS[activeView].data_key].type.length === 0 && (
              <tr className="tb-tnx-item">
                <td colSpan={8} className="text-center">
                  <strong>No transactions pending disbursal</strong>
                </td>
              </tr>
            )}
            { loading === false && ledgerData.days[OPTIONS[activeView].data_key].type.map((item, key) => {
              return (
                <tr className="tb-tnx-item" key={key}>
                  <td>{ item.type }</td>
                  <td className="border-left text-center">{ CurrencyFormat().format( convertPenniesToDecimals(item.credit) ) }</td>
                  <td className="border-left text-center">{ CurrencyFormat().format( convertPenniesToDecimals(item.debit) ) }</td>
                  <td className="border-left text-center">{ CurrencyFormat().format( convertPenniesToDecimals(item.credit - item.debit) ) }</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </CardBody>

        { loading === false && (
          <CardFooter className="bg-light">
            <ButtonGroup className="float-right ml-2">
              <Button
                color={ activeView === OPTION_KEYS.SEVEN_DAY ? 'secondary' : 'primary' }
                onClick={() => setActiveView(OPTION_KEYS.SEVEN_DAY)}
              >
                { OPTIONS[OPTION_KEYS.SEVEN_DAY].title }
              </Button>
            </ButtonGroup>
            <ButtonGroup className="float-right ml-2">
              <Button
                color={ activeView === OPTION_KEYS.THIRTY_DAY ? 'secondary' : 'primary' }
                onClick={() => setActiveView(OPTION_KEYS.THIRTY_DAY)}
              >
                { OPTIONS[OPTION_KEYS.THIRTY_DAY].title }
              </Button>
            </ButtonGroup>
            <ButtonGroup className="float-right ml-2">
              <Button
                color={ activeView === OPTION_KEYS.NINETY_DAY ? 'secondary' : 'primary' }
                onClick={() => setActiveView(OPTION_KEYS.NINETY_DAY)}
              >
                { OPTIONS[OPTION_KEYS.NINETY_DAY].title }
              </Button>
            </ButtonGroup>
          </CardFooter>
        )}

      </Card>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Card className="card-stretch">
        <CardHeader className="pt-4 pb-3 card-bordered bg-white border-bottom-0">
          <div className="float-right pb-1">
            <Icon name="info" id="popover-toggle" className="text-primary" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => togglePopover(popoverOpen === false)}/>
          </div>
          <h5 className="text-primary mb-0">
            Transaction<small> Type Breakdown | {OPTIONS[activeView].title}</small>
          </h5>
        </CardHeader>

        <Popover isOpen={popoverOpen} target="popover-toggle" toggle={() => togglePopover(popoverOpen === false)}>
          <PopoverHeader className="bg-primary text-white">{OPTIONS[activeView].title} Transactions</PopoverHeader>
          <PopoverBody>
            {OPTIONS[activeView].description}
          </PopoverBody>
        </Popover>

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered">
            <tbody>
            <tr className="tb-tnx-head">
              <th><span>ID</span></th>
              <th className="border-left text-center"><span>Processing date</span></th>
              <th className="text-center"><span>Provider</span></th>
              <th className="border-left text-center"><span>Amount</span></th>
              <th className="border-left text-center"><span>Method</span></th>
              <th className="text-center"><span>Type</span></th>
            </tr>
            { loading && Array.from(Array(2).keys()).map((key) => (
              <tr className="tb-tnx-item" key={key}>
                <td colSpan={6}>
                  <Skeleton count={1} height="50px" className="m-0 p-1" />
                </td>
              </tr>
            ))
            }
            { loading === false && ledgerData[OPTIONS[activeView].data_key].length === 0 && (
              <tr className="tb-tnx-item">
                <td colSpan={8} className="text-center">
                  <strong>No transactions pending disbursal</strong>
                </td>
              </tr>
            )}
            { loading === false && ledgerData[OPTIONS[activeView].data_key].map((account, key) => {
              const type = TransactionTypeTransformer(account.type_id);
              const method = TransactionMethodTransformer(account.method_id);

              return (
                <tr className="tb-tnx-item" key={key}>
                  <td>
                    <Link
                      to={`/transaction/${account.id}`}
                    >{ account.id }
                    </Link>
                  </td>
                  <td className="border-left text-center">{ moment(account.completed_at).format('Do MMMM YYYY')  }</td>
                  <td className="text-center">{ ProviderNameTransformer(account.provider_name) }</td>
                  <td className="border-left text-center">{ CurrencyFormat().format( convertPenniesToDecimals(account.amount) ) }</td>
                  <td className="border-left text-center  w-150px">
                    <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
                    <span className="date">{ method.label }</span>
                  </td>
                  <td className="text-center w-150px">
                    <Icon name={`ni text-primary icon ${type.icon} pr-1`} />
                    <span className="date">{ type.label }</span>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </CardBody>

      </Card>

    </React.Fragment>
  )
}

export default TransactionFlow;
