import React from 'react';
import * as Sentry from "@sentry/browser";
import axios from "axios";

const UNAUTHORIZED_ERROR_URL = '/errors/401';

/**
 * Generate the error redirect url
 * @param name
 * @param message
 * @return {string}
 */
const errorRedirect = (name = null, message = null) => {
  const hasError = window.location.href.indexOf('error=defined') !== -1;

  let errorURL = '/?error=defined';

  if (name) {
    errorURL += `&name=${name}`;
  }

  if (message) {
    errorURL += `&message=${message}`;
  }

  if (hasError === false) {
    window.location.href = errorURL
  }
};

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.config.bubbleErrors && error.config.bubbleErrors.includes(error.response.status)) {
      throw error;
    }

    if (error?.response?.status && error?.response?.status?.toString().startsWith('410')) {
      return error.response;
    } else if (error?.response?.status && error?.response?.status?.toString().startsWith('401')) {
      Sentry.captureException(error);
      window.location.href = UNAUTHORIZED_ERROR_URL;
    } else {
      Sentry.captureException(error);
      errorRedirect(error.name, error.message);
    }
  });

class ErrorInterceptor extends React.Component {
  componentDidCatch(error) {
    Sentry.captureException(error);
    //errorRedirect(error.name, error.message);
  }

  render() {
    return this.props.children;
    try {
      return this.props.children;
    } catch (error) {
      Sentry.captureException(error);
      errorRedirect(error.name, error.message);

      return (
        <React.Fragment />
      )
    }
  }
}

export default ErrorInterceptor;
