import React, { useState, useEffect } from 'react';
import {
  Icon,
} from "src/components/Component";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const StatBox = ({
  title,
  figure,
  percentage,
  period,
  loading,
  popoverTitle,
  popoverDescription,
  range,
}) => {
  const [popoverOpen, togglePopover] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(`tooltip-${(Math.random() + 1).toString(36).substring(7)}`);
  }, [])

  return (
    <div className="flex flex-col rounded-md border animate-in fade-in-50 relative">

      {title && (
        <div className="border-bottom bg-gray-50 mb-2 px-4 py-2">
          <div className="absolute right-3 top-2" id={id}>
            {popoverTitle && popoverDescription && id && (
              <div className='float-right mt-0.5 mr-1'>
                <Icon name="info" className="text-primary" style={{ fontSize: '16px', cursor: 'pointer' }} onClick={() => togglePopover(popoverOpen === false)} />
              </div>
            )}
          </div>

          <h6 className="text-md text-primary">
            {title}
            {range && (
              <React.Fragment>
                <span> | </span>
                <span className='text-md text-slate-500 font-light'>
                  {range}
                </span>
              </React.Fragment>
            )}
          </h6>

          {id && (
            <Popover isOpen={popoverOpen} target={id} toggle={() => togglePopover(popoverOpen === false)}>
              <PopoverHeader className="bg-primary text-white">{popoverTitle}</PopoverHeader>
              <PopoverBody>
                {popoverDescription}
              </PopoverBody>
            </Popover>
          )}
        </div>
      )}


      <div className={`align-end flex-sm-wrap g-4 flex-md-nowrap h-auto px-4 ${ title ? 'pt-1' : 'pt-3' } pb-3`}>

        {loading === true && (
          <div className="nk-sale-data w-100 text-center mt-3 mb-3">
            <Skeleton count={1} height="40px" />
            {period && (
              <div className="sub-title mt-2">
                <Skeleton count={1} height="20px" />
              </div>
            )}
          </div>
        )}

        {loading === false && (
          <div className="nk-sale-data">
            <span className="amount">{figure}</span>
            {period && (
              <span className="sub-title">
                {period}
                <span className="change down text-danger pl-1">
                  {percentage && (
                    <React.Fragment>
                      <Icon name="arrow-long-down" />
                      {percentage}%
                    </React.Fragment>
                  )}
                </span>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default StatBox;
