import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Icon,
  Button,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import moment from "moment";
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const RowFormatter = ({item, childFilterState, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount));
  let completed = false;
  let refunded = false;

  item.transactions.forEach((transaction) => {
    if (transaction.completed_at && transaction.status === 'COMPLETE') {
      completed = true;
    } else if (transaction.status === 'REFUNDED') {
      refunded = true;
    }
  })

  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <PiiObfuscator className="title" type="customer_name">
          <strong>{item.customer_name}</strong>
          <br />
          {item.email}
        </PiiObfuscator>
      </td>
      <td>
        <span className="title">
          {item.booking && (
            <Link to={`/booking/${item.booking.id}`} className="link__payment_link underline text-violet-800">{item.booking.booking_reference}</Link>
          )}
          {!item.booking && (
            <Link to={`/ecommerce/${item.id}/assign`}>
              <Button color="dark" className="bg-violet-800 text-white border-none">Assign to Booking</Button>
            </Link>
          )}
        </span>
      </td>
      <td className="border-l text-center">
        <span className="date">{total}</span>
      </td>
      <td className='text-center border-l'>
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className='text-center'>
        <span className="title">{moment(item.expires_at).format('Do MMM, HH:mm')}</span>
      </td>
      {childFilterState === true && (
        <td className="text-center border-l">
          <PiiObfuscator className="title" type="organisation">{item.organisation.name}</PiiObfuscator>
        </td>
      )}
      <td className="text-center border-l">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-light text-white ${completed ? 'bg-emerald-600' : 'bg-gray-600'}`}>
          <Icon name={`ni text-white icon ${completed ? 'ni-check' : refunded ? 'ni-rewind-fill' : 'ni-cross'}`} />
          <span className="date">{completed ? 'Paid' : refunded ? 'Refunded' : 'Pending'}</span>
        </span>
      </td>
    </tr>
  );
}

const TableHeaders = (childFilterState) => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Customer</span>
    </th>
    <th className='text-left'>
      <span>Booking Ref</span>
    </th>
    <th className='text-centre border-l'>
      <span>Amount</span>
    </th>
    <th className='text-centre border-l'>
      <span>Created</span>
    </th>
    <th className='text-centre'>
      <span>Expires</span>
    </th>
    {childFilterState === true && (
      <th className="text-center border-l">
        <span>Organisation</span>
      </th>
    )}
    <th className="text-center border-l">
      <span>Status</span>
    </th>
  </tr>
);

const PageOptions = ({triggerReload, loading}) => {
  return (
    <ul className="nk-block-tools g-3">
            <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
        </li>
    </ul>
  );
};

const PaymentLinks = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const pathFilters = {};
  let pageTitle = 'Ecommerce Sessions';

  if (urlParams.get('has_booking_reference')) {
    pathFilters.filter_booking_exists = urlParams.get('has_booking_reference') === '1' ? true : false;
    pageTitle = pathFilters.filter_booking_exists === true ? 'Assigned Ecommerce Sessions' : 'Unassigned Ecommerce Sessions';
  }

  if (urlParams.get('status')) {
    pathFilters.filter_status = urlParams.get('status');
  }

  return (
    <DataTable
      title={pageTitle}
      columnCount={9}
      largeHeading={true}
      TableHeaders={TableHeaders}
      PageOptions={PageOptions}
      RowFormatter={RowFormatter}
      childOrganisationsOption={true}
      dateFilterOption={true}
      dateFilterLabel="Created Date"
      endpoint={API_ROUTES.agent['ecommerce:get']()}
      searchPlaceholder="Customer or Booking Ref"
      pathFilters={pathFilters}
      noHeader={true}
      pathFiltersTranslations={{
        filter_booking_exists: {
          name: 'Has Booking',
          filterName: 'Unassigned Only',
          selectedValue: false,
          removeIfNotSelected: true,
        },
        filter_status: {
          name: 'status',
        },
      }}
    />
  );
}

export default PaymentLinks;
