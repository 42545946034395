import React, {useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block, BlockTitle, Button,
  Icon,
} from "src/components/Component";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import CurrencyFormat from "../../../../../../transformer/currency-format";
import {convertPenniesToDecimals} from "../../../../../../utils/money";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "../../../../../../config/api";
import {useSelector} from "react-redux";
import PiiObfuscator from "../../../../../../components/pii-obfuscator";

const Payouts = ({ data, loading, triggerReload }) => {
  const [batches, setBatches] = useState([]);
  const [matchingTransactions, setMatchingTransactions] = useState([]);
  const [loadingMatching, setLoadingMatching] = useState(true);
  const [allLoading, setAllLoading] = useState(false);

  const { organisation: { organisation } } = useSelector((state) => state);

  const findMatching = async () => {
    setLoadingMatching(true);
    const user = await Auth.currentAuthenticatedUser();

    const matching = await Axios(
      API_ROUTES.bank['transaction:search'](),
      {
        method: 'post',
        data: {
          organisation,
          keyword: data.booking_reference,
          is_debit: true,
          is_credit: false,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setMatchingTransactions(matching.data.data);
    setLoadingMatching(false);
  };

  const addToBooking = async (item) => {
    setAllLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.bank['transaction:add-to-booking'](item.id, data.id),
      {
        method: 'put',
        data: {
          organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
  };

  const unlinkFromBooking = async (transactionID) => {
    setAllLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.bank['transaction:delete-from-booking'](transactionID, data.id),
      {
        method: 'post',
        data: {
          organisation: organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
  };

  useEffect(() => {
    if (data?.bank_transactions) {
      let supplierTXs = [];
      data?.bank_transactions.forEach((item) => {
        if (item.is_debit) {
          supplierTXs.push(item);
        }
      });
      setBatches(supplierTXs);
    }

    if (loading === false) {
      findMatching();
      setAllLoading(false);
    }
  }, [loading]);

  return (
    <React.Fragment>
      <Head title="Supplier Payments" />

      <div className="mb-3">
        <BlockTitle tag="h4" className="text-primary mb-0">Supplier Payments</BlockTitle>
        <p className="text-secondary fw-bold">Payments from your bank account to suppliers, for this booking.</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">Posting Date</th>
            <th scope="col" className="text-center border-left">Type</th>
            <th scope="col" className="text-center border-left">Amount</th>
            <th scope="col" className="text-center border-left">Description</th>
            <th scope="col" className="text-center border-left pr-0 pl-0">Actions</th>
          </tr>
          </thead>
          <tbody>
          { (loading === true || allLoading === true) && [0, 1].map((key) => (
            <tr className='tb-tnx-item' key={key}>
              <td colSpan={7}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && allLoading === false && batches.length === 0 && (
            <tr className='tb-tnx-item'>
              <td colSpan={7} className="text-center">
                <strong>No supplier payments have been made against this booking</strong>
              </td>
            </tr>
          )}
          { loading === false && allLoading === false && batches.map((item, key) => (
            <tr className='tb-tnx-item' key={key}>
              <td>{ moment(item.posting_date).format('Do MMMM YYYY') }</td>
              <td className="text-center border-left">{ item.type }</td>
              <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals( item.amount )) }</td>
              <td className="text-center border-left">
                <PiiObfuscator className="title" type="bank_description">{ item.description }</PiiObfuscator>
              </td>
              <td className="border-left text-center pl-2 pr-2" style={{ width: '75px' }}>
                <Button className="btn btn-sm btn-primary btn-green text-center" onClick={() => unlinkFromBooking(item.id)}>
                  <Icon className="pr-1" name="unlink" /> Unlink
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </Block>

      <div className="mb-3 mt-5">
        <BlockTitle tag="h4" className="text-primary mb-0">Potential Matches</BlockTitle>
        <p className="text-secondary fw-bold">Transactions from your bank account(s) that look like they match this booking</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">Posting Date</th>
            <th scope="col" className="text-center border-left">Flow</th>
            <th scope="col" className="text-center border-left">Type</th>
            <th scope="col" className="text-center border-left">Amount</th>
            <th scope="col" className="text-center border-left">Description</th>
            <th className="text-center border-left">{' '}</th>
          </tr>
          </thead>
          <tbody>
          { (loadingMatching === true || allLoading === true) && [0, 1].map((key) => (
            <tr className='tb-tnx-item' key={key}>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loadingMatching === false && allLoading === false && matchingTransactions.length === 0 && (
            <tr className='tb-tnx-item'>
              <td colSpan={6} className="text-center">
                <strong>No transactions found that match this booking</strong>
              </td>
            </tr>
          )}
          { loadingMatching === false && allLoading === false && matchingTransactions.map((item, key) => (
            <React.Fragment key={item.id}>
              <tr className='tb-tnx-item'>
                <td>{ moment(item.posting_date).format('Do MMMM YYYY') }</td>
                <td className="text-center border-left">Debit</td>
                <td className="text-center border-left">{ item.type }</td>
                <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals( item.amount )) }</td>
                <td className="text-center border-left">
                  <PiiObfuscator className="title" type="bank_description">{ item.description }</PiiObfuscator>
                </td>
                <td className="text-center border-left p-0">
                  <Button className="btn btn-sm btn-primary btn-green text-center" onClick={() => addToBooking(item)}>
                    <Icon className="pr-1" name="link-h" /> Link to Transaction
                  </Button>
                </td>
              </tr>
            </React.Fragment>
          ))}
          </tbody>
        </table>
      </Block>

    </React.Fragment>
  );
};
export default Payouts;
