/**
 * Calculate value of completed transactions
 * @param data
 * @param includeBankTransaction
 * @returns {null|*}
 * @constructor
 */
const TransactionCompletedTotalValue = (data, includeBankTransaction = true) => {
  let total = 0;

  // Calculate the transaction total
  const transactions = data?.transactions;

  if (typeof transactions !== 'undefined' && transactions.length >= 1) {
    total += transactions.reduce((previous, current) => {
      if (current.status.id === 'COMPLETE') {
        return previous + current.amount
      }

      return previous;
    }, 0);
  }

  // calculate the bank transactions total
  if (data?.bank_transactions && includeBankTransaction === true) {
    data?.bank_transactions.forEach((item) => {
      if (item.is_credit === true) {
        total += item.amount;
      }
    });
  }

  return total;
};

export default TransactionCompletedTotalValue;
