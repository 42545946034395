import React, { useEffect, useId, useState } from 'react';
import ChecboxFilter from './checkbox';
import TextFilter from './text';
import DateFilter from './date';
import moment from "moment";

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Popover } from 'react-tiny-popover'

const activeCheck = (type, value) => {
  if (type === 'checkbox' && value === true) {
    return true;
  }

  if (type === 'text' && value.length >= 1) {
    return true;
  }

  if (type === 'date' && (value.startDate || value.endDate)) {
    return true;
  }

  return false
}

const FilterWrapper = ({ onToggle, type, value, fieldTitle, title, onChange }) => {
  const id = useId();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isActive = activeCheck(type, value);

  useEffect(() => {
    if (isActive) {
      setIsPopoverOpen(false);
    }
  }, [isActive])

  const clickAction = () => {
    if (isActive) {
      if (type === 'checkbox') onToggle(false);
      if (type === 'text') onChange('');
      if (type === 'date') onChange({ startDate: null, endDate: null });
    } else {
      if (type === 'checkbox') {
        onToggle(true);
      } else {
        setIsPopoverOpen(!isPopoverOpen);
      }
    }
  }

  return (
    <div className='relative float-left'>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={(() => {
          if (type === 'checkbox') {
            return (
              <ChecboxFilter onToggle={onToggle} value={value} fieldTitle={fieldTitle} title={title} />
            )

          }

          if (type === 'text') {
            return (
              <TextFilter onChange={onChange} value={value} fieldTitle={fieldTitle} title={title} />
            );
          }

          return (
            <React.Fragment />
          )
        })(setIsPopoverOpen)}
      >
        <div onClick={clickAction}>
          {type === 'date' && !value.startDate && !value.endDate && (
            <DateFilter onToggle={setIsPopoverOpen} onChange={onChange} value={value} fieldTitle={fieldTitle} title={title} />
          )}
          {(type !== 'date' || (type === 'date' && value.startDate && value.endDate)) && (
            <button
              id={id}
              type="button"
              className={`mr-3 hs-dropdown-toggle py-1 pl-2 pr-1 inline-flex gap-x-2 text-xs font-left rounded-md hover:bg-slate-700 ${isActive ? 'text-white border border-emerald-700 bg-emerald-700' : 'bg-white text-violet-800 border border-violet-800'}`}
            >
              {type === 'date' && value.startDate && value.endDate && (
                <span className='text-xs'>
                  <strong>Date:</strong> {moment(value.startDate).format('Do MMM')} to {moment(value.endDate).format('Do MMM')}
                </span>
              )}
              {(!isActive || type !== 'date') && (
                <React.Fragment>{title}</React.Fragment>
              )}
              <span className={`border-l pl-1 ${isActive ? 'border-white' : 'border-violet-800'}`}>
                {isActive && (
                  <XMarkIcon className='h-4 w-4' />
                )}
                {!isActive && (
                  <PlusIcon className='h-4 w-4' />
                )}

              </span>

            </button>
          )}
        </div>
      </Popover>

    </div>
  );
};

export default FilterWrapper;