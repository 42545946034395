import React from "react";
import Content from "../../layout/content/Content";
import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";
import UserProfileActivityPage from "./UserProfileActivity";
import { Route, Switch, Link } from "react-router-dom";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { Card } from "reactstrap";

const UserProfileLayout = () => {
  return (
    <React.Fragment>
      <Content>
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle className="text-primary mb-2">Your Profile</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
              <li
                className={`nav-item ${
                  window.location.pathname === `/user/profile`
                    ? "active current-page"
                    : ""
                } `}
              >
                <Link
                  to={`/user/profile`}
                  className={`nav-link
                    ${window.location.pathname === `/user/profile` ? "active" : ""}
                  `}
                >
                  <Icon name="user-fill-c" />
                  <span>Account Detail</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  window.location.pathname === `/user/activity` ? "active" : ""
                }`}
              >
                <Link
                  to={`/user/activity`}
                  className={`nav-link ${
                    window.location.pathname === `/user/activity` ? "active" : ""
                  }`}
                >
                  <Icon name="activity-round-fill" />
                  <span>Account Activity</span>
                </Link>
              </li>
              <li
                className={`nav-item ${
                  window.location.pathname === `/user/setting` ? "active" : ""
                }`}
              >
                <Link
                  to={`/user/setting`}
                  className={`nav-link ${
                    window.location.pathname === `/user/setting` ? "active" : ""
                  }`}
                >
                  <Icon name="lock-alt-fill" />
                  <span>Security Settings</span>
                </Link>
              </li>
            </ul>

            <div className="card-inner card-inner-lg">
              <Switch>
                <Route
                  exact
                  path={`/user/profile`}
                  render={() => <UserProfileRegularPage />}
                />
                <Route
                  exact
                  path={`/user/activity`}
                  render={() => <UserProfileActivityPage />}
                />
                <Route
                  exact
                  path={`/user/setting`}
                  render={() => <UserProfileSettingPage />}
                />
              </Switch>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;
