import React from 'react';
import { Button } from 'src/components/Component';
import {Link} from "react-router-dom";

const ReassignButton = ({ data }) => {
  return (
    <Link to={`/payment-links/${data.payment_link.id}/assign`}>
      <Button
        id="settle-button"
        color="white"
        outline
        className="btn-green text-white text-center w-100" style={{ display: 'block' }}
      >
        Assign to Booking
      </Button>
    </Link>
  )
}

export default ReassignButton;
