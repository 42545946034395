import React from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Row,
  Col,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import CurrencyFormat from "src/transformer/currency-format";
import {convertPenniesToDecimals} from "src/utils/money";
import {useSelector} from "react-redux";
import ProviderNameTransformer from "src/transformer/provider-name";
import Field from "../../../../../bookings/pages/booking/views/booking-information/components/field";
import moment from "moment";

const Settlement = ({ data = {}, loading = true, triggerReload = false }) => {
  const { enums } = useSelector((state) => state.enums);

  const titleClass = 'text-primary mb-0 text-xl font-bold';
  const descriptionClass = 'text-secondary text-sm';

  if (loading === true) {
    return (
      <React.Fragment>
        <Row>
          <Col sm={12}>
            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className={titleClass}>Settlement Detail</BlockTitle>
              <p className={descriptionClass}>Detail related to how the provider settled funds for the transaction to receiving account</p>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

            <div className="divider"/>

            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className={titleClass}>Batch Detail</BlockTitle>
              <p className={descriptionClass}>Detail related to the batch that the provider settled to receiving account</p>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  if (!data?.settlement?.batch) {
    return (
      <div className="pt-3 pb-2 text-secondary">
        <p className="fs-18px fw-bold text-indigo">Settlement information has not yet been received from { ProviderNameTransformer(data.payment_provider.name) }</p>
        <p className="fs-16px">We are awaiting settlement data from your transaction provider, this data provides information to us regarding the transaction and how it was settled.</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Head title="Payment Information" />
      <Row>
        <Col sm={12}>

          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Settlement Detail</BlockTitle>
            <p className={descriptionClass}>Detail related to how the provider settled the transaction to receiving account</p>
          </div>

          <div className="row g-3 ml-2 mr-2">

            <Field
              name="Transaction Settlement Amount"
              description="The amount that was settled to the receiving account by the acquirer"
              itemWidth={4}
              data={CurrencyFormat(enums.currency, data?.settlement?.currency).format( convertPenniesToDecimals(data?.settlement?.amount) )} />

            <Field
              name="Settlement Currency"
              description="The currency that was settled to the receiving account by the acquirer"
              itemWidth={4}
              data={ enums.currency[data?.settlement?.currency].major_unit } />

            { data.settlement && data?.settlement?.currency && data.currency !== data?.settlement?.currency && (
              <Field
                name="Settlement Exchange Rate"
                description="The exchange rate that was used to convert the transaction amount to the settlement amount"
                itemWidth={4}
                data={ <span>
                  {(data?.settlement?.amount / data.amount).toFixed(2) } {enums.currency[data.currency].major_unit} / 1 {enums.currency[data?.settlement?.currency].major_unit}
                </span> }/>
            )}

            <Field
              name="Provider"
              description="The provider that settled the transaction to the receiving account"
              itemWidth={4}
              data={ ProviderNameTransformer(data.payment_provider.name) } />

          </div>

          <div className="divider"/>

          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Batch Detail</BlockTitle>
            <p className={descriptionClass}>Detail related to the batch that the provider settled to receiving account</p>
          </div>

          <Block>
            <div className="row g-3 ml-2 mr-2">
              <Field
                name="Batch Settlement Amount"
                description="The total amount that was settled to the receiving account by the acquirer for the batch"
                itemWidth={4}
                data={CurrencyFormat(enums.currency, data?.settlement?.batch?.currency).format( convertPenniesToDecimals(data?.settlement?.batch?.amount) )} />

              <Field
                name="Bank Reference"
                description="The bank reference that was provided by the acquirer for the settlement"
                itemWidth={4}
                data={ data?.settlement?.batch?.bank_reference } />

              <Field
                name="Posting Date"
                description="The date that the batch was posted to the receiving account"
                itemWidth={4}
                data={moment(data?.settlement?.batch?.posting_date).format('DD/MM/yy')} />

            </div>
          </Block>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Settlement;
