const environment = process.env.REACT_APP_API_ENV;

export const FRONTEND_ENDPOINTS = {
  assets: {
    dev: 'https://assets.staging.felloh.com/',
    pr: 'https://assets.staging.felloh.com/',
    sandbox: 'https://assets.sandbox.felloh.com/',
    staging: 'https://assets.staging.felloh.com/',
    production: 'https://assets.felloh.com/',
  },
  pay: {
    dev: 'http://localhost:3010/',
    pr: 'https://pay.staging.felloh.com/',
    sandbox: 'https://pay.sandbox.felloh.com/',
    staging: 'https://pay.staging.felloh.com/',
    production: 'https://pay.felloh.com/',
  },
};

export const API_ENDPOINTS = {
  agent: {
    dev: 'http://localhost:3010/',
    pr: 'https://agent.staging.felloh.com/',
    sandbox: 'https://agent.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/agent/',
    production: 'https://api.felloh.com/agent/',
  },
  audit: {
    dev: 'http://localhost:4101/',
    pr: 'https://audit.staging.felloh.com/',
    sandbox: 'https://audit.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/audit/',
    production: 'https://api.felloh.com/audit/',
  },
  bank: {
    dev: 'http://localhost:3030/',
    pr: 'https://bank.staging.felloh.com/',
    sandbox: 'https://bank.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/bank/',
    production: 'https://api.felloh.com/bank/',
  },
  enums: {
    dev: 'http://localhost:3030/',
    pr: 'https://enums.staging.felloh.com/',
    sandbox: 'https://enums.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/enums/',
    production: 'https://api.felloh.com/enums/',
  },
  ledger: {
    dev: 'http://localhost:3040/',
    pr: 'https://ledger.staging.felloh.com/',
    sandbox: 'https://ledger.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/ledger/',
    production: 'https://api.felloh.com/ledger/',
  },
  payment: {
    dev: 'http://localhost:3100/',
    pr: 'https://payment.staging.felloh.com/',
    sandbox: 'https://payment.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/payment/',
    production: 'https://api.felloh.com/payment/',
  },
  reporting: {
    dev: 'http://localhost:3030/',
    pr: 'https://reporting.staging.felloh.com/',
    sandbox: 'https://reporting.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/reporting/',
    production: 'https://api.felloh.com/reporting/',
  },
  saltedge: {
    dev: 'http://localhost:3034/',
    pr: 'https://saltedge.staging.felloh.com/',
    sandbox: 'https://saltedge.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/saltedge/',
    production: 'https://api.felloh.com/saltedge/',
  },
  token: {
    dev: 'http://localhost:3061/',
    pr: 'https://token.staging.felloh.com/',
    sandbox: 'https://token.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/token/',
    production: 'https://api.felloh.com/token/',
  },
  trustPayments: {
    dev: 'http://localhost:3001/',
    pr: 'https://trust-payments.staging.felloh.com/',
    sandbox: 'https://trust-payments.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/trust-payments/',
    production: 'https://api.felloh.com/trust-payments/',
  },
  user: {
    dev: 'http://localhost:3001/',
    pr: 'https://user.staging.felloh.com/',
    sandbox: 'https://user.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/user/',
    production: 'https://api.felloh.com/user/',
  },
  webhooks: {
    dev: 'http://localhost:3030/',
    pr: 'https://webhooks.staging.felloh.com/',
    sandbox: 'https://webhooks.sandbox.felloh.com/',
    staging: 'https://staging.felloh.com/webhooks/',
    production: 'https://api.felloh.com/webhooks/',
  },
};

export const FRONTEND_ROUTES = {
  assets: {
    'base': () => FRONTEND_ENDPOINTS.assets[environment],
  },
  pay: {
    'payment-link': (paymentLinkID) =>
      `${FRONTEND_ENDPOINTS.pay[environment]}${paymentLinkID}`,
  },
};

export const API_ROUTES = {
  agent: {
    'booking:get': (bookingID) =>
      `${API_ENDPOINTS.agent[environment]}bookings/${bookingID}`,

    'bookings:create': () =>
      `${API_ENDPOINTS.agent[environment]}bookings`,

    'booking:delete': (bookingID) =>
      `${API_ENDPOINTS.agent[environment]}bookings/${bookingID}`,

    'booking:update-reference': (bookingID) =>
      `${API_ENDPOINTS.agent[environment]}bookings/${bookingID}/update-reference`,

    'bookings:get': () =>
      `${API_ENDPOINTS.agent[environment]}bookings`,

    'credit-note:get': (id) =>
      `${API_ENDPOINTS.agent[environment]}credit-notes/${id}`,

    'credit-note:assign': (id) =>
      `${API_ENDPOINTS.agent[environment]}credit-notes/${id}/assign`,

    'credit-notes:get': () =>
      `${API_ENDPOINTS.agent[environment]}credit-notes`,

    'credit-notes:put': () =>
      `${API_ENDPOINTS.agent[environment]}credit-notes`,

    'csv:generate': (type) =>
      `${API_ENDPOINTS.agent[environment]}csv/${type}`,

    'customer:get': (customerID) =>
      `${API_ENDPOINTS.agent[environment]}customers/${customerID}`,

    'customers:get': () =>
      `${API_ENDPOINTS.agent[environment]}customers`,

    'chargebacks:get': () =>
      `${API_ENDPOINTS.agent[environment]}chargebacks`,

    'charges:get': () =>
      `${API_ENDPOINTS.agent[environment]}charges`,
      
    'charges:get:aggregates': () =>
     `${API_ENDPOINTS.agent[environment]}charges/aggregates`,

    'ecommerce:get': () =>
      `${API_ENDPOINTS.agent[environment]}ecommerce`,

    'ecommerce:get:one': (ecommerceID) =>
      `${API_ENDPOINTS.agent[environment]}ecommerce/${ecommerceID}`,

    'ecommerce:assign:one': (ecommerceID) =>
      `${API_ENDPOINTS.agent[environment]}ecommerce/${ecommerceID}/assign`,

    'payment:reassign': (paymentID) =>
      `${API_ENDPOINTS.agent[environment]}payments/${paymentID}/re-assign`,

    'payment:get': (paymentID) =>
      `${API_ENDPOINTS.agent[environment]}payments/${paymentID}`,

    'payments:get': () =>
      `${API_ENDPOINTS.agent[environment]}payments`,

    'payment:complete': (transactionID) =>
      `${API_ENDPOINTS.agent[environment]}transactions/${transactionID}/complete`,

    'payment:reverse': (transactionID) =>
      `${API_ENDPOINTS.agent[environment]}transactions/${transactionID}/reverse`,

    'payment-link:assign': (id) =>
      `${API_ENDPOINTS.agent[environment]}payment-links/${id}/assign`,

    'payment-link:delete': (id) =>
      `${API_ENDPOINTS.agent[environment]}payment-links/${id}`,

    'payment-link:get': (id) =>
      `${API_ENDPOINTS.agent[environment]}payment-links/${id}`,

    'payment-links:get': () =>
      `${API_ENDPOINTS.agent[environment]}payment-links`,

    'payment-links:put': () =>
      `${API_ENDPOINTS.agent[environment]}payment-links`,

    'refund:get': (id) =>
      `${API_ENDPOINTS.agent[environment]}refunds/${id}`,

    'refunds:get': () =>
      `${API_ENDPOINTS.agent[environment]}refunds`,

    'refund:authorise': (authorisationCode) =>
      `${API_ENDPOINTS.agent[environment]}refunds/${authorisationCode}/authorise`,

    'refund:create': (transactionID) =>
      `${API_ENDPOINTS.agent[environment]}transactions/${transactionID}/refund`,

    'refund:decline': (authorisationCode) =>
      `${API_ENDPOINTS.agent[environment]}refunds/${authorisationCode}/decline`,

    'suppliers:get': () =>
      `${API_ENDPOINTS.agent[environment]}suppliers`,

    'supplier:create': () =>
      `${API_ENDPOINTS.agent[environment]}supplier/create`,
  },
  audit: {
    'audit:get': () =>
      `${API_ENDPOINTS.audit[environment]}`,
  },
  bank: {
    'account:get': (id) =>
      `${API_ENDPOINTS.bank[environment]}account/${id}`,
    'account:rename': (id) =>
      `${API_ENDPOINTS.bank[environment]}account/${id}/rename`,
    'account:get:statistics': (id) =>
      `${API_ENDPOINTS.bank[environment]}account/${id}/statistics`,
    'batch:get': (id) =>
      `${API_ENDPOINTS.bank[environment]}batch/${id}`,
    'batches:get': () =>
      `${API_ENDPOINTS.bank[environment]}batch`,
    'batches:statistics': () =>
      `${API_ENDPOINTS.bank[environment]}statistics/batches`,
    'statistics:get': () =>
      `${API_ENDPOINTS.bank[environment]}statistics`,
    'transaction:get:booking-suggestions': (id) =>
      `${API_ENDPOINTS.bank[environment]}transaction/${id}/booking-suggestions`,
    'transaction:search': () =>
      `${API_ENDPOINTS.bank[environment]}transaction-search`,
    'transaction:add-to-booking': (transactionID, bookingID) =>
      `${API_ENDPOINTS.bank[environment]}transaction/${transactionID}/booking/${bookingID}`,
    'transaction:delete-from-booking': (transactionID, bookingID) =>
      `${API_ENDPOINTS.bank[environment]}transaction/${transactionID}/booking/${bookingID}/remove`
  },
  enums: {
    'enums:get': () =>
      `${API_ENDPOINTS.enums[environment]}`,
  },
  ledger: {
    'beneficiary:activate': (id) =>
      `${API_ENDPOINTS.ledger[environment]}beneficiaries/${id}/activate`,
    'beneficiaries:create': () =>
      `${API_ENDPOINTS.ledger[environment]}beneficiaries`,
    'beneficiaries:get': () =>
      `${API_ENDPOINTS.ledger[environment]}beneficiaries`,
    'disbursement:get': (id) =>
      `${API_ENDPOINTS.ledger[environment]}disbursements/${id}`,
    'disbursements:get': () =>
      `${API_ENDPOINTS.ledger[environment]}disbursements`,
    'ledger:get': () =>
      `${API_ENDPOINTS.ledger[environment]}`,
    'statistics:get': () =>
      `${API_ENDPOINTS.ledger[environment]}statistics`
  },
  payment: {
    'organisation:providers': (organisationID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/providers`,
    'organisation:providers:set-weights': (organisationID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/providers/set-weights`,
    'organisation:provider:create': (organisationID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/provider`,
    'organisation:provider:get': (organisationID, providerID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/provider/${providerID}`,
    'organisation:provider:update': (organisationID, providerID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/provider/${providerID}`,
    'organisation:provider:active:change': (organisationID, providerID, state) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/provider/${providerID}/active/${state}`,
    'organisation:provider:delete': (organisationID, providerID) =>
      `${API_ENDPOINTS.payment[environment]}organisation/${organisationID}/provider/${providerID}`,
    'providers:list': () =>
      `${API_ENDPOINTS.payment[environment]}providers`,
    'transaction-methods:list': () =>
      `${API_ENDPOINTS.payment[environment]}transaction-methods`,
    'payment-link:get': (id) =>
      `${API_ENDPOINTS.payment[environment]}payment-link/${id}`
  },
  reporting: {
    'customers': (organisationID) =>
    `${API_ENDPOINTS.reporting[environment]}customers?organisation=${organisationID}`,
    'credit-notes': (organisationID) =>
      `${API_ENDPOINTS.reporting[environment]}credit-notes?organisation=${organisationID}`,
    'dashboard:statistics': (organisationID, currency = 'GBX') =>
      `${API_ENDPOINTS.reporting[environment]}dashboard/stats?organisation=${organisationID}&currency=${currency}`,
    'declines': (organisationID) =>
      `${API_ENDPOINTS.reporting[environment]}declines?organisation=${organisationID}`,
    'rolling-reserve': (organisationID) =>
      `${API_ENDPOINTS.reporting[environment]}rolling-reserve?organisation=${organisationID}`,
  },
  saltedge: {
    'bank:add': () => `${API_ENDPOINTS.saltedge[environment]}bank/add`,
    'bank:resync': () => `${API_ENDPOINTS.saltedge[environment]}bank/re-sync`,
    'customer:refresh': () => `${API_ENDPOINTS.saltedge[environment]}customer/refresh`,
  },
  trustPayments: {
    'account:get': () =>
      `${API_ENDPOINTS.trustPayments[environment]}account`,
    'batch:get': (batchID) =>
      `${API_ENDPOINTS.trustPayments[environment]}batches/${batchID}`,
    'batches:get': () =>
      `${API_ENDPOINTS.trustPayments[environment]}batches`
  },
  token: {
    'key:create': () =>
      `${API_ENDPOINTS.token[environment]}key`,
    'key:delete': (id) =>
      `${API_ENDPOINTS.token[environment]}key/${id}`,
    'keys:list': () =>
      `${API_ENDPOINTS.token[environment]}keys`,
  },
  user: {
    'account:get': () =>
      `${API_ENDPOINTS.user[environment]}account`,

    'login-link:get': (id) =>
      `${API_ENDPOINTS.user[environment]}login-link/${id}`,

    'login-link:update': (id) =>
      `${API_ENDPOINTS.user[environment]}login-link/${id}/update`,

    'organisations:create': () =>
      `${API_ENDPOINTS.user[environment]}organisations`,

    'organisations:get': () =>
      `${API_ENDPOINTS.user[environment]}organisations`,

    'organisation:get': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}`,

    'organisation:archive': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/archive`,

    'organisation:update': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}`,

    'organisation:payout-configuration': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/payout-configuration`,

    'organisation:payout-configuration:change': (organisationID, action) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/payout-configuration`,

    'organisation:feature': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/features`,

    'organisation:feature:change': (organisationID, action) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/features/${action}`,

    'organisation:image-upload': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/image-upload`,

    'organisation:subscription': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/subscription`,

    'organisation:user:add': (organisationID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/user`,

    'organisation:users': () =>
      `${API_ENDPOINTS.user[environment]}users`,

    'organisation:users:activate': (organisationID, userID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/users/${userID}/activate`,

    'organisation:users:roles': (organisationID, userID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/users/${userID}/roles`,

    'organisation:user:roles:change': (organisationID, userID, action) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/users/${userID}/role/${action}`,

    'organisation:users:update-status': (organisationID, userID) =>
      `${API_ENDPOINTS.user[environment]}organisations/${organisationID}/users/${userID}/update-status`,

    'user:password:reset': () =>
      `${API_ENDPOINTS.user[environment]}user-password-reset`,

    'user:add': () =>
      `${API_ENDPOINTS.user[environment]}user`,

    'user:get': (userID) =>
      `${API_ENDPOINTS.user[environment]}users/${userID}`,
  },
  webhooks: {
    'webhooks:logs': () =>
    `${API_ENDPOINTS.webhooks[environment]}logs`,
    'webhook:get': (id) =>
      `${API_ENDPOINTS.webhooks[environment]}webhook/${id}`,
    'webhook:notification:update': (id, action) =>
      `${API_ENDPOINTS.webhooks[environment]}webhook/${id}/notifications/${action}`,
    'webhook:delete': (id) =>
      `${API_ENDPOINTS.webhooks[environment]}webhook/${id}`,
    'webhooks:create': () =>
      `${API_ENDPOINTS.webhooks[environment]}create`,
    'webhooks:list': () =>
      `${API_ENDPOINTS.webhooks[environment]}`,
  },
};

export default API_ROUTES;
