import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";

import CreditNotes from "./pages/credit-notes";
import CreditNote from "./pages/credit-note";
import CreditNoteCreate from './pages/credit-notes/create';
import Customers from "./pages/customers";
import Customer from "./pages/customer";
import GroupMenu from "src/components/group-menu";

import { PencilSquareIcon, UserGroupIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Customers', icon: UserGroupIcon, path: '/customers', check: true },
    { name: 'Credit Notes', icon: PencilSquareIcon, path: '/customers/credit-notes', check: HasRole(account, ['credit-notes:view']) && HasFeature(features, ['credit-notes']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Customers" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const redirects = [
  { from: '/credit-notes', to: '/customers/credit-notes' },
  { from: '/credit-note/create', to: '/customers/credit-notes/create' },
  { from: '/credit-notes/:id', to: '/customers/credit-note/:id' },
];

const Payments = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}

        {HasFeature(features, ['booking-and-payment']) && (
          <React.Fragment>
            <Route exact path={`/customers`} component={() => <Layout><Customers /></Layout>} />
            <Route exact path={`/customer/:id`} component={() => <Customer />} />
            <Route exact path={`/customer/:id/payments`} component={() => <Customer />} />
            <Route exact path={`/customer/:id/bookings`} component={() => <Customer />} />
            <Route exact path={`/customer/:id/payment-links`} component={() => <Customer />} />
          </React.Fragment>

        )}

        {HasRole(account, ['credit-notes:view']) && HasFeature(features, ['credit-notes']) && (
          <React.Fragment>
            <Route exact path={`/customers/credit-notes`} component={() => <Layout><CreditNotes /></Layout>} />
            <Route exact path={`/customers/credit-note/:id`} component={() => <Layout><CreditNote /></Layout>} />
          </React.Fragment>
        )}

        {HasRole(account, ['credit-notes:create']) && HasFeature(features, ['credit-notes']) && (
          <Route exact path={`/customers/credit-notes/create`} component={() => <Layout><CreditNoteCreate /></Layout>} />
        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Payments;
