import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

import Integrations from "./pages/integrations";
import Users from "./pages/users";
import User from "./pages/user";
import Audit from "./pages/audit";
import Billing from "./pages/billing";
import Suppliers from "./pages/suppliers";
import SupplierCreate from "./pages/supplier/create";

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import { FingerPrintIcon, Square3Stack3DIcon, BuildingOffice2Icon, UsersIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Audit Trail', icon: FingerPrintIcon, path: '/organisation/audit', check: HasRole(account, ['organisation:audit']) && HasFeature(features, ['audit']) },
    { name: 'Billing', icon: DocumentDuplicateIcon, path: '/organisation/billing', check: HasRole(account, ['billing:view']) },
    { name: 'Integrations', icon: Square3Stack3DIcon, path: '/organisation/integrations', check: HasRole(account, ['organisation:integrations']) },
    { name: 'Suppliers', icon: BuildingOffice2Icon, path: '/organisation/suppliers', check: HasRole(account, ['suppliers:manage']) && HasFeature(features, ['suppliers']) },
    { name: 'Users', icon: UsersIcon, path: '/organisation/users', check: HasRole(account, ['users:manage']) && HasFeature(features, ['users']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Organisation" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const BasePathRenderer = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  let path = '/';

  if (HasRole(account, ['users:manage']) && HasFeature(features, ['users'])) {
    path = `/organisation/users`;
  }

  if (path === '' && HasRole(account, ['organisation:integrations'])) {
    path = `/organisation/integrations`;
  }

  if (path === '' && HasRole(account, ['organisation:audit'])) {
    path = `/organisation/audit`;
  }

  if (path === '' && HasRole(account, ['billing:view'])) {
    path = `/organisation/billing`;
  }

  return (
    <Route exact path="/organisation" component={(props) => {
      return (
        <Redirect to={path} />
      )
    }} 
    />
  );
};

const redirects = [
  { from: '/integrations', to: '/organisation/integrations' },
  { from: '/users', to: '/organisation/users' },
  { from: '/users/:id', to: '/organisation/user/:id' },
  { from: '/users/:id/roles', to: '/organisation/user/:id/roles' },
  { from: '/users/:id/organisations', to: '/organisation/user/:id/organisations' },
  { from: '/audit', to: '/organisation/audit' },
  { from: '/billing', to: '/organisation/billing' },
  { from: '/suppliers', to: '/organisation/suppliers' },
  { from: '/supplier/create', to: '/organisation/supplier/create' },
];

const Payments = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        { RedirectRenderer({ redirects }) }
        { BasePathRenderer() }

        { HasRole(account, ['organisation:integrations']) && (
          <React.Fragment>
            <Route exact path={`/organisation/integrations`} component={() => <Layout><Integrations /></Layout>} />
          </React.Fragment>
        )}

        { HasRole(account, ['users:manage']) && HasFeature(features, ['users']) && (
          <React.Fragment>
            <Route exact path={`/organisation/users`} component={() => <Layout><Users /></Layout>} />
            <Route exact path={`/organisation/user/:id`} component={() => <Layout><User /></Layout>} />
            <Route exact path={`/organisation/user/:id/roles`} component={() => <Layout><User /></Layout>} />
            <Route exact path={`/organisation/user/:id/organisations`} component={() => <Layout><User /></Layout>} />
          </React.Fragment>
        )}

        { HasRole(account, ['organisation:audit']) && HasFeature(features, ['audit']) && (
          <Route exact path={`/organisation/audit`} component={() => <Layout><Audit /></Layout>} />
        )}

        { HasRole(account, ['billing:view']) && (
          <Route exact path={`/organisation/billing`} component={() => <Layout><Billing /></Layout>} />
        )}

        { HasRole(account, ['suppliers:manage']) && HasFeature(features,['suppliers']) && (
          <React.Fragment>
            <Route exact path={`/organisation/suppliers`} component={() => <Layout><Suppliers /></Layout>} />
            <Route exact path={`/organisation/supplier/create`} component={() => <Layout><SupplierCreate /></Layout>} />
          </React.Fragment>
        )}


      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Payments;
