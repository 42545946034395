/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  SET_RUNNING_VERSION,
  SET_LATEST_VERSION,
  CHECK_NEW_VERSION,
} from 'src/actions/applicationActions';

const initialState = {
  running_version: null,
  latest_version: null,
  new_version: false,
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RUNNING_VERSION: {
      return produce(state, (draft) => {
        draft.running_version = action.payload;
      });
    }

    case SET_LATEST_VERSION: {
      return produce(state, (draft) => {
        draft.latest_version = action.payload;
      });
    }

    case CHECK_NEW_VERSION: {
      return produce(state, (draft) => {
        draft.new_version = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default applicationReducer;
