import React, { useState } from 'react';
import {Row, Col, FormGroup, Card, Form, Spinner, Alert} from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Content from 'src/layout/content/Content';
import Head from 'src/layout/head/Head';
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button, Icon,
} from 'src/components/Component';
import {Auth} from 'aws-amplify';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import { useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";

const WebhookCreate = ({ ...props }) => {
  const [ childOrganisations, setChildOrganisations ] = useState(false);
  const history = useHistory();
  const { organisation } = useSelector((state) => state);

  return (
    <React.Fragment>
        <Head title="Create Webhook" />
        <Content>
            <Card className="card-bordered p-5">
                <BlockHead size="lg" wide="sm" className="p-0">
                    <BlockHeadContent>
                        <BlockTitle className="text-primary" page>Create a New Webhook</BlockTitle>
                    </BlockHeadContent>
                </BlockHead>

                <div className="nk-divider divider md pb-4" />

                <Block size="lg">
                    <Formik
                      initialValues={{
                          url: '',
                      }}
                      validationSchema={Yup.object().shape({
                          url: Yup.string()
                            .url('URL is not valid')
                            .required('Webhook url is required')
                      })}
                      onSubmit={async (values, { setSubmitting, setErrors }) => {
                        const user = await Auth.currentAuthenticatedUser();

                        try {
                          const response = await Axios(
                            API_ROUTES.webhooks['webhooks:create'](),
                            {
                              method: 'post',
                              data: {
                                url: values.url,
                                organisation: organisation.organisation,
                                child_organisations: childOrganisations,
                              },
                              headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
                            }
                          );

                          setSubmitting(false);
                          history.push(`/developers/webhook/${response.data.data.id}`);
                        } catch(error) {
                          setErrors({ general: 'An error has occurred, a member of our team has been notified. Please try again later.' });
                        }
                      }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => {
                            return (
                              <Form className="gy-3"  onSubmit={handleSubmit}>
                                {errors.general && (
                                  <div className="mb-3">
                                    <Alert color="danger" className="alert-icon">
                                      {" "}
                                      <Icon name="alert-circle" /> { errors.general }
                                    </Alert>
                                  </div>
                                )}
                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label text-primary">Webhook URL</label>
                                              <span className="form-note">The url to send webhook notifications to</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="url"
                                                    name="url"
                                                    value={values.url}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="e.g. https://felloh.com/api/webhooks"
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.url && errors.url && <span className="invalid">{errors.url}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  <Row className="g-3 align-center">
                                    <Col lg="5">
                                      <FormGroup>
                                        <label className="form-label text-primary">Child Organisations</label>
                                        <span className="form-note">Whether to apply the webhook to all child organisations</span>
                                      </FormGroup>
                                    </Col>
                                    <Col lg="7">
                                      <FormGroup>
                                        <div className="form-control-wrap">
                                          <div className="custom-control custom-switch">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input form-control"
                                              checked={childOrganisations}
                                              onChange={(e) => setChildOrganisations(e.target.checked)}
                                              disabled={isSubmitting}
                                              id="child_organisations"
                                              name="child_organisations"
                                            />
                                            <label className="custom-control-label" htmlFor="child_organisations" />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="g-3">
                                      <Col lg="7" className="offset-lg-5">
                                          <FormGroup>
                                              <Button
                                                type="submit"
                                                color="primary"
                                                size="lg"
                                                className="float-right"
                                                disabled={Object.keys(errors).length >= 1}
                                              >
                                                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : 'Next'}
                                              </Button>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </Form>
                            );
                        }}
                    </Formik>
                </Block>
            </Card>
        </Content>
    </React.Fragment>

  );
};

export default WebhookCreate;
