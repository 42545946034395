import React, { useCallback, useEffect, useState } from 'react';
import SaleRevenue from 'src/components/partials/default/sale-revenue/SaleRevenue';
import SalesOverview from 'src/components/partials/default/sales-overview/SalesOverview';
import ActionCenter from "src/components/partials/action-center/ActionCenter";
import * as Sentry from '@sentry/browser';

import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from "src/config/api";
import HasRole from "../../../../../../utils/has-role";
import StatBox from "./stat-box";
import HasFeature from "../../../../../../utils/has-feature";
import { convertPenniesToDecimals } from "../../../../../../utils/money";
import millify from 'millify';

const DashboardStatistics = () => {
  const { account } = useSelector((state) => state.account);
  const [currency, setCurrency] = useState('GBX');
  const [oldCurrency, setOldCurrency] = useState('GBX');

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(true);
  const [data, setData] = useState({
    thirty_day_payments: [],
    bookings: {
      day: 0,
      week: 0,
      month: 0,
      year_breakdown: [],
    },
    payment_links: {},
    transactions: {
      total: 0,
      declined: 0,
    },
    ledger: {},
    stats: {},
  });

  const { organisation } = useSelector((state) => state);

  const getData = useCallback(async () => {
    if (loaded === false) {
      setLoading(true);
      setLedgerLoading(true);
    }

    const hasRole = HasRole(account, ['ledger:view']) && HasFeature(organisation.features, ['ledger']);

    try {
      const user = await Auth.currentAuthenticatedUser();

      let promises = [
        Axios(
          API_ROUTES.reporting['dashboard:statistics'](organisation.organisation, currency),
          {
            method: 'get',
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          }
        ),
      ];

      if (hasRole) {
        promises.push(Axios(
          API_ROUTES.ledger['statistics:get'](),
          {
            method: 'post',
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
            data: {
              organisation: organisation.organisation,
            }
          }
        ));
      }

      const results = await Promise.all(promises);

      // Calculate transaction statistics
      const transactionsTotalWeekly = results[0].data.data.stats.transactions.status.weekly
        .reduce((partialSum, a) => partialSum + (a.status !== 'ABANDONED' && a.status !== 'PENDING' ? a.count : 0), 0);
      const transactionsDeclinedWeekly = results[0].data.data.stats.transactions.status.weekly
        .reduce((partialSum, a) => partialSum + (a.status === 'DECLINED' ? a.count : 0), 0);

      const paymentLinksTotalWeekly = results[0].data.data.stats.payment_links.status.weekly
        .reduce((partialSum, a) => partialSum + a.count, 0);
      const paymentLinksNotActiveWeekly = results[0].data.data.stats.payment_links.status.weekly
        .reduce((partialSum, a) => partialSum + (a.status !== 'active' ? a.count : 0), 0);

      setData({
        ...data,
        ...results[0].data.data,
        payment_links: {
          total: paymentLinksTotalWeekly,
          complete: paymentLinksNotActiveWeekly,
          percentageComplete: paymentLinksTotalWeekly === 0 || paymentLinksNotActiveWeekly === 0 ? 0 : ((paymentLinksNotActiveWeekly / paymentLinksTotalWeekly) * 100).toFixed(2),
        },
        transactions: {
          total: transactionsTotalWeekly,
          declined: transactionsDeclinedWeekly,
          percentageDeclined: transactionsTotalWeekly === 0 || transactionsDeclinedWeekly === 0 ? 0 : ((transactionsDeclinedWeekly / transactionsTotalWeekly) * 100).toFixed(2),
        },
        ledger: hasRole ? results[1].data.data : 0,
      });

      setLoading(false);
      setLedgerLoading(false);
      setLoaded(true);
      setOldCurrency(currency);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [organisation.organisation, account, currency]);

  useEffect(() => {
    getData();
  }, [organisation.organisation, currency]);

  return (
    <React.Fragment>

      <div className='col-span-6 lg:col-span-4'>

        <SalesOverview
          data={data.thirty_day_payments}
          loading={loading}
          currency={currency}
          oldCurrency={oldCurrency}
          setCurrency={setCurrency}
        />

        <SaleRevenue data={data.bookings} loading={loading} />

      </div>

      <div className='col-span-6 lg:col-span-2'>
        <ActionCenter data={data.stats} loading={loading} />

        <div className="grid grid-cols-2 gap-2 h-auto">
          {HasRole(account, ['ledger:view']) && HasFeature(organisation.features, ['ledger']) && (
            <React.Fragment>
              <React.Fragment>
              <StatBox
                    title={<React.Fragment>Funds <span className="font-bold">Settled</span></React.Fragment>}
                    period="This week"
                    figure={`£${millify(convertPenniesToDecimals(data?.ledger?.settled?.week?.amount))}`}
                    loading={ledgerLoading}
                    popoverTitle="Funds Settled"
                    popoverDescription="Transactions that have been settled by the merchant acquirer"
                  />
                  <StatBox
                    title={<React.Fragment>Funds <span className="font-bold">Disbursed</span></React.Fragment>}
                    period="This week"
                    figure={`£${millify(convertPenniesToDecimals(data?.ledger?.disbursed?.week?.amount))}`}
                    loading={ledgerLoading}
                    popoverTitle="Funds Disbursed"
                    popoverDescription="Funds that have been disbursed to your bank account"
                  />
                 <StatBox
                    title={<React.Fragment>Ledger <span className="font-bold">Balance</span></React.Fragment>}
                    period="Current"
                    figure={`£${millify(convertPenniesToDecimals(data?.ledger?.balance))}`}
                    loading={ledgerLoading}
                    popoverTitle="Ledger Balance"
                    popoverDescription="This is the balance of your virtual ledger. This is made up transactions in, disbursements out and any adjustments."
                  />
                  <StatBox
                    title="Disbursements"
                    period="Week so far"
                    figure={data?.ledger?.disbursed?.week?.count}
                    loading={loading}
                    popoverTitle="Disbursements"
                    popoverDescription="The number of disbursements made in the last 7 days to your bank account"
                  />
              </React.Fragment>
            </React.Fragment>
          )}
            <StatBox
              title={<React.Fragment>Payment <span className="font-bold">Acceptance</span></React.Fragment>}
              period="This week"
              figure={`${Math.round(100 - data.transactions.percentageDeclined)}%`}
              loading={loading}
              popoverTitle="Payment Acceptance %"
              popoverDescription="The percentage of accepted transactions vs declined transactions in the last 7 days"
            />
            <StatBox
              title={<React.Fragment>Link <span className="font-bold">Conversion %</span></React.Fragment>}
              period="This week"
              figure={`${Math.round(data.payment_links.percentageComplete)}%`}
              loading={loading}
              popoverTitle="Link Conversion %"
              popoverDescription="Payment links that have been paid vs those which have not, that were created in the last 7 days"
            />
        </div>

      </div>

    </React.Fragment>
  );
}
export default DashboardStatistics;
