import React from 'react';
import {Row, Col, FormGroup, Card, Form, Spinner, Alert} from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Content from 'src/layout/content/Content';
import Head from 'src/layout/head/Head';
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button, Icon,
} from 'src/components/Component';
import {Auth} from 'aws-amplify';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import { useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";

const BeneficiaryCreate = ({ ...props }) => {
  const history = useHistory();
  const { organisation } = useSelector((state) => state);

  return (
    <React.Fragment>
        <Head title="Form Layouts" />
        <Content>
            <Card className="card-bordered p-5">
                <BlockHead size="lg" wide="sm" className="p-0">
                    <BlockHeadContent>
                        <BlockTitle className="text-primary" page>Add a Beneficiary</BlockTitle>
                    </BlockHeadContent>
                </BlockHead>

                <div className="nk-divider divider md pb-4" />

                <Block size="lg">
                    <Formik
                      initialValues={{
                          name: '',
                          sortCode: '',
                          accountNumber: '',
                      }}
                      validationSchema={Yup.object().shape({
                          name: Yup.string()
                            .min(3)
                            .max(255)
                            .test('len', 'Account name must only contain letters / numbers', val => {
                              return (/^[\w\-\s]+$/).test(val?.toString());
                            })
                            .required('Account Name is required'),
                          accountNumber: Yup.number()
                            .typeError('Account Number must be numeric')
                            .test('len', 'Account number must be between 6 and 8 digits', val => {
                              return typeof val === 'number' && val?.toString().length >= 6 && val?.toString().length <= 8;
                            })
                            .required('Account Number is required'),
                          sortCode: Yup.number()
                            .typeError('Sort Code must be numeric')
                            .test('len', 'Sort Code must be 6 digits', val => val?.toString().length === 6)
                            .required('Sort Code is required')
                      })}
                      onSubmit={async (values, { setSubmitting, setErrors }) => {
                        const user = await Auth.currentAuthenticatedUser();

                        try {
                          await Axios(
                            API_ROUTES.ledger['beneficiaries:create'](),
                            {
                              method: 'put',
                              data: {
                                account_name: values.name,
                                account_number: values.accountNumber,
                                sort_code: values.sortCode,
                                organisation: organisation.organisation,
                              },
                              headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
                            }
                          );
                          setSubmitting(false);
                          history.push(`/beneficiaries`);
                        } catch(error) {
                          const message = error?.response?.data?.errors[0]?.message;
                          setErrors({ general: message ? message : 'An error has occurred, a member of our team has been notified. Please try again later.' });
                          setSubmitting(false);
                        }
                      }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => {
                            return (
                              <Form className="gy-3"  onSubmit={handleSubmit}>
                                {errors.general && (
                                  <div className="mb-3">
                                    <Alert color="danger" className="alert-icon">
                                      {" "}
                                      <Icon name="alert-circle" /> { errors.general }
                                    </Alert>
                                  </div>
                                )}
                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label">Account Name</label>
                                              <span className="form-note">The name of the account holder</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.name && errors.name && <span className="invalid">{errors.name}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  <Row className="g-3 align-center">
                                    <Col lg="5">
                                      <FormGroup>
                                        <label className="form-label">Account Number</label>
                                        <span className="form-note">The bank account number</span>
                                      </FormGroup>
                                    </Col>
                                    <Col lg="7">
                                      <FormGroup>
                                        <div className="form-control-wrap">
                                          <input
                                            type="text"
                                            id="accountNumber"
                                            name="accountNumber"
                                            value={values.accountNumber}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className="form-control form-control-xl"
                                          />
                                          {touched.accountNumber && errors.accountNumber && <span className="invalid">{errors.accountNumber}</span>}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="g-3 align-center">
                                    <Col lg="5">
                                      <FormGroup>
                                        <label className="form-label">Sort Code</label>
                                        <span className="form-note">The bank account sort code</span>
                                      </FormGroup>
                                    </Col>
                                    <Col lg="7">
                                      <FormGroup>
                                        <div className="form-control-wrap">
                                          <input
                                            type="text"
                                            id="sortCode"
                                            name="sortCode"
                                            value={values.sortCode}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            className="form-control form-control-xl"
                                          />
                                          {touched.sortCode && errors.sortCode && <span className="invalid">{errors.sortCode}</span>}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="g-3">
                                      <Col lg="7" className="offset-lg-5">
                                          <FormGroup>
                                              <Button
                                                type="submit"
                                                color="primary"
                                                size="lg"
                                                className="float-right"
                                                disabled={Object.keys(errors).length >= 1}
                                              >
                                                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : 'Submit'}
                                              </Button>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </Form>
                            );
                        }}
                    </Formik>
                </Block>
            </Card>
        </Content>
    </React.Fragment>

  );
};

export default BeneficiaryCreate;
