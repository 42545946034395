import {takeLatest, put, select} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import * as Sentry from "@sentry/browser";
import Axios from "axios";
import posthog from 'posthog-js'

import { LOAD } from 'src/actions/applicationActions';
import {
  AUTH_CHECKED,
  SILENT_LOGIN,
  LOGOUT,
  SET_ACCOUNT_DATA,
} from 'src/actions/accountActions';
import { getAccount } from "../selectors";

import API_ROUTES from "../../config/api";

function* getAccountDetail() {
  try {
    let account = yield select(getAccount);

    if (account.user === null) {
      return null;
    }

    const user = yield Auth.currentAuthenticatedUser();

    const result = yield Axios(
      API_ROUTES.user['account:get'](),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    yield put({ type: SET_ACCOUNT_DATA, payload: result.data.data.user });
  } catch (error) {
    return null;
  }
}

function* getAuthState() {
  let user;

  try {
    user = yield Auth.currentUserInfo();

    // Set the sentry user properties
    Sentry.setUser({ email:  user.attributes.email, id: user.attributes.sub });


    if (posthog.__loaded) {
      posthog.identify(user.attributes.sub);
      posthog.people.set({ email: user.attributes.email })
    }
  } catch (error) {
    user = null;
  }

  yield put({ type: AUTH_CHECKED, payload: true });
  yield put({ type: SILENT_LOGIN, payload: { user } });
}

export function* logout() {
  yield Auth.signOut();

  yield put({ type: SILENT_LOGIN, payload: { user: null } });
}

export default function* sagas() {
  yield takeLatest(LOAD, getAccountDetail);
  yield takeLatest(SILENT_LOGIN, getAccountDetail);
  yield takeLatest(LOAD, getAuthState);
  yield takeLatest(LOGOUT, logout);
}
