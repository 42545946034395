import React from "react";
import { Route, Redirect } from "react-router-dom";
import { compile } from 'path-to-regexp';

const RedirectRenderer = ({ redirects }) => {
  if (!redirects || !redirects.length || redirects.length === 0) {
    return <React.Fragment />;
  }

  return redirects.map((redirect, index) => (
    <Route exact key={index} path={redirect.from} render={(props) => {
      const redirectParams = { 
        pathname: redirect.to,
        search: props?.location?.search 
      };

      if (props?.match?.params) {
        redirectParams.pathname = compile(redirect.to)(props.match.params);
      }

      return (
        <Redirect exact to={redirectParams} />
      )
    }} 
    />
  ))
}

export default RedirectRenderer;