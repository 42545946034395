const PaymentLinkStatusTransformer = (type) => {
  if (type === 'paid') {
    return {
      icon: 'ni-check',
      label: 'Paid',
      color: 'bg-emerald-600'
    };
  }

  if (type === 'active') {
    return {
      icon: 'ni-swap-v',
      label: 'Pending',
      color: 'bg-gray-600'
    }
  }

  if (type === 'expired') {
    return {
      icon: 'ni-archive',
      label: 'Expired',
      color: 'bg-rose-600'
    }
  }

  return {
    icon: 'ni-question',
    label: type,
    color: 'bg-gray-600'
  };
}

export default PaymentLinkStatusTransformer;
