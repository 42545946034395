import React, { useState } from "react";
import {
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, FormGroup, Spinner,
} from "reactstrap";
import { Button, Icon } from "src/components/Component";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import { ACTIVE, DISABLED } from "src/constants/user";

const UserActions = ({ user, loading, refreshUsers }) => {
  const { account } = useSelector((state) => state.account);
  const organisation = useSelector((state) => state.organisation);
  const [suspendModalForm, setSuspendModalForm] = useState(false);
  const [suspendUserProcessing, setSuspendUserProcessing] = useState(false);
  const [suspendTooltipOpen, setSuspendTooltipOpen] = useState(false);

  if (loading === true) {
    return <React.Fragment />
  }

  const action = user.status !== DISABLED ? DISABLED : ACTIVE;

  const updateUserStatus = async () => {
    setSuspendUserProcessing(true);

    const currentUser = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.user['organisation:users:update-status'](organisation.organisation, user.id),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${currentUser.signInUserSession.idToken.jwtToken}` },
        data: { status: action }
      }
    );

    refreshUsers();
    setSuspendUserProcessing(false);
    setSuspendModalForm(false);
  }

  const toggleSuspendModalForm = () => setSuspendModalForm(!suspendModalForm);

  return (
    <React.Fragment>
      <Button
        id="suspend-user-button"
        color="white"
        outline
        className={action === ACTIVE ? 'btn-primary' : 'btn-danger'}
        disabled={loading === true || user.id === account.id}
        onClick={toggleSuspendModalForm}
      >
        <Icon name="user-cross" />
        <span>{action === ACTIVE ? 'Enable' : 'Disable'} User</span>
      </Button>
      {(user.id === account.id) && (
        <Tooltip placement="bottom" isOpen={suspendTooltipOpen} target="suspend-user-button" toggle={() => setSuspendTooltipOpen(!suspendTooltipOpen)}>
          You cannot perform this action on your own user
        </Tooltip>
      )}

      <Modal isOpen={suspendModalForm} toggle={toggleSuspendModalForm}>
        <ModalHeader
          toggle={toggleSuspendModalForm}
          className={`text-white ${action === ACTIVE ? 'bg-primary' : 'bg-danger'}`}
          close={
            <button className="close" onClick={toggleSuspendModalForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          {action === ACTIVE ? 'Activate User' : 'Disable User'}
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you would like to {action === ACTIVE ? 'activate' : 'disable'} the user?</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color={action === ACTIVE ? 'primary' : 'danger'} type="submit" disabled={suspendUserProcessing} onClick={
              (ev) => {
                ev.preventDefault();
                updateUserStatus();
              }
            } size="lg">
              {suspendUserProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default UserActions;
