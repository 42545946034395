import React, { useState, useEffect, useCallback } from 'react';

import { InboxIcon } from '@heroicons/react/24/outline';
import {
  Icon,
} from 'src/components/Component';
import { Link } from 'react-router-dom';
import DataTable from 'src/components/data-table';
import { useSelector } from "react-redux";
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import { Auth } from "aws-amplify";
import axios from "axios";
import {
  CardBody,
  CardHeader,
  CardFooter,
  Spinner,
  Button,
} from "reactstrap";
import Axios from "axios";
import StatBox from "../main/components/statistics/stat-box";
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import Chart from './chart';
import { convertPenniesToDecimals } from "src/utils/money";

const RowFormatter = ({item, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount));
  const method = TransactionMethodTransformer(item.method);
  const type = TransactionTypeTransformer(item.type);

  return (
    <React.Fragment>
      <tr key={item.id} className="tb-tnx-item">
        <td className="border-none pb-0">
          <span>{moment(item.created_at).format('LT')}</span>
        </td>
        <td rowSpan={2} className="text-center border-none pl-3 pr-3 hidden lg:table-cell">
          <ul>
            <li>
              <Link
                to={`/transaction/${item.id}`}
                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="info" style={{ fontSize: '23px', color: '#798bff' }} />
              </Link>
            </li>
          </ul>
        </td>
        <td rowSpan={2} className="border-left pb-0 w-50 lg:w-auto lg:hidden">
          <strong>{item.narrative ? item.narrative.reason : 'Transaction Declined'}</strong>
        </td>
        <td rowSpan={2} className="text-center border-left hidden lg:table-cell">
          <span className="date">{total}</span>
        </td>
        <td rowSpan={1} className="border-left pb-0 hidden lg:table-cell">
          <strong>{item.narrative ? item.narrative.reason : 'Transaction Declined'}</strong>
        </td>
        <td rowSpan={2} className="text-center border-left w-150px hidden lg:table-cell">
          <Icon name={`ni text-primary icon ${type.icon} pr-1`} />
          <span className="date">{type.label}</span>
        </td>
        <td rowSpan={2} className="text-center w-150px hidden lg:table-cell">
          <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
          <span className="date">{method.label}</span>
        </td>
      </tr>
      <tr>
        <td className="!border-0 pt-0 hidden lg:table-cell">
          <span>{moment(item.created_at).format('Do MMMM YYYY')}</span>
        </td>
        <td className="!border-0 border-top-0 pt-0 pl-2 hidden lg:table-cell">
          {item.narrative ? item.narrative.description : ''}
        </td>
      </tr>
    </React.Fragment>
  );
}

const PageOptions = ({requestParams}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.ledger['ledger:get'](),
      { ...requestParams, type: 'csv' },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFetchingCSV(false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button color="dark" disabled={fetchingCSV} className="bg-violet-800 text-white border-none" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export CSV</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = () => (
  <tr className="bg-gray-200 text-slate-500 text-bold uppercase text-xs text-left">
    <th className="border-right">
      <span>Time</span>
    </th>
    <th className="tb-tnx-action border-left border-right text-center pl-3 pr-3 hidden lg:table-cell">
      <span>More Info</span>
    </th>
    <th className="text-center border-left hidden lg:table-cell">
      <span>Amount</span>
    </th>
    <th className="border-left hidden lg:table-cell">
      <span>Reason</span>
    </th>
    <th className="text-center border-left hidden lg:table-cell">
      <span>Type</span>
    </th>
    <th className="text-center hidden lg:table-cell">
      <span>Method</span>
    </th>
  </tr>
);

const TopBlock = () => {
  const { organisation } = useSelector((state) => state);

  const [range, setRange] = useState(30);
  const [loading, setLoading] = useState(true);
  const [declineData, setDeclineData] = useState({
    declines: {},
    ecr: {},
  });

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.reporting['declines'](organisation.organisation),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setDeclineData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <div className="flex-col mt-1 mb-4">
      <div className="h-full grid grid-cols-6 gap-4">

        <div className='h-full grid grid-cols-1 lg:grid-cols-2 col-span-6 lg:col-span-3 gap-4'>
          <StatBox
            title={<React.Fragment>30 Day <span className="fw-normal">Decline Rate</span></React.Fragment>}
            period="Decline % in Last 30 Days"
            figure={`${declineData.declines['30']}%`}
            loading={loading}
            popoverTitle="30 Day Decline Rate"
            popoverDescription="Transaction declines vs succesfull transactions, as a percentage, over the last 30 days"
          />
          <StatBox
            title={<React.Fragment>30 Day <span className="fw-normal">ECR</span></React.Fragment>}
            period="Declines that were retried successfully"
            figure={`${declineData.ecr['30']}%`}
            loading={loading}
            popoverTitle="30 Day ECR"
            popoverDescription="Percentage of declined transactions that were retried and succeeded, over the last 30 days"
          />
          <StatBox
            title={<React.Fragment>90 Day <span className="fw-normal">Decline Rate</span></React.Fragment>}
            period="Decline % in Last 90 Days"
            figure={`${declineData.declines['90']}%`}
            loading={loading}
            popoverTitle="90 Day Decline Rate"
            popoverDescription="Transaction declines vs succesfull transactions, as a percentage, over the last 90 days"
          />
          <StatBox
            title={<React.Fragment>90 Day <span className="fw-normal">ECR</span></React.Fragment>}
            period="Declines that were retried successfully"
            figure={`${declineData.ecr['90']}%`}
            loading={loading}
            popoverTitle="90 Day ECR"
            popoverDescription="Percentage of declined transactions that were retried and succeeded, over the last 90 days"
          />
          <StatBox
            title={<React.Fragment>365 Day <span className="fw-normal">Decline Rate</span></React.Fragment>}
            period="Decline % in Last 365 Days"
            figure={`${declineData.declines['365']}%`}
            loading={loading}
            popoverTitle="365 Day Decline Rate"
            popoverDescription="Transaction declines vs succesfull transactions, as a percentage, over the last 365 days"
          />
          <StatBox
            title={<React.Fragment>365 Day <span className="fw-normal">ECR</span></React.Fragment>}
            period="Declines that were retried successfully"
            figure={`${declineData.ecr['365']}%`}
            loading={loading}
            popoverTitle="365 Day ECR"
            popoverDescription="Percentage of declined transactions that were retried and succeeded, over the last 365 days"
          />
        </div>

        <div className='h-full col-span-6 lg:col-span-3'>
          <div className="h-full col-span-6 rounded-md border border-slate-200 animate-in fade-in-50">
            <Chart data={declineData.by_month} loading={loading} />
          </div>
        </div>

        <div className="h-full col-span-6 rounded-md border border-slate-200 animate-in fade-in-50">
          <CardHeader className="py-4 bg-gray-50">
            <h2 className="text-xl font-normal text-purple-900">
              Decline Reason <span className="font-bold">Aggregates</span>
            </h2>
          </CardHeader>

          <CardBody className="p-0">
            <table className="min-w-full divide-y divide-slate-400 dark:divide-gray-700">
              <thead className="bg-gray-00 text-xs">
                <tr className="bg-gray-200 text-slate-500 text-bold uppercase">
                  <th className="px-6 py-3 text-start"><span>Reason</span></th>
                  <th className="px-6 py-3 text-start hidden lg:table-cell"><span>Description</span></th>
                  <th className="b-l-2" style={{ width: '150px' }}><span>Count</span></th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 text-sm'>

                {loading && Array.from(Array(2).keys()).map((key) => (
                  <tr key={key}>
                    <td colSpan={4} className='px-6 py-3 text-center'>
                      <Skeleton count={1} height="50px" className="m-0 p-1" />
                    </td>
                  </tr>
                ))
                }
                {loading === false && declineData.reasons[range].length === 0 && (
                  <tr >
                    <td colSpan={4} className='px-6 py-10 text-lg text-center text-purple-800 font-light'>
                    <InboxIcon className="mx-auto h-8 w-8 mb-1" aria-hidden="true" />
                      <strong>No declines found for last {range} days</strong>
                    </td>
                  </tr>
                )}
                {loading === false && declineData.reasons[range].map((item, key) => (
                  <tr key={key}>
                    <td className='px-6 py-3'>{item.reason !== 'rejected' ? item.reason : 'transaction declined'}</td>
                    <td className='px-6 py-3 hidden lg:table-cell'>{item.description ? item.description : ''}</td>
                    <td className='px-6 py-3 text-center' style={{ width: '150px' }}>{item.item_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="bg-light overflow-hidden">

  
            <Button className={`float-right ${range === 365 ? 'bg-slate-800' : 'bg-purple-800'}`} onClick={() => setRange(365)}>
              365 Days
            </Button>
            <Button className={`float-right mr-2 ${range === 90 ? 'bg-slate-800' : 'bg-purple-800'}`} onClick={() => setRange(90)}>
              90 Days
            </Button>
            <Button className={`float-right mr-2 ${range === 30 ? 'bg-slate-800' : 'bg-purple-800'}`} onClick={() => setRange(30)}>
              30 Days
            </Button>
          </CardFooter>
        </div>

      </div>


    </div>
  );
};

const Declines = () => {
  return (
    <DataTable
      title="Decline Breakdown"
      TopBlock={TopBlock}
      columnCount={9}
      itemTitle="Declines"
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={API_ROUTES.agent['payments:get']()}
      dateFilterOption={true}
      pathFilters={{
        statuses: ['DECLINED', 'REJECTED', 'TIMEOUT']
      }}
      hideFilteredItems={true}
      dateFilterLabel="Transaction Date"
      childOrganisationsOption={false}
      hideFilters={true}
    />
  );
};

export default Declines;
