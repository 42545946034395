import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Button } from "src/components/Component";

const TextFilter = ({ title, onChange, value, fieldTitle }) => {
  const [searchValue, setSearchValue] = useState(value);

  const submit = () => {
    onChange(searchValue);
  }

  return (
    <div className='bg-white px-4 py-4 border shadow-md rounded-md overflow-hidden z-50'>
      <div className='text-md font-bold text-violet-800 border-b pb-1 border-violet-800'>{title} Filter</div>
      <div className="mt-4">
        <div className={`border border-slate-300`}>
          <input
            type="text"
            className="form-control border-transparent form-focus-none w-[350px]"
            placeholder={`Search by ${fieldTitle}`}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>
      < Button onClick={submit} className="btn btn-outline-light bg-violet-800 text-white mr-1 border-none px-4 w-100 mt-4 text-center hover:bg-slate-600 justify-center">
        <MagnifyingGlassIcon className="w-3 h-3 mr-1" />
        Search
      </ Button>
    </div>
  );
};

export default TextFilter;