import React, { useState, useEffect } from "react";

import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Col,
} from "src/components/Component";

import Ranger from "../ranger";
import { getInitialWeights, normaliseWeights, calcInitialPoints, getWeight, indexLabel } from "../utils";

const Group = ({ organisation, providers, group, setWeights }) => {
  const [points, setPoints] = useState([]);

  const transactionMethod = group.transactionMethod;

  useEffect(() => {
    const action = () => {
      if (!organisation.id || !providers || !providers.length) {
        return;
      }

      const initialWeights = getInitialWeights(providers, transactionMethod);
      const weights = normaliseWeights(initialWeights);
      const initialPoints = calcInitialPoints(weights);

      setPoints(initialPoints);
    }

    action();
  }, [organisation.id]);

  useEffect(() => {
    const action = () => {
      const newWeights = {};
      providers.forEach((provider, index) => {
        const paymentProviderId = provider.id;
        const weight = getWeight(points, index);
        const key = `${paymentProviderId}-${transactionMethod}`;
        const item = {
          payment_provider_id: paymentProviderId,
          transaction_method: transactionMethod,
          weight,
        };
        newWeights[key] = item;
      });
      setWeights((prevState) => ({ ...prevState, ...newWeights }));
    }

    action();
  }, [points]);

  if (providers && providers.length === 1) {
    return <React.Fragment />
  }

  return (
    <Col size="6">
      <div className="card-bordered card mb-4">
        <div className="card-inner">

          <Block size="lg">
            <BlockHead size="lg" wide="sm" className="p-0">
              <BlockHeadContent>
                <BlockTitle tag="h4" className="text-primary">
                    {group.key}
                </BlockTitle>
              </BlockHeadContent>
            </BlockHead>
          </Block>

          {(!providers || (providers.length === 0)) && (
            <Block size="lg">
              No providers configured for this group.
            </Block>
          )}

          {(providers && (providers.length > 0)) && (
            <>
              <Block size="lg">
                <Ranger points={points} setPoints={setPoints} />
              </Block>

              <Block size="lg">
                <div className="nk-data data-list data-list-s2">
                  {providers.map((provider, index) => (
                    <div key={provider.id} className="data-item">
                      <div className="data-col">
                        <span className="data-label">({indexLabel(index)}) {provider.name}</span>
                        <span className="data-value">{getWeight(points, index)}%</span>
                      </div>
                    </div>
                  ))}
                </div>
              </Block>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default Group;
