import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async-creatable';
import { ModalHeader, Modal, ModalBody, FormGroup, Button, ModalFooter, Row, Col } from "reactstrap";
import { Auth } from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";

const ReassignTransaction = ({ modalState, toggle, triggerReload }) => {
  const { id } = useParams();
  const { organisation } = useSelector((state) => state);
  const [bookingValue, setBookingValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  /**
     * Find bookings using the API
     * @type {function(*): *}
     */
  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );
    return result.data.data;
  }, []);

  const handleReassign = async () => {
    if (!bookingValue?.id) {
      setErrors({ booking: 'Please select a booking' })
      return;
    }

    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    try {
      const result = await Axios(
        API_ROUTES.agent['payment:reassign'](id),
        {
          method: 'post',
          data: {
            booking_id: bookingValue.id,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      setLoading(false);
      if (result.status === 200) {
        toggle();
        if (triggerReload !== false) {
          triggerReload();
        }
      } else {
        setErrors({ booking: result.message || 'unable to reassign this transaction' })
      }
    } catch (error) {
      setLoading(false);
      setErrors({ booking: error.message || 'something went wrong please try again later.' })
    }
  };


  return (
    <Modal isOpen={modalState} toggle={toggle} className="modal-md">
      <ModalHeader className="text-xl text-purple-800 bg-gray-50" toggle={toggle}>
        Re-assign transaction to another Booking
      </ModalHeader>
      <ModalBody>
        <Row className="g-3 align-center">
          <Col lg="5">
            <FormGroup>
              <label className="form-label text-primary">Booking Reference</label>
              <span className="form-note">The customers reference for their booking</span>
            </FormGroup>
          </Col>
          <Col lg="7">
            <FormGroup>
              <div className="form-control-wrap">
                <AsyncSelect
                  cacheOptions
                  className={`form-control form-control-xl p-0 border-0 indicator-hidden`}
                  value={bookingValue}
                  getOptionLabel={e => `${e.booking_reference} | ${e.customer_name}`}
                  getOptionValue={e => e.id}
                  loadOptions={findBookings}
                  onChange={(value) => setBookingValue(value)}
                  placeholder="e.g. TEST-123"
                  classNamePrefix="react-select"
                />
              </div>
              {typeof errors.booking !== 'undefined' && (
                <small className="text-danger">{errors.booking}</small>
              )}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="bg-violet-800" onClick={handleReassign} disabled={loading}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReassignTransaction;