import React from "react";

import { Modal, ModalBody, Spinner } from "reactstrap";
import OrganisationSelector from "../selector";
import {useSelector} from "react-redux";

const OrganisationModal = () => {
  const organisation = useSelector((state) => state.organisation);

  const loadingOrganisations = (
    <React.Fragment>
      <Spinner animation="border" role="status" className="mt-1 mr-3 text-primary"> </Spinner>
      <h4 className="nk-modal-title text-primary">Loading Organisation(s)</h4>
    </React.Fragment>
  );

  const organisationSelect = (
    <React.Fragment>
      <h4 className="nk-modal-title text-primary">Select Organisation</h4>
      <div className="nk-modal-text text-left">
        <OrganisationSelector width={100} />
      </div>
    </React.Fragment>
  );

  return (
    <Modal isOpen={true} toggle={() => {}} >
      <ModalBody className="modal-body-xxl text-center p-5">
        <div className="nk-modal">
          { organisation.fetching_organisations === true
            ? loadingOrganisations
            : organisationSelect
          }
        </div>
      </ModalBody>
    </Modal>
  );
}

export default OrganisationModal;
