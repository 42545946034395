import React from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import API_ROUTES from "src/config/api";
import DataTable from "src/components/data-table";
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const RowFormatter = ({item, enums}) => {
  const history = useHistory();

  const rowClick = (event, url) => {  
    if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'INPUT') {
      history.push(url)
    }
  }

  return (
    <tr key={item.id} className="tb-tnx-item cursor-pointer hover:bg-gray-50" onClick={(event) => rowClick(event, `/customer/${item.id}`)}>
      <td className="text-left border-r font-bold">
        <PiiObfuscator type="customer_name">{item.customer_name}</PiiObfuscator>
        <br />
        <span className="font-light">
          <PiiObfuscator type="email">{item.email}</PiiObfuscator>
        </span>
      </td>
      <td className="text-center border-r hidden lg:table-cell">
        <span className="title">{moment(item.created_at).format('MMMM Do YYYY')}</span>
      </td>
      <td className="text-center border-r hidden lg:table-cell">
        <span className="title">
          {item?.aggregates?.transaction?.last_completed ? moment(item?.aggregates?.transaction?.last_completed).format('MMMM Do YYYY') : '-'}
        </span>
      </td>
      <td className="tb-tnx-id text-center border-r w-24 hidden lg:table-cell">
        {item?.aggregates?.transaction?.count}
      </td>
      <td className="tb-tnx-id text-center border-r w-24">
        {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(item?.aggregates?.transaction?.amount))}
      </td>
      <td className="tb-tnx-id text-center border-r w-24 hidden lg:table-cell">
        {item?.aggregates?.booking?.count}
      </td>
      <td className="tb-tnx-id text-center border-r w-24 hidden lg:table-cell">
        {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(item?.aggregates?.booking?.amount))}
      </td>
    </tr>
  );
}

const TableHeaders = ({childFilterState}) => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th rowSpan="2" className='text-left border-r'>
        <span className="">Customer</span>
      </th>

      <th rowSpan="2" className='text-center border-r hidden lg:table-cell'>
        <span>Customer Since</span>
      </th>
      <th rowSpan="2" className='text-center border-r hidden lg:table-cell'>
        <span>Last Transaction</span>
      </th>
      <th colSpan="2" className="text-center border-r hidden lg:table-cell">
        <span>Transactions</span>
      </th>
      <th colSpan="2" className="text-center border-r hidden lg:table-cell">
        <span>Bookings</span>
      </th>
      <th colSpan="2" className="text-center border-r lg:hidden">
        <span>Transaction Value</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="tb-tnx-id text-center hidden lg:table-cell">
        <span className="">Count</span>
      </th>
      <th className="tb-tnx-id text-center border-r hidden lg:table-cell">
        <span className="">Value</span>
      </th>
      <th className="tb-tnx-id text-center hidden lg:table-cell">
        <span className="">Count</span>
      </th>
      <th className="tb-tnx-id text-center border-r hidden lg:table-cell">
        <span className="">Value</span>
      </th>
    </tr>
  </React.Fragment>
);

const CustomersTable = () => {
  const endpoint = API_ROUTES.agent['customers:get']();

  return (
    <React.Fragment>
      <div className="flex flex-col rounded-md border border-slate-200 animate-in fade-in-50 mb-4 bg-gray-50">
        <div className="card-inner border-b">
          <div className="card-title-group">
            <div className="card-title w-100">
              <div className="float-right">
                <Link className="text-purple-900 text-sm" to={`/customers`}>
                  View more <ChevronRightIcon className="h-4 inline-block" />
                </Link>
              </div>
              <h2 className="text-xl font-normal text-purple-900">
              Recent <span className="font-bold">Customers</span>
              </h2>
            </div>
          </div>
        </div>
        <DataTable
          columnCount={9}
          limit={10}
          autoReload={false}
          TableHeaders={TableHeaders}
          RowFormatter={RowFormatter}
          endpoint={endpoint}
          forceShowCount={false}
          hideFilters={true}
          cardClass=' '
        />
      </div>

    </React.Fragment>

  );
};

export default CustomersTable;
