import React, {useState} from "react";
import Head from "src/layout/head/Head";
import {Card, ModalHeader, Modal, ModalBody, ModalFooter} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "src/components/Component";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Axios from "axios";
import API_ROUTES from "../../config/api";
import { logout, obfuscatePII } from 'src/actions/accountActions';

const UserProfileSettingPage = () => {
  const { account, piiObfuscated } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const triggerPasswordReset = async() => {
    setSubmitting(true);

    await Axios(
      API_ROUTES.user['user:password:reset'](),
      {
        method: 'post',
        data: {
          email: account.email,
        },
      }
    );

    await dispatch(logout());
  };

  const triggerPIIObfuscation = async(state) => {
    await dispatch(obfuscatePII(state));
  }

  return (
    <React.Fragment>
      <Head title="Security Settings" />

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={() => setModal(true)}>Change Password</Button>
                    </li>
                    <li>
                      <em className="text-soft text-date fs-12px">
                        Last changed: <span>{ moment(account.password_changed).format('Do MMMM YYYY') }</span>
                      </em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>PII Obfuscation</h6>
                  <p>Whether to obfuscate all personally identifiable customer data.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <div className="form-control-wrap">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            name="demo_mode"
                            className="custom-control-input form-control"
                            checked={piiObfuscated === true}
                            onChange={(e) => { triggerPIIObfuscation(e.target.checked) }}
                            id="demo_mode"
                          />
                          <label className="custom-control-label" htmlFor="demo_mode" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalHeader
          className="bg-danger text-white"
          toggle={() => setModal(false)}
          close={
            <button className="close" onClick={() => setModal(false)}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Are you sure?
        </ModalHeader>
        <ModalBody>
          <p>By clicking continue you will automatically be logged out.</p>
          <p>We will send an email to <strong>{account.email}</strong> with a link to reset your password.</p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button color="danger" disabled={submitting} onClick={() => triggerPasswordReset()}>Continue</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;
