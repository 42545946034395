import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Col,
  Row,
} from 'src/components/Component';

import moment from "moment";
import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import CurrencyFormat from "src/transformer/currency-format";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import StatBox from "../../../dashboard/pages/main/components/statistics/stat-box";
import { convertPenniesToDecimals } from "src/utils/money";
import ProviderName from "src/transformer/provider-name";

const RowFormatter = ({item, enums}) => {
  const history = useHistory();

  const rowClick = (event, url) => {
    history.push(url)
  }

  return (
    <tr
      key={item.bank_reference}
      className="tb-tnx-item cursor-pointer hover:bg-gray-50"
      onClick={(event) => rowClick(event, `/batches/${item.id}`)}
    >
      <td className='border-r'>
        <span className="title">{moment(item.posting_date).format('Do MMMM YYYY')}</span>
      </td>
      <td className='text-center'>
        {item.bank_reference}
      </td>
      <td className='text-center'>
        {ProviderName(item.payment_provider.name)}
      </td>
      <td className='text-center border-l border-r'>
        {CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount))}
      </td>
      <td className='text-center'>
        {item.transaction_count}
      </td>
      <td className='text-center'>
        {item.adjustment_count}
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className='text-left border-r'>
      <span>Date</span>
    </th>
    <th className='text-center'>
      <span>Bank Reference</span>
    </th>
    <th className='text-center'>
      <span>Provider</span>
    </th>
    <th className='text-center border-l border-r'>
      <span>Amount</span>
    </th>
    <th className='text-center'>
      <span>Transaction<br />Count</span>
    </th>
    <th className='text-center'>
      <span>Adjustments<br />Count</span>
    </th>
  </tr>
);

const TopBlock = () => {
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [accountData, setAccountData] = useState({});

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.bank['batches:statistics'](),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        params: {
          organisation: organisation.organisation
        }
      }
    );

    setAccountData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <div className='my-4'>

      <Row className="mt-4 mb-2">
        <Col md="12" xl="4">
          <StatBox
            title="Funds Settled"
            period="Today"
            figure={CurrencyFormat().format(loading ? 0 : convertPenniesToDecimals(accountData?.totals['today']))}
            loading={loading}
            popoverTitle="Funds Settled"
            popoverDescription="Funds settled today to the receiving bank account from the acquirer."
          />
        </Col>
        <Col md="12" xl="4">
          <StatBox
            title="Funds Settled"
            period="Last 7 Days"
            figure={CurrencyFormat().format(loading ? 0 : convertPenniesToDecimals(accountData?.totals['7']))}
            loading={loading}
            popoverTitle="Funds Settled"
            popoverDescription="Funds settled over the last 7 days to the receiving bank account from the acquirer."
          />
        </Col>
        <Col md="12" xl="4">
          <StatBox
            title="Funds Settled"
            period="Last 30 Days"
            figure={CurrencyFormat().format(loading ? 0 : convertPenniesToDecimals(accountData?.totals['30']))}
            loading={loading}
            popoverTitle="Funds Disbursed"
            popoverDescription="Funds settled over the last 30 days to the receiving bank account from the acquirer."
          />
        </Col>
      </Row>

    </div>
  );
};


const Transactions = () => {
  const endpoint = API_ROUTES.bank['batches:get']();

  return (
    <div className="ml-3">
      <DataTable
        title="Acquirer Batches"
        itemTitle="Batches"
        columnCount={9}
        TableHeaders={TableHeaders}
        RowFormatter={RowFormatter}
        TopBlock={TopBlock}
        endpoint={endpoint}
        hideFilters={true}
        noHeader={true}
      />
    </div>
  );
};

export default Transactions;
