import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HasRole from 'src/utils/has-role';
import moment from "moment";

import {
  Button,
  Icon,
} from 'src/components/Component';
import DataTable from 'src/components/data-table';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, Spinner } from "reactstrap";
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import { Auth } from "aws-amplify";
import axios from "axios";
import PiiObfuscator from "src/components/pii-obfuscator";
import HasFeature from "src/utils/has-feature";
import { convertPenniesToDecimals } from "src/utils/money";
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const RowFormatter = ({item, childFilterState, account, organisation, enums}) => {
  const reducedTransactionsTotal = item
    .transactions
    .reduce((previous, current) => previous + (current.status === 'COMPLETE' ? current.amount : 0), 0)
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(reducedTransactionsTotal));

  let transactionCount = 0;
  item.transactions.forEach((t) => {
    if (t.status === 'COMPLETE') transactionCount += 1;
  });

  return (
    <tr key={item.id} className="tb-tnx-item">
      <td className="tb-tnx-id" style={{ inlineSize: '150px', overflowWrap: 'break-word', maxWidth: '180px' }}>
        <Link to={`/booking/${item.id}`} className="text-violet-800 underline">{item.booking_reference}</Link>
      </td>
      <td>
        <strong>
          <PiiObfuscator className="title" type="customer_name">{item.customer_name}</PiiObfuscator>
        </strong>
        <br />
        <PiiObfuscator className="title" type="email">{item.email}</PiiObfuscator>
      </td>
      {childFilterState === true && (
        <td className="text-center border-l">
          <span className="title">{item.organisation.name}</span>
        </td>
      )}
      <td className="text-center border-l">
        <Link to={`/booking/${item.id}/transactions`}>{transactionCount}</Link>
      </td>
      <td className="text-center">
        <span className="date">{item.gross_amount ? CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.gross_amount)) : '-'}</span>
      </td>
      <td className="text-center border-r">
        <span className="date">{total}</span>
      </td>
      <td className="text-center border-r">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="tb-tnx-action border-l pl-3">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
          >
            <Icon name="menu" />
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-plain">
              <li>
                <Link to={`/booking/${item.id}`}>
                  <Icon name="info-i" /> Information
                </Link>
              </li>
              <li>
                <Link to={`/booking/${item.id}/transactions`}>
                  <Icon name="wallet-in" /> Transactions
                </Link>
              </li>
              {HasRole(account, ['payouts:view']) && HasFeature(organisation.features, ['payouts']) && (
                <li>
                  <Link to={`/booking/${item.id}/payouts`}>
                    <Icon name="wallet-out" /> Payouts
                  </Link>
                </li>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

const PageOptions = ({requestParams, triggerReload, loading}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);

  const createCSV = async () => {
    setFetchingCSV(true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.agent['bookings:get'](),
      { ...requestParams, type: 'csv' },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFetchingCSV(false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
      <li>
        <Button disabled={fetchingCSV} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV()}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = ({childFilterState}) => (
  <tr className="tb-tnx-head">
    <th className="tb-tnx-id text-left">
      <span className="">Booking Ref</span>
    </th>
    <th className='text-left'>
      <span>Customer</span>
    </th>
    {childFilterState === true && (
      <th className="text-center">
        <span>Organisation</span>
      </th>
    )}
    <th className="text-center border-l">
      <span># Transactions</span>
    </th>
    <th className="text-center">
      <span>Booking Value</span>
    </th>
    <th className="text-center">
      <span>Paid Value</span>
    </th>
    <th className="text-center border-l">
      <span>Created</span>
    </th>
    <th className="tb-tnx-action border-l">
      <span>&nbsp;</span>
    </th>
  </tr>
);

const Bookings = () => {
  const endpoint = API_ROUTES.agent['bookings:get']();

  return (
    <DataTable
      title="Bookings"
      largeHeading={true}
      columnCount={10}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      childOrganisationsOption={true}
      dateFilterOption={true}
      dateFilterLabel="Departure Date"
      endpoint={endpoint}
      noHeader={true}
      searchPlaceholder="Customer, Email or Booking Ref"
    />
  );
};

export default Bookings;
