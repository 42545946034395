import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TransactionTypeTransformer from '../../../../../../transformer/transaction-type';
import TransactionMethodTransformer from '../../../../../../transformer/transaction-method';
import {Link} from 'react-router-dom';
import CurrencyFormat from '../../../../../../transformer/currency-format';
import {Icon} from '../../../../../../components/Component';
import {CardHeader, CardBody, CardFooter, Button, ButtonGroup, PopoverHeader, Popover, PopoverBody} from 'reactstrap';
import moment from 'moment';
import ProviderNameTransformer from '../../../../../../transformer/provider-name';
import {convertPenniesToDecimals} from '../../../../../../utils/money';
import {useSelector} from "react-redux";

const OPTION_KEYS = {
  PENDING_DISBURSAL: 'PENDING_DISBURSAL',
  PENDING_SETTLEMENT: 'PENDING_SETTLEMENT',
  TEMPORARY_HELD: 'PENDING_SETTLETEMPORARY_HELDMENT',
};

const OPTIONS = {
  [OPTION_KEYS.PENDING_DISBURSAL]: {
    title: 'Pending Disbursal',
    description: 'Transactions that have been settled by the merchant acquirer into the felloh operated virtual trust and are awaiting payout',
    data_key: 'pending_disbursal_transactions',
  },
  [OPTION_KEYS.TEMPORARY_HELD]: {
    title: 'Temporary ID Held',
    description: 'Transactions that have been settled by the merchant acquirer into the felloh operated virtual trust but ae still on a temporary id, so will not be paid out',
    data_key: 'temp_held_transactions',
  },
  [OPTION_KEYS.PENDING_SETTLEMENT]: {
    title: 'Pending Settlement',
    description: 'Transactions that have not yet been settled by the merchant acquirer into the felloh operated virtual trust',
    data_key: 'unsettled_transactions',
  }
};

const TransactionFlow = ({ loading, ledgerData }) => {
  const { enums } = useSelector((state) => state.enums);

  const [activeView, setActiveView] = useState(OPTION_KEYS.PENDING_DISBURSAL);
  const [popoverOpen, togglePopover] = useState(false);

  return (
    <React.Fragment>
      <div className="flex-col rounded-md border animate-in fade-in-50">
        <CardHeader className="bg-gray-50 text-purple-900 py-3">
          <div className="float-right pb-1">
            <Icon name="info" id="popover-toggle" className="text-primary" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => togglePopover(popoverOpen === false)}/>
          </div>
          <h2 className="text-xl text-purple-900">
            Transactions | <strong>{OPTIONS[activeView].title}</strong>
          </h2>
        </CardHeader>

        <Popover isOpen={popoverOpen} target="popover-toggle" toggle={() => togglePopover(popoverOpen === false)}>
          <PopoverHeader className="bg-primary text-white">{OPTIONS[activeView].title} Transactions</PopoverHeader>
          <PopoverBody>
            {OPTIONS[activeView].description}
          </PopoverBody>
        </Popover>

        <CardBody className="p-0">
          <table className="table table-tranx border-light card-bordered mb-0">
            <tbody>
            <tr className="tb-tnx-head">
              <th className='text-left'><span>ID</span></th>
              <th className="border-left text-center"><span>Processing date</span></th>
              <th className="text-center"><span>Provider</span></th>
              <th className="border-left text-center" style={{ maxWidth: '120px' }}><span>Transaction Amount</span></th>
              { activeView === OPTION_KEYS.PENDING_DISBURSAL && <th className="text-center"  style={{ maxWidth: '120px' }}><span>Settlement Amount</span></th> }
              <th className="border-left text-center"><span>Method</span></th>
              <th className="text-center"><span>Type</span></th>
            </tr>
            { loading && Array.from(Array(2).keys()).map((key) => (
              <tr className="tb-tnx-item" key={key}>
                <td colSpan={7}>
                  <Skeleton count={1} height="50px" className="m-0 p-1" />
                </td>
              </tr>
            ))
            }
            { loading === false && ledgerData[OPTIONS[activeView].data_key].length === 0 && (
              <tr className="tb-tnx-item">
                <td colSpan={8} className="text-center">
                  <strong>No transactions pending disbursal</strong>
                </td>
              </tr>
            )}
            { loading === false && ledgerData[OPTIONS[activeView].data_key].map((account, key) => {
              const type = TransactionTypeTransformer(account.type_id);
              const method = TransactionMethodTransformer(account.method_id);

              return (
                <tr className="tb-tnx-item" key={key} >
                  <td>
                    <Link
                    className="text-violet-800 underline"
                      to={`/transaction/${account.id}`}
                    >{ account.id }
                    </Link>
                  </td>
                  <td className="border-left text-center">{ moment(account.completed_at).format('Do MMMM YYYY')  }</td>
                  <td className="text-center">{ ProviderNameTransformer(account.provider_name) }</td>
                  <td className="border-left text-center" style={{ maxWidth: '120px' }}>
                    { CurrencyFormat(enums.currency, account.currency).format( convertPenniesToDecimals(account.amount) ) }
                  </td>
                  { activeView === OPTION_KEYS.PENDING_DISBURSAL && (
                    <td className="text-center" style={{ maxWidth: '120px' }}>
                      { CurrencyFormat(enums.currency, account.settlement_currency).format( convertPenniesToDecimals(account.settlement_amount) ) }
                    </td>
                  )}
                  <td className="border-left text-center  w-150px">
                    <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
                    <span className="date">{ method.label }</span>
                  </td>
                  <td className="text-center w-150px">
                    <Icon name={`ni text-primary icon ${type.icon} pr-1`} />
                    <span className="date">{ type.label }</span>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </CardBody>

        { loading === false && (
          <CardFooter className="bg-light overflow-hidden">
            <ButtonGroup className="float-right ml-2">
              <Button
                className={activeView === OPTION_KEYS.PENDING_DISBURSAL  ? 'bg-slate-800' : 'bg-purple-800'}
                onClick={() => setActiveView(OPTION_KEYS.PENDING_DISBURSAL)}
              >
                Pending Disbursal
              </Button>
              <Button
                color={ activeView === OPTION_KEYS.PENDING_DISBURSAL ? 'secondary' : 'primary' }
                disabled={true}
              >
                { ledgerData[OPTIONS[OPTION_KEYS.PENDING_DISBURSAL].data_key].length }
              </Button>
            </ButtonGroup>
            <ButtonGroup className="float-right ml-2">
              <Button
                className={activeView === OPTION_KEYS.TEMPORARY_HELD  ? 'bg-slate-800' : 'bg-purple-800'}
                onClick={() => setActiveView(OPTION_KEYS.TEMPORARY_HELD)}
              >
                Temp ID Held
              </Button>
              <Button
                color={ activeView === OPTION_KEYS.TEMPORARY_HELD ? 'secondary' : 'primary' }
                disabled={true}
              >
                { ledgerData[OPTIONS[OPTION_KEYS.TEMPORARY_HELD].data_key].length }
              </Button>
            </ButtonGroup>
            <ButtonGroup className="float-right ml-2">
              <Button
                className={activeView === OPTION_KEYS.PENDING_SETTLEMENT  ? 'bg-slate-800' : 'bg-purple-800'}
                onClick={() => setActiveView(OPTION_KEYS.PENDING_SETTLEMENT)}
              >
                Pending Settlement
              </Button>
              <Button
                color={ activeView === OPTION_KEYS.PENDING_SETTLEMENT ? 'secondary' : 'primary' }
                disabled={true}
              >
                { ledgerData[OPTIONS[OPTION_KEYS.PENDING_SETTLEMENT].data_key].length }
              </Button>
            </ButtonGroup>
          </CardFooter>
        )}

      </div>

    </React.Fragment>
  )
}

export default TransactionFlow;
