import React, {useCallback, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

import {
  BlockHeadContent, BlockTitle,
  BlockHead,
  Block,
  BlockBetween,
  Button,
  Col, Icon,
  Row,
} from 'src/components/Component';

import API_ROUTES from "src/config/api";
import {useSelector} from "react-redux";
import {Auth} from "aws-amplify";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";
import CurrencyFormat from "src/transformer/currency-format";
import moment from "moment-business-days";
import StatBox from "src/pages/dashboard/pages/main/components/statistics/stat-box";
import Head from "src/layout/head/Head";
import {convertPenniesToDecimals} from "src/utils/money";
import ProviderName from "src/transformer/provider-name";

const Transactions = () => {
  const { batch_id } = useParams();

  const { enums } = useSelector((state) => state.enums);
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [batchData, setBatchData] = useState({});

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.bank['batch:get'](batch_id),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        data: {
          organisation: organisation.organisation
        }
      }
    );

    setBatchData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <div className='py-4 px-2'>

      <Head title="Batch" />

      <BlockHead size="sm" className="border-b-2 mb-8 border-gray-200">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className="text-3xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text" page>Batch <span className="fw-normal">Detail</span></BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <div className="toggle-expand-content">
                <ul className="nk-block-tools g-3">
                  <Link to={'/batches'}>
                    <Button color="dark" className="btn-white text-violet-800 border-violet-800">
                      <Icon name="back-ios" />
                      <span>Back</span>
                    </Button>
                  </Link>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>

        <Row className="mb-2">
          <Col md="12" xl="3">
            <StatBox
              title="Bank Reference"
              figure={loading ? 0 : batchData?.bank_reference}
              loading={loading}
            />
          </Col>
          <Col md="12" xl="3">
            <StatBox
              title="Amount"
              figure={CurrencyFormat(enums.currency, batchData?.currency).format(loading ? 0 :  convertPenniesToDecimals(batchData?.amount))}
              loading={loading}
            />
          </Col>
          <Col md="12" xl="3">
            <StatBox
              title="Posting Date"
              figure={moment(batchData?.posting_date).format('Do MMMM YYYY')}
              loading={loading}
            />
          </Col>

          <Col md="12" xl="3">
            <StatBox
              title="Provider"
              figure={ ProviderName(batchData?.payment_provider?.name) }
              loading={loading}
            />
          </Col>

        </Row>

<div className='py-4'>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-primary" page><span className="fw-normal">Transactions</span></BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div className="nk-block mb-4">
          <div className="card-bordered card-stretch card">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table className="table table-tranx">
                  <tbody>
                  <tr className="tb-tnx-head">
                    <th className='text-left'><span>Transaction ID</span></th>
                    <th className='text-center border-r'><span>Booking Reference</span></th>
                    <th className='text-center border-r'><span>Amount</span></th>
            
                    <th className='text-center'><span>Transaction Date</span></th>
                  </tr>
                  { loading && Array.from(Array(2).keys()).map((key) => (
                    <tr className="tb-tnx-item" key={key}>
                      <td colSpan={7}>
                        <Skeleton count={1} height="50px" className="m-0 p-1" />
                      </td>
                    </tr>
                  ))
                  }
                  { loading === false && batchData.transaction_settlements.length >= 1 && batchData.transaction_settlements?.map((account, key) => (
                    <tr className="tb-tnx-item" key={key}>
                      <td>
                        <Link className="underline text-violet-800" to={`/transaction/${account.transaction.id}`}>{account.transaction.id}</Link>
                      </td>
                      <td className='text-center border-r'>{ account.transaction.booking?.booking_reference || 'N/A' }</td>
                      <td className='text-center border-r'>{ CurrencyFormat(enums.currency, account.transaction.currency).format(loading ? 0 :  convertPenniesToDecimals(account.transaction.amount)) }</td>
                      <td className='text-center'>{moment(account.transaction.completed_at).format('Do MMM, HH:mm')}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-primary" page><span className="fw-normal">Adjustments</span></BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div className="nk-block mb-4">
          <div className="card-bordered card-stretch card">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table className="table table-tranx">
                  <tbody>
                  <tr className="tb-tnx-head">
                    <th><span>Description</span></th>
                    <th><span>Amount</span></th>
                  </tr>
                  { loading && Array.from(Array(2).keys()).map((key) => (
                    <tr className="tb-tnx-item" key={key}>
                      <td colSpan={7}>
                        <Skeleton count={1} height="50px" className="m-0 p-1" />
                      </td>
                    </tr>
                  ))
                  }
                  { loading === false && batchData.adjustments.length === 0 && (
                    <tr className="tb-tnx-item">
                      <td colSpan={2} className="text-center fw-bold">No adjustments in batch</td>
                    </tr>
                  )}
                  { loading === false && batchData.adjustments.length >= 1 && batchData.adjustments?.map((account, key) => (
                    <tr className="tb-tnx-item" key={key}>
                      <td>{ account.description }</td>
                      <td>{ CurrencyFormat(enums.currency, account.currency).format(loading ? 0 :  convertPenniesToDecimals(account.amount)) }</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Block>

    </div>
  );
};

export default Transactions;
