import React from "react";
import Head from "src/layout/head/Head";
import DataTable from "src/components/data-table";
import moment from "moment";
import CurrencyFormat from "src/transformer/currency-format";
import { convertPenniesToDecimals } from "src/utils/money";
import { Link } from "react-router-dom";
import { Icon } from "src/components/Component";
import API_ROUTES from "src/config/api";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import HasRole from 'src/utils/has-role';
import HasFeature from "src/utils/has-feature";


const RowFormatter = ({item, account, organisation, enums}) => {
  const reducedTransactionsTotal = item
    .transactions
    .reduce((previous, current) => previous + (current.status === 'COMPLETE' ? current.amount : 0), 0)
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(reducedTransactionsTotal));

  let transactionCount = 0;
  item.transactions.forEach((t) => {
    if (t.status === 'COMPLETE') transactionCount += 1;
  });

  return (
    <tr key={item.id} className="tb-tnx-item">
      <td className="tb-tnx-id">
        <Link to={`/booking/${item.id}`} className="text-violet-800 underline">{item.booking_reference}</Link>
      </td>
      <td className="text-center border-left">
        <Link to={`/booking/${item.id}/transactions`}>{transactionCount}</Link>
      </td>
      <td className="text-center">
        <span className="date">{item.gross_amount ? CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.gross_amount)) : '-'}</span>
      </td>
      <td className="text-center">
        <span className="date">{total}</span>
      </td>
      <td className="text-center border-right">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="tb-tnx-action border-left pl-3">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
          >
            <Icon name="menu" />
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-plain">
              <li>
                <Link to={`/booking/${item.id}`}>
                  <Icon name="info-i" /> Information
                </Link>
              </li>
              <li>
                <Link to={`/booking/${item.id}/transactions`}>
                  <Icon name="wallet-in" /> Transactions
                </Link>
              </li>
              {HasRole(account, ['payouts:view']) && HasFeature(organisation.features, ['payouts']) && (
                <li>
                  <Link to={`/booking/${item.id}/payouts`}>
                    <Icon name="wallet-out" /> Payouts
                  </Link>
                </li>
              )}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className="tb-tnx-id text-left">
      <span className="">Booking Ref</span>
    </th>
    <th className="text-center border-left">
      <span># Transactions</span>
    </th>
    <th className="text-center">
      <span>Booking Value</span>
    </th>
    <th className="text-center">
      <span>Paid Value</span>
    </th>
    <th className="text-center">
      <span>Created</span>
    </th>
    <th className="tb-tnx-action border-left">
      <span>&nbsp;</span>
    </th>
  </tr>
);


const Information = ({ customerID }) => {

  const endpoint = API_ROUTES.agent['bookings:get']();

  const pathFilters = {
    'customer_id': customerID,
  }

  return (
    <React.Fragment>
      <Head title="Customer Bookings" />

    <DataTable
      title="Bookings"
      largeHeading={true}
      columnCount={10}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      endpoint={endpoint}
      noHeader={true}
      hideFilters={true}
      pathFilters={pathFilters}
      cardClass=' '
    />

    </React.Fragment >
  )
}

export default Information;