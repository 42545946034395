import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Skeleton from "react-loading-skeleton";

import { API_ROUTES } from 'src/config/api';
import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
    Block,
    BlockTitle,
} from "src/components/Component";

import { buildFormData } from "../utils";
import Form from "../form";

const OrganisationPaymentConfigCreate = ({ organisation }) => {
  const [paymentProviders, setPaymentProviders] = useState();
  const [transactionMethods, setTransactionMethods] = useState();

  const { enums } = useSelector((state) => state.enums);
  const currencies = Object.values(enums.currency).map((item) => ({ id: item.minor_unit }));

  const history = useHistory();

  const initialValues = {
      name: '',
      paymentProvider: '',
      paymentProviderName: '',
      transactionMethods: [],
      currencies: [],
      publicKey: '',
      secretKey: '',
      googlepay_enabled: false,
      applepay_enabled: false,
  }

  useEffect(() => {
    const action = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['providers:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setPaymentProviders(data);
    };

    action();
  }, []);

  useEffect(() => {
    const action = async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.payment['transaction-methods:list'](),
        {
          method: 'get',
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      const data = result.data.data;

      setTransactionMethods(data);
    };

    action();
  }, []);

  const processForm = async (values) => {
      const user = await Auth.currentAuthenticatedUser();

      const data = buildFormData(values);
      await Axios(
        API_ROUTES.payment['organisation:provider:create'](organisation.id),
        {
            method: 'post',
            data,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );
      history.push(`/organisations/${organisation.id}/payment-config`);
  };

  if (!paymentProviders || !transactionMethods || !currencies) {
    return (
      <div className="mt-3">
        <Skeleton count={3} height="150px" className="mb-5" style={{ width: '100%' }}/>
      </div>
    );
  }

  return (
      <React.Fragment>
          <Head title="New Payment Provider" />
          <div className="p-4">
          <Content>
            <BlockTitle tag="h4" className="text-primary mb-2">Create a Payment Provider</BlockTitle>

            <div className="nk-divider divider md pb-2" />

            <Block size="lg">
              <Form
                organisation={organisation}
                paymentProviders={paymentProviders}
                transactionMethods={transactionMethods}
                currencies={currencies}
                initialValues={initialValues}
                buttonText="Create Payment Provider"
                processForm={processForm}
              />
            </Block>
          </Content>
          </div>
      </React.Fragment>
  );
};
export default OrganisationPaymentConfigCreate;
