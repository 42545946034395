import React from "react";
import Head from "src/layout/head/Head";
import {
  Block, Icon
} from "src/components/Component";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import CurrencyFormat from 'src/transformer/currency-format';
import {useSelector} from "react-redux";
import {convertPenniesToDecimals} from "../../../../../../utils/money";

const BookingTransactions = ({ data, loading }) => {
  const { enums } = useSelector((state) => state.enums);

  if (loading === false && data.applied_credit_notes.length === 0) {
    return (
      <React.Fragment>
        <h5 className="text-center p-5 text-primary">No credit notes applied to booking</h5>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Head title="Booking Transactions" />

      <Block>
        <table className="table table-tranx">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">Short ID</th>
            <th scope="col" className="text-center border-left">Amount</th>
            <th scope="col" className="text-center border-left">Description</th>
            <th scope="col" className="border-left">{ ' ' }</th>
          </tr>
          </thead>
          <tbody>
          { loading === true && [0, 1, 2, 3].map((item) => (
            <tr className='tb-tnx-item' key={item}>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && data.applied_credit_notes && data.applied_credit_notes.map((creditNote, key) => {
            return (
              <tr className='tb-tnx-item' key={key}>
                <td>{ creditNote.short_id }</td>
                <td className="text-center border-left">
                  { CurrencyFormat(enums.currency, creditNote.currency).format(convertPenniesToDecimals(creditNote.amount) ) }
                </td>
                <td className="text-center border-left">{ creditNote.description ? creditNote.description : 'Not Set' }</td>
                <td className="border-left text-center" style={{ width: '75px' }}>
                  <ul className="nk-tb-actions gx-1">
                    <li>
                      <Link
                        to={`/credit-notes/${creditNote.id}`}
                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                      >
                        <Icon name="info" />
                      </Link>
                    </li>
                  </ul>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Block>

    </React.Fragment>
  );
};
export default BookingTransactions;
