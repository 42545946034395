import React from "react";
import Icon from "../../icon/Icon";
import Button from "../../button/Button";
import { CardHeader } from "reactstrap";
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";

const ActionCenter = ({ data, loading }) => {
  let authorisedTransactionCount = 0;

  if (data?.transactions?.status?.weekly) {
    for (const item of data?.transactions?.status?.weekly) {
      if (item.status === 'AUTHORIZED') {
        authorisedTransactionCount = item.count;
      }
    }
  }

  const actionsCount = (data?.chargebacks?.last_seven_days > 0 ? 1 : 0)
    + (data?.payment_links?.unassigned > 0 ? 1 : 0)
    + (data?.payment?.greater_seven_days > 0 ? 1 : 0)
    + (authorisedTransactionCount > 0 ? 1 : 0)
    + (data?.payment_links?.greater_seven_days > 0 ? 1 : 0);

  const actionsTotal = loading === true || Object.keys(data).length === 0 ? 0 : (data?.chargebacks?.last_seven_days || 0)
    + (data.payment_links?.unassigned || 0)
    + (data.payment?.greater_seven_days || 0)
    + (authorisedTransactionCount || 0)
    + (data?.payment_links?.greater_seven_days || 0);

  return (
    <div className="flex flex-col rounded-md border animate-in fade-in-50 mb-2">
      <CardHeader className="border-bottom bg-gray-50">
        {actionsTotal > 0 && (
          <div className="float-right text-sm pt-0.5">
            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-sm text-xs font-medium bg-rose-100 text-rose-900">{actionsCount} { actionsCount === 1 ? 'alert' : 'alerts' }</span>
          </div>
        )}

        <h2 className="text-xl font-normal text-purple-900">
          Action <span className="font-bold">Center</span>
        </h2>
      </CardHeader>
      {" "}
      <div className="card-inner-group">

        {loading && (
          <div className="p-4">
            <div className="text-center fs-20px w-100">
              <Skeleton count={2} height="70px" />
            </div>
          </div>
        )}

        {(loading === false && actionsTotal === 0) && (
          <div className="text-center mt-5 mb-5">
            You are up to date
          </div>
        )}

        {loading === false && data.chargebacks && data.chargebacks.last_seven_days > 0 && (
          <div className="card-inner">
            <div className="nk-wg-action">
              <div className="nk-wg-action-content">
                <Icon name="icon ni ni-shield-star" className="text-primary" />
                <div className="title">Chargebacks</div>
                <p>
                  You have <strong>{data.chargebacks.last_seven_days}</strong> new chargeback{data.chargebacks.last_seven_days > 1 ? 's' : ''} in the last 7 days.
                </p>
              </div>
              <Link to={`/chargebacks`}>
                <Button className="btn-icon btn-trigger mr-n2">
                  <Icon name="forward-ios" />
                </Button>
              </Link>
            </div>
          </div>
        )}

        {loading === false && data.payment_links && data.payment_links.unassigned > 0 && (
          <div className="card-inner">
            <div className="nk-wg-action">
              <div className="nk-wg-action-content">
                <Icon name="unlink-alt" className="text-primary" />
                <div className="title">Unassigned Payment Links</div>
                <p>
                  You have <strong>{data.payment_links.unassigned}</strong> payment links with no booking reference.
                </p>
              </div>
              <Link to={`/payment-links?has_booking_reference=0`}>
                <Button className="btn-icon btn-trigger mr-n2">
                  <Icon name="forward-ios" />
                </Button>
              </Link>
            </div>
          </div>
        )}

        {loading === false && authorisedTransactionCount > 0 && (
          <div className="card-inner">
            <div className="nk-wg-action">
              <div className="nk-wg-action-content">
                <Icon name="check-circle-cut" className="text-primary" />
                <div className="title">Authorised Transactions</div>
                <p>
                  You have <strong>{authorisedTransactionCount}</strong> unsettled authorised transactions.
                </p>
              </div>
              <Link to={`/payments`}>
                <Button className="btn-icon btn-trigger mr-n2">
                  <Icon name="forward-ios" />
                </Button>
              </Link>
            </div>
          </div>
        )}

        {loading === false && data.payment_links && data.payment_links.greater_seven_days > 0 && (
          <div className="card-inner">
            <div className="nk-wg-action">
              <div className="nk-wg-action-content">
                <Icon name="focus" className="text-primary" />
                <div className="title">Pending Payment Links</div>
                <p>
                  You have <strong>{data.payment_links.greater_seven_days}</strong> pending payment links older than 7 days.
                </p>
              </div>
              <Link to={`/payment-links?status=active`}>
                <Button className="btn-icon btn-trigger mr-n2">
                  <Icon name="forward-ios" />
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ActionCenter;
