import React from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap';
import {
  Button,
  Icon,
} from 'src/components/Component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Options from './options';

import API_ROUTES from "../../../../config/api";
import DataTable from "src/components/data-table";

const RowFormatter = ({item, triggerReload}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td className="text-left">
        <span className="title">{item.name}</span>
      </td>

      <td className="text-left">
        <span className="title">{item.public_key}</span>
      </td>

      <td className="text-left">
        <span className="title">************{item.secret_key_last_four}</span>
      </td>

      <td className="text-center">
        <span className="title">{moment(item.created_at).format('HH:mm - Do MMM YYYY')}</span>
      </td>

      <td className="tb-tnx-action">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
          >
            <Icon name="more-h" />
          </DropdownToggle>
          <Options item={item} triggerReload={triggerReload} />
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className="text-left">
      <span>Name</span>
    </th>
    <th className="text-left">
      <span>Public Key</span>
    </th>
    <th className="text-left">
      <span>Secret Key</span>
    </th>
    <th>
      <span>Created On</span>
    </th>
    <th className="tb-tnx-action">
      <span>&nbsp;</span>
    </th>
  </tr>
);

const PageOptions = () => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Link to="/developers/api-keys/create" id="link__create_api_key">
          <Button color="light" outline className="bg-violet-800 border-none text-white">
            <Icon name="plus" />
            <span>Create API Key</span>
          </Button>
        </Link>
      </li>
    </ul>
  );
};

const APIKeysComponent = () => {
  const endpoint = API_ROUTES.token['keys:list']();

  return (
    <DataTable
      title="API Keys"
      largeHeading={true}
      columnCount={9}
      limit={200}
      loadingCount={3}
      autoReload={false}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={endpoint}
      forceShowCount={false}
      noHeader={true}
      hideCounter={true}
    />
  );
};

export default APIKeysComponent;
