import React, {useState} from 'react';

import {
  Button,
  Icon,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import moment from "moment";
import API_ROUTES from "../../../../config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import {Spinner} from "reactstrap";
import { useHistory } from 'react-router-dom';
import {Auth} from "aws-amplify";
import axios from "axios";
import PiiObfuscator from "../../../../components/pii-obfuscator";
import {convertPenniesToDecimals} from "../../../../utils/money";

const RowFormatter = ( { item } ) => {
  const history = useHistory();

  const rowClick = (event, url) => {  
    history.push(url)
  }

  return (
    <tr
      key={item.id}
      className="tb-tnx-item cursor-pointer hover:bg-gray-50"
      onClick={(event) => rowClick(event, `/batch/?disbursement_id=${item.id}`)}
    >
      <td>
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="text-center border-left">
        <span className="date">{item.bank_reference}</span>
      </td>
      <td className="text-center">
        <PiiObfuscator className="date" type="company">{ item.receiving_account.name }</PiiObfuscator>
      </td>
      <td className="text-center">
        <PiiObfuscator className="date" type="account_number">{ item.receiving_account.account_number }</PiiObfuscator>
      </td>
      <td className="text-center">
        <span className="date">{ item.receiving_account.sort_code }</span>
      </td>
      <td className="text-center border-left">
        <span className="date">{ item.transactions.length }</span>
      </td>
      <td className="text-center">
        <span className="date">{ CurrencyFormat().format( convertPenniesToDecimals(item.amount) ) }</span>
      </td>
    </tr>
  );
}

const PageOptions = ({requestParams}) => {
  const [fetchingCSV, setFetchingCSV] = useState(false);
  const [fetchingCSVTransactions, setFetchingCSVTransactions] = useState(false);

  const setLoading = (type, status) => {
    if (type === 'csv') {
      setFetchingCSV(status);
    } else if (type === 'csv-transactions') {
      setFetchingCSVTransactions(status);
    }
  }

  const createCSV = async (type) => {
    setLoading(type, true);

    const user = await Auth.currentAuthenticatedUser();

    const response = await axios.post(
      API_ROUTES.ledger['disbursements:get'](),
      { ...requestParams, type },
      {
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setLoading(type, false);
    window.location.href = response.data.data.url;
  }

  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={fetchingCSV} outline className="border-violet-800 bg-white text-violet-800 text-xs mr-2 hover:bg-slate-800 hover:border-slate-800" id="link_generate_csv" onClick={() => createCSV('csv')}>
          {
            fetchingCSV === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export Disbursements</span>
              </React.Fragment>
            )
          }
        </Button>

        <Button disabled={fetchingCSVTransactions} outline className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800"  id="link_generate_csv" onClick={() => createCSV('csv-transactions')}>
          {
            fetchingCSVTransactions === true ? (
              <React.Fragment>
                <Spinner size="sm" color="primary" className="mr-2"> </Spinner> Preparing CSV
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Icon name="download" />
                <span>Export Transactions</span>
              </React.Fragment>
            )
          }
        </Button>
      </li>
    </ul>
  );
};

const TableHeaders = () => (
  <tr className="tb-tnx-head">

    <th className='text-left'>
      <span>Date</span>
    </th>
    <th className="text-center border-left">
      <span>Bank Reference</span>
    </th>
    <th className="text-center">
      <span>Receiving Account</span>
    </th>
    <th className="text-center">
      <span>Account Number</span>
    </th>
    <th className="text-center">
      <span>Sort Code</span>
    </th>
    <th className="text-center border-left">
      <span>Transactions</span>
    </th>
    <th className="text-center">
      <span>Amount</span>
    </th>
  </tr>
);

const Bookings = () => {
  const endpoint = API_ROUTES.ledger['disbursements:get']();

  return (
    <DataTable
      title="Disbursements"
      columnCount={9}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      dateFilterOption={true}
      dateFilterLabel="Disbursement Date"
      searchPlaceholder="Bank reference"
      endpoint={endpoint}
      childOrganisationsOption={true}
      noHeader={true}
    />
  );
};

export default Bookings;
