import React from "react";
import {Row, Col, FormGroup, ButtonGroup, Spinner, Alert, Label} from "reactstrap";
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from "yup";

import {
    Button,
    Icon,
} from "src/components/Component";

import { getProviderFields, providerFieldLabel, providerFieldId, GOOGLEPAY_ALLOWED_CARD_NETWORKS_OPTIONS } from "../utils";

const Form = ({
  organisation,
  paymentProviders,
  transactionMethods,
  currencies,
  initialValues,
  buttonText,
  processForm,
  secretKeyOptional = false,
}) => {
  let secretKeyValidation = Yup.string().max(255);
  if (!secretKeyOptional) {
    secretKeyValidation = secretKeyValidation.required('Secret key is required');
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
          name: Yup.string().max(150).required('Name is required'),
          paymentProvider: Yup.string().max(36).required('Payment provider is required'),
          transactionMethods: Yup.array().min(1, 'At least one transaction method is required.'),
          currencies: Yup.array().min(1, 'At least one currency is required.'),
          publicKey: Yup.string().max(255).required('Public key is required'),
          secretKey: secretKeyValidation,
      })}
      onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
      }) => {
          setSubmitting(true);

          try {
              if (secretKeyOptional && values.secretKey === '') {
                values.secretKey = null;
              }
              await processForm(values);
              setSubmitting(false);
          } catch(error) {
              console.error(error);
              const errors = {
                  general: [
                      'An error has occurred, a member of our team has been notified. Please try again later.',
                  ],
              };
              if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                  error.response.data.errors.forEach((item) => {
                      switch (item.code) {
                          case 'validation.payment_provider_id':
                              errors.paymentProvider = item.message;
                              break;
                          case 'validation.public_key':
                              errors.publicKey = item.message;
                              break;
                          case 'validation.secret_key':
                              errors.secretKey = item.message;
                              break;
                          default:
                              errors.general.push(item.message);
                      }
                  });
              }
              setSubmitting(false);
              setErrors(errors);
          }
      }}
      >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
          }) => (
            <form noValidate className="is-alter" onSubmit={handleSubmit}>

                {errors.general && (
                  <div className="mb-3">
                    {[].concat(errors.general).map((error) => (
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {error}
                    </Alert>
                    ))}
                  </div>
                )}

              <Row className="g-3 align-start">

                <Col sm="12">
                  <h5 className="pb-2 text-secondary">Provider Configuration</h5>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Name<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="form-control form-control-xl"
                      />
                    </div>
                    { typeof errors.name !== 'undefined'  && typeof touched.name !== 'undefined' && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                    { (typeof errors.name === 'undefined' || typeof touched.name === 'undefined') && (
                      <span className="form-note">A descriptive name for this payment configuration</span>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Payment Provider<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    { values.paymentProvider !== '' && (
                      <div className="form-control-wrap">
                        <ButtonGroup>
                          <Button disabled color="secondary">{ values.paymentProviderName }</Button>
                          <Button onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("paymentProvider", '');
                          }} color="danger"><Icon name="cross" /></Button>
                        </ButtonGroup>
                      </div>
                    )}
                    { values.paymentProvider === '' && (
                      <React.Fragment>
                        <div className="form-control-wrap">
                          <Select
                            isClearable
                            name="paymentProvider"
                            id="paymentProvider"
                            className="form-control form-control-xl p-0 border-0 indicator-hidden"
                            value={values.paymentProvider}
                            getOptionLabel={item => item.name}
                            getOptionValue={item => item.id}
                            options={paymentProviders}
                            onChange={(option, e) => {
                              setFieldValue("paymentProvider", option.id);
                              setFieldValue("paymentProviderName", option.name);
                            }}
                            placeholder=""
                            classNamePrefix="react-select"
                          />
                        </div>
                        { typeof errors.paymentProvider !== 'undefined'  && typeof touched.paymentProvider !== 'undefined' && (
                          <small className="text-danger">{errors.paymentProvider}</small>
                        )}
                        { (typeof errors.paymentProvider === 'undefined' || typeof touched.paymentProvider === 'undefined') && (
                          <span className="form-note">The payment provider that the configuration is for</span>
                        )}
                      </React.Fragment>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Transaction Methods<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <Select
                        isMulti
                        name="transactionMethods"
                        id="transactionMethods"
                        className="form-control form-control-xl p-0 border-0"
                        defaultValue={values.transactionMethods}
                        getOptionLabel={item => item.id}
                        getOptionValue={item => item.id}
                        options={transactionMethods}
                        onChange={(option, e) => {
                          setFieldValue("transactionMethods", option);
                        }}
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </div>
                    { typeof errors.transactionMethods !== 'undefined'  && typeof touched.transactionMethods !== 'undefined' && (
                      <small className="text-danger">{errors.transactionMethods}</small>
                    )}
                    { (typeof errors.transactionMethods === 'undefined' || typeof touched.transactionMethods === 'undefined') && (
                      <span className="form-note">Transaction methods that can be used with the provider</span>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Currencies<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <Select
                        isMulti
                        name="currencies"
                        id="currencies"
                        className="form-control form-control-xl p-0 border-0"
                        defaultValue={values.currencies}
                        getOptionLabel={item => item.id}
                        getOptionValue={item => item.id}
                        options={currencies}
                        onChange={(option, e) => {
                          setFieldValue("currencies", option);
                        }}
                        placeholder=""
                        classNamePrefix="react-select"
                      />
                    </div>
                    { typeof errors.currencies !== 'undefined'  && typeof touched.currencies !== 'undefined' && (
                      <small className="text-danger">{errors.transactionMethods}</small>
                    )}
                    { (typeof errors.currencies === 'undefined' || typeof touched.currencies === 'undefined') && (
                      <span className="form-note">Currencies that can be used with the provider</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <div className="nk-divider divider md pb-4" />

              <Row className="g-3 align-start">

                <Col sm="12">
                  <h5 className="pb-2 text-secondary">API Keys</h5>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Public Key<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="publicKey"
                        name="publicKey"
                        value={values.publicKey}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="form-control form-control-xl"
                      />
                    </div>
                    { typeof errors.publicKey !== 'undefined'  && typeof touched.publicKey !== 'undefined' && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                    { (typeof errors.publicKey === 'undefined' || typeof touched.publicKey === 'undefined') && (
                      <span className="form-note">The public key used by the payment provide</span>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Secret Key<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <input
                        type="text"
                        id="secretKey"
                        name="secretKey"
                        value={values.secretKey}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="form-control form-control-xl"
                        placeholder={(secretKeyOptional && !touched.secretKey) ? "<Current key retained>": ""}
                      />
                    </div>
                    { typeof errors.secretKey !== 'undefined'  && typeof touched.secretKey !== 'undefined' && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                    { (typeof errors.secretKey === 'undefined' || typeof touched.secretKey === 'undefined') && (
                      <span className="form-note">The secret key used by the payment provider</span>
                    )}
                  </FormGroup>
                </Col>

              </Row>

              {
                getProviderFields(values.paymentProviderName).length >= 1 && (
                  <React.Fragment>
                    <div className="nk-divider divider md" />

                    <Row className="g-3 align-start pb-4 clearfix">

                      <Col sm="12">
                        <h5 className="pb-2 text-secondary">Provider Specific Configuration</h5>
                      </Col>

                      {getProviderFields(values.paymentProviderName).map((field) => {
                        // Avoid error for uncontrolled input changing to be controlled
                        // https://reactjs.org/docs/forms.html#controlled-components
                        if (!(providerFieldId(field) in values)) {
                          if ( field === 'googlePayAllowedCardNetworks') {
                            values[providerFieldId(field)] = GOOGLEPAY_ALLOWED_CARD_NETWORKS_OPTIONS;
                          } else {
                            values[providerFieldId(field)] = '';
                          }
                        }
                        return (
                          <Col sm="6" key={providerFieldId(field)}>
                            <FormGroup>
                              <Label htmlFor="default-2" className="form-label text-primary">
                                {providerFieldLabel(field)}
                              </Label>
                              <div className="form-control-wrap input-group">
                                {field === 'googlePayAllowedCardNetworks' ? (
                                  <Select
                                    isMulti
                                    id={providerFieldId(field)}
                                    name={providerFieldId(field)}
                                    value={values[providerFieldId(field)]}
                                    className="form-control form-control-xl p-0 border-0"
                                    options={GOOGLEPAY_ALLOWED_CARD_NETWORKS_OPTIONS}
                                    getOptionLabel={item => item}
                                    getOptionValue={item => item}
                                    onChange={(option, e) => {
                                      setFieldValue(providerFieldId(field), option);
                                    }}
                                    placeholder=""
                                    classNamePrefix="react-select"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    id={providerFieldId(field)}
                                    name={providerFieldId(field)}
                                    value={values[providerFieldId(field)]}
                                    onChange={handleChange}
                                    className="form-control form-control-xl"
                                  />
                                )}
                              </div>
                              { touched[providerFieldId(field)] && errors[providerFieldId(field)] && (
                                <small className="text-danger">{errors.name}</small>
                              )}
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  </React.Fragment>
                )
              }


              <div className="nk-divider divider md pb-4" />

              <Row className="g-3 align-start">

                <Col sm="12">
                  <h5 className="pb-2 text-secondary">APM's</h5>
                </Col>

                <Col sm="6">
                  <Row className="g-3 align-center">
                      <Col lg="5">
                          <FormGroup>
                              <label className="form-label" htmlFor={`googlepay-switch`}>Google Pay</label>
                          </FormGroup>
                      </Col>
                      <Col lg="7">
                          <FormGroup>
                              <div className="form-control-wrap">
                                  <div className="custom-control custom-switch">
                                      <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          id="googlepay-switch"
                                          name="googlepay_enabled"
                                          checked={values.googlepay_enabled}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                      />
                                      <label className="custom-control-label" htmlFor={`googlepay-switch`} />
                                  </div>
                              </div>
                          </FormGroup>
                      </Col>
                  </Row>
                </Col>

                <Col sm="6">
                  <Row className="g-3 align-center">
                      <Col lg="5">
                          <FormGroup>
                              <label className="form-label" htmlFor={`applepay-switch`}>Apple Pay</label>
                          </FormGroup>
                      </Col>
                      <Col lg="7">
                          <FormGroup>
                              <div className="form-control-wrap">
                                  <div className="custom-control custom-switch">
                                      <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          id="applepay-switch"
                                          name="applepay_enabled"
                                          checked={values.applepay_enabled}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                      />
                                      <label className="custom-control-label" htmlFor={`applepay-switch`} />
                                  </div>
                              </div>
                          </FormGroup>
                      </Col>
                  </Row>
                </Col>

              </Row>

                <div className="nk-divider divider md" />

                <Row className="g-3">
                    <Col lg="7" className="offset-lg-5">
                        <FormGroup>
                            <Button disabled={Object.keys(errors).length > 0 || isSubmitting === true} type="submit" color="primary" size="lg" className="float-right">
                                {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : buttonText}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>

            </form>
          )}
    </Formik>
  );
};
export default Form;
