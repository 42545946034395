import React, {useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import HasRole from '../../../../utils/has-role';

import {
  Button, Col,
  Icon, Row,
} from 'src/components/Component';

import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import {useSelector} from "react-redux";
import PiiObfuscator from "../../../../components/pii-obfuscator";
import {Auth} from "aws-amplify";
import Axios from "axios";
import StatBox from "../../../dashboard/pages/main/components/statistics/stat-box";
import CreditNoteAssignButton from "./components/assign-link";
import {convertPenniesToDecimals} from "../../../../utils/money";

const RowFormatter = ({item, triggerReload, childFilterState, account, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format( convertPenniesToDecimals(item.amount) );
  const hasAssignRole = HasRole(account, ['credit-notes:assign']);

  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        { item.short_id }
      </td>
      <td>
        <PiiObfuscator className="title" type="customer_name">{ item.customer_name }</PiiObfuscator>
      </td>
      <td className="text-center border-left">
        { item.booking_created_from && (
          <Link to={`/booking/${item.booking_created_from.id}`} className="link__payment_link">{item.booking_created_from.booking_reference}</Link>
        )}
        { !item.booking_created_from && "Not Set" }
      </td>
      <td className="text-center">
        { item.booking_applied_to && (
          <Link to={`/booking/${item.booking_applied_to.id}`} className="link__payment_link">{item.booking_applied_to.booking_reference}</Link>
        )}
        { !item.booking_applied_to && hasAssignRole && (
          <CreditNoteAssignButton id={item.id} triggerReload={triggerReload} toggle={false} />
        )}
        { !item.booking_applied_to && !hasAssignRole && (
          <React.Fragment>Not Assigned</React.Fragment>
        ) }
      </td>
      { childFilterState === true && (
        <td className="text-center border-left">
          <span className="title">{item.organisation.name}</span>
        </td>
      )}
      <td className="text-center border-left">
        <span className="date">{ total }</span>
      </td>

      <td className="tb-tnx-action border-left pl-3">
        <Link
          to={`/customers/credit-note/${item.id}`}
          className="text-soft dropdown-toggle btn btn-icon btn-trigger"
        >
          <Icon name="info" style={{ fontSize: '23px', color: '#798bff' }} />
        </Link>
      </td>
    </tr>
  );
}

const PageOptions = () => {
  const { account } = useSelector((state) => state.account);

  return (
    <ul className="nk-block-tools g-3">
      { HasRole(account, ['credit-notes:create']) && (
        <li>
          <Link to={'/credit-note/create'} id="link_create_credit_note">
            <Button color="dark" className="bg-violet-800 text-white border-none">
              <Icon name="plus" />
              <span>Create Credit Note</span>
            </Button>
          </Link>
        </li>
      )}
    </ul>
  );
};

const TableHeaders = ({childFilterState}) => (
  <tr className="tb-tnx-head">
    <th>
      <span>Short ID</span>
    </th>
    <th>
      <span>Customer Name</span>
    </th>
    <th className="border-left text-center">
      <span>Booking | Created From</span>
    </th>
    <th className="text-center">
      <span>Booking | Assigned To</span>
    </th>
    { childFilterState === true && (
      <th className="text-center border-left">
        <span>Organisation</span>
      </th>
    )}
    <th className="border-left text-center">
      <span>Credit Value</span>
    </th>
    <th className="tb-tnx-action border-left">
      <span>&nbsp;</span>
    </th>
  </tr>
);

const TopBlock = () => {
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    totals: {},
    thirty_day_created: {},
    unassigned: {},
  });

  const getData = useCallback(async () => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.reporting['credit-notes'](organisation.organisation),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData();
  }, [organisation.organisation]);

  return (
    <div className="mb-3">
      <Row>
        <Col md="12" xl="4">
          <StatBox
            title="Un-assigned Credit Notes"
            period={
              <React.Fragment>
                <strong>{data.unassigned.item_count}</strong> Credit note(s) that have not been assigned
              </React.Fragment>
            }
            figure={
              <React.Fragment>
                {CurrencyFormat().format( convertPenniesToDecimals(data.unassigned.item_value) )}
              </React.Fragment>
            }
            loading={loading}
          />
        </Col>

        <Col md="12" xl="4">
          <StatBox
            title="New Credit Notes"
            period={
              <React.Fragment>
                <strong>{data.thirty_day_created.item_count}</strong> Credit note(s) created in the last 30 days
              </React.Fragment>
            }
            figure={
              <React.Fragment>
                {CurrencyFormat().format( convertPenniesToDecimals(data.thirty_day_created.item_value) )}
              </React.Fragment>
            }
            loading={loading}
          />
        </Col>

        <Col md="12" xl="4">
          <StatBox
            title="Total Credit Notes"
            period={
              <React.Fragment>
                <strong>{data.totals.item_count}</strong> credit note(s) created | All time
              </React.Fragment>
            }
            figure={
              <React.Fragment>
                {CurrencyFormat().format( convertPenniesToDecimals(data.totals.item_value) )}
              </React.Fragment>
            }
            loading={loading}
          />
        </Col>
      </Row>

    </div>
  );
};

const PaymentLinks = () => {
  return (
    <DataTable
      title="Credit Notes"
      largeHeading={true}
      columnCount={9}
      topBlock={TopBlock}
      dateGroupingField="created_at"
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      childOrganisationsOption={true}
      dateFilterOption={true}
      dateFilterLabel="Created Date"
      endpoint={API_ROUTES.agent['credit-notes:get']()}
      searchPlaceholder="Customer name or short id"
    />
  );
}

export default PaymentLinks;
