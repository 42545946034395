import React from "react";
import Head from "src/layout/head/Head";
import DataTable from "src/components/data-table";
import moment from "moment";
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import TransactionStatusTransformer from "src/transformer/transaction-status";
import TransactionFromTransformer from "src/transformer/transaction-from";
import CurrencyFormat from "src/transformer/currency-format";
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import { Link, useHistory } from "react-router-dom";
import { Button, Icon } from "src/components/Component";
import API_ROUTES from "src/config/api";

const RowFormatter = ({item, childFilterState, enums}) => {
  const total = CurrencyFormat(enums.currency, item.currency).format(convertPenniesToDecimals(item.amount));
  const method = TransactionMethodTransformer(item.method);
  const type = TransactionTypeTransformer(item.type, item.metadata);
  const status = TransactionStatusTransformer(item.status);
  const from = TransactionFromTransformer(item.payment_link);
  const history = useHistory();

  const rowClick = (event, url) => {  
    if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'INPUT') {
      history.push(url)
    }
  }

  return (
    <tr key={item.id} className="tb-tnx-item cursor-pointer hover:bg-gray-50" onClick={(event) => rowClick(event, `/transaction/${item.id}`)}>
      <td className="text-left">
        <span className="text-center title">
          {item.booking && (
            <Link to={`/booking/${item.booking.id}`} className="link__payment_link text-violet-800 underline">{item.booking.booking_reference}</Link>
          )}
          {!item.booking && item?.payment_link?.id && (
            <Link to={`/payment-links/${item.payment_link.id}/assign`}>
               <Button color="dark" className="bg-violet-800 text-white border-none">Assign to Booking</Button>
            </Link>
          )}
        </span>
      </td>
      <td className="text-center border-l">
        <span className="date">{total}</span>
      </td>
      {childFilterState === true && (
        <td className="text-center border-l">
          <PiiObfuscator className="title" type="organisation">{item.organisation.name}</PiiObfuscator>
        </td>
      )}

      <td className="text-center border-l w-150px">
        <Icon name={`ni text-primary icon ${from.icon} pr-1`} style={{ fontSize: '21px' }} />
        <span className="date" style={{ verticalAlign: 'top' }}>{from.label}</span>
      </td>
      <td className="text-right border-l w-120px">
        <div style={{ width: '38px', padding: '0 4px 0 6px', maxWidth: '38px', margin: '0 auto' }}>
        {
          typeof type.image !== 'undefined' && (
            <img src={type.image} className={type.imageClass} />
          )
        }
        {
          typeof type.image === 'undefined' && (
            <Icon name={`ni text-primary icon ${type.icon} pr-3`} style={{ fontSize: '21px' }} />
          )
        }
        </div>
      </td>
      <td className="text-left border-left w-100px pl-0">
        <span className="date" style={{ verticalAlign: 'top' }}>{type.label}</span>
      </td>
      <td className="text-center w-150px">
        <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
        <span className="date" style={{ verticalAlign: 'top' }}>{method.label}</span>
      </td>
      <td className="text-center border-l">
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="text-center w-150px border-l px-1">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-light text-white ${status.color}`}>
          <Icon name={`ni text-white icon ${status.icon}`} />
          <span className="date" style={{ verticalAlign: 'top' }}>{status.label}</span>
        </span>
      </td>
    </tr>
  );
}

const TableHeaders = ({childFilterState}) => (
  <tr className="tb-tnx-head">
    <th className='text-left'>
      <span>Booking Ref</span>
    </th>
    <th className="text-center border-l">
      <span>Amount</span>
    </th>
    {childFilterState === true && (
      <th className="text-center border-l">
        <span>Organisation</span>
      </th>
    )}
    <th className="text-center border-l">
      <span>From</span>
    </th>
    <th colSpan={2} className="text-center border-left">
      <span>Type</span>
    </th>
    <th className="text-center">
      <span>Method</span>
    </th>
    <th className="border-l">
      <span>Date</span>
    </th>
    <th className="text-center border-l">
      <span>Status</span>
    </th>
  </tr>
);

const Information = ({ customerID }) => {

  const endpoint = API_ROUTES.agent['payments:get']();

  const pathFilters = {
    'customer_id': customerID,
  }

  return (
    <React.Fragment>
      <Head title="Customer Detail" />

      <div>
        <DataTable
          title={'Payments'}
          itemTitle={'Payments'}
          largeHeading={true}
          columnCount={11}
          TableHeaders={TableHeaders}
          RowFormatter={RowFormatter}
          endpoint={endpoint}
          childOrganisationsOption={true}
          dateFilterOption={true}
          dateFilterLabel="Payment Date"
          searchPlaceholder="Customer or Booking Ref"
          noHeader={true}
          hideFilters={true}
          pathFilters={pathFilters}
          cardClass=' '
        />
      </div>

    </React.Fragment >
  )
}

export default Information;