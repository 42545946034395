import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {Alert, Form, FormGroup, Spinner, Input} from 'reactstrap';
import YupPassword from 'yup-password';
import {useHistory, useParams} from 'react-router-dom'

import {
  BlockTitle,
  Button,
  Icon,
} from 'src/components/Component';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import {Auth} from "aws-amplify";
import {VIEWS} from "../../index";

YupPassword(Yup) // extend yup

function MagicLinkForm({ className, onSubmitSuccess, onSubmitFailure, setView, ...rest }) {
  const [loading, setLoading] = useState(false);
  const [linkUsed, setLinkUsed] = useState(false);
  const [data, setData] = useState(null);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const run = async () => {
      setLoading(true);

      try {
        const result = await Axios(
          API_ROUTES.user['login-link:get'](id),
          {
            method: 'get',
          }
        );

        if (result.status === 403) {
          setLinkUsed(true);
        } else {
          setData(result.data.data);

          setLoading(false);
        }
      } catch(error) {
        history.push('/errors/404');
      }
    };

    if (!data) {
      run();
    }
  }, [id]);

  return (
    <Formik
      initialValues={{
        passwordConfirmation: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters long')
          .max(255)
          .minSymbols(1, 'Password must contain at least 1 symbol')
          .minNumbers(1, 'Password must contain at least 1 number')
          .minLowercase(1, 'Password must contain at least 1 lowercase letter')
          .minUppercase(1, 'Password must contain at least 1 uppercase letter')
          .required('Password is required'),
        passwordConfirmation: Yup.string()
          .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.password === value
          })
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await Axios(
            API_ROUTES.user['login-link:update'](id),
            {
              method: 'post',
              data: {
                password: values.password,
              },
            }
          );

          let user = await Auth.signIn(data.user.cognito_sub, values.password);

          history.push('/');
          onSubmitSuccess(user);
        } catch (error) {
          const message = (error.message) || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
        const passwordLength = Yup.string().min(8).isValidSync(values.password);
        const specialCharacter = Yup.string().minSymbols(1).isValidSync(values.password);
        const numbers = Yup.string().minNumbers(1).isValidSync(values.password);
        const upperLower = Yup.string().minLowercase(1).minUppercase(1).isValidSync(values.password);
        const passwordMatch = values.password === values.passwordConfirmation && values.password !== '';

        const allGood = passwordLength && specialCharacter && numbers && upperLower && passwordMatch;

        if (linkUsed === true) {
          return (
            <React.Fragment>
              <div  className="text-primary mb-5">
                <BlockTitle tag="h2" className="text-primary">Login Link Used</BlockTitle>
                <br />
                <p className="fs-18px"><strong>This login link has already been used</strong>. Please generate another one from the login page or alternatively contact support if you believe this link has been used fraudulently.</p>
                <Button color="secondary" size="l" className="mt-5" onClick={(event) => { event.preventDefault(); setView(VIEWS.LOGIN) }}>
                  Back to login page
                </Button>
              </div>
            </React.Fragment>
          );
        }

        if (loading === true) {
          return (
            <React.Fragment>
              <div className="text-center">
                <h2>Loading...</h2>
                <Spinner animation="border" role="status" className="mt-1 mr-3 text-primary"> </Spinner>
              </div>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment>
            <div  className="text-primary mb-5">
            <BlockTitle tag="h1" className="text-primary mb-5 text-3xl">Hey { data?.user?.first_name }</BlockTitle>

              <h4 className={ allGood !== false ? 'text-primary' : 'd-none' }><strong>Your password matches our minimum requirements.</strong></h4>
              <h4 className={ allGood === false ? 'text-primary' : 'd-none' }><strong>Lets sort out your new password. It must,</strong></h4>
              <ul className={ allGood === false ? 'ml-3 mt-3' : 'd-none' }>
                <li className={passwordLength ? 'd-none' : 'text-danger fw-bold'}>- Be at least 8 characters long</li>
                <li className={numbers ? 'd-none' : 'text-danger fw-bold'}>- Include numbers</li>
                <li className={upperLower ? 'd-none' : 'text-danger fw-bold'}>- Include uppercase and lowercase characters</li>
                <li className={specialCharacter ? 'd-none' : 'text-danger fw-bold'}>- Include at least one special character</li>
                <li className={passwordMatch ? 'd-none' : 'text-danger fw-bold'}>- Password fields must match</li>
              </ul>
            </div>
            <div className="nk-divider divider md" />
            {errors.submit && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> { errors.submit }
                </Alert>
              </div>
            )}
            <Form noValidate className="is-alter" onSubmit={handleSubmit}>
              <FormGroup>
                <div className="form-control-wrap">
                  <div className="form-label-group">
                    <label className="form-label text-primary" htmlFor="default-01">
                      Choose your Password
                    </label>
                  </div>
                  <Input
                    type={"password"}
                    id="password"
                    bsSize="xl"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholder="Enter your password"
                    className={`form-control-lg form-control is-shown`}
                  />
                </div>
                <div className="form-control-wrap mt-5">
                  <div className="form-label-group">
                    <label className="form-label text-primary" htmlFor="default-01">
                      Confirm your Password
                    </label>
                  </div>
                  <Input
                    type={"password"}
                    id="passwordConfirmation"
                    bsSize="xl"
                    name="passwordConfirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.passwordConfirmation}
                    placeholder="Enter your password again"
                    className={`form-control-lg form-control is-shown`}
                  />
                </div>
              </FormGroup>
              <FormGroup className="mt-5">
                <Button size="lg" className="btn-block bg-purple-800" type="submit" color="primary" disabled={allGood === false || isSubmitting}>
                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : "Update & Login"}
                </Button>
              </FormGroup>
            </Form>
          </React.Fragment>
        )
      }}
    </Formik>
  );
}

MagicLinkForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onSubmitFailure: PropTypes.func,
};

MagicLinkForm.defaultProps = {
  onSubmitSuccess: () => {},
  onSubmitFailure: () => {},
};

export default MagicLinkForm;
