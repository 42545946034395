import React from "react";
import Head from "src/layout/head/Head";
import {
  Block, BlockTitle
} from "src/components/Component";
import Skeleton from "react-loading-skeleton";
import CurrencyFormat from 'src/transformer/currency-format';
import {convertPenniesToDecimals} from "../../../../../../utils/money";

const BookingElements = ({ data, loading }) => {
  return (
    <React.Fragment>
      <Head title="Booking Elements" />

      <div className="mb-3">
        <BlockTitle tag="h4" className="text-primary mb-0">Elements</BlockTitle>
        <p className="text-secondary fw-bold">Elements that make up the booking (hotels, flights, insurance, etc...)</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" className="text-center">Booking Reference</th>
            <th scope="col" className="text-center">Type</th>
            <th scope="col" className="text-center">Destination (Air)</th>
            <th scope="col" className="border-left text-center">Amount</th>
          </tr>
          </thead>
          <tbody>
          { loading === true && [0, 1, 2, 3].map(() => (
            <tr className='tb-tnx-item'>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && data.components && data.components.map((component, index) => {
            return (
              <tr className='tb-tnx-item'>
                <td>{ index + 1 }</td>
                <td>{ component.supplier.name }</td>
                <td className="text-center">{ component.booking_reference }</td>
                <td className="text-center">{ component.type }</td>
                <td className="text-center">{ component.destination_air }</td>
                <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals(component.amount) ) }</td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Block>

    </React.Fragment>
  );
};
export default BookingElements;
