import React from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Row,
  Col,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import Field from "../../../../../bookings/pages/booking/views/booking-information/components/field";
import CurrencyFormat from "src/transformer/currency-format";
import {convertPenniesToDecimals} from "src/utils/money";
import {useSelector} from "react-redux";
import moment from "moment";
import HasFeature from "src/utils/has-feature";

const Payout = ({ data = {}, loading = true, triggerReload = false }) => {
  const { enums } = useSelector((state) => state.enums);
  const { organisation } = useSelector((state) => state);

  if (loading === true) {
    return (
      <React.Fragment>
        <Row>
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className="text-primary mb-0">Bank Reconciliation</BlockTitle>
            <p className="text-secondary fw-bold">Reconciliation from Felloh to your bank account</p>
          </div>

          <Block>
            <Skeleton count={2} height="40px" className="mb-2" />
          </Block>
        </Row>
      </React.Fragment>
    )
  }

  if (!HasFeature(organisation.features, ['aisp'])) {
    return (
      <div className="pt-3 pb-2 text-secondary">
        <p className="fs-18px fw-bold text-indigo">Your bank account is not connected to your Felloh organisation</p>
        <p className="fs-16px">Please get in touch with Felloh customer support to enquire about adding this feature to your account.</p>
      </div>
    );
  }

  if (data.batch.length === 0 || !data.batch[0].disbursal?.bank_transaction?.id) {
    return (
      <div className="pt-3 pb-2 text-secondary">
        <p className="fs-18px fw-bold text-indigo">The disbursal was not found in your bank account</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Head title="Payment Information" />
      <Row>
        <Col sm={12}>
        <div className="mb-3 mt-1">
          <BlockTitle tag="h4" className="text-primary mb-0">Bank Reconciliation</BlockTitle>
          <p className="text-secondary fw-bold">Reconciliation from Felloh to your bank account</p>
        </div>

        <Block>

          <div className="row g-3 ml-2 mr-2">

            <Field
              name="Felloh ID"
              description="The unique felloh identifier for this line item"
              itemWidth={4}
              data={ data.batch[0].disbursal?.bank_transaction.id } />

            <Field
              name="Total Amount"
              description="The total amount that was received into the bank account"
              itemWidth={4}
              data={CurrencyFormat(enums.currency, 'GBX').format( convertPenniesToDecimals(data.batch[0].disbursal?.bank_transaction.amount) )} />

            <Field
              name="Posting Date"
              description="The date that the disbursal was received in your bank account"
              itemWidth={4}
              data={moment(data.batch[0].disbursal?.bank_transaction.posting_date).format('DD/MM/yy')} />

            <Field
              name="Type"
              description="The method utilised to move funds into your account"
              itemWidth={4}
              data={ data.batch[0].disbursal?.bank_transaction.type } />

            <Field
              name="Bank Reference"
              description="The description of the transaction from your bank account"
              itemWidth={8}
              data={data.batch[0].disbursal?.bank_transaction.description } />

          </div>
        </Block>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Payout;
