import React from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Row,
  Col,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import Field from "../../../../../bookings/pages/booking/views/booking-information/components/field";
import {Link} from "react-router-dom";
import CurrencyFormat from "src/transformer/currency-format";
import {convertPenniesToDecimals} from "src/utils/money";
import {useSelector} from "react-redux";
import moment from "moment";
import ProviderNameTransformer from "src/transformer/provider-name";

const Payout = ({ data = {}, loading = true, triggerReload = false }) => {
  const { enums } = useSelector((state) => state.enums);
  
  const titleClass = 'text-primary mb-0 text-xl font-bold';
  const descriptionClass = 'text-secondary text-sm';

  if (loading === true) {
    return (
      <React.Fragment>
        <Row>
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Payout Detail</BlockTitle>
            <p className={descriptionClass}>Payouts from the Felloh managed trust account to receiving account</p>
          </div>

          <Block>
            <Skeleton count={2} height="40px" className="mb-2" />
          </Block>
        </Row>
      </React.Fragment>
    )
  }

  if (!data?.settlement?.batch) {
    return (
      <div className="pt-3 pb-2 text-secondary">
        <p className="fs-18px fw-bold text-indigo">Dibursal Not Created - Pending settlement information from { ProviderNameTransformer(data.payment_provider.name) }</p>
        <p className="fs-16px">Disbursals to your account will only be undertaken once we have received settlement from { ProviderNameTransformer(data.payment_provider.name) }.</p>
      </div>
    );
  }

  if (!data.batch || data.batch.length === 0 || data.batch[0].completed_at === null) {
    return (
      <div className="pt-3 pb-2 text-secondary">
        <p className="fs-18px fw-bold text-indigo">There are no disbursal's for this transaction</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Head title="Payment Information" />
      <Row>
        <Col sm={12}>
        <div className="mb-3 mt-1">
          <BlockTitle tag="h4" className={titleClass}>Disbursal Detail</BlockTitle>
          <p className={descriptionClass}>Disbursal information for this transaction from the Felloh managed trust account to receiving account</p>
        </div>

        <Block>
          <div className="row g-3 ml-2 mr-2">

            <Field
              name="Disbursal ID"
              description="The unique felloh identifier for the disbursal"
              itemWidth={4}
              data={ <Link
                to={`/batch/?disbursement_id=${data.batch[0].disbursal.id}`}
              >
                { data.batch[0].disbursal.id }
              </Link> } />

            <Field
              name="Bank Reference"
              description="The bank reference for the disbursal"
              itemWidth={4}
              data={ data.batch[0].disbursal.bank_reference } />

            <Field
              name="Total Disbursal Amount"
              description="The total amount disbursed (this includes other transactions in the batch)"
              itemWidth={4}
              data={CurrencyFormat(enums.currency, data.batch[0].disbursal.currency).format( convertPenniesToDecimals(data.batch[0].disbursal.amount) )} />

            <Field
              name="Disbursal Date"
              description="The date that the disbursal was initiated"
              itemWidth={4}
              data={moment(data.batch[0].complete_at).format('DD/MM/yy')} />

          </div>
        </Block>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Payout;
