import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

const PrivateRoute = ({ exact, component: Component, ...rest }) => {
  const account = useSelector((state) => state.account);

  return (
    <Route
      exact={exact}
      rest
      render={(props) =>
        account.user && account.user.username ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  );
};

export default PrivateRoute;
