import React, {useCallback, useEffect, useState} from 'react';

import Content from '../../../layout/content/Content';
import {
  Button, Icon, BlockHeadContent, BlockHead, BlockBetween, BlockTitle, Row, Col,
} from 'src/components/Component';
import {Link, useParams} from 'react-router-dom';
import DataTable from 'src/components/data-table';
import API_ROUTES from "../../../config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import { convertPenniesToDecimals } from "../../../utils/money";
import {useSelector} from "react-redux";
import {Auth} from "aws-amplify";
import Axios from "axios";
import StatBox from "../../dashboard/pages/main/components/statistics/stat-box";
import TransactionFlow from './components/transaction-flow'
import TransactionLink from "./components/transaction-flow/components/transaction-link";
import PiiObfuscator from "../../../components/pii-obfuscator";

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th>
      <span>type</span>
    </th>
    <th className="border-left">
      <span>Description</span>
    </th>
    <th className="border-left text-center" style={{ minWidth: '100px' }}>
      <span>Credit</span>
    </th>
    <th className="text-center" style={{ minWidth: '100px' }}>
      <span>Debit</span>
    </th>
    <th className="text-center" style={{ minWidth: '100px' }}>
      <span>Counterparty</span>
    </th>
    <th className="border-left text-center" style={{ minWidth: '150px' }}>
      <span>Linked Entity</span>
    </th>
  </tr>
);

const TopBlock = () => {
  const { accountID } = useParams();
  const { organisation } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [ledgerData, setLedgerData] = useState({});

  const getData = useCallback(async (accountID) => {
    setLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.bank['account:get:statistics'](accountID),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        data: {
          organisation: organisation.organisation
        }
      }
    );

    setLedgerData(result.data.data);
    setLoading(false);
  });

  useEffect(() => {
    getData(accountID);
  }, [organisation.organisation, accountID]);

  let matchedTransactions = ((ledgerData?.days?.seven?.transactions?.matched / ledgerData?.days?.seven?.transactions?.total) * 100).toFixed(2);
  let unMatchedTransactions = ((ledgerData?.days?.seven?.transactions?.unmatched / ledgerData?.days?.seven?.transactions?.total) * 100).toFixed(2);

  return (
    <Content>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className="text-primary" page>Bank Account <span className="fw-normal">Summary</span></BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="text-right">
            <Link to={'/bank-account'}>
              <Button color="light" outline className="btn-white">
                <Icon name="back-ios" />
                <span>Back</span>
              </Button>
            </Link>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Row className="mt-4 mb-2">
        <Col md="12" xl="4">
          <StatBox
            title={ <React.Fragment>Matched <span className="fw-normal">Transactions</span></React.Fragment> }
            period="Last 7 Days"
            figure={ `${ isNaN(matchedTransactions) ? 0 : matchedTransactions }%` }
            loading={loading}
          />
        </Col>
        <Col md="12" xl="4">
          <StatBox
            title={ <React.Fragment>Unmatched <span className="fw-normal">Transactions</span></React.Fragment> }
            period="Last 7 Days"
            figure={ `${ isNaN(unMatchedTransactions) ? 100 : unMatchedTransactions }%` }
            loading={loading}
          />
        </Col>

        <Col md="12" xl="4">
          <StatBox
            title={ <React.Fragment>Account <span  className="fw-normal">Balance</span></React.Fragment> }
            period="Current"
            figure={CurrencyFormat().format( convertPenniesToDecimals(ledgerData?.current?.balance) )}
            loading={loading}
          />
        </Col>
      </Row>

      <TransactionFlow ledgerData={ledgerData} loading={loading} />

    </Content>
  );
};


const RowFormatter = ({item, triggerReload}) => {
  return (
    <React.Fragment key={item.id}>
      <tr className="tb-tnx-item">
        <td className="border-right">
          <span className="title">{ item.type || item.category }</span>
        </td>
        <td className="border-right">
          <span className="title">
            <PiiObfuscator className="title" type="bank_description">{  item.description }</PiiObfuscator>
          </span>
        </td>
        <td className="text-center">
          <span className="title">{ CurrencyFormat().format(convertPenniesToDecimals( item.is_credit ? item.amount : 0)) }</span>
        </td>
        <td className="text-center border-right">
          <span className="title">{ CurrencyFormat().format(convertPenniesToDecimals(item.is_debit ? item.amount : 0)) }</span>
        </td>
        <td className="border-right text-center">
          <span className="title">{ item.payee || item.payer || '---' }</span>
        </td>
        <td className="text-center border-right" style={{ width: '175px', padding: 0 }}>
          <TransactionLink item={item} triggerReload={triggerReload} />
        </td>
      </tr>
    </React.Fragment>
  );
}

const Bookings = () => {
  const { accountID } = useParams();

  return (
    <DataTable
      title="Bank Account Statement"
      columnCount={9}
      TopBlock={TopBlock}
      itemTitle="ledger entries"
      dateGroupingField="posting_date"
      TableHeaders={TableHeaders}
      dateFilterOption={true}
      dateFilterLabel="Posting Date"
      searchPlaceholder="Transaction description"
      RowFormatter={RowFormatter}
      endpoint={API_ROUTES.bank['account:get'](accountID)}
      childOrganisationsOption={false}
    />
  )
};

export default Bookings;
