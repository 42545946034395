import React from "react";

import {Link} from "react-router-dom";

const ActivityTransformer = (type, entity_id) => {
  switch(type) {
    case 'api-keys:view':
    case 'api-keys:create':
    case 'api-keys:delete':
      return <Link to={`/developers`}>{entity_id}</Link>
    case 'beneficiaries:view':
      return <Link to={`/beneficiaries`}>{entity_id}</Link>
    case 'booking:create':
    case 'booking:view':
      return <Link to={`/bookings/${entity_id}`}>{entity_id}</Link>
    case 'bookings:view':
      return <Link to={`/bookings`}>{entity_id}</Link>
    case 'chargebacks:view':
      return <Link to={`/chargebacks`}>{entity_id}</Link>
    case 'organisation:audit:view':
      return <Link to={`/audit`}>{entity_id}</Link>
    case 'organisation:feature:add':
    case 'organisation:view':
      return <Link to={`/organisations/${entity_id}`}>{entity_id}</Link>
    case 'organisation:view:features':
      return <Link to={`/organisations/${entity_id}/features`}>{entity_id}</Link>
    case 'payment-link:assign-to-booking':
    case 'payment-link:create':
    case 'payment-link:view':
      return <Link to={`/payment-links/${entity_id}`}>{entity_id}</Link>
    case 'payment-links:view':
      return <Link to={`/payment-links`}>{entity_id}</Link>
    case 'suppliers:view':
      return <Link to={`/suppliers`}>{entity_id}</Link>
    case 'transaction:view':
      return <Link to={`/transactions/${entity_id}`}>{entity_id}</Link>
    case 'transactions:view':
      return <Link to={`/payments`}>{entity_id}</Link>
    case 'users:get':
      return <Link to={`/users`}>{entity_id}</Link>
    case 'user:create':
      return <Link to={`users/${entity_id}`}>{entity_id}</Link>
    case 'user:role:add':
    case 'user:roles:get':
    case 'user:role:remove':
      return <Link to={`users/${entity_id}/roles`}>{entity_id}</Link>
    default:
      return <React.Fragment>{ entity_id }</React.Fragment>;
  }
}

export default ActivityTransformer;
