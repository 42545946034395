import React from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import { PlusIcon } from '@heroicons/react/24/outline';

const DateFilter = ({ onToggle, title, onChange, value }) => {
  const handleValueChange = (newValue) => {
    onChange(newValue);
    onToggle(false);
  }

  return (
    <div className='mr-3 hs-dropdown-toggle p-0 rounded-md hover:bg-slate-700bg-white text-violet-800 border border-violet-800 relative cursor-pointer'>
      <div className='inline-block'>
        <Datepicker
          placeholder='Payment Date'
          toggleClassName="hidden"
          containerClassName="bg-transparent relative z-10"
          inputClassName="cursor-pointer bg-transparent z-10 w-[128px] hs-dropdown-toggle py-1 pl-2 pr-1 inline-flex gap-x-2 text-xs font-left rounded-md hover:bg-slate-700bg-white text-violet-800 placeholder-violet-800"
          value={value}
          onChange={handleValueChange}
          showShortcuts={true}
        />
      </div>
      <div className='z-0 absolute right-0 top-0 pt-1 pr-1'>
        <div className='border-l pl-1 border-violet-800'>
        <PlusIcon className='h-4 w-4 ' />
        </div>
      </div>
    </div>
  )
}

export default DateFilter;