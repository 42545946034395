import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import { Form, FormGroup, Spinner, Input } from "reactstrap";
import {
  Button,
  Icon,
} from "src/components/Component";
import { Link } from 'react-router-dom';

function LoginForm({ className, onSubmitSuccess, onSubmitFailure, setView, token, ...rest }) {
  const [passState, setPassState] = useState(false);

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          let user = await Auth.signIn(values.email.toLowerCase(), values.password, {
            captcha: token,
          });

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const error = new Error(user.challengeName);
            error.name = user.challengeName;
            onSubmitFailure(error, values.email, values.password);
          } else {
            onSubmitSuccess(user);
          }
        } catch (error) {
          onSubmitFailure(error, values.email, values.password);
          const message = (error.message) || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => {
        return (
          <React.Fragment>
            {errors.submit && (
              <div className="bg-rose-100 border-t-4 border-rose-500 rounded-b text-teal-900 px-4 py-3 mb-4" role="alert">
                <div className="flex">
                  <div>
                    <ExclamationTriangleIcon className="h-6 w-6 text-rose-500 mr-4 mt-2" aria-hidden="true" />
                  </div>
                  <div>
                    <p className="font-bold">Authentication Failed</p>
                    <p>{errors.submit}</p>
                  </div>
                </div>
              </div>
            )}
            <div className='mt-2 mb-6'>
              <h1 className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-1 font-normal">Login</h1>
            </div>

              <Form noValidate className="is-alter" onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="form-label-group">
                    <label className="form-label text-primary" htmlFor="default-01">
                      Email Address
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      id="email"
                      name="email"
                      bsSize="xl"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter your email address or username"
                      className="form-control-lg form-control"
                      tabIndex="1"
                    />
                    {touched.email && errors.email && <span className="invalid">{errors.email}</span>}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-label-group">
                    <label className="form-label text-primary" htmlFor="password">
                      Password
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                    >
                      <Icon name="eye" className="passcode-icon icon-show" />

                      <Icon name="eye-off" className="passcode-icon icon-hide" />
                    </a>
                    <Input
                      type={passState ? "text" : "password"}
                      id="password"
                      bsSize="xl"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Enter your password"
                      className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                      tabIndex="2"
                    />
                    {touched.password && errors.password && <span className="invalid">{errors.password}</span>}
                  </div>
                </FormGroup>
                <div className="mb-3 text-right">
                  <Link
                    className="link link-primary"
                    to="/password-reset"
                    id="link__forgotten_password"
                  >
                    Forgotten Your Password?
                  </Link>
                </div>

                <FormGroup>
                  <Button size="lg" className="btn-block bg-purple-800" type="submit" color="primary" tabIndex="3">
                    {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : "Sign In"}
                  </Button>
                </FormGroup>

                <div className="mt-4 text-sm text-gray-600 text-center">
                  <p>Don't have an account? <a href="https://onboard.felloh.com" className="text-violet-800 underline hover:underline">Register here</a>
                  </p>
                </div>
              </Form>

          </React.Fragment>
        )
      }}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  setView: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => { },
  onSubmitFailure: () => { },
};

export default LoginForm;
