import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

import Ledger from "./pages/ledger";
import Disbursements from "./pages/disbursements";
import Acquirer from "./pages/acquirer";
import AcquirerBatch from "./pages/acquirer/batch";
import Charges from './pages/charges';

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import { ArrowDownOnSquareStackIcon, CalculatorIcon, QueueListIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Acquirer Settlement', icon: ArrowDownOnSquareStackIcon, path: '/settlement/acquirer', check: HasRole(account, ['acquirer:reporting']) && HasFeature(features, ['acquirer-reporting']) },
    { name: 'Acquirer Charges', icon: CalculatorIcon, path: '/settlement/charges', check: HasRole(account, ['charges:view']) && HasFeature(features, ['acquirer-reporting']) },
    { name: 'Disbursals', icon: ArrowDownOnSquareStackIcon, path: '/settlement/disbursements', check: HasRole(account, ['payouts:view', 'payouts:create']) && HasFeature(features, ['payouts']) },
    { name: 'Ledger', icon: QueueListIcon, path: '/settlement/ledger', check: HasRole(account, ['ledger:view']) && HasFeature(features, ['ledger']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Settlement" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const BasePathRenderer = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  let path = '/';

  if (HasRole(account, ['ledger:view']) && HasFeature(features, ['ledger'])) {
    path = `/settlement/ledger`;
  } else if (HasRole(account, ['payouts:view', 'payouts:create']) && HasFeature(features, ['payouts'])) {
    path = `/settlement/disbursements`;
  } else if (HasRole(account, ['acquirer:reporting']) && HasFeature(features, ['acquirer-reporting'])) {
    path = `/settlement/acquirer`;
  } else if (HasRole(account, ['charges:view']) && HasFeature(features, ['acquirer-reporting'])) {
    path = `/settlement/charges`;
  }

  return (
    <Route exact path="/settlement" component={(props) => {
      return (
        <Redirect to={path} />
      )
    }}
    />
  );
};

const redirects = [
  { from: '/ledger', to: '/settlement/ledger' },
  { from: '/disbursements', to: '/settlement/disbursements' },
  { from: '/batches', to: '/settlement/acquirer' },
  { from: '/batches/:batch_id', to: '/settlement/acquirer/:batch_id' },
];

const SettlementRouter = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}
        {BasePathRenderer()}

        {HasRole(account, ['ledger:view']) && HasFeature(features, ['ledger']) && (
          <Route exact path={`/settlement/ledger`} component={() => <Layout><Ledger /></Layout>} />
        )}

        {HasRole(account, ['payouts:view', 'payouts:create']) && HasFeature(features, ['payouts']) && (
          <Route exact path={`/settlement/disbursements`} component={() => <Layout><Disbursements /></Layout>} />
        )}

        {HasRole(account, ['charges:view']) && HasFeature(features, ['acquirer-reporting']) && (
          <Route exact path={`/settlement/charges`} component={() => <Layout><Charges /></Layout>} />
        )}

        {HasRole(account, ['acquirer:reporting']) && HasFeature(features, ['acquirer-reporting']) && (
          <React.Fragment>
            <Route exact path={`/settlement/acquirer`} component={() => <Layout><Acquirer /></Layout>} />
            <Route exact path={`/settlement/acquirer/:batch_id`} component={() => <AcquirerBatch />} />
          </React.Fragment>
        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default SettlementRouter;
