import React from "react";
import Head from "src/layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  Block,
  BlockTitle,
} from "src/components/Component";
import {Link} from "react-router-dom";
import moment from "moment";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "../../../../../components/pii-obfuscator";
import { useSelector } from "react-redux";
import {convertPenniesToDecimals} from "../../../../../utils/money";

const PaymentInformation = ({ data = {}, loading = true }) => {
  const { enums } = useSelector((state) => state.enums);

  if (loading === true) {
    return (
      <React.Fragment>
        <div className="card-inner">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockTitle tag="h5">Information</BlockTitle>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Skeleton count={4} height="40px" className="mb-2" />
          </Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockTitle tag="h5">Booking Links</BlockTitle>
            </BlockBetween>
          </BlockHead>
          <Block>
            <Skeleton count={4} height="40px" className="mb-2" />
          </Block>

        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Head title="Credit Note Information" />
      <div className="card-inner">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockTitle tag="h5">Information</BlockTitle>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="row g-3">
            <div className="col-6">
              <span className="sub-text">Short ID:</span>
              <span>{ data.short_id }</span>
            </div>

            <div className="col-6">
              <span className="sub-text">Customer Name:</span>
              <span><PiiObfuscator type="customer_name">{ data.customer_name }</PiiObfuscator></span>
            </div>

            <div className="col-6">
              <span className="sub-text">Amount:</span>
              <span>{CurrencyFormat(enums.currency, data.currency).format( convertPenniesToDecimals(data.amount) )}</span>
            </div>

            <div className="col-6">
              <span className="sub-text">Created At:</span>
              <span>At {moment(data.created_at).format('HH:mm:ss')} on {moment(data.created_at).format('DD/MM/yy')}</span>
            </div>

            <div className="col-12">
              <span className="sub-text">Description:</span>
              <span>{ data.description ? data.description : 'Not set' }</span>
            </div>

          </div>
        </Block>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockTitle tag="h5">Booking Links</BlockTitle>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="row g-3">

            <div className="col-6">
              <span className="sub-text">Booking | Created From:</span>
              <span>
                   { data?.booking_created_from && (
                     <Link to={`/booking/${data.booking_created_from.id}`}>{data.booking_created_from.booking_reference}</Link>
                   )}
                { !data?.booking_created_from && 'Not Set' }
              </span>
            </div>

            <div className="col-6">
              <span className="sub-text">Booking | Applied to:</span>
              <span>
                   { data?.booking_applied_to && (
                     <Link to={`/booking/${data.booking_applied_to.id}`}>{data.booking_applied_to.booking_reference}</Link>
                   )}
                { !data?.booking_applied_to && 'Not Applied' }
              </span>
            </div>

          </div>
        </Block>

      </div>
    </React.Fragment>
  );
};
export default PaymentInformation;
