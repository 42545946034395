import React from "react";
import Head from "src/layout/head/Head";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css';
import PiiObfuscator from "src/components/pii-obfuscator";

const UserOrganisations = ({ user, parentLoading }) => {
  const organisationUsers = Object.keys(user).length >= 1 && user.organisations.length > 0 ? user.organisations : [];
  organisationUsers.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <React.Fragment>
      <Head title="User Organisations" />
      <div>
        <div style={{ display: parentLoading === true ? 'block' : 'none' }} className="mt-3">
          <Skeleton count={2} height="50px" className="mb-4" style={{
            width: '100%',
          }}/>
        </div>

        <div style={{ display: parentLoading === false ? 'block' : 'none' }}>
          <table className="table table-tranx">
            <thead>
            <tr className="tb-tnx-head">
              <th className="tb-tnx-id">
                <span className="">Organisation</span>
              </th>
              <th>
                <span>Shop ID</span>
              </th>
            </tr>
            </thead>
            <tbody>
            { organisationUsers.map((item) => {
              return (
                <tr key={item.id} className="tb-tnx-item">
                  <td className="tb-tnx-id">
                    <PiiObfuscator type="company">{item.name}</PiiObfuscator>
                  </td>
                  <td>
                    <span className="title">{item.id}</span>
                  </td>
                </tr>
              );
            })
            }
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserOrganisations;
