import React  from 'react';

import DataTable from 'src/components/data-table';
import API_ROUTES from 'src/config/api';
import moment from "moment";
import {useSelector} from "react-redux";
import ActivityTransformer from "../../transformer/activity-transformer";

const RowFormatter = ({item}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <span className="title">{ moment(item.created_at).format('HH:mm') }</span>
      </td>
      <td>
        { item.type }
      </td>
      <td>
        { ActivityTransformer(item.type, item.entity_id) }
      </td>
      <td>
        { item.user.email }
      </td>
      <td>
        { item.id }
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th>
        <span>Time</span>
      </th>
      <th>
        <span>Type</span>
      </th>
      <th>
        Entity ID
      </th>
      <th>
        <span>User</span>
      </th>
      <th>
        <span>Event ID</span>
      </th>
    </tr>
  </React.Fragment>
);

const Suppliers = () => {
  const { account } = useSelector((state) => state.account);

  return (
    <DataTable
      dateGroupingField="created_at"
      title="User Activity"
      itemTitle="activities"
      columnCount={8}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      pathFilters={{
        'user': account.id,
      }}
      showFilters={false}
      endpoint={API_ROUTES.audit['audit:get']()}
      searchPlaceholder="Event type"
      childOrganisationsOption={false}
    />
  );

}
export default Suppliers;
