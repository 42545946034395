import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

import GroupMenu from "src/components/group-menu";
import APIKeys from "./pages/api-keys";
import Webhooks from "./pages/webhooks";
import APIKeyCreate from "./pages/api-keys/create";
import WebhookCreate from "./pages/webhooks/create";
import WebhookNotifications from './pages/webhooks/notifications';
import WebhookLogs from './pages/webhooks-logs';

import RedirectRenderer from "src/components/redirect-renderer";

import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";

import { CodeBracketIcon, MegaphoneIcon, QueueListIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'API Keys', icon: CodeBracketIcon, path: '/developers/api-keys', check: HasRole(account, ['developers:manage:keys']) && HasFeature(features, ['developers']) },
    { name: 'Webhook Congifuration', icon: MegaphoneIcon, path: '/developers/webhooks', check: HasRole(account, ['developers:manage:webhooks']) && HasFeature(features, ['developers']) },
    { name: 'Webhook Logs', icon: QueueListIcon, path: '/developers/webhooks/logs', check: HasRole(account, ['developers:manage:webhooks']) && HasFeature(features, ['developers']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Developers" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

let redirects = [
  { from: '/developers', to: '/developers/api-keys' },
];

const BasePathRenderer = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  let path = '/';

  if (HasRole(account, ['developers:manage:keys']) && HasFeature(features, ['developers'])) {
    path = `/developers/api-keys`;
  }

  if (HasRole(account, ['developers:manage:webhooks']) && HasFeature(features, ['developers'])) {
    path = `/developers/webhooks`;
  }

  return (
    <Route exact path="/developers" component={(props) => {
      return (
        <Redirect to={path} />
      )
    }}
    />
  );
};

const Developers = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (HasRole(account, ['developers:manage:keys']) === false) {
    redirects = { from: '/developers', to: '/developers/webhooks' };
  }

  if (account?.id) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}
        {BasePathRenderer()}

        {HasRole(account, ['developers:manage:keys']) && HasFeature(features, ['developers']) && (
          <React.Fragment>
            <Route exact path={`/developers/api-key/create`} component={APIKeyCreate} />
            <Route exact path={`/developers/api-keys`} component={() => <Layout><APIKeys /></Layout>} />
          </React.Fragment>
        )}

        {HasRole(account, ['developers:manage:webhooks']) && HasFeature(features, ['developers']) && (
          <React.Fragment>
            <Route exact path={`/developers/webhooks`} component={() => <Layout><Webhooks /></Layout>} />
            <Route exact path={`/developers/webhooks/logs`} component={() => <Layout><WebhookLogs /></Layout>} />
            <Route exact path={`/developers/webhook/create`} component={WebhookCreate} />
            <Route exact path={`/developers/webhook/:id`} component={WebhookNotifications} />
          </React.Fragment>

        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Developers;

