import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import Bookings from './pages/bookings';
import Booking from './pages/booking';
import BookingCreate from './pages/create';
import PayoutCreate from './pages/booking/views/create-payout';

import RedirectRenderer from "src/components/redirect-renderer";
import HasFeature from "src/utils/has-feature";
import HasRole from "src/utils/has-role";
import GroupMenu from "src/components/group-menu";

import { BriefcaseIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const items = [
    { name: 'Bookings', icon: BriefcaseIcon, path: '/bookings', check: true },
  ];
  
  return (
    <div id="content">
      <GroupMenu items={items} title="Bookings" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const redirects = [];

const BookingsRoutes = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id && HasFeature(features, ['booking-and-payment'])) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}

        <Route exact path={`/bookings`} component={() => <Layout><Bookings /></Layout>} />

        <Route exact path={`/booking/:id`} component={Booking} />
        <Route exact path={`/booking/:id/elements`} component={Booking} />
        <Route exact path={`/booking/:id/transactions`} component={Booking} />
        <Route exact path={`/booking/:id/credit-notes`} component={Booking} />

        {HasRole(account, ['booking-and-payment:create:booking']) && (
          <Route exact path={`/bookings/create`} component={BookingCreate} />
        )}

        {HasRole(account, ['payouts:view']) && HasFeature(features, ['payouts']) && (
          <Route exact path={`/booking/:id/payouts`} component={Booking} />
        )}

        {HasFeature(features, ['aisp']) && (
          <Route exact path={`/booking/:id/supplier-payments`} component={Booking} />
        )}

        {HasRole(account, ['payouts:view', 'payouts:create']) && HasFeature(features, ['payouts', 'supplier-payouts']) && (
          <Route exact path={`/payout/create`} component={PayoutCreate} />
        )}

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default BookingsRoutes;
