import React  from 'react';

import DataTable from 'src/components/data-table';
import API_ROUTES from 'src/config/api';
import moment from "moment";
import ActivityTransformer from "src/transformer/activity-transformer";
import PiiObfuscator from "src/components/pii-obfuscator";

const RowFormatter = ({item}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <span className="title">{ moment(item.created_at).format('HH:mm') }</span>
      </td>
      <td className='text-center'>
        { item.type }
      </td>
      <td className='text-center'>
        { ActivityTransformer(item.type, item.entity_id) }
      </td>
      <td className='text-center'>
        <PiiObfuscator type="email">{ item?.user?.email }</PiiObfuscator>
      </td>
      <td className='text-center'>
        { item.id }
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th className='text-left'>
        <span>Time</span>
      </th>
      <th>
        <span>Type</span>
      </th>
      <th>
        Entity ID
      </th>
      <th>
        <span>User</span>
      </th>
      <th>
        <span>Event ID</span>
      </th>
    </tr>
  </React.Fragment>
);

const AuditTrail = () => (
  <DataTable
    dateGroupingField="created_at"
    largeHeading={true}
    title="Audit Events"
    columnCount={8}
    TableHeaders={TableHeaders}
    RowFormatter={RowFormatter}
    endpoint={API_ROUTES.audit['audit:get']()}
    noHeader={true}
    searchPlaceholder="Event type"
  />
);

export default AuditTrail;
