import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import GroupMenu from "src/components/group-menu";

import Main from './pages/main';
import Declines from './pages/declines';
import Exposure from './pages/exposure';
import Customers from './pages/customers';

import HasRole from "src/utils/has-role";
import HasFeature from "src/utils/has-feature";
import RedirectRenderer from "src/components/redirect-renderer";
import { PresentationChartLineIcon, UserGroupIcon, ScaleIcon, LifebuoyIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  const items = [
    { name: 'Insights', icon: PresentationChartLineIcon, path: '/insights', check: true },
    { name: 'Customers', icon: UserGroupIcon, path: '/insights/customers', check: HasRole(account, ['booking-and-payment:organisation-access']) },
    { name: 'Payment Acceptance', icon: ScaleIcon, path: '/insights/payment-acceptance', check: HasRole(account, ['acquirer:reporting']) && HasFeature(features, ['acquirer-reporting']) },
    { name: 'Exposure', icon: LifebuoyIcon, path: '/insights/exposure', check: HasRole(account, ['organisation:risk:statistics']) && HasFeature(features, ['risk']) },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Insights"  />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const redirects = [
  { from: '/', to: '/insights' },
];

const Dashboard = () => {
  const { organisation: { features }, account: { account } } = useSelector((state) => state);

  if (account?.id) {
    return (
      <React.Fragment>

        { RedirectRenderer({ redirects }) }

        <Route path="/insights" exact component={() => <Layout><Main /></Layout>} />
        
        { HasRole(account, ['booking-and-payment:organisation-access']) && (
          <Route path="/insights/customers" component={() => <Layout><Customers /></Layout>} />
        )}
        { HasRole(account, ['acquirer:reporting']) && HasFeature(features,['acquirer-reporting']) && (
          <Route path="/insights/payment-acceptance" component={() => <Layout><Declines /></Layout>} />
        )}
        { HasRole(account, ['organisation:risk:statistics']) && HasFeature(features,['risk']) && (
            <Route exact path={`/insights/exposure`} component={() => <Layout><Exposure /></Layout>} />
        )}
      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Dashboard;
