const RefundStatusTransformer = (status) => {
  if (status === 'AUTHORISED') {
    return {
      icon: 'ni-check',
      label: 'In Progress',
      color: 'bg-gray-600'
    };
  }

  if (status === 'COMPLETED') {
    return {
      icon: 'ni-check',
      label: 'Complete',
      color: 'bg-emerald-600'
    };
  }

  if (status === 'PENDING_AUTHORISATION') {
    return {
      icon: 'ni-check',
      label: 'Pending Approval',
      color: 'bg-gray-600'
    };
  }

  if (status === 'AUTHORISATION_DECLINED') {
    return {
      icon: 'ni-cross',
      label: 'Approval Declined',
      color: 'bg-rose-600'
    }
  }

  if (status === 'FAILED') {
    return {
      icon: 'ni-cross',
      label: 'Refund Failed',
      color: 'bg-rose-600'
    }
  }

  return status;
}

export default RefundStatusTransformer;
