import React from "react";

import {
  Button,
  Icon,
} from 'src/components/Component';
import moment from 'moment';
import { Link } from 'react-router-dom';

import API_ROUTES from "../../../../config/api";
import DataTable from "src/components/data-table";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Options from "../webhooks/options";

const RowFormatter = ({item, triggerReload}) => {
  return (
    <tr key={item.id} className="tb-tnx-item">
      <td>
        <span className="title">{item.url}</span>
      </td>

      <td className="text-center">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-white ${item.child_organisation ? 'bg-emerald-600' : 'bg-rose-600'}`}>
          <Icon name={`ni text-white icon ${item.child_organisation ? 'ni-check' : 'ni-cross'} pr-1`} />
          <span className="date">{item.child_organisation ? 'Enabled' : 'Disabled'}</span>
        </span>
      </td>

      <td className="text-center">
        <span className="title">{moment(item.created_at).format('HH:mm - Do MMM YYYY')}</span>
      </td>

      <td className="tb-tnx-action">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
          >
            <Icon name="more-h" />
          </DropdownToggle>
          <Options item={item} triggerReload={triggerReload} />
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head">
    <th className="text-left">
      <span>URL</span>
    </th>
    <th>
      <span>Child Organisations</span>
    </th>
    <th>
      <span>Created On</span>
    </th>
    <th className="tb-tnx-action">
      <span>&nbsp;</span>
    </th>
  </tr>
);

const PageOptions = () => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Link to="/developers/webhooks/create" id="link__create_api_key">
        <Button color="light" outline className="bg-violet-800 border-none text-white">
            <Icon name="plus" />
            <span>Create Webhook</span>
          </Button>
        </Link>
      </li>
    </ul>
  );
};


const WebhooksComponent = () => {
  const endpoint = API_ROUTES.webhooks['webhooks:list']();

  return (
    <DataTable
      title="Webhooks"
      largeHeading={true}
      columnCount={4}
      limit={200}
      loadingCount={3}
      autoReload={false}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={endpoint}
      forceShowCount={false}
      noHeader={true}
      hideCounter={true}
    />
  );
};

export default WebhooksComponent;
