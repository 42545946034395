import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { BeakerIcon } from '@heroicons/react/20/solid';

import { pollLatestVersion } from 'src/actions/applicationActions';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const doCacheClear = async () => {
  // Log on sentry that a new version reload has been triggered
  Sentry.captureMessage('New version reload triggered');
  window.location.reload(true);
}

const VERSION_POLL_INTERVAL = 300000; // 5 mins

const NewVersionBanner = () => {
  const {
    new_version: newVersion,
  } = useSelector((state) => state.application);

  const dispatch = useDispatch();
  const query = useQuery();

  const showVersion = query.get('version');

  useEffect(() => {
    const doPoll = setInterval(() => {
      dispatch(pollLatestVersion());
    }, VERSION_POLL_INTERVAL);

    return () => clearTimeout(doPoll);
  }, []);

  if (newVersion || showVersion) {
    return (
      <div className="bg-violet-100 border-t-4 border-violet-500 rounded-b text-violet-900 px-4 py-3 my-2" role="alert">
        <div className="flex">
          <div className="py-1">
            <BeakerIcon className="h-6 w-6 text-violet-500 mr-4 mt-1" aria-hidden="true" />
          </div>
          <div>
            <p className="font-bold">New version detected</p>
            <p className="text-sm">
              We have made improvements to dashboard since you last visited.&nbsp;
              <a className="alert-link" href="#reload" onClick={() => doCacheClear()}>
                Click here to update.
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  } else {
    return <React.Fragment />
  }
}

export default NewVersionBanner;
