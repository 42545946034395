import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import {Alert, Form, FormGroup, Spinner, Input} from "reactstrap";

import {
  BlockTitle,
  Button,
  Icon,
} from "src/components/Component";

function ConfirmationForm({ className, onSubmitSuccess, onSubmitFailure, username, password, ...rest }) {

  return (
    <Formik
      initialValues={{
        verification: '',
      }}
      validationSchema={Yup.object().shape({
        verification: Yup.string().max(6).required('Verification Code is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await Auth.confirmSignUp(username, values.verification);

          const user = await Auth.signIn(username, password);

          onSubmitSuccess(user);
        } catch (error) {
          const message = (error.message) || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
        return (
          <React.Fragment>
            <div  className="text-primary mb-5">
            <BlockTitle tag="h1" className="text-primary mb-5 text-3xl">Enter Confirmation Code</BlockTitle>
              <p>Please enter the 6 digit code that we have emailed to you to verify your email address.</p>
            </div>
            {errors.submit && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> { errors.submit }
                </Alert>
              </div>
            )}
            <Form noValidate className="is-alter" onSubmit={handleSubmit}>
              <FormGroup>
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="default-01"
                    name="verification"
                    value={values.verification}
                    size="xl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter your verification code"
                    className="form-control-lg form-control"
                  />
                  {touched.verification && errors.verification && <span className="invalid">{errors.verification}</span>}
                </div>
              </FormGroup>
              <FormGroup className="mt-5">
                <Button size="lg" className="btn-block bg-purple-800" type="submit" color="primary">
                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
          </React.Fragment>
        )
      }}
    </Formik>
  );
}

ConfirmationForm.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  onSubmitFailure: PropTypes.func,
};

ConfirmationForm.defaultProps = {
  onSubmitSuccess: () => {},
  onSubmitFailure: () => {},
};

export default ConfirmationForm;
