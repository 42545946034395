import React, { useState } from 'react';
import { Tooltip } from "reactstrap";
import User from './dropdown/user/User';
import OrganisationSelector from "src/components/organisation/selector";
import { Icon } from "../../components/Component";
import { useChat } from 'react-live-chat-loader'
import { Bars4Icon } from '@heroicons/react/20/solid';

import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const Header = ({ fixed, theme, className, sidebarToggle, ...props }) => {
  const [state, loadChat] = useChat();

  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => { setOpen(!tooltipOpen) };

  return (
    <React.Fragment>
      <header className="w-auto text-sm py-2 sm:py-4 w-100 pr-4 pl-1">
        <nav
          className="grid grid-cols-4 gap-4 h-full"
          aria-label="Global"
        >
          <div className="col-span-2">
            <div className='float-left'>
              <OrganisationSelector showWithNoDefault={false} />
            </div>
          </div>
          <div className="col-span-2 flex flex-row items-center justify-end">

            <div className="relative pr-1 mr-2 border-r-2 hidden lg:inline-flex">
              <Link
                className="flex items-center py-1 px-3 rounded-md text-xs text-white bg-violet-800 hover:bg-violet-900 mr-2"
                to="/bookings/create"
              >
                <span><PlusIcon className='h-3 w-3 inline -mt-0.5 mr-0.5' /> Create Booking</span>
              </Link>
              <Link
                className="flex items-center py-1 px-3 rounded-md text-xs text-white bg-violet-800 hover:bg-violet-900 mr-2"
                to="/payment-link/create"
              >
                <span><PlusIcon className='h-3 w-3 inline -mt-0.5 mr-0.5' /> Create Payment Link</span>
              </Link>
            </div>


            {state !== 'test' && (
              <div className="h-[1.5rem] inline-flex justify-center items-center mr-2">
                <div className="user-toggle" onClick={() => loadChat({ open: true })}>
                  <Icon id="question-icon" style={{ fontSize: '32px', color: '#6b21a8', cursor: 'pointer' }} name="question" />
                  <Tooltip placement="right" isOpen={tooltipOpen} target="question-icon" toggle={toggle}>
                    Help & Guides
                  </Tooltip>
                </div>
              </div>
            )}

            <div className="h-[2.375rem] inline-flex justify-center items-center gap-x-2">
              <User />
            </div>

            <Bars4Icon
              className="h-7 w-7 ml-3 lg:hidden"
              onClick={sidebarToggle}
              style={{ color: '#6b21a8', cursor: 'pointer'}}
            />

          </div>

        </nav>

        <div className="relative w-[100%] lg:hidden mt-4 mx-2 overflow-hidden">
          <Link
            className="flex items-center py-3 px-3 rounded-md text-xs text-white bg-violet-800 hover:bg-violet-900 w-[49%] float-left"
            to="/bookings/create"
          >
            <span><PlusIcon className='h-3 w-3 inline -mt-0.5 mr-0.5' /> Create Booking</span>
          </Link>
          <Link
            className="flex items-center py-3 px-3 rounded-md text-xs text-white bg-violet-800 hover:bg-violet-900 w-[49%] float-right"
            to="/payment-link/create"
          >
            <span><PlusIcon className='h-3 w-3 inline -mt-0.5 mr-0.5' /> Create Payment Link</span>
          </Link>
        </div>


      </header>
      <div>
      </div>
    </React.Fragment>
  );
};
export default Header;
