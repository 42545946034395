import VisaLogo from './visa.png';
import MasercardLogo from './mastercard.png';
import AmexLogo from './amex.png';

export const brandLogoTransformer = (brand = null) => {
  if (brand === 'VISA') {
    return {
      icon: 'ni-cc-visa',
      image: VisaLogo,
      imageClass: 'w-100 h-auto -mt-[3px] inline',
      name: 'Visa'
    };
  }

  if (brand === 'MASTER' || brand === 'MASTERCARD') {
    return {
      icon: 'ni-cc-mastercard',
      image: MasercardLogo,
      imageClass: 'w-100 h-auto -mt-[3px] inline',
      name: 'Mastercard'
    };
  }

  if (brand === 'AMEX' || brand === 'AMERICAN EXPRESS') {
    return {
      icon: 'ni-american-express',
      name: 'American Express',
      image : AmexLogo,
      imageClass: 'w-100 h-auto -mt-[3px] inline',
    }
  }

  return {
    icon: 'ni-cc-alt2',
    name: 'Card',
    image: null,
  };
};

export const cardTypeTransformer = (cardType, brand, suffix) => {
  const lookup = brandLogoTransformer(brand);

  if (cardType === 'CREDIT') {
    return {
      label: `Credit ${suffix}`,
      icon: lookup.icon,
      image: lookup.image,
      imageClass: lookup.imageClass,
    };
  }

  if (cardType === 'DEBIT') {
    return {
      label: `Debit ${suffix}`,
      icon: lookup.icon,
      image: lookup.image,
      imageClass: lookup.imageClass,
    };
  }

  return {
    label: suffix,
    icon: lookup.icon,
  };
};

const TransactionTypeTransformer = (type, metadata = null) => {
  if (type === 'CARD') {
    return cardTypeTransformer(metadata?.card_type, metadata?.payment_brand, 'Card');
  }

  if (type === 'OPEN_BANKING') {
    return {
      icon: 'ni-building',
      label: 'Open Banking',
    }
  }

  if (type === 'BANK') {
    return {
      icon: 'ni-wallet',
      label: 'Bank',
    }
  }

  return {
    icon: 'ni-question',
    label: type
  };
}

export default TransactionTypeTransformer;
