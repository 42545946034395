export const AUTH_CHECKED = '@account/auth-checked';
export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const SET_ACCOUNT_DATA = '@account/set-account-data';
export const LOGOUT = '@account/logout';
export const OBFUSCATE_PII = '@account/obfuscate-pii'

export function authChecked(user) {
  return (dispatch) => dispatch({
    type: AUTH_CHECKED,
    payload: true,
  });
}

export function setAccountData(data) {
  return (dispatch) => dispatch({
    type: SET_ACCOUNT_DATA,
    payload: data
  });
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT
    });
  };
}

export function obfuscatePII(action) {
  return async (dispatch) => {
    dispatch({
      type: OBFUSCATE_PII,
      payload: action,
    });
  };
}
