import React, {useState} from 'react';
import {Button} from "../../../../../../../components/Component";
import Icon from "../../../../../../../components/icon/Icon";
import {Link} from "react-router-dom";
import {FormGroup, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import Axios from "axios";
import API_ROUTES from "../../../../../../../config/api";
import {Auth} from "aws-amplify";
import Skeleton from "react-loading-skeleton";

let searchDebounce = null;

const TransactionLink = ({ item, triggerReload }) => {
  const [updating, setUpdating] = useState(false);
  const [bookingID, setBookingID] = useState(null);
  const [modalForm, setModalForm] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(true);
  const [suggestedBookings, setSuggestedBookings] = useState([]);
  const [searchText, setSearchText] = useState('Searching for bookings that may be linked to this payment...');
  const [searchValue, setSearchValue] = useState('');

  const { organisation: { organisation } } = useSelector((state) => state);

  const toggleForm = async () => {
    const modalFormStatus = !modalForm;

    setSearchValue('');
    setModalForm(modalFormStatus);

    if (modalFormStatus === true) {
      setSuggestionsLoading(true);
      setSearchText('Searching for bookings that may be linked to this payment...')
      const user = await Auth.currentAuthenticatedUser();

      const bookings = await Axios(
        API_ROUTES.bank['transaction:get:booking-suggestions'](item.id),
        {
          method: 'post',
          data: { organisation },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setSearchText('We have found the following bookings that may be linked to this payment')
      setSuggestionsLoading(false);
      setSuggestedBookings(bookings.data.data);
    }
  };

  const bookingSearch = async (value) => {
    setSearchValue(value);
    setSuggestionsLoading(true);
    setSearchText('Searching for bookings...')

    clearTimeout(searchDebounce);
    searchDebounce = setTimeout(async () => {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Axios(
        API_ROUTES.agent['bookings:get'](organisation.organisation),
        {
          method: 'post',
          data: {
            take: 10,
            keyword: value,
            skip: 0,
            organisation,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setSearchText('We have found the following bookings')
      setSuggestionsLoading(false);
      setSuggestedBookings(result.data.data.map((booking) => ({
        booking_id: booking.id,
        booking_reference: booking.booking_reference,
      })));
    }, 1000);
  }

  const addToBooking = async (bookingID) => {
    const user = await Auth.currentAuthenticatedUser();
    setUpdating(true);

    await Axios(
      API_ROUTES.bank['transaction:add-to-booking'](item.id, bookingID),
      {
        method: 'put',
        data: {
          organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setModalForm(false);
    setUpdating(false);
    setBookingID(bookingID);
  };

  if (item.disbursal) {
    return (
      <Link to={`/batch/?disbursement_id=${item.disbursal.id}`}>
        <Button className="btn btn-sm btn-primary text-center" style={{ width: '140px' }}>View Disbursement</Button>
      </Link>
    )
  }

  if (item.booking || bookingID) {
    return (
      <Link to={`/booking/${bookingID ? bookingID : item.booking.id}`}>
        <Button className="btn btn-sm btn-primary" style={{ width: '140px', justifyContent: 'center' }}>View Booking</Button>
      </Link>
    )
  }

  return (
    <React.Fragment>
      <Button className="btn btn-sm btn-primary btn-green text-center" onClick={toggleForm}>
        <Icon className="pr-1" name="link-h" /> Link to Booking
      </Button>

      <Modal isOpen={modalForm} toggle={toggleForm} size="lg">
        { updating === false && (
          <ModalHeader
            toggle={toggleForm}
            className="bg-secondary text-white"
            close={
              <React.Fragment>
                { updating === false && (
                  <button className="close" onClick={toggleForm}>
                    <Icon name="cross" className="text-white" />
                  </button>
                )}
              </React.Fragment>
            }
          >
            Link to Booking
          </ModalHeader>
        )}
        <ModalBody>
          { updating === true && (
            <div className="text-center mt-4 pt-5 pb-5 mb-4 text-primary">
              <h4 className="text-primary">Adding to Booking</h4>
              <br />
              <Spinner style={{ marginLeft: '1em' }} size="xl"> </Spinner>
            </div>
          )}
          { updating === false && (
            <React.Fragment>
              <p><strong>Search by booking reference</strong></p>
              <FormGroup>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="bookingref"
                    name="bookingref"
                    placeholder="e.g. XXX-123"
                    value={searchValue}
                    className="form-control form-control-xl"
                    onChange={(e) => bookingSearch(e.target.value)}
                  />
                </div>
              </FormGroup>

              <p><strong>{ searchText }</strong></p>
              <table className="table table-tranx border-light card-bordered">
                <tbody>
                <tr className="tb-tnx-head">
                  <th><span>Booking Reference</span></th>
                  <th><span>Customer Name</span></th>
                  <th className="text-center">{' '}</th>
                </tr>
                {suggestionsLoading && Array.from(Array(2).keys()).map((key) => (
                  <tr className="tb-tnx-item" key={key}>
                    <td colSpan={6}>
                      <Skeleton count={3} height="50px" className="m-0 p-1"/>
                    </td>
                  </tr>
                ))}
                { suggestionsLoading === false && suggestedBookings.length === 0 && (
                  <tr className="tb-tnx-item">
                    <td colSpan={8} className="text-center">
                      <strong>No suggestions found</strong>
                    </td>
                  </tr>
                )}

                {suggestionsLoading === false && suggestedBookings.map((booking) => (
                  <tr className="tb-tnx-item" key={booking.booking_id}>
                    <td>
                      <a href={ `booking/${booking.booking_id}` } target="_blank" rel="noreferrer">{ booking.booking_reference }</a>
                    </td>
                    <td>
                      { booking.customer_name }
                    </td>
                    <td style={{ width: '150px', padding: '0 0px 0px 5px' }}>
                      <Button className="btn btn-sm btn-primary btn-green text-center" onClick={() => addToBooking(booking.booking_id)}>
                        <Icon className="pr-1" name="link-h" /> Link to Booking
                      </Button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default TransactionLink;
