import React, { useEffect, useRef, useId } from "react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import CurrencyFormat from 'src/transformer/currency-format';
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { convertPenniesToDecimals } from "../../../../utils/money";
import { Chart } from "chart.js/auto";
import millify from "millify";

import 'chartjs-adapter-moment';

const SalesOverview = ({ data, loading, currency, setCurrency, oldCurrency }) => {
  const { organisation: { currencies } } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);
  const canvasRef = useRef(null);
  const canvasID = useId();

  useEffect(() => {
    if (loading === false) {
      var config = {
        type: "line",
        data: {
          labels: data.map((item) => new Date(item.item_date)),
          dataUnit: "GBP",
          datasets: [
            {
              label: "Sales Overview",
              color: "#798bff",
              fill: true,
              backgroundColor: "rgba(74,18,124,0)",
              borderColor: "#670199",
              barPercentage: 0.1,
              categoryPercentage: 0.1,
              borderWidth: 2,
              lineTension: 0.1,
              pointRadius: false,
              data: data.map((item) => convertPenniesToDecimals(item.item_total)),
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            backgroundColor: "#eff6ff",
            titleFontSize: 13,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              display: true,
              ticks: {
                autoSkip: true,
                beginAtZero: true,
                fontSize: 11,
                fontColor: "#9eaecf",
                callback: function (value, index, values) {
                  return `£${millify(value)}`
                },
                padding: 10,
                min: 0,
                stepSize: 20000,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
            x: {
              display: true,
              type: 'time',
              time: {
                parser: 'MM/DD/YYYY HH:mm',
                tooltipFormat: 'll HH:mm',
                unit: 'day',
                unitStepSize: 1,
                displayFormats: {
                  'day': 'Do MMM'
                }
              },
              ticks: {
                fontSize: 9,
                fontColor: '#9eaecf',
                source: "auto",
                padding: 10,
                display: true,
                autoSkip: true,
                maxTicksLimit: 4,
                maxRotation: 0
              },
              grid: {
                drawBorder: false,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
          },
        },
      };

      const canvas = canvasRef.current;

      if (canvas !== null) {
        const chart = new Chart(canvasRef.current, config);

        return () => {
          chart.destroy()
        }
      }
    }
  }, [loading, data, currency]);

  if (loading || currency !== oldCurrency) {
    return (
      <div className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">
        <CardHeader className="bg-gray-50 mb-2 text-purple-900">
          <h2 className="text-xl text-purple-900">
            Payments <span className="font-bold">Overview</span>
          </h2>
        </CardHeader>

        <CardBody className="card-inner pt-0">
          <div className="text-center mb-1 fs-20px w-25 float-right">
            <Skeleton count={1} height="50px" />
          </div>
          <div className="text-center mb-1 fs-20px w-25">
            <Skeleton count={1} height="50px" />
          </div>
          <div className="text-center fs-20px w-100">
            <Skeleton count={1} height="200px" />
          </div>
        </CardBody>
        {currencies.length > 1 && (
          <CardFooter className="bg-light">
            {currencies.map((currencyItem, index) => (
              <Button
                className={`float-right ml-2 ${currencyItem === currency ? 'bg-slate-800' : 'bg-purple-800'}`}
                onClick={() => setCurrency(currencyItem)}
                key={index}
              >
                {enums.currency[currencyItem].major_unit}
              </Button>
            ))}
          </CardFooter>
        )}
      </div>
    );
  }


  const total = convertPenniesToDecimals(data.reduce((total, item) => total + item.item_total, 0));
  const count = data.reduce((total, item) => total + item.item_count, 0);


  return (
    <div className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">
       <CardHeader className="bg-gray-50 mb-2 text-purple-900">
          <div className="float-right text-sm pt-0.5">
            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-sm text-xs font-medium bg-purple-100 text-purple-800">Last 30 Days</span>
          </div>
          <h2 className="text-xl text-purple-900">
            Payments <span className="font-bold">Overview</span>
          </h2>
        </CardHeader>

      <CardBody className="card-inner pt-0">
        <div className="nk-sale-data-group align-center justify-between gy-3 gx-5">
          <div className="nk-sale-data">
            <span className="amount">{CurrencyFormat(enums.currency, currency).format(total)}</span>
          </div>
          <div className="nk-sale-data">
            <span className="amount sm">
              {count} <small>Completed</small>
            </span>
          </div>
        </div>
        <div className="pt-4 grow max-h-[250px]">
          <canvas id={canvasID} ref={canvasRef}></canvas>
        </div>
      </CardBody>
      {currencies.length > 1 && (
        <CardFooter className="bg-light">
          {currencies.map((currencyItem, index) => (
            <Button
              className={`float-right ml-2 ${currencyItem === currency ? 'bg-slate-800' : 'bg-purple-800'}`}
              onClick={() => setCurrency(currencyItem)}
              key={index}
            >
              {enums.currency[currencyItem].major_unit}
            </Button>
          ))}
        </CardFooter>
      )}
    </div >
  );
};
export default SalesOverview;
