import React, { useEffect, useState } from 'react';
import {
  Col, Row
} from 'src/components/Component';
import { API_ROUTES } from 'src/config/api';

import 'react-loading-skeleton/dist/skeleton.css'
import {Card, CardBody, CardHeader, Spinner} from 'reactstrap';
import {Auth} from 'aws-amplify';
import Axios from 'axios';
import {useSelector} from 'react-redux';

const RoleGroup = ({ id, name, data, dataKey }) => {
  const [groupData, setGroupData] = useState(null);
  const { organisation } = useSelector((state) => state);

  useEffect(() => {
    if (typeof data[dataKey] !== 'undefined') {
      setGroupData(data[dataKey])
    }
  }, [data, dataKey]);

  const changeStatus = async (key, status) => {
    let data = [...groupData];
    data[key].changing = true;
    setGroupData(data);

    const user = await Auth.currentAuthenticatedUser();
    const action = status === true ? 'add' : 'remove';

    await Axios.post(
      API_ROUTES.user["organisation:user:roles:change"](organisation.organisation, id, action),
      JSON.stringify({
        'role-id': data[key].id,
      }),
      {
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          'Content-Type': 'application/json',
        },
      }
    );

    data = [...groupData];
    data[key].enabled = status;
    data[key].changing = false;
    setGroupData(data);
  }

  if (!groupData) {
    return <React.Fragment />
  }

  return (
    <React.Fragment>
      <Row className="g-gs pb-4" style={{ width: '100%' }}>
        <Col size="12">
          <h4 className="text-primary text-xl border-b pb-1 border-gray-200 font-bold">{name}</h4>
        </Col>

        { groupData.map((item, key) => {
          return (
            <Col size="4" key={key}>
              <Card className="card-bordered mb-0 pb-o">
                <CardHeader className="bg-purple-50 text-purple-900 font-bolder pr-0">
                  <div className="custom-control custom-switch float-right  pr-0 mr-0">
                    <span>{ item.changing && <Spinner size="sm" className="mt-1 mr-3"> </Spinner>}</span>
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      checked={item.enabled}
                      disabled={item.changing}
                      onChange={() => changeStatus(key, item.enabled === false)}
                      id={`role-switch-${item.name.replace(' | ', '').toLowerCase()}`}
                    />
                    <label className="custom-control-label" htmlFor={`role-switch-${item.name.replace(' | ', '').toLowerCase()}`} />
                  </div>
                  { item.name }
                </CardHeader>
                <CardBody className="card-inner pt-2 pb-2">
                  { item.description }
                </CardBody>
              </Card>
            </Col>
          );
        })}

      </Row>
    </React.Fragment>
  );
};
export default RoleGroup;
