/**
 * Calculate the total count of booking elements
 * @param data
 * @returns {number}
 * @constructor
 */
const ElementsCount = (data) => {
  const components = data.components;

  if (typeof components === 'undefined' || components.length === 0) return 0;

  let count = 0;

  components.forEach((component) => {
    count += 1;
  });

  return count;
};

export default ElementsCount;
