import React, {useState} from 'react';
import {Button, Icon} from "src/components/Component";
import {FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import {Auth} from "aws-amplify";
import CurrencyFormat from "src/transformer/currency-format";
import {useSelector} from "react-redux";
import bigDecimal from 'js-big-decimal';

const RefundButton = ({ triggerReload, item }) => {
  const { enums } = useSelector((state) => state.enums);

  const [error, setError] = useState(null);
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const [amount, setAmount] = useState(parseInt(bigDecimal.divide(item.amount, 100), 10));
  const [reason, setReason] = useState('');

  const toggleForm = () => setModalForm(!modalForm);

  const createRefund = async () => {
    if (parseInt(bigDecimal.multiply(amount, 100), 10) > item.amount) {
      setError('Refund amount cannot be more than the transaction amount');

      return null;
    }

    setModalFormProcessing(true);
    const authUser = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.agent['refund:create'](item.id),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${authUser.signInUserSession.idToken.jwtToken}` },
        data: { amount: Math.ceil(amount * 100), description: reason },
      }
    );

    triggerReload();
    toggleForm();
    setModalFormProcessing(false);
  };

  const updateAmount = (newAmount) => {
    setError(null);
    setAmount(newAmount);

    if (parseInt(bigDecimal.multiply(newAmount, 100), 10) > item.amount) {
      setError('Refund amount cannot be more than the transaction amount');
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 mt-1">
        <div className="w-100">
          <Button color="danger"
                  className="text-white w-100 text-center"
                  onClick={toggleForm}
                  style={{ display: 'block' }}>Refund</Button>
        </div>
      </div>

      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-red-500 text-white text-lg"
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Request a Refund
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label htmlFor="default-2" className="form-label text-primary">
              Refund Amount<small className="text-danger pl-1 pb-3">required</small>
            </Label>
            <div className="form-control-wrap input-group">
              <div className="input-group-addon pl-3 pr-3">{CurrencyFormat(enums.currency, item.currency).getCurrencySymbol()}</div>
              <input
                type="number"
                id="amount"
                disabled={modalFormProcessing}
                value={ amount }
                onChange={(e) => updateAmount(e.target.value)}
                className={`form-control form-control-xl ${error !== null || amount < 0.01 ? 'error' : ''}`}
                min={0}
                step={0.01}
                placeholder="e.g. 200.00"
              />
            </div>
            { error !== null && (
              <small className="text-danger">{ error }</small>
            )}
            { amount < 0.01 && (
              <small className="text-danger">Refund amount cannot be empty</small>
            )}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="default-2" className="form-label text-primary">
              Reason for refund
            </Label>
            <div className="form-control-wrap input-group">
              <textarea
                id="description"
                rows={2}
                disabled={modalFormProcessing}
                value={ reason }
                onChange={(e) => setReason(e.target.value)}
                className={`form-control form-control-xl`}
                placeholder="e.g. Unable to book holiday due to lack of availability"
              />
            </div>
          </FormGroup>

        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button className="bg-red-600 hover:bg-red-700 text-white" type="submit" disabled={modalFormProcessing || error !== null || amount < 0.01} onClick={
              (ev) => {
                ev.preventDefault();
                createRefund();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default RefundButton;
