import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import GroupMenu from "src/components/group-menu";
import Organisations from './pages/organisations';
import OrganisationCreate from './pages/organisations/create'
import Organisation from './pages/organisation';

import RedirectRenderer from "src/components/redirect-renderer";

const Layout = ({ children }) => {
  const { organisation: { organisations } } = useSelector((state) => state);

  const items = [
    { name: 'Organisations', path: '/admin/organisations', check: organisations.map((org) => org.id).includes('X1234') },
  ];

  return (
    <div id="content">
      <GroupMenu items={items} title="Admin" />
      <div className="mt-4 mb-8">
        {children}
      </div>
    </div>
  )
};

const redirects = [
  { from: '/organisations', to: '/admin/organisations' },
  { from: '/organisation/create', to: '/admin/organisatios/create' },
  { from: '/organisations/:id', to: '/admin/organisation/:id'},
  { from: '/organisations/:id/payouts', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/features', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/payment-config', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/payment-config/create', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/payment-config/:providerId/edit', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/payment-config/set-weights', to: '/admin/organisation/:id' },
  { from: '/organisations/:id/theming', to: '/admin/organisation/:id' },
];

const Payments = () => {
  const { organisation: { organisations } } = useSelector((state) => state);

  if (typeof organisations === 'object' && organisations.map((org) => org.id).includes('X1234')) {
    return (
      <React.Fragment>

        {RedirectRenderer({ redirects })}

        {
          organisations.map((org) => org.id).includes('X1234') && (
            <React.Fragment>
              <Route exact path={`/admin/organisations`} component={() => <Layout><Organisations /></Layout>} />
              <Route exact path={`/admin/organisations/create`} component={() => <Layout><OrganisationCreate /></Layout>} />

              <Route exact path={`/admin/organisation/:id`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/payouts`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/features`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/payment-config`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/payment-config/create`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/payment-config/:providerId/edit`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/payment-config/set-weights`} component={Organisation} />
              <Route exact path={`/admin/organisation/:id/theming`} component={Organisation} />
            </React.Fragment>
          )
        }

      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Payments;
