import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Head from "../../../../../layout/head/Head";
import Content from "../../../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  Icon,
} from "../../../../../components/Component";

import {
  Button,
  Alert,
  CardFooter,
  Card,
  CardBody,
  Spinner,
  CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup
} from "reactstrap";
import API_ROUTES, { FRONTEND_ROUTES } from "../../../../../config/api";
import { Link, useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import CurrencyFormat from "../../../../../transformer/currency-format";
import { MOTO_IN_PERSON, MOTO_OTHER } from '../../../../../constants/transaction-methods';
import { Auth } from "aws-amplify";
import { convertPenniesToDecimals } from "../../../../../utils/money";
import Item from "src/pages/admin/pages/organisation/payment-config/item";

const ProjectCardPage = () => {
  const { id } = useParams();

  const { enums } = useSelector((state) => state.enums);
  const { account } = useSelector((state) => state.account);
  const organisation = useSelector((state) => state.organisation);

  const [alert, setAlert] = useState(null);
  const [data, setData] = useState(null);
  const [isDeletable, setIsDeletable] = useState(false);
  const [archiveModelForm, setArchiveModelForm] = useState(false);
  const [archiveModelFormProcessing, setArchiveModelFormProcessing] = useState(false);

  const history = useHistory();

  const url = FRONTEND_ROUTES.pay["payment-link"](id);

  const getPaymentLink = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['payment-link:get'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setData(result.data.data);

    // Figure out whether the payment link is deletable
    const statuses = result?.data?.data?.transactions ? result?.data?.data?.transactions?.map((transaction) => transaction.status) : [];

    if (
      statuses.length === 0 ||
      (
        !statuses.includes('COMPLETE') &&
        !statuses.includes('PENDING') &&
        !statuses.includes('AUTHORIZED') &&
        !statuses.includes('PAYMENTINPROGRESS')
      )
    ) {
      setIsDeletable(true);
    }
  }, []);

  useEffect(() => {
    getPaymentLink();
  }, []);

  const copyLinkToClipboard = async () => {
    setAlert('The payment link has been copied to your clipboard.')

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(url);
    } else {
      return document.execCommand('copy', true, url);
    }
  }

  const toggleArchiveModelForm = () => setArchiveModelForm(!archiveModelForm);

  /**
   * Open the payment link url
   * @param e
   * @param method
   */
  const openPaymentLink = (e, method = null) => {
    e.preventDefault();

    let linkUrl = url;
    if (method !== null) {
      linkUrl = `${url}?method=${method}`;
    }

    window.open(linkUrl, '_blank');
  }

  /**
   * Archive the payment link
   * @returns {Promise<void>}
   */
  const archivePaymentLink = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setArchiveModelFormProcessing(true);

    await Axios(
      API_ROUTES.agent['payment-link:delete'](id),
      {
        method: 'delete',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    history.push({
      pathname: '/payment-links',
    });
  };

  const emailLink = !data ? '' : `mailto:${data.email}?subject=Booking Payment Request - ${data.organisation.name}&body=Hi ${data.customer_name}, %0D%0A %0D%0A Please make the Payment of ${CurrencyFormat(enums.currency, data.currency).format(convertPenniesToDecimals(data.amount))} to ${data.organisation.name} using the following link ${url} %0D%0A %0D%0A Many Thanks, %0D%0A ${account.first_name}`;

  return (
    <React.Fragment>
      <Head title="Payment Link Details" />
      <Content>
        {alert && (
          <Alert className="alert-icon" color="info">
            <Icon name="info" />
            {alert}
          </Alert>
        )}

        {!data && (
          <div className="text-center mt-5 fs-21px">
            <Spinner color="primary"> </Spinner>
          </div>
        )}

        {data && (
          <Block className="mt-3">
            <Row className="g-gs">
              <Col sm="12">

                <Card className="card-bordered rounded-xl my-4">
                  <CardBody className="card-inner" style={{ minHeight: '100px' }}>
                    <BlockHead size="sm" className="mb-2">
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
                            Payment Link</BlockTitle>
                        </BlockHeadContent>
                      </BlockBetween>
                    </BlockHead>

                    <div className="row g-3">
                      <div className="col-6">
                        <span className="sub-text font-weight-bold text-primary">Payment Link ID:</span>
                        <span>{id}</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text font-weight-bold text-primary">Booking ID:</span>
                        <span>
                          {data?.booking !== null && (
                            <React.Fragment>
                              <Link className="text-violet-800 underline mt-0.5 block float-left" to={`/booking/${data.booking.id}`}>{data.booking.booking_reference}</Link>
                              <Link to={`/payment-links/${id}/re-assign`} className="bg-violet-800 text-white px-2 py-1 rounded-md text-xs ml-2 block float-left hover:bg-slate-700"> Re-assign</Link>
                            </React.Fragment>
                          )}
                          {data.booking === null && (
                            <React.Fragment>
                              <strong>Unassigned</strong> | <Link to={`/payment-links/${id}/assign`}>Assign to Booking</Link>
                            </React.Fragment>
                          )}
                        </span>
                      </div>

                    </div>
                    <div className="row g-3">
                      <div className="col-6">
                        <span className="sub-text font-weight-bold text-primary">Amount:</span>
                        <span>{CurrencyFormat(enums.currency, data.currency).format(convertPenniesToDecimals(data.amount))}</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text font-weight-bold text-primary">Customer:</span>
                        <span>{ data?.customer?.id 
                          ? <Link to={`/customer/${data.customer.id}`} className="text-violet-800 underline">{data.customer_name}</Link>
                          : data.customer_name
                        }</span>
                      </div>
                    </div>
                    {
                      Item.description && (
                        <div className="row g-3">
                          <div className="col-12">
                            <span className="sub-text font-weight-bold text-primary">Description:</span>
                            <span>{data.description}</span>
                          </div>
                        </div>
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="g-gs">
                      <Col md="6">
                      <Card className="card-bordered rounded-lg">
                          <CardHeader className="bg-slate-300 text-slate-800 py-2 px-4">
                            <Icon name="con ni ni-link" className="float-right pt-0.5" />
                            <h5 className="title font-extrabold">Send Payment Link</h5>
                          </CardHeader>
                          <CardBody className="card-inner" style={{ minHeight: '100px' }}>
                            <p>To send payment link to customer either click on 'Email Customer' or click 'Copy Payment Link' button</p>
                          </CardBody>
                          <CardFooter>
                          <Button className="bg-violet-800 text-white ml-2 float-right border-0" onClick={() => { window.location.href = emailLink }}>Email Customer</Button>
                         
                            <Button className="bg-violet-800 text-white float-right border-0" onClick={copyLinkToClipboard}>Copy Payment Link</Button>
                   </CardFooter>
                        </Card>
                      </Col>

                      <Col md="6">
                      <Card className="card-bordered rounded-lg">
                      <CardHeader className="bg-slate-300 text-slate-800 py-2 px-4">
                          <Icon name="con ni ni-link" className="float-right fs-15px pt-0.5" />
                            <h5 className="title font-extrabold">Take Payment in Person</h5>
                          </CardHeader>
                          <CardBody className="card-inner" style={{ minHeight: '100px' }}>
                            <p>To take a payment in person click this link and hand the device over to the customer to complete.</p>
                          </CardBody>
                          <CardFooter>
                            {organisation.payment_methods && organisation.payment_methods.indexOf('MOTO_IN_PERSON') !== -1 && (
                              <Button
                                className="bg-violet-800 text-white float-right border-0"
                                onClick={(e) => openPaymentLink(e, MOTO_IN_PERSON)}
                              >
                                Open Payment Link
                              </Button>
                            )}
                            {organisation.payment_methods && organisation.payment_methods.indexOf('MOTO_IN_PERSON') === -1 && (
                              <span className="text-danger"><strong>This feature is disabled</strong></span>
                            )}
                          </CardFooter>
                        </Card>
                      </Col>

                      <Col md="6">
                      <Card className="card-bordered rounded-lg">
                      <CardHeader className="bg-slate-300 text-slate-800 py-2 px-4">
                          <Icon name="con ni ni-link" className="float-right fs-15px pt-0.5" />
                            <h5 className="title font-extrabold">Take Payment over Phone</h5>
                          </CardHeader>
                          <CardBody className="card-inner" style={{ minHeight: '100px' }}>
                            <p>To take a payment over phone person click this link and request customer for card details.</p>

                          </CardBody>
                          <CardFooter>
                            {organisation.payment_methods && organisation.payment_methods.indexOf('MOTO_OTHER') !== -1 && (
                              <Button
                                className="bg-violet-800 text-white float-right border-0"
                                onClick={(e) => openPaymentLink(e, MOTO_OTHER)}
                              >
                                Accept Payment Over Phone
                              </Button>
                            )}
                            {organisation.payment_methods && organisation.payment_methods.indexOf('MOTO_OTHER') === -1 && (
                              <span className="text-danger"><strong>This feature is disabled</strong></span>
                            )}
                          </CardFooter>
                        </Card>
                      </Col>

                      {isDeletable === true && (
                        <Col md="6">
                          <Card className="card-bordered rounded-lg">
                          <CardHeader className="bg-red-500 text-white">
                          <Icon name="con ni ni-link" className="float-right fs-15px pt-0.5" />
                              <h5 className="title text-white font-extrabold">Archive Payment Link</h5>
                            </CardHeader>
                            <CardBody className="card-inner" style={{ minHeight: '100px' }}>
                              <p>To archive the payment link and make it unavailable for your customer to use. </p>
                            </CardBody>
                            <CardFooter>
                              <Button color="danger" className="bg-red-500 float-right border-0" onClick={toggleArchiveModelForm}>Archive Payment Link</Button>
                            </CardFooter>
                          </Card>
                        </Col>
                      )}

                    </Row>
          </Block>
        )}

        <Modal isOpen={archiveModelForm} toggle={toggleArchiveModelForm}>
          <ModalHeader
            toggle={toggleArchiveModelForm}
            className={`text-white bg-danger`}
            close={
              <button className="close" onClick={toggleArchiveModelForm}>
                <Icon name="cross" className="text-white" />
              </button>
            }
          >
            Archive Payment Link
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you would like to archive the payment link?</p>
          </ModalBody>
          <ModalFooter className="bg-light">
            <FormGroup>
              <Button color="danger" type="submit" disabled={archiveModelFormProcessing} onClick={
                (ev) => {
                  ev.preventDefault();
                  archivePaymentLink();
                }
              } size="lg">
                {archiveModelFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
              </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>

      </Content>
    </React.Fragment>
  );
};
export default ProjectCardPage;
