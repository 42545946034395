import React, { useEffect, useCallback, useState } from "react";
import Head from "src/layout/head/Head";
import JSONPretty from 'react-json-pretty';
import Axios from "axios";
import { Auth } from "aws-amplify";
import { API_ROUTES } from 'src/config/api';
import { useSelector } from "react-redux";
import moment from "moment";
import Skeleton from 'react-loading-skeleton';
import { InboxIcon } from "@heroicons/react/24/outline";

import 'react-loading-skeleton/dist/skeleton.css'
import './index.css';

const WebhooksComponent = () => {
  const { organisation } = useSelector((state) => state);

  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState(null);

  const nextPage = useCallback(() => {
    setLoadingMore(true);
    setSkip(skip + 50);
  }, [skip]);

  const fetch = useCallback(async () => {
    if (loadingMore === false) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.webhooks['webhooks:logs'](),
      {
        method: 'post',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        data: {
          organisation: organisation.organisation,
          skip: skip,
        }
      }
    );

    if (result.data.data.length > 0 && loadingMore === false) {
      setSelected(result.data.data[0]);
    }

    setTotal(result.data.meta.count);

    if (skip === 0) {
      setData(result.data.data);
    } else {
      setData([
        ...data,
        ...result.data.data,
      ]);
    }

    setLoadingMore(false);
    setLoading(false);
  });


  useEffect(() => {
    fetch();
  }, [organisation, skip]);

  let selectedData = null;
  if (selected?.response) {
    try {
      selectedData = JSON.parse(selected.response);
    } catch (e) { }
  }

  if (loading === true) {
    return (
      <React.Fragment>
        <Head title="Webhook Logs" />

        <div class="grid grid-cols-2 flex flex-col bg-white border rounded-xl h-auto overflow-hidden">

          <div className="py-3 px-4">
            <Skeleton count={10} height="50px" style={{
              maxWidth: '100%',
              marginBottom: '1em',
            }} />
          </div>

          <div className="border-l w-full">
            <div className="bg-gray-100 px-4 py-2 w-100 mb-3">
              <h1 className="text-lg text-purple-800 font-extrabold">Request Detail</h1>
            </div>

            <div className="py-3 px-4">
              <Skeleton count={4} height="50px" style={{
                maxWidth: '100%',
                marginBottom: '1em',
              }} />
            </div>

            <div className="bg-gray-100 px-4 py-2 w-100">
              <h1 className="text-lg text-purple-800 font-extrabold">Response Headers</h1>
            </div>

            <div className="py-3 px-4">
              <Skeleton count={4} height="50px" style={{
                maxWidth: '100%',
                marginBottom: '1em',
              }} />
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }

  if (total === 0) {
    return (
      <React.Fragment>
        <Head title="Webhook Logs" />

        <div class="w-100 bg-white border rounded-xl h-auto overflow-hidden py-16 text-center text-purple-800">
          <InboxIcon className="mx-auto h-12 w-12 mb-1" aria-hidden="true" />
          <p className="text-2xl font-bold">No webhook log entries found</p>
          <p className="font-light">You need to have made a transaction and set up a webhook to see log entries here</p>
        </div>

      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="Webhook Logs" />

      <div class="grid grid-cols-2 flex flex-col bg-white border rounded-xl h-auto overflow-hidden">

        <div className="max-h-[150vh] overflow-scroll">

          {
            data.map((item) => {
              return (
                <div
                  className={`py-3 px-4 border-b ${item.id === selected.id ? ' bg-purple-100' : ' cursor-pointer'}`}
                  onClick={() => {
                    setSelected(item);
                    window.scrollTo(0, 0);
                  }}
                  key={item.id}
                >
                  <div className="grid-cols-4 grid">
                    <div className="col-span-3">
                      {moment(item.created_at).format('Do MMM YYYY, HH:mm')}
                    </div>
                    <div className="text-right">
                      {item.is_success === true && (
                        <span className="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                          Success
                        </span>
                      )}
                      {item.is_success === false && (
                        <span className="py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                          Error
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            })
          }

          {total > data.length + 1 &&
            <div className="py-3 px-4 text-center">
              {loadingMore === true && (
                <Skeleton count={10} height="50px" style={{
                  maxWidth: '100%',
                  marginBottom: '1em',
                }} />
              )}
              {loadingMore === false && (
                <button className="text-purple-800 hover:text-purple-600 font-bold" onClick={nextPage}>Load More</button>
              )}

            </div>
          }

          {data.length >= total && (
            <div className="py-3 px-4 text-center bg-purple-50 text-slate-800">
              Showing <strong>{total}</strong> of <strong>{total}</strong> results
            </div>
          )}

        </div>

        <div className="border-l w-full">
          <div className="bg-gray-100 px-4 py-2 w-100">
            <h1 className="text-lg text-purple-800 font-extrabold">Request Detail</h1>
          </div>
          <div className="px-4 py-4 w-100">
            <div className="grid-cols-3 grid pb-2">
              <div className="text-purple-800">
                URL
              </div>
              <div className="col-span-2">
                {selected?.url}
              </div>
            </div>
            <div className="grid-cols-3 grid pb-2">
              <div className="text-purple-800">
                ID
              </div>
              <div className="col-span-2">
                {selected?.id}
              </div>
            </div>
            <div className="grid-cols-3 grid pb-2">
              <div className="text-purple-800">
                Method
              </div>
              <div className="col-span-2">
                POST
              </div>
            </div>
            <div className="grid-cols-3 grid pb-2">
              <div className="text-purple-800">
                Date
              </div>
              <div className="col-span-2">
                {moment(selected?.created_at).format('Do MMMM YYYY')}
              </div>
            </div>
            <div className="grid-cols-3 grid pb-2">
              <div className="text-purple-800">
                Execution Time
              </div>
              <div className="col-span-2">
                {moment(selected?.created_at).format('HH:mm:ss')}
              </div>
            </div>
          </div>
          {selectedData?.status && (
            <React.Fragment>
              <div className="bg-gray-100 px-4 py-2 w-100">
                <h1 className="text-lg text-purple-800 font-extrabold">Response Detail</h1>
              </div>
              <div className="px-4 py-4 w-100">
                <div className="grid-cols-3 grid">
                  <div className="text-purple-800">
                    Status Code
                  </div>
                  <div className="col-span-2">
                    {selectedData?.status || '2XX'}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedData?.headers && (
            <React.Fragment>
              <div className="bg-gray-100 px-4 py-2 w-100">
                <h1 className="text-lg text-purple-800 font-extrabold">Response Headers</h1>
              </div>
              <div>
                <JSONPretty id="json-pretty" data={selectedData.headers} mainStyle="padding:1.5em" />
              </div>
            </React.Fragment>
          )}
        </div>

      </div>
    </React.Fragment>
  )
};

export default WebhooksComponent;
