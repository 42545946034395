import React from 'react';
const ChecboxFilter = ({ onToggle, value, fieldTitle, title }) => {
  return (
    <div className='bg-white px-4 py-4 border shadow-md rounded-md overflow-hidden z-50'>
      <div className='text-md font-bold text-violet-800 border-b pb-1 border-violet-800'>{title} Filter</div>
      <div className="custom-control custom-control-sm custom-checkbox mt-2">
        <input
          type="checkbox"
          className="custom-control-input form-control"
          defaultChecked={value}
          onChange={() => onToggle()}
          id="showChildOrganisations"
        />
        <label className="custom-control-label" htmlFor="showChildOrganisations">
          {" "}
          {fieldTitle}
        </label>
      </div>
    </div>
  )
}

export default ChecboxFilter;