import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone-uploader'
import {Auth} from "aws-amplify";
import Axios from "axios";
import { API_ROUTES, FRONTEND_ROUTES } from 'src/config/api';
import Skeleton from "react-loading-skeleton";

const imageBase64 = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function(event) {
      // event.target.result contains base64 encoded image
      const base64String = event.target.result;
      resolve(base64String)
    };
    reader.readAsDataURL(file);
  });
}

const ImageUpload = ({ organisation }) => {
  const [dropzoneHidden, setDropzoneHidden] = useState(false);
  const [image, setImage] = useState(null);

  const handleSubmit = async (files, allFiles) => {
    if (allFiles.length >= 1) {
      const image = await imageBase64(files[0].file);
      const type = files[0].file.type;
      const extension = files[0].file.name.split('.')[1];

      const user = await Auth.currentAuthenticatedUser();

      await Axios.put(
        API_ROUTES.user['organisation:image-upload'](organisation.id),
        JSON.stringify({ image, type, extension }),
        {
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setImage(image);
      setDropzoneHidden(true);
    }
  }

  useEffect(() => {
    if (organisation.image) {
      setImage(`${FRONTEND_ROUTES.assets.base()}${organisation.image}`);
    }
  }, [organisation]);

  return (
    <div className="mt-3">
      <h5>Organisation Image</h5>

      <div className="mt-3 p-3 w-100 bg-lighter">
        { !organisation.id && (
          <Skeleton count={1} height="200px" style={{
            width: '100%',
          }}/>
        )}
        { organisation.id && image && (
          <img
            className="h-max-150px mb-2"
            alt="organisation"
            src={image}
          />
        )}
        { organisation.id && dropzoneHidden === false && (
          <React.Fragment>
            <Dropzone
              onSubmit={handleSubmit}
              dropzoneDisabled
              accept="image/*"
              maxFiles={1}
              PreviewComponent={null}
              multiple={false}
              submitButtonContent="Save new image"
              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
            />
          </React.Fragment>
        )}
      </div>

    </div>
  );
};
export default ImageUpload;
