import React from "react";
import { Row, Col } from "reactstrap";
import { BlockTitle, Block, Icon } from "src/components/Component";
import Field from "src/pages/bookings/pages/booking/views/booking-information/components/field";
import Head from "src/layout/head/Head";
import { brandLogoTransformer } from "src/transformer/transaction-type";
import { CheckIcon, XMarkIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import CurrencyFormat from 'src/transformer/currency-format';
import { convertPenniesToDecimals } from "src/utils/money";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import ParseName from 'parse-full-name';
import ReactCountryFlag from "react-country-flag";

import 'react-loading-skeleton/dist/skeleton.css'

const Information = ({ customerID, data, loading }) => {
  const { enums } = useSelector((state) => state.enums);

  const titleClass = 'text-primary mb-0 text-xl font-bold';

  if (loading) return (
    <div className="px-4 py-4">
      <Head title="Customer Detail" />

      <Row>
        <Col xl="9">
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Customer Information</BlockTitle>
          </div>

          <Skeleton count={2} height="50px" className="my-2 p-1" />

          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Customer Address</BlockTitle>
          </div>

          <Skeleton count={3} height="50px" className="my-2 p-1" />

        </Col>
        <Col xl="3" className="pl-xl-4 bl-xl">
          <div className="mb-3 mt-4 mt-xl-1">
            <BlockTitle tag="h4" className={titleClass}>Bookings</BlockTitle>
          </div>
          <Skeleton count={1} height="50px" className="my-2 p-1" />
          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Completed Payments</BlockTitle>
          </div>
          <Skeleton count={1} height="50px" className="my-2 p-1" />
          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Failed Payments</BlockTitle>
          </div>
          <Skeleton count={1} height="50px" className="my-2 p-1" />
        </Col>
        <Col xl="12" className="border-t mt-4 mb-2">
          <div className="divider-xl-down" />
          <div className="mb-3 mt-4">
            <BlockTitle tag="h4" className={titleClass}>Payment Methods</BlockTitle>
          </div>
          <div className="w-100 mt-4">
            <Skeleton count={3} height="50px" className="my-2 p-1" />
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="px-4 py-4">
      <Head title="Customer Detail" />

      <Row>
        <Col xl="9">
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className={titleClass}>Customer Information</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Felloh Customer ID"
                  description="The ID that identifies the customer"
                  data={
                    <React.Fragment>
                      {customerID}
                    </React.Fragment>
                  } />

                <Field
                  name="Customer Name"
                  description="The Customer name"
                  data={
                    <React.Fragment>
                      {data?.customer_name ? `${ParseName.parseFullName(data.customer_name).first} ${ParseName.parseFullName(data.customer_name).last}` : <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="Email Address"
                  description="The Customer email address"
                  data={
                    <React.Fragment>
                      {data?.email ? data.email.toLowerCase() : <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="Created At"
                  description="The date the customer was created"
                  data={
                    <React.Fragment>
                      {moment(data?.created_at).format('Do MMM YYYY, HH:mm')}
                    </React.Fragment>
                  } />
              </Row>
            </div>
          </Block>

          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Customer Address</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Address 1"
                  description="The first line of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.address_1 || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="Address 2"
                  description="The second line of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.address_2 || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="City"
                  description="The city of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.city || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="County"
                  description="The county of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.county || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="Country"
                  description="The country of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.country || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />

                <Field
                  name="Postcode"
                  description="The postcode of the customer address"
                  data={
                    <React.Fragment>
                      {data.address.postcode || <span className="text-slate-500">Not Set</span>}
                    </React.Fragment>
                  } />
              </Row>
            </div>
          </Block>

        </Col>
        <Col xl="3" className="pl-xl-4 bl-xl">

          <div className="divider-xl-down" />

          <div className="mb-3 mt-4 mt-xl-1">
            <BlockTitle tag="h4" className={titleClass}>Bookings</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Count"
                  description="The total number of bookings for the customer"
                  data={
                    <React.Fragment>
                      {data?.statistics?.bookings?.count || 0}
                    </React.Fragment>
                  } />

                <Field
                  name="Value"
                  description="The value of all bookings for the customer"
                  data={
                    <React.Fragment>
                      {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(data.statistics.bookings.amount))}
                    </React.Fragment>
                  } />


              </Row>
            </div>
          </Block>

          <div className="divider-xl-down" />

          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Completed Payments</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Count"
                  description="The number of completed payments for the customer"
                  data={
                    <React.Fragment>
                      {data?.statistics?.transactions?.complete?.count || 0}
                    </React.Fragment>
                  } />

                <Field
                  name="Value"
                  description="The value of all completed payments for the customer"
                  data={
                    <React.Fragment>
                      {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(data.statistics.transactions.complete.amount))}
                    </React.Fragment>
                  } />


              </Row>
            </div>
          </Block>

          <div className="divider-xl-down" />

          <div className="mb-3 mt-10">
            <BlockTitle tag="h4" className={titleClass}>Failed Payments</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-center">

                <Field
                  name="Count"
                  description="The number of failed payments for the customer"
                  data={
                    <React.Fragment>
                      {data?.statistics?.transactions?.failed?.count || 0}
                    </React.Fragment>
                  } />

                <Field
                  name="Value"
                  description="The value of all failed payments for the customer"
                  data={
                    <React.Fragment>
                      {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(data.statistics.transactions.failed.amount))}
                    </React.Fragment>
                  } />
              </Row>
            </div>
          </Block>
        </Col>

        {
          data?.tokens && data?.tokens?.length >= 1 && (
            <Col xl="12" className="border-t mt-8 mb-2">
              <div className="divider-xl-down" />
              <div className="mb-3 mt-4">
                <BlockTitle tag="h4" className={titleClass}>Payment Methods</BlockTitle>
                <p className="text-sm mb-2">Found <strong>{data.tokens.length}</strong> payment method</p>
              </div>
              <div className="w-100 mt-4">
                <table className="table table-tranx border">
                  <thead>
                    <tr className="tb-tnx-head">
                      <th className="text-left">Card Number</th>
                      <th className="text-left border-l">
                        Cardholder Name &<br />
                        Issuer
                      </th>

                      <th className="text-center border-l">Currency</th>
                      <th className="text-center">Type</th>
                      <th className="text-center border-r">Brand</th>
                      <th className="text-center border-r">Vaulted</th>
                      <th className="text-center border-r">Created On</th>
                      <th colSpan={2} />
                    </tr>
                  </thead>
                  <tbody>
                    {data.tokens.map((token, index) => {
                      const type = brandLogoTransformer(token.scheme);

                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td rowSpan={2} className="align-middle w-[190px]">
                              {token.bin} **** **** ****
                            </td>
                            <td className="text-left border-b-0 border-l">
                              <strong>{token.cardholder_name || 'Not Available'}</strong>
                            </td>
                            <td className="text-center align-middle border-l w-[120px]" rowSpan={2}>
                              {token.currency}
                            </td>
                            <td className="text-center align-middle w-[120px]" rowSpan={2}>
                              {token.type === 'CREDIT' ? 'Credit' : 'Debit'}
                            </td>
                            <td className="text-center border-r align-middle w-[130px]" rowSpan={2}>
                              <div style={{ width: '38px', padding: '0 4px 0 6px', maxWidth: '38px', margin: '0 auto' }}>
                                {
                                  typeof type.image !== 'undefined' && (
                                    <img src={type.image} className={`${type.imageClass} -mt-0.5`} />
                                  )
                                }
                                {
                                  typeof type.image === 'undefined' && (
                                    <Icon name={`ni text-primary icon ${type.icon}`} style={{ fontSize: '21px' }} />
                                  )
                                }
                              </div>

                            </td>

                            <td className="text-centre border-r align-middle" rowSpan={2}>
                              {
                                token.stored === true && (
                                  <CheckIcon className="h-5 w-5 text-green-500 mx-auto" />
                                )
                              }
                              {
                                token.stored === false && (
                                  <XMarkIcon className="h-5 w-5 text-red-500 mx-auto" />
                                )
                              }

                            </td>
                            <td className="text-center border-r align-middle" rowSpan={2}>
                              {moment(token.created_at).format('Do MMM, HH:mm')}
                            </td>
                            <td className="align-middle text-center pl-1 pr-1" rowSpan={2}><EllipsisHorizontalIcon className="h-5 w-5 text-gray-500 mx-auto" /></td>
                          </tr>
                          <tr>
                            <td className="text-left !border-t-0 !pt-0 border-l !pl-2">
                              {token.issuer || 'Not Available'}
                              {
                                token.country && (
                                  <ReactCountryFlag countryCode={token.country} svg
                                    style={{
                                      width: '1.1em',
                                      height: '1.1em',
                                      marginTop: '-0.3em',
                                      marginLeft: '0.4em',
                                    }} />
                                )
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          )
        }

      </Row>


    </div>
  )
}

export default Information;