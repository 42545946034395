import React, { useState } from "react";
import { Icon } from "../../Component";

const OutlinedInput = ({ label, bsSize, id, icon, onChange = null }) => {
  const [focus, setFocus] = useState(false);
  return (
    <div className={`form-control-wrap ${focus ? "focused" : ""}`}>
      {icon && (
        <div className="form-icon form-icon-right xl">
          <Icon name={icon} />
        </div>
      )}
      <input
        type="text"
        className={`form-control-outlined form-control ${bsSize ? `form-control-${bsSize}` : ""}`}
        id={id}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <label className="form-label-outlined text-gray" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default OutlinedInput;
