import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
} from 'src/components/Component';
import DataTable from 'src/components/data-table';
import API_ROUTES from "src/config/api";
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "src/components/pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import moment from "moment";
import ParseName from 'parse-full-name';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const RowFormatter = ({item, childFilterState, enums}) => {
  const history = useHistory();

  return (
    <tr key={item.id} className="tb-tnx-item cursor-pointer hover:bg-gray-50" onClick={() => history.push(`/customer/${item.id}`)}>
      <td className="text-left border-r font-bold">
        <PiiObfuscator type="customer_name">
          {ParseName.parseFullName(item.customer_name).first} {ParseName.parseFullName(item.customer_name).last}
        </PiiObfuscator>
        <br />
        <span className="font-light">
          <PiiObfuscator type="email">{item.email.toLowerCase()}</PiiObfuscator>
        </span>
      </td>
      <td className="text-center border-r">
        <span className="title">{moment(item.created_at).format('Do MMM YYYY, HH:mm')}</span>
      </td>
      {childFilterState === true && (
        <td className="text-center border-r">
          <PiiObfuscator className="title" type="organisation">{item?.organisation?.name}</PiiObfuscator>
        </td>
      )}
      <td className="text-center border-r">
        <span className="title">
          {item?.aggregates?.transaction?.last_completed ? moment(item?.aggregates?.transaction?.last_completed).format('Do MMM YYYY, HH:mm') : '-'}
        </span>
      </td>
      <td className="tb-tnx-id text-center border-r w-24">
      {item?.aggregates?.transaction?.count}
      </td>
      <td className="tb-tnx-id text-center border-r w-24">
        {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(item?.aggregates?.transaction?.amount))}
      </td>
      <td className="tb-tnx-id text-center border-r w-24">
        {item?.aggregates?.booking?.count}
      </td>
      <td className="tb-tnx-id text-center border-r w-24">
        {CurrencyFormat(enums.currency, 'GBX').format(convertPenniesToDecimals(item?.aggregates?.booking?.amount))}
      </td>
    </tr>
  );
}

const TableHeaders = ({childFilterState}) => (
  <React.Fragment>
    <tr className="tb-tnx-head">
      <th rowSpan="2" className='text-left border-r'>
        <span className="">Customer</span>
      </th>

      <th rowSpan="2" className='text-center border-r'>
        <span>Customer Since</span>
      </th>
      { childFilterState === true && (
        <th rowSpan="2" className='text-center border-r'>
          <span>Organisation</span>
        </th>
      )}
      <th rowSpan="2" className='text-center border-r'>
        <span>Last Transaction</span>
      </th>
      <th colSpan="2" className="text-center border-r">
        <span>Transactions</span>
      </th>
      <th colSpan="2" className="text-center border-r">
        <span>Bookings</span>
      </th>
    </tr>
    <tr className="tb-tnx-head">
      <th className="tb-tnx-id text-center">
        <span className="">Count</span>
      </th>
      <th className="tb-tnx-id text-center border-r">
        <span className="">Value</span>
      </th>
      <th className="tb-tnx-id text-center">
        <span className="">Count</span>
      </th>
      <th className="tb-tnx-id text-center border-r">
        <span className="">Value</span>
      </th>
    </tr>
  </React.Fragment>
);

const PageOptions = ({triggerReload, loading}) => {
  return (
    <ul className="nk-block-tools g-3">
      <li>
        <Button disabled={loading} className="border-violet-800 bg-white text-violet-800 text-xs hover:bg-slate-800 hover:border-slate-800" id="trigger_reload" onClick={() => triggerReload()}>
          <ArrowPathIcon className='h-4 w-5' />
        </Button>
      </li>
    </ul>
  );
};

const Bookings = () => {
  const endpoint = API_ROUTES.agent['customers:get']();

  return (
    <DataTable
      title="Customers"
      largeHeading={true}
      columnCount={9}
      TableHeaders={TableHeaders}
      RowFormatter={RowFormatter}
      PageOptions={PageOptions}
      endpoint={endpoint}
      noHeader={true}
      searchPlaceholder="Customer name or email"
    />
  );
};

export default Bookings;
