import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, FormGroup, Card, Spinner, Label } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async-creatable';

import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "src/components/Component";
import API_ROUTES from "src/config/api";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { useSelector } from "react-redux";
import CurrencyFormat from "src/transformer/currency-format";
import Validator from "validator";
import { convertPenniesToDecimals } from "src/utils/money";

const defaultFormValues = {
  booking: '',
  name: '',
  email: '',
  departure_date: '',
  return_date: '',
};

const FormLayoutsPage = ({ ...props }) => {
  const { id } = useParams();

  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    allowCustomerFieldsEntry: false,
    bookingFieldEnabled: false,
    showAdvancedOptions: false,
    showSelectDropdown: false,
    asyncSelectLoaded: false,
  });

  const [formValues, setFormValues] = useState(defaultFormValues);

  const getEcommerceEntity = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent["ecommerce:get:one"](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      },
    );

    setData(result.data.data);
  }, []);

  useEffect(() => {
    getEcommerceEntity();
  }, []);

  /**
   * Constantly check form field validity
   */
  useEffect(() => {
    checkFormValidity();
  }, [formValues]);

  const history = useHistory();

  const updateFormField = ((e) => {
    let value = e.target.value

    setTouched({ ...touched, [e.target.id]: true });

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  const updateDateField = (date, field) => setFormValues({
    ...formValues,
    [field]: new Date(date).toISOString().split('T')[0],
  });

  const formSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      ...touched,
      name: true,
      email: true,
      booking: true,
      departure_date: true,
      return_date: true,
    });

    const errorCheck = checkFormValidity();

    if (Object.keys(errorCheck).length >= 1) {
      return false;
    }

    const user = await Auth.currentAuthenticatedUser();

    setSubmitting(true);

    let bookingID = formValues.booking_id

    // Create the booking if it is new
    if (formState.allowCustomerFieldsEntry === true) {
      const bookingResponse = await Axios(
        API_ROUTES.agent['bookings:create'](),
        {
          method: 'put',
          data: {
            organisation: organisation.organisation,
            customer_name: formValues.name,
            email: formValues.email,
            booking_reference: formValues.booking,
            departure_date: formValues.departure_date !== '' ? formValues.departure_date : null,
            return_date: formValues.return_date !== '' ? formValues.return_date : null,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      bookingID = bookingResponse.data.data.id;
    }

    await Axios(
      API_ROUTES.agent['ecommerce:assign:one'](id),
      {
        method: 'post',
        data: {
          booking_id: bookingID,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );


    history.push(`/booking/${bookingID}`);
  }

  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFormState({ ...formState, asyncSelectLoaded: true });
    return result.data.data;
  }, []);

  const populateBookingDetails = async (value) => {
    if (value !== null) {
      let newDuplicate = false;
      let data = { ...value };

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true) {
        const bookingCheck = await findBookings(value.value);

        for (const booking of bookingCheck) {
          if (booking.booking_reference === value.value) {
            newDuplicate = true;
            data = booking;
          }
        }
      }

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true && newDuplicate === false) {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: true });
        await setFormValues({
          ...formValues,
          ...defaultFormValues,
          bookingField: value,
          booking: value.value,
          booking_id: null,
        });
      } else {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: false });

        await setFormValues({
          ...formValues,
          bookingField: value,
          booking: data.booking_reference,
          booking_id: data.id,
          name: data.customer_name,
          email: data.email,
          departure_date: data.departure_date,
          return_date: data.return_date,
        });
      }
    }
  }

  /**
   * Check the validity of the form and update state
   * @returns {{}}
   */
  const checkFormValidity = () => {
    const errorObj = {};

    if (formValues.name === '') errorObj.name = 'Name cannot be empty';

    if (formValues.email === '') errorObj.email = 'Email cannot be empty';

    if (!Validator.isEmail(formValues.email) && typeof errorObj.email === 'undefined') errorObj.email = 'Email is Invalid';

    if (formValues.booking === '') errorObj.booking = 'Booking cannot be empty';

    if (formValues.departure_date !== ''
      && formValues.return_date !== ''
      && new Date(formValues.return_date).getTime() < new Date(formValues.departure_date).getTime()
    ) {
      errorObj.return_date = 'Return date must be greater than or equal to departure date';
    }

    setErrors(errorObj);

    return errorObj;
  };

  return (
    <React.Fragment>
      <Head title="Assign to Booking" />

      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal py-2" page>
              Assign Ecommerce Session to Booking
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        {!data && (
          <div className="text-center mt-5 fs-21px">
            <Spinner color="primary"> </Spinner>
          </div>
        )}

        {data && (
          <React.Fragment>
            <Block>

              <Card className="card-bordered p-5">
                <div className="row g-3">
                  <div className="col-6 mt-1">
                    <span className="sub-text font-weight-bold text-primary">Ecommerce Session ID:</span>
                    <span>{id}</span>
                  </div>
                  {data.customer_name && (
                    <div className="col-6 mt-1">
                      <span className="sub-text font-weight-bold text-primary">Customer Name:</span>
                      <span>{data.customer_name}</span>
                    </div>
                  )}
                  <div className="col-6 mt-1">
                    <span className="sub-text font-weight-bold text-primary">Amount:</span>
                    <span>{CurrencyFormat(enums.currency, data.currency).format(convertPenniesToDecimals(data.amount))}</span>
                  </div>
                </div>
              </Card>

            </Block>

            <Block>

              <Card className="card-bordered p-5">
                <Block size="lg">
                  <form className="gy-3">
                    <Row className="g-3 align-center">
                      <Col lg="5">
                        <FormGroup>
                          <label className="form-label text-primary">Booking Reference poop</label>
                          <span className="form-note">The customers reference for their booking</span>
                        </FormGroup>
                      </Col>
                      <Col lg="7">
                        <FormGroup>
                          <div className="form-control-wrap">
                            <AsyncSelect
                              isClearable
                              className="form-control form-control-xl p-0 border-0 indicator-hidden"
                              allowCreateWhileLoading={false}
                              value={formValues.bookingField}
                              getOptionLabel={e => {
                                if (e.__isNew__) {
                                  return e.label;
                                }

                                return `${e.booking_reference} | ${e.customer_name}`;
                              }}
                              getOptionValue={e => e.id}
                              loadOptions={findBookings}
                              onChange={populateBookingDetails}
                              menuIsOpen={formState.asyncSelectLoaded === true}
                              placeholder=""
                              classNamePrefix="react-select"
                              disabled={isSubmitting}
                              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                let response = false;

                                if (inputValue && inputValue.length >= 1) {
                                  response = true;
                                }

                                if (inputValue && inputValue.length > 0 && selectOptions && selectOptions.length > 0) {
                                  selectOptions.forEach((item) => {
                                    if (item.booking_reference.toLowerCase() === inputValue.toLowerCase()) response = false;
                                  })
                                }

                                return response;
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="nk-divider divider md pb-2" />

                    <Row className="g-3 align-center">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Customer Name<small className="text-danger pl-1 pb-3">required</small>
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formValues.name}
                              onChange={updateFormField}
                              className={`form-control form-control-xl ${typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' ? 'error' : ''}`}
                              disabled={formState.allowCustomerFieldsEntry === false}
                            />
                          </div>
                          {typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                          {(typeof errors.name === 'undefined' || typeof touched.name === 'undefined') && (
                            <span className="form-note">The customers full name</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Customer Email<small className="text-danger pl-1 pb-3">required</small>
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={formValues.email}
                              onChange={updateFormField}
                              className={`form-control form-control-xl ${typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' ? 'error' : ''}`}
                              disabled={formState.allowCustomerFieldsEntry === false}
                            />
                          </div>
                          {typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' && (
                            <small className="text-danger">{errors.email}</small>
                          )}
                          {(typeof errors.email === 'undefined' || typeof touched.email === 'undefined') && (
                            <span className="form-note">The customers email address</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Departure Date
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              id="departure_date"
                              name="departure_date"
                              value={formValues.departure_date}
                              onChange={(value) => updateDateField(value, 'departure_date')}
                              disabled={formState.allowCustomerFieldsEntry === false}
                              className="form-control form-control-xl date-picker"
                            />
                          </div>
                          <span className="form-note">The date that the booking commences</span>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Return Date
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              id="return_date"
                              name="return_date"
                              value={formValues.return_date}
                              onChange={(value) => updateDateField(value, 'return_date')}
                              disabled={formState.allowCustomerFieldsEntry === false}
                              className={`form-control form-control-xl date-picker ${typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' ? 'error' : ''}`}
                            />
                          </div>
                          {typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' && (
                            <small className="text-danger">{errors.return_date}</small>
                          )}
                          {(typeof errors.return_date === 'undefined' || typeof touched.return_date === 'undefined') && (
                            <span className="form-note">The date that the booking ends / returns</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="nk-divider divider md" />
                    <Row className="g-3">
                      <Col lg="7" className="offset-lg-5">
                        <FormGroup>
                          <Button color="primary" size="lg" disabled={isSubmitting || !formValues.bookingField || (formState.allowCustomerFieldsEntry === true && Object.keys(errors).length >= 1)} onClick={formSubmit} className="float-right">
                            {
                              isSubmitting ?
                                <Spinner size="sm" color="light"> </Spinner>
                                : formState.allowCustomerFieldsEntry === true ? 'Create Booking & Assign' : 'Assign'
                            }
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </Block>
              </Card>

            </Block>
          </React.Fragment>
        )}
      </Content>
    </React.Fragment>
  );
};

export default FormLayoutsPage;
