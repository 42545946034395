import React, {useCallback, useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import {Auth} from "aws-amplify";
import Axios from "axios";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Button,
  Icon,
  Row,
  Col,
} from "src/components/Component";

import { API_ROUTES } from '../../../../../config/api';
import HasRole from '../../../../../utils/has-role';
import Item from './item';

const activeState = (item) => {
  if (!item.payment_provider.is_active) {
    return false;
  }
  if (!item.is_active) {
    return false;
  }
  return true;
};

const OrganisationPaymentConfig = ({ organisation }) => {
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  const { account } = useSelector((state) => state.account);

  const getProviders = useCallback(async (triggerLoading = true) => {
    if (triggerLoading || providers.length === 0) {
      setLoading(true);
    }

    if (typeof organisation.id === 'undefined' || !organisation.id) {
      return null;
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.payment['organisation:providers'](organisation.id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setProviders(result.data.data);
    setLoading(false);
  }, [organisation.id]);

  useEffect(() => {
    getProviders();
  }, [getProviders, organisation.id]);

  return (
    <div className="card-inner card-inner-lg">
      <Head title="Payment Configuration" />

      { HasRole(account, ['organisation:management']) && (
        <ul className="nk-block-tools g-3 float-right">
          <li>
            <Link to={`/organisations/${organisation.id}/payment-config/set-weights`}>
              <Button color="light" outline className="btn-action">
                <Icon name="exchange" />
                <span>Set Weightings</span>
              </Button>
            </Link>
          </li>
          <li>
            <Link to={`/organisations/${organisation.id}/payment-config/create`}>
              <Button color="light" outline className="btn-white">
                <Icon name="plus" />
                <span>Create Payment Provider</span>
              </Button>
            </Link>
          </li>
        </ul>
      )}

      <Block>
        <div className="nk-data data-list data-list-s2">
          <BlockTitle tag="h4" className="text-primary mb-2">Payment Providers</BlockTitle>

          <div className="nk-divider divider md pb-2" />

          <div style={{ display: loading === true ? 'block' : 'none' }} className="mt-3">
            <Skeleton count={3} height="150px" className="mb-5" style={{
              width: '100%',
            }}/>
          </div>

          <Row className="g-gs">
            { !loading && providers.map((item, key) => {
              if (activeState(item)) {
                return (
                  <Col size="6" key={key}>
                    <Item
                      item={item}
                      organisation={organisation}
                      activeState={activeState(item)}
                      reload={() => (getProviders())}
                    />
                  </Col>
                );
              }
              return null;
            })}

            { !loading && providers.map((item, key) => {
              if (!activeState(item)) {
                return (
                  <Col size="6" key={key}>
                    <Item
                      item={item}
                      organisation={organisation}
                      activeState={activeState(item)}
                      reload={() => (getProviders())}
                    />
                  </Col>
                );
              }
              return null;
            })}
          </Row>

        </div>
      </Block>

    </div>
  );
};
export default OrganisationPaymentConfig;
