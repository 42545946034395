import React, { useState } from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  BlockTitle,
  Row,
  Col,
  Icon,
} from "src/components/Component";
import { Link } from "react-router-dom";
import moment from "moment";
import Skeleton from 'react-loading-skeleton'
import ParseName from 'parse-full-name';

import 'react-loading-skeleton/dist/skeleton.css'
import CurrencyFormat from 'src/transformer/currency-format';
import TransactionStatusTransformer from "src/transformer/transaction-status";
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import ProviderNameTransformer from "src/transformer/provider-name";
import PiiObfuscator from "src/components/pii-obfuscator";
import { useSelector } from "react-redux";
import { convertPenniesToDecimals } from "src/utils/money";
import ReassignTransaction from "./components/re-assign-transaction";
import Field from "../../../../../bookings/pages/booking/views/booking-information/components/field";
import HasFeature from "../../../../../../utils/has-feature";
import BankStatus from "./components/bank-status";
import HasRole from "src/utils/has-role";
import { Alert } from "reactstrap";
import RefundStatusTransformer from "src/transformer/refund-status";
import AssignBookingButton from "../../components/assign-booking-button";
import RefundButton from "./components/refund-button";
import CompleteButton from "./components/complete-button";
import ReverseButton from "./components/reverse-button";
import { MapContainer, TileLayer } from 'react-leaflet'
import ReactCountryFlag from "react-country-flag";
import CountryCodeLookup from 'country-code-lookup';
import { brandLogoTransformer } from "src/transformer/transaction-type";

import 'leaflet/dist/leaflet.css';

const PaymentInformation = ({ data = {}, loading = true, triggerReload = false }) => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);
  const { account } = useSelector((state) => state.account);

  const [reassignModalState, setReassignModalState] = useState(false);

  const toggleReassignModal = () => { setReassignModalState(!reassignModalState); };

  if (loading === true) {
    return (
      <React.Fragment>
        <Row>
          <Col xl="9">

            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payment Details</BlockTitle>
              <p className="text-secondary text-sm">Base information about the payment</p>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

            <div className="divider" />

            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payment Method</BlockTitle>
              <p className="text-secondary text-sm">Information about the card that was used</p>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

            <div className="divider" />

            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Provider</BlockTitle>
              <p className="text-secondary text-sm">Provider specific information for the payment</p>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

          </Col>

          <Col xl="3" className="pl-xl-4 bl-xl">

            <div className="divider-xl-down" />

            <div className="mb-3 mt-4 mt-xl-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Actions</BlockTitle>
            </div>

            <Block>
              <Skeleton count={1} height="40px" className="mb-2" />
            </Block>


            <div className="mb-3 mt-4 mt-xl-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Status Overview</BlockTitle>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

            <div className="divider" />

            <div className="mb-3 mt-4 mt-xl-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Booking</BlockTitle>
            </div>

            <Block>
              <Skeleton count={2} height="40px" className="mb-2" />
            </Block>

          </Col>

        </Row>
      </React.Fragment>
    )
  }

  let externallySettled = false;
  if (data?.metadata?.payment_brand) {
    if (data?.metadata?.payment_brand.toLowerCase().indexOf('american express') !== -1) externallySettled = true;
    if (data?.metadata?.payment_brand.toLowerCase().indexOf('amex') !== -1) externallySettled = true;
  }

  return (
    <React.Fragment>
      <Head title="Payment Information" />
      <Row>
        {
          data.refund &&
          Array.isArray(data.refund) &&
          data.refund.length >= 1 &&
          (
            data?.refund?.map((item) => item.status.id).indexOf('PENDING_AUTHORISATION') !== -1
            || data?.refund?.map((item) => item.status.id).indexOf('AUTHORISED') !== -1
          ) &&
          (
            <Col xl="12">
              <Alert color="danger" className="alert-icon mb-4">
                {" "}
                <Icon name="alert-circle" /> There is currently a refund in progress for this payment.
              </Alert>
            </Col>
          )
        }
        <Col xl="9">
          <div className="mb-3 mt-1">
            <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payment Details</BlockTitle>
            <p className="text-secondary text-sm">Base information about the payment</p>
          </div>

          <Block>
            <div className="row g-3">
            {data?.customer?.id && (
                <Field
                  name="Customer ID"
                  description="The felloh customer ID that this transaciton is linked to"
                  itemWidth={4}
                  data={
                    <Link to={`/customer/${data.customer.id}`} className="text-violet-800 underline">
                      {data.customer.id}
                    </Link>
                  
                  } />
              )}

              <Field
                name="Payment Amount"
                description="The value of the payment that the customer was charged"
                itemWidth={4}
                data={CurrencyFormat(enums.currency, data.currency).format(convertPenniesToDecimals(data.amount))} />

              <Field
                name="Type"
                description="The payment method that payment was undertaken on"
                itemWidth={4}
                data={TransactionTypeTransformer(data.type.id).label} />

              <Field
                name="Method"
                description="The method that was used to process the payment"
                itemWidth={4}
                data={TransactionMethodTransformer(data.method.id).label} />

              <Field
                name="Initiated At"
                description="The time at which the payment was initiated by the customer"
                itemWidth={4}
                data={`At ${moment(data.created_at).format('HH:mm:ss')} on ${moment(data.created_at).format('DD/MM/yy')}`} />

              {data.completed_at && (
                <Field
                  name="Completed At"
                  description="The time at which the payment was completed by the customer"
                  itemWidth={4}
                  data={`At ${moment(data.completed_at).format('HH:mm:ss')} on ${moment(data.completed_at).format('DD/MM/yy')}`} />
              )}

            </div>

            <div className="divider" />

            {data.refund && Array.isArray(data.refund) && data.refund.length >= 1 && (
              <React.Fragment>
                <div className="mb-3 mt-1">
                  <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Refund Detail</BlockTitle>
                  <p className="text-secondary text-sm">Information about refunds on this payment</p>
                </div>

                <div className="p-1 mb-1">

                  {
                    data.refund.map((item, key) => (
                      <div className="row g-3 border m-2 border-grey">
                        <Col xl={12}>
                          <h6>Refund {key + 1} of {data.refund.length}</h6>
                        </Col>
                        <Field
                          name="Refund Status"
                          description="The status of the refund"
                          itemWidth={6}
                          data={RefundStatusTransformer(item.status.id).label} />

                        <Field
                          name="Refund Amount"
                          description="The amount that the refund is for"
                          itemWidth={6}
                          data={CurrencyFormat(enums.currency, data.currency).format(convertPenniesToDecimals(item.amount))} />

                        <Field
                          name="Created At"
                          description="The time at which the refund was created at"
                          itemWidth={6}
                          data={`At ${moment(item.created_at).format('HH:mm:ss')} on ${moment(item.created_at).format('DD/MM/yy')}`} />

                        {item.authorised_at && item.status.id !== 'AUTHORISATION_DECLINED' && item.status.id !== 'PENDING_AUTHORISATION' && (
                          <Field
                            name="Authorised At"
                            description="The time at which the refund was given authorisation"
                            itemWidth={6}
                            data={`At ${moment(item.authorised_at).format('HH:mm:ss')} on ${moment(item.authorised_at).format('DD/MM/yy')}`} />
                        )}

                        {item.completed_at && item.status.id === 'COMPLETED' && (
                          <Field
                            name="Completed At"
                            description="The time at which the refund was authroised"
                            itemWidth={6}
                            data={`At ${moment(item.completed_at).format('HH:mm:ss')} on ${moment(item.completed_at).format('DD/MM/yy')}`} />
                        )}
                      </div>
                    ))
                  }

                </div>

                <div className="divider" />
              </React.Fragment>
            )}

            {data.metadata && (
              <React.Fragment>
                <div className="mb-3 mt-1">
                  <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payment Method</BlockTitle>
                  <p className="text-secondary text-sm">Information about the payment method that was used to undertake payment</p>
                </div>

                <div className="row g-3">

                  {data?.metadata?.cardholder_name && (
                    <Field
                      name="Cardholder Name"
                      description="The name on the card that was used"
                      itemWidth={4}
                      data={<PiiObfuscator type="customer_name">
                        {ParseName.parseFullName(data.metadata.cardholder_name).title} {ParseName.parseFullName(data.metadata.cardholder_name).first} {ParseName.parseFullName(data.metadata.cardholder_name).last}
                      </PiiObfuscator>} />
                  )}

                  {data?.metadata?.bin_type && (
                    <Field
                      name="Card Type"
                      description="The type of card that was used"
                      itemWidth={4}
                      data={data.metadata.bin_type} />
                  )}

                  {data?.metadata?.card_type && (
                    <Field
                      name="Card Brand"
                      description="The brand of the card that was used"
                      itemWidth={4}
                      data={(data.metadata.card_type)} />
                  )}

                  {data?.metadata?.issuing_country && (
                    <Field
                      name="Issuing Country"
                      description="The issuing country of the card"
                      itemWidth={4}
                      data={
                        <React.Fragment>
                          {CountryCodeLookup.byIso(data.metadata.issuing_country).country}
                          <ReactCountryFlag countryCode={data.metadata.issuing_country} svg
                            style={{
                              width: '1.1em',
                              height: '1.1em',
                              marginTop: '-0.2em',
                              marginLeft: '0.3em',
                            }} />

                        </React.Fragment>
                      } />
                  )}


                  {data?.metadata?.last_four_digits && (
                    <Field
                      name="Card Number"
                      description="The last four digits of the card that was used"
                      itemWidth={4}
                      data={<span>**** **** **** {data.metadata.last_four_digits}</span>} />
                  )}

                  {data?.metadata?.payment_brand && (
                    <Field
                      name="Card Network"
                      description="The network of the card that was used"
                      itemWidth={4}
                      data={brandLogoTransformer(data.metadata.payment_brand).name} />
                  )}

                </div>

                <div className="divider" />
              </React.Fragment>
            )}

            <div className="mb-3 mt-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Payment Provider</BlockTitle>
              <p className="text-secondary text-sm">Provider specific information for the payment</p>
            </div>

            <Block>
              <div className="row g-3">

                <Field
                  name="Provider"
                  description="The provider that the payment was processed by"
                  itemWidth={4}
                  data={ProviderNameTransformer(data.payment_provider.name)} />

                <Field
                  name="Reference"
                  description="The provider reference for the payment"
                  itemWidth={8}
                  data={data.provider_reference} />

                {data.narrative && (
                  <React.Fragment>
                    <Field
                      name="Payment Narrative"
                      description="The reference why the payment was not processed"
                      itemWidth={4}
                      data={data.narrative.reason} />

                    <Field
                      name="Payment Narrative"
                      description="A description of why the payment was not processed"
                      itemWidth={8}
                      data={data.narrative.description} />

                  </React.Fragment>
                )}
                {!data.narrative && data.message && (
                  <Field
                    name="Message"
                    description="Message from the provider"
                    itemWidth={6}
                    data={data.message} />
                )}
              </div>

            </Block>

            <div className="divider" />

            <div className="mb-3 mt-4 mt-xl-1">
              <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">{data?.payment_link?.id ? 'Payment Link' : 'Ecommerce Link'}</BlockTitle>
              <p className="text-secondary text-sm">Information related to the {data?.payment_link?.id ? 'Payment Link' : 'Ecommerce Link'} that generated this payment</p>
            </div>

            <Block>
              <div className="nk-data data-list data-list-s2">

                <Row className="g-3 align-center">
                  {data?.payment_link?.id && (
                    <Field
                      name="Payment Link ID"
                      description={`The ID of the payment link`}
                      itemWidth={6}
                      data={data?.payment_link.id} />
                  )}

                  {data?.payment_link?.description && (
                    <Field
                      name="Description"
                      description={`The description of the payment link`}
                      itemWidth={6}
                      data={data?.payment_link.description} />
                  )}

                  {data?.ecommerce?.id && (
                    <Field
                      name="Ecommerce ID"
                      description={`The ID of the ecommerce link`}
                      itemWidth={6}
                      data={data?.ecommerce.id} />
                  )}

                </Row>

              </div>
            </Block>

          </Block>

        </Col>

        <Col xl="3" className="pl-xl-4 bl-xl">

          <div className="divider-xl-down" />

          {(
            data.status.id === 'PREAUTHORIZED' ||
            (data?.status?.id === 'COMPLETE' && !data?.booking?.id && data?.payment_link?.id) ||
            (
              data.status.id === 'COMPLETE'
              && Array.isArray(data.refund)
              && data?.refund?.map((item) => item.status.id).indexOf('PENDING_AUTHORISATION') === -1
              && data?.refund?.map((item) => item.status.id).indexOf('AUTHORISED') === -1
              && HasRole(account, ['refund:request'])
            )
          ) && (
              <div className="w-100 mb-4">
                <div className="mb-3 mt-4 mt-xl-1">
                  <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Actions</BlockTitle>
                </div>

                {data?.status?.id === 'COMPLETE' && !data?.booking?.id && data?.payment_link?.id && (
                  <AssignBookingButton data={data} />
                )}

                {data.status.id === 'PREAUTHORIZED' && (
                  <React.Fragment>
                    <CompleteButton triggerReload={triggerReload} item={data} />
                    <ReverseButton triggerReload={triggerReload} item={data} />
                  </React.Fragment>
                )}
                {
                  data.status.id === 'COMPLETE'
                  && Array.isArray(data.refund)
                  && data?.refund?.map((item) => item.status.id).indexOf('PENDING_AUTHORISATION') === -1
                  && data?.refund?.map((item) => item.status.id).indexOf('AUTHORISED') === -1
                  && HasRole(account, ['refund:request'])
                  && (
                    <RefundButton triggerReload={triggerReload} item={data} />
                  )
                }
                {data.booking && data.booking.id && (
                  <React.Fragment>
                    <Link className="text-violet-800 underline w-100" to='#' onClick={toggleReassignModal}>
                      <button className="btn btn-outline-primary bg-violet-800 text-white w-100 justify-center mt-1">
                        Assign to another Booking
                      </button>
                    </Link>
                    <ReassignTransaction modalState={reassignModalState} toggle={toggleReassignModal} triggerReload={triggerReload} />
                  </React.Fragment>
                )}
              </div>
            )}

          <div className="mb-3 mt-4 mt-xl-1">
            <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Status Overview</BlockTitle>
          </div>

          <Block>
            <div className="nk-data data-list data-list-s2">

              <Row className="g-3 align-cente">

                <Field
                  name="Processing Status"
                  description="Status of the transaction"
                  itemWidth={12}
                  data={
                    <span>
                      <em className={`pr-1 icon ni ${TransactionStatusTransformer(data.status.id).icon} font-weight-bold`} />
                      {TransactionStatusTransformer(data.status.id).label}
                    </span>
                  } />

                {data.status.id === 'COMPLETE' && (
                  <Field
                    name="Acquirer Settlement"
                    description="Settlement status of the transaction from the merchant acquirer to bank account"
                    itemWidth={12}
                    data={<span>
                      <em className={`pr-1 icon ni ${externallySettled === true ? 'ni-flag' : !data?.settlement?.batch ? 'ni-alert' : 'ni-check'} font-weight-bold`} />
                      {externallySettled === true ? 'Externally Settled' : !data?.settlement?.batch ? 'Not Settled' : 'Settled'}
                    </span>} />
                )}

                {data.status.id === 'COMPLETE' && externallySettled === false && HasFeature(organisation.features, ['payouts']) && (
                  <Field
                    name="In Felloh Managed Trust"
                    description="Whether the funds are in the Felloh managed trust account"
                    itemWidth={12}
                    data={<span>
                      <em className={`pr-1 icon ni ${data?.settlement?.batch && data?.settlement?.batch?.found_in_felloh_managed_trust === true ? 'ni-check' : 'ni-alert'} font-weight-bold`} />
                      {data?.settlement?.batch && data?.settlement?.batch?.found_in_felloh_managed_trust === true ? 'Received' : 'Not Received'}
                    </span>} />
                )}

                {data.status.id === 'COMPLETE' && externallySettled === false && HasFeature(organisation.features, ['payouts']) && (
                  <Field
                    name="Disbursal Status"
                    description="Whether the transaction has been disbursed to the merchant bank account"
                    itemWidth={12}
                    data={<span>
                      <em className={`pr-1 icon ni ${!data.batch || data.batch.length === 0 || data.batch[0].completed_at === null ? 'ni-alert' : 'ni-check'} font-weight-bold`} />
                      {!data.batch || data.batch.length === 0 || data.batch[0].completed_at === null ? 'Not Disbursed' : 'Disbursed'}
                    </span>} />
                )}

                {data.status.id === 'COMPLETE' && externallySettled === false && (
                  <Field
                    name="Received in Bank Account"
                    description="Whether the settlement / disbursal has been received in your bank account"
                    itemWidth={12}
                    data={<BankStatus data={data} />} />
                )}
              </Row>
            </div>
          </Block>

          {data.booking && data.booking.id && (
            <React.Fragment>

              <div className="divider" />

              <div className="mb-3 mt-4 mt-xl-1">
                <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Booking</BlockTitle>
              </div>

              <Block>
                <div className="nk-data data-list data-list-s2">

                  <Row className="g-3 align-center">

                    <Field
                      name="Booking"
                      description="The booking that the transaction was processed for"
                      itemWidth={12}
                      data={
                        <Link className="text-violet-800 underline" to={`/booking/${data.booking.id}`}>{data.booking.booking_reference}</Link>
                      } />

                    <Field
                      name="Customer Name"
                      description="The name of the customer that the booking was made for"
                      itemWidth={12}
                      data={data.booking.customer_name} />

                    {data.assigned_at && (
                      <Field
                        name="Assigned to Booking at"
                        description="When the transaction was assigned to the booking"
                        itemWidth={12}
                        data={<span>{moment(data.assigned_at).format('HH:mm:ss')} on {moment(data.assigned_at).format('DD/MM/yy')}</span>} />
                    )}

                  </Row>

                </div>

              </Block>

            </React.Fragment>
          )}

          {data?.risk?.browser && data?.risk?.city && (
            <React.Fragment>

              <div className="divider" />

              <div className="mb-3 mt-4 mt-xl-1">
                <BlockTitle tag="h4" className="text-primary mb-0 text-xl font-bold">Risk Insights</BlockTitle>
              </div>

              <Block>
                <div className="nk-data data-list data-list-s2">

                  <Row className="g-3 align-center">

                    {data?.risk?.location.indexOf('coordinates') !== -1 && (
                      <MapContainer
                        center={[JSON.parse(data?.risk?.location).coordinates[1], JSON.parse(data?.risk?.location).coordinates[0]]}
                        zoom={9}
                        scrollWheelZoom={false}
                        zoomControl={false}
                        doubleClickZoom={false}
                        touchZoom={false}
                        boxZoom={false}
                        className="h-[250px] w-95 grayscale-[100%] mt-2 mx-auto opacity-90 rounded-md"
                      >
                        <TileLayer
                          attribution='&copy;'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                      </MapContainer>
                    )}

                    {data?.risk?.city && data?.risk?.region && (
                      <p className="px-1.5 mt-2">Payer IP address was geolocated to <strong className="text-slate-800">{data?.risk?.city}, {data?.risk.region}</strong><ReactCountryFlag countryCode={data?.risk.country_code} svg
                        style={{
                          width: '1.1em',
                          height: '1.1em',
                          marginTop: '-0.2em',
                          marginLeft: '0.3em',
                        }} /></p>
                    )}

                    <p className="px-1.5 mt-2">Their IP address was found on <strong className="text-slate-800">{data?.risk?.blocklists}</strong> IP warning lists and is using <strong className="text-slate-800">{data?.risk?.isp}</strong> as their internet service provider.</p>

                    <p className="px-1.5 mt-2">The payment was undertaken in <strong className="text-slate-800">{data?.risk?.browser}</strong> browser on <strong className="text-slate-800">{data?.risk?.os}</strong> OS.</p>
                  </Row>

                </div>

              </Block>

            </React.Fragment>
          )}

        </Col>

      </Row>

    </React.Fragment>
  );
};
export default PaymentInformation;
