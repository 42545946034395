import { takeLatest, put } from 'redux-saga/effects';

import { SET_ENUMS_DATA } from 'src/actions/enumsActions';
import { LOAD } from 'src/actions/applicationActions';
import Axios from "axios";

import API_ROUTES from "../../config/api";

function* getEnums() {
  const result = yield Axios(
    API_ROUTES.enums['enums:get'](),
    {
      method: 'get',
    }
  );

  yield put({ type: SET_ENUMS_DATA, payload: result.data.data });
}

export default function* sagas() {
  yield takeLatest(LOAD, getEnums);
}
