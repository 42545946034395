import React, {useCallback, useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import {Auth} from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import RoleGroup from './role-group';
import {useSelector} from "react-redux";

const UserRoles = ({ id, user }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const { organisation } = useSelector((state) => state);

  const roleFormatter = (roles) => {
    const response = {};

    roles.forEach((item) => {
      if (typeof response[item.group] === 'undefined') {
        response[item.group] = [];
      }

      response[item.group].push(item);
    });

    return response;
  };

  const getRoles = useCallback(async (triggerLoading = true) => {
    if (triggerLoading) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user["organisation:users:roles"](organisation.organisation, id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setRoles(roleFormatter(result.data.data));
    setLoading(false);
  }, [id, organisation.organisation]);

  useEffect(() => {
    getRoles(roles.length === 0);
  }, [getRoles]);

  return (
    <React.Fragment>
      <Head title="User Roles" />
      <Block>
        <div style={{ display: loading === true ? 'block' : 'none' }} className="mt-3">
          <Skeleton count={5} height="50px" className="mb-4" style={{
            width: '100%',
          }}/>
        </div>

        <div className="pt-2" style={{ display: loading === false ? 'block' : 'none' }}>
          <RoleGroup id={id} name="Acquirer" dataKey="acquirer" data={roles} />
          <RoleGroup id={id} name="Booking & Payments" dataKey="booking-and-payment" data={roles} />
          <RoleGroup id={id} name="Credit Notes" dataKey="credit-notes" data={roles} />
          <RoleGroup id={id} name="Developers" dataKey="developers" data={roles} />
          <RoleGroup id={id} name="Organisation" dataKey="organisation" data={roles} />
          <RoleGroup id={id} name="Financial" dataKey="financial" data={roles} />
          <RoleGroup id={id} name="Refunds" dataKey="refund" data={roles} />
          <RoleGroup id={id} name="Risk" dataKey="risk" data={roles} />
          <RoleGroup id={id} name="Users" dataKey="users" data={roles} />
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserRoles;
