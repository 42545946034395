import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ErrorBanner = () => {
  let query = useQuery();
  const error = query.get('error');
  const message = query.get('message');

  useEffect(() => {
    if (error === 'defined' && typeof message !== 'undefined') {
      toast.error("An error has occurred. Our team has been notified, we will investigate immediately and notify you by email once resolved.", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        style: { backgroundColor: '#f44336' },
      });
    }
  }, [error, message]);

  if (error === 'defined' && typeof message !== 'undefined') {
    return (
      <ToastContainer/>
    )
  } else {
    return <React.Fragment />
  }
}

export default ErrorBanner;
