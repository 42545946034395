/**
 * Calculate the transaction count
 * @param data
 * @returns {number}
 * @constructor
 */
const TransactionAllCount = (data) => {
  let count = 0;

  // Count number of transactions
  if (typeof data.transactions !== 'undefined') {
    count += data.transactions.length
  }

  // Add bank transactions
  if (data?.bank_transactions) {
    data?.bank_transactions.forEach((item) => {
      if (item.is_credit === true) {
        count += 1;
      }
    });
  }

  return count;
}

export default TransactionAllCount;
