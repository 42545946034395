import React, {useEffect, useState} from "react";
import Head from "src/layout/head/Head";
import {
  Block, BlockTitle, Button, Icon
} from "src/components/Component";
import moment from "moment";
import {Link} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import CurrencyFormat from 'src/transformer/currency-format';
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import TransactionStatusTransformer from "src/transformer/transaction-status";
import {useSelector} from "react-redux";
import {convertPenniesToDecimals} from "src/utils/money";
import HasFeature from "src/utils/has-feature";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import PiiObfuscator from "src/components/pii-obfuscator";

const BookingTransactions = ({ data, loading, triggerReload }) => {
  const [matchingTransactions, setMatchingTransactions] = useState([]);
  const [loadingMatching, setLoadingMatching] = useState(true);
  const [allLoading, setAllLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const { enums } = useSelector((state) => state.enums);
  const { organisation } = useSelector((state) => state);

  const findMatching = async () => {
    if (HasFeature(organisation.features, ['aisp'])) {
      setLoadingMatching(true);
      const user = await Auth.currentAuthenticatedUser();

      const matching = await Axios(
        API_ROUTES.bank['transaction:search'](),
        {
          method: 'post',
          data: {
            organisation: organisation.organisation,
            keyword: data.booking_reference,
            is_debit: false,
            is_credit: true,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setMatchingTransactions(matching.data.data);
      setLoadingMatching(false);
    }
  };

  const joinTransactions = (data) => {
    let txs = [...data?.transactions];
    let btxs = [...data?.bank_transactions];

    if (btxs && btxs.length >= 1) {
      data?.bank_transactions.forEach((item) => {
        if (item.is_credit === true) {
          txs.push({
            id: item.id,
            amount: item.amount,
            currency: 'GBX',
            method: {id: 'BANK'},
            type: {id: 'BANK'},
            status: {id: 'COMPLETE'},
            created_at: item.posting_date,
          });
        }
      });
    }

    if (txs && txs.length >= 1) {
      txs.sort((a,b) =>{
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    }

    setTransactions(txs);
  }

  const addToBooking = async (item) => {
    setAllLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.bank['transaction:add-to-booking'](item.id, data.id),
      {
        method: 'put',
        data: {
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
  };

  const unlinkFromBooking = async (transactionID) => {
    setAllLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.bank['transaction:delete-from-booking'](transactionID, data.id),
      {
        method: 'post',
        data: {
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    triggerReload();
  };

  useEffect(() => {
    if (loading === false) {
      joinTransactions(data);
      findMatching();
      setAllLoading(false);
    }
  }, [loading]);


  let paymentLinks = data?.payment_links;

  if (paymentLinks && paymentLinks.length >= 1) {
    paymentLinks.sort((a,b) =>{
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  const titleClass = 'text-primary mb-0 text-xl font-bold';
  const descriptionClass = 'text-secondary text-sm';

  return (
    <React.Fragment>
      <Head title="Booking Payments" />

      <div className="mb-3">
        <BlockTitle tag="h4" className={titleClass}>Payment Links</BlockTitle>
        <p className={descriptionClass}>Payment links that have been linked to the booking.</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">Created At</th>
            <th scope="col" className="text-center border-left">Expires At</th>
            <th scope="col" className="text-center border-left">Amount</th>
            <th scope="col" className="text-center border-left">Status</th>
            <th scope="col" className="text-center border-left pr-0 pl-0">Actions</th>
          </tr>
          </thead>
          <tbody>
          { (loading === true || allLoading === true) && [0, 1].map((item) => (
            <tr className='tb-tnx-item' key={item}>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && allLoading === false && paymentLinks && paymentLinks.length === 0 && (
            <tr className='tb-tnx-item'>
              <td colSpan={6} className="text-center">
                <strong>No payment have been made for this booking</strong>
              </td>
            </tr>
          )}
          { loading === false && allLoading === false && paymentLinks && paymentLinks.map((paymentLink, key) => {
            return (
              <tr className='tb-tnx-item' key={key}>
                <td>{ moment(paymentLink.created_at).format('Do MMMM YYYY, h:mm:ss a') }</td>
                <td className="text-center border-left">{ moment(paymentLink.expires_at).format('Do MMMM YYYY, h:mm:ss a') }</td>
                <td className="text-center border-left">
                  { CurrencyFormat(enums.currency, paymentLink.currency).format(convertPenniesToDecimals(paymentLink.amount) ) }
                </td>
                <td className="text-center border-left" style={{ textTransform: 'capitalize' }}>{ paymentLink.status }</td>
                <td className="border-left text-center" style={{ width: '75px' }}>
                  { paymentLink.status === 'active' && (
                    <Link
                      to={`/payment-links/${paymentLink.id}`}
                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                    >
                      <Icon name="info" />
                    </Link>
                  )}
                  {
                    paymentLink.status !== 'active' && (
                      <React.Fragment>---</React.Fragment>
                    )
                  }
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Block>

      <div className="mb-3 mt-5">
        <BlockTitle tag="h4" className={titleClass}>Payments</BlockTitle>
        <p className={descriptionClass}>Customer payments that have been linked to the booking.</p>
      </div>

      <Block>
        <table className="table table-tranx border-light card-bordered">
          <thead>
          <tr className='tb-tnx-item'>
            <th scope="col">Time</th>
            <th scope="col" className="text-center border-left">Amount</th>
            <th scope="col" className="text-center border-left">Method</th>
            <th scope="col" className="text-center">Type</th>
            <th scope="col" className="text-center">Status</th>
            <th scope="col" className="text-center border-left pr-0 pl-0">Actions</th>
          </tr>
          </thead>
          <tbody>
          { (loading === true || allLoading === true) && [0, 1].map((item) => (
            <tr className='tb-tnx-item' key={item}>
              <td colSpan={6}>
                <Skeleton count={1} height="40px" />
              </td>
            </tr>
          ))}
          { loading === false && allLoading === false && transactions && transactions.length === 0 && (
            <tr className='tb-tnx-item'>
              <td colSpan={6} className="text-center">
                <strong>No payments have been made on this booking</strong>
              </td>
            </tr>
          )}
          { loading === false && allLoading === false && transactions && transactions.map((transaction, key) => {
            const method = TransactionMethodTransformer(transaction.method.id);
            const type = TransactionTypeTransformer(transaction.type.id);
            const status = TransactionStatusTransformer(transaction.status.id);

            return (
              <tr className='tb-tnx-item' key={key}>
                <td>{ moment(transaction.created_at).format('Do MMMM YYYY, h:mm:ss a') }</td>
                <td className="text-center border-left">
                  { CurrencyFormat(enums.currency, transaction.currency).format(convertPenniesToDecimals(transaction.amount) ) }
                </td>
                <td className="text-center border-left w-150px">
                  <Icon name={`ni text-primary icon ${type.icon} pr-1`} />
                  <span className="date">{ type.label }</span>
                </td>
                <td className="text-center w-150px">
                  <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
                  <span className="date">{ method.label }</span>
                </td>
                <td className="text-center w-150px">
                  <Icon name={`ni text-primary icon ${status.icon} pr-1`} />
                  <span className="date">{ status.label }</span>
                </td>
                <td className="border-left text-center pl-2 pr-2" style={{ width: '75px' }}>
                  { transaction.type.id !== 'BANK' && (
                      <ul className="nk-tb-actions gx-1 center">
                        <li>
                          <Link
                            to={`/transaction/${transaction.id}`}
                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                          >
                            <Icon name="info" />
                          </Link>
                        </li>
                      </ul>
                  )}
                  { transaction.type.id === 'BANK' && (
                    <Button className="btn btn-sm btn-primary btn-green text-center" onClick={() => unlinkFromBooking(transaction.id)}>
                      <Icon className="pr-1" name="unlink" /> Unlink
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Block>

      { HasFeature(organisation.features, ['aisp']) && (
        <React.Fragment>
          <div className="mb-3 mt-5">
            <BlockTitle tag="h4" className={titleClass}>Potential Matches</BlockTitle>
            <p className={descriptionClass}>Payments from your bank account(s) that look like they match this booking</p>
          </div>

          <Block>
            <table className="table table-tranx border-light card-bordered">
              <thead>
              <tr className='tb-tnx-item'>
                <th scope="col">Posting Date</th>
                <th scope="col" className="text-center border-left">Flow</th>
                <th scope="col" className="text-center border-left">Type</th>
                <th scope="col" className="text-center border-left">Amount</th>
                <th scope="col" className="text-center border-left">Description</th>
                <th scope="col" className="text-center border-left pr-0 pl-0">Actions</th>
              </tr>
              </thead>
              <tbody>
              { (loadingMatching === true || allLoading === true) && [0, 1].map((key) => (
                <tr className='tb-tnx-item' key={key}>
                  <td colSpan={6}>
                    <Skeleton count={1} height="40px" />
                  </td>
                </tr>
              ))}
              { loadingMatching === false && allLoading === false && matchingTransactions.length === 0 && (
                <tr className='tb-tnx-item'>
                  <td colSpan={6} className="text-center">
                    <strong>No payments found that match this booking</strong>
                  </td>
                </tr>
              )}
              { loadingMatching === false && allLoading === false && matchingTransactions.map((item, key) => (
                <React.Fragment>
                  <tr className='tb-tnx-item' key={key}>
                    <td>{ moment(item.posting_date).format('Do MMMM YYYY') }</td>
                    <td className="text-center border-left">Credit</td>
                    <td className="text-center border-left">{ item.type }</td>
                    <td className="text-center border-left">{ CurrencyFormat().format(convertPenniesToDecimals( item.amount )) }</td>
                    <td className="text-center border-left">
                      <PiiObfuscator className="title" type="bank_description">{ item.description }</PiiObfuscator>
                    </td>
                    <td className="text-center border-left p-0">
                      <Button className="btn btn-sm btn-primary btn-green text-center" onClick={() => addToBooking(item)}>
                        <Icon className="pr-1" name="link-h" /> Link to Booking
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              </tbody>
            </table>
          </Block>

        </React.Fragment>
      )}

    </React.Fragment>
  );
};
export default BookingTransactions;
