import React from 'react';
import { Alert } from 'reactstrap';

const UnpaidInvoiceBanner = ({ organisation }) => {
  if (organisation.unpaid_invoice === true) {
    return (
      <Alert color="danger" className="alert-fill">
        <span style={{ fontSize: '1.15em' }}>
          <strong>Your invoice is past due</strong> - Your access to the felloh dashboard is limited to taking payments.
        </span>
      </Alert>
    )
  }

  return (
    <React.Fragment />
  );
}

export default UnpaidInvoiceBanner;
