import React, { useId, useEffect } from "react";
import { CardBody, CardHeader } from "reactstrap";
import { Icon } from "../../../Component";
import { Chart } from "chart.js/auto";
import moment from 'moment';
import Skeleton from "react-loading-skeleton";

const SaleRevenue = ({ data, loading }) => {
  const id = useId();

  useEffect(() => {
    if (loading === false) {
      var config = {
        type: "line",
        data: {
          labels: data.year_breakdown.map((item) => moment(new Date(item.txn_month)).format('MMMM')),
          dataUnit: "GBP",
          stacked: true,
          datasets: [
            {
              label: "Bookings",
              color: "#798bff",
              fill: true,
              backgroundColor: "rgba(74,18,124,0)",
              borderColor: "#670199",
              barPercentage: 1,
              categoryPercentage: 0.1,
              borderWidth: 2,
              lineTension: 0.1,
              pointRadius: false,
              data: data.year_breakdown.map((item) => item.monthly_sum),
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            backgroundColor: "#eff6ff",
            titleFontSize: 13,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              display: true,
              ticks: {
                beginAtZero: true,
                fontSize: 11,
                fontColor: "#9eaecf",
                padding: 10,
                min: 0,
                stepSize: 5000,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
            x: {
              display: true,
              ticks: {
                fontSize: 9,
                fontColor: '#9eaecf',
                source: "auto",
                padding: 10,
                display: true,
                autoSkip: true,
                maxTicksLimit: 4,
                maxRotation: 0
              },
              grid: {
                drawBorder: false,
              },
              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
              },
            },
          },
        },
      };

      var ctx = document.getElementById(id).getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="flex flex-col rounded-md border animate-in fade-in-50 mt-2">
        <CardHeader className="bg-gray-50 mb-2 text-purple-900">
          <h2 className="text-xl text-purple-900">
            Bookings <span className="font-bold">Overview</span>
          </h2>
        </CardHeader>


        <CardBody className="card-inner pt-0">
          <div className="text-center mb-1 fs-20px w-25 float-left pr-4">
            <Skeleton count={1} height="50px" />
          </div>
          <div className="text-center mb-1 fs-20px w-25 float-left pr-4">
            <Skeleton count={1} height="50px" />
          </div>
          <div className="text-center mb-1 fs-20px w-25 float-left">
            <Skeleton count={1} height="50px" />
          </div>
          <div className="text-center fs-20px w-100">
            <Skeleton count={1} height="200px" />
          </div>
        </CardBody>
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-md border animate-in fade-in-50 mt-2">
      <CardHeader className="bg-gray-50 mb-2 text-purple-900">
        <div className="float-right text-sm pt-0.5">
          <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-sm text-xs font-medium bg-purple-100 text-purple-800">Month on Month</span>
        </div>
        <h2 className="text-xl text-purple-900">
          Bookings <span className="font-bold">Overview</span>
        </h2>
      </CardHeader>

      <CardBody className="card-inner pt-0">
        <div className="nk-sale-data-group flex-md-nowrap g-4 w-100">
          <div className="nk-sale-data" stlye={{ minWidth: '125px' }}>
            <span className="amount">
              {data.day}{" "}
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                4.26%
              </span>
            </span>
            <span className="sub-title">Today</span>
          </div>
          <div className="nk-sale-data" stlye={{ minWidth: '125px' }}>
            <span className="amount">
              {data.week}{" "}
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                4.26%
              </span>
            </span>
            <span className="sub-title">This Week</span>
          </div>
          <div className="nk-sale-data" stlye={{ minWidth: '125px' }}>
            <span className="amount">
              {data.month}{" "}
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                4.26%
              </span>
            </span>
            <span className="sub-title">This Month</span>
          </div>
        </div>
        <div className="pt-4 grow max-h-[250px]">
          <canvas id={id}></canvas>
        </div>
      </CardBody>
    </div>
  );
};
export default SaleRevenue;
