import React from 'react';
import {Row, Card, CardImg, CardBody, CardTitle, CardText, Button, Col, Badge, CardFooter} from 'reactstrap';
import { Icon } from 'src/components/Component';
import Head from 'src/layout/head/Head';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import WebhooksLogo from './images/webhooks.png'
import NuapayLogo from './images/nuapay.png'
import NuveiLogo from './images/nuvei.png'
import TotalProcessingLogo from './images/total-processing.png'
import StripeLogo from './images/stripe.png'
import APILogo from './images/api.png'
import OpenBankingLogo from './images/open-banking.png'

const Integrations = ({ ...props }) => {
  const { organisation } = useSelector((state) => state);
  return (
    <React.Fragment>
      
      <Head title="Integrations" />

      <Row className="mb-5 ml-1 mr-1 mt-2">
        <h2 className="nk-block-title page-title text-primary">Payment <span className="fw-normal">Integrations</span></h2>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row>

          <Col md="12" lg="4" xl="3">
            <Card className="card-bordered integration-item">
              <div className='image-container'>
                <div className='badges'>
                  <Badge color="white">Open Banking Payments</Badge>
                </div>
                <CardImg top src={ NuapayLogo } alt="Nuapay logo" />
              </div>
              <CardBody className="card-inner pt-0">
                <CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Nuapay</CardTitle>

                <CardText>Take payments using open banking</CardText>
              </CardBody>
              <CardFooter>
                {organisation?.integrations?.payments?.indexOf('NUAPAY') > -1 && (
                  <Button className='btn-primary btn-block w-100 text-center'>
                    <Icon name="setting"/>Manage
                  </Button>
                )}
                {organisation?.integrations?.payments?.indexOf('NUAPAY') === -1 && (
                  <Button className='btn-green btn-block w-100 text-center'>
                    <Icon name="setting"/>Add
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>

          <Col md="12" lg="4" xl="3">
            <Card className="card-bordered integration-item">
              <div className='image-container'>
                <div className='badges'>
                  <Badge color="white">Card Processing</Badge>
                </div>
                <CardImg top src={ NuveiLogo } alt="total processing logo" />
              </div>
              <CardBody className="card-inner pt-0">
                <CardTitle CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Nuvei</CardTitle>
                <CardText>Take payments via credit/debit card</CardText>
              </CardBody>
              <CardFooter>
              {organisation?.integrations?.payments?.indexOf('NUVEI') > -1 && (
                  <Button className='btn-primary btn-block w-100 text-center'>
                    <Icon name="setting"/>Manage
                  </Button>
                )}
                {organisation?.integrations?.payments?.indexOf('NUVEI') === -1 && (
                  <Button className='btn-green btn-block w-100 text-center'>
                    <Icon name="setting"/>Add
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>

          <Col md="12" lg="4" xl="3">

            <Card className="card-bordered integration-item">
              <div className='image-container'>
                <div className='badges'>
                  <Badge color="white">Card Processing</Badge>
                </div>
                <CardImg top src={ StripeLogo } alt="total processing logo" />
              </div>
              <CardBody className="card-inner pt-0">
                <CardTitle CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Stripe</CardTitle>
                <CardText>Take payments via credit/debit card</CardText>
              </CardBody>
              <CardFooter>
                {organisation?.integrations?.payments?.indexOf('STRIPE') > -1 && (
                  <Button className='btn-primary btn-block w-100 text-center'>
                    <Icon name="setting"/>Manage
                  </Button>
                )}
                {organisation?.integrations?.payments?.indexOf('STRIPE') === -1 && (
                  <Button className='btn-green btn-block w-100 text-center'>
                    <Icon name="setting"/>Add
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>

          <Col md="12" lg="4" xl="3">
            <Card className="card-bordered integration-item">
              <div className='image-container'>
                <div className='badges'>
                  <Badge color="white">Card Processing</Badge>
                </div>
                <CardImg top src={ TotalProcessingLogo } alt="stripe logo" />
              </div>
              <CardBody className="card-inner pt-0">
                <CardTitle CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Total Processing</CardTitle>
                <CardText>Take payments via credit/debit card</CardText>
              </CardBody>
              <CardFooter>
              {organisation?.integrations?.payments?.indexOf('TOTAL_PROCESSING') > -1 && (
                  <Button className='btn-primary btn-block w-100 text-center'>
                    <Icon name="setting"/>Manage
                  </Button>
                )}
                {organisation?.integrations?.payments?.indexOf('TOTAL_PROCESSING') === -1 && (
                  <Button className='btn-green btn-block w-100 text-center'>
                    <Icon name="setting"/>Add
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Col>

          </Row>

        </Col>
      </Row>

      <Row className="mb-5 ml-1 mr-1 mt-5">
        <h2 className="nk-block-title page-title text-primary">Accounting & Finance <span className="fw-normal">Integrations</span></h2>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row>

          <Col md="12" lg="4" xl="3">
            <Card className="card-bordered integration-item">
              <div className='image-container'>
                <div className='badges'>
                  <Badge color="white">Open Banking Integration (AISP)</Badge>
                </div>
                <CardImg top src={ OpenBankingLogo } alt="Open Banking logo" />
              </div>
              <CardBody className="card-inner pt-0">
                <CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Banking Integration</CardTitle>
                <CardText>Link your bank account, for easy end to end reconcilliation of payments</CardText>
              </CardBody>
              <CardFooter>
                <Button className='btn-green btn-block w-100 text-center'>
                    <Icon name="plus"/>Add
                </Button>       
              </CardFooter>
            </Card>
          </Col>

          </Row>

        </Col>

      </Row>

      <Row className="mb-5 ml-1 mr-1 mt-5">
        <h2 className="nk-block-title page-title text-primary">Other <span className="fw-normal">Integrations</span></h2>
      </Row>

      <Row>
        <Col className="ml-1 mr-1">
          <Row className='d-flex'>

          <Col md="12" lg="3" xl="3" className='d-flex'>
              <Card className="card-bordered integration-item flex-fill">
                <div className='image-container'>
                  <CardImg top src={ APILogo } alt="API logo" />
                </div>
                <CardBody className="card-inner pt-0">
                  <CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Felloh API</CardTitle>

                  <CardText>Programatically access the Felloh API's</CardText>

                </CardBody>
                <CardFooter>
                  <Row>

                    <Col sm="12" lg="6">
                      <Link to='/developers'>
                        <Button className='btn-primary btn-block w-100 text-center'>
                          <Icon name="setting"/>Manage
                        </Button>
                      </Link>
                    </Col>

                    <Col sm="12" lg="6">
                      <a href='https://developers.felloh.com' target='__blank'>
                        <Button className='btn-secondary btn-block w-100 text-center'>
                          <Icon name="setting"/>Documentation
                        </Button>
                      </a>
                    </Col>

                  </Row>
                </CardFooter>
              </Card>
            </Col>

            <Col md="12" lg="3" xl="3" className='d-flex'>
              <Card className="card-bordered integration-item flex-fill">
                <div className='image-container'>
                  <CardImg top src={ WebhooksLogo } alt="Webhooks logo" />
                </div>
                <CardBody className="card-inner pt-0">
                  <CardTitle tag="h5" className='text-xl text-purple-900 font-bold'>Webhooks</CardTitle>

                  <CardText>Receive webhooks when changes happen within the felloh ecosystem</CardText>


                </CardBody>
                <CardFooter>
                  <Row>

                    <Col sm="12" lg="6">
                      <Link to='/developers'>
                        <Button className='btn-primary btn-block w-100 text-center'>
                          <Icon name="setting"/>Manage
                        </Button>
                      </Link>
                    </Col>

                    <Col sm="12" lg="6">
                      <a href='https://developers.felloh.com/webhooks' target='__blank'>
                        <Button className='btn-secondary btn-block w-100 text-center'>
                          <Icon name="setting"/>Documentation
                        </Button>
                      </a>
                    </Col>

                  </Row>
                </CardFooter>
              </Card>
            </Col>

          </Row>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Integrations;
