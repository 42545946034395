import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import { CardHeader } from 'reactstrap';

import UserRoles from './roles';
import UserDetail from './detail';
import UserOrganisations from './organisations';

import { API_ROUTES } from 'src/config/api';
import {
  BlockTitle,
  Icon, Button,
} from 'src/components/Component';
import { Auth } from 'aws-amplify';
import Axios from 'axios';

import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import UserActions from "./actions";
import PiiObfuscator from "src/components/pii-obfuscator";

const UserProfileLayout = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, doRefresh] = useState(0);

  const { id } = useParams();

  const refreshUsers = () => {
    doRefresh(refresh + 1)
  }

  const getUser = useCallback(async (showLoading = false) => {
    if (showLoading === true) {
      setLoading(true);
    }

    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.user['user:get'](id),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setLoading(false);
    setUser(result.data.data);
  }, [id]);

  useEffect(() => {
    getUser(true);
  }, [refresh]);

  return (
    <div className="flex min-h-[400px] flex-col rounded-md border animate-in fade-in-50">

      <CardHeader className="bg-gray-50 mb-0 text-purple-900">
        <div className="float-right">
          {user.is_api_user === false && (
            <UserActions user={user} loading={loading} refreshUsers={refreshUsers} />
          )}
          {user.is_api_user === true && (
            <Link to={'/developers'}>
              <Button color="light" outline className="btn-white">
                <Icon name="back-ios" />
                <span>Back</span>
              </Button>
            </Link>
          )}
        </div>
        <h2 className="text-xl text-purple-900">
          {
            !user.id && (
              <Skeleton count={1} height="32px" className="p0 m0" style={{
                width: '450px',
                maxWidth: '100%',
              }} />
            )
          }
          {
            user.id && (
              <BlockTitle className="text-primary text-2xl pt-0.5">
                {user.is_api_user === false ? (
                  <PiiObfuscator type="customer_name">{user.first_name} <strong>{user.last_name}</strong></PiiObfuscator>
                ) : <React.Fragment>
                  API Key <strong>Permissions</strong>
                </React.Fragment>}
              </BlockTitle>
            )
          }
        </h2>
      </CardHeader>

      {user.is_api_user === false && (
        <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
          <li
            className={`nav-item ${window.location.pathname === `/organisation/user/${id}`
              ? "active current-page"
              : ""
              } `}
          >
            <Link
              to={`/organisation/user/${id}`}
              className={`nav-link
                    ${window.location.pathname === `/organisation/user/${id}` ? "active" : ""}
                  `}
            >
              <Icon name="info-i" />
              <span>Account</span>
            </Link>
          </li>
          <li
            className={`nav-item ${window.location.pathname === `/organisation/user/${id}/roles`
              ? "active current-page"
              : ""
              } `}
          >
            <Link
              to={`/organisation/user/${id}/roles`}
              className={`nav-link
                    ${window.location.pathname === `/organisation/user/${id}/roles` ? "active" : ""}
                  `}
            >
              <Icon name="user-list" />
              <span>Roles</span>
            </Link>
          </li>
          <li
            className={`nav-item ${window.location.pathname === `/organisation/user/${id}/organisations` ? "active" : ""
              }`}
          >
            <Link
              to={`/organisation/user/${id}/organisations`}
              className={`nav-link ${window.location.pathname === `/organisation/user/${id}/organisations` ? "active" : ""
                }`}
            >
              <Icon name="building" />
              <span>Organisation Membership</span>
            </Link>
          </li>
        </ul>
      )}

      <div className="px-4 py-2">
        <Switch>
          <Route
            exact
            path={`/organisation/user/:id/`}
            render={() => <UserDetail id={id} user={user} parentLoading={loading} />}
          />
          <Route
            exact
            path={`/organisation/user/:id/roles`}
            render={() => <UserRoles id={id} user={user} />}
          />
          <Route
            exact
            path={`/organisation/user/:id/organisations`}
            render={() => <UserOrganisations id={id} user={user} parentLoading={loading} />}
          />
        </Switch>
      </div>

    </div>
  );
};

export default UserProfileLayout;
