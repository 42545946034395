import React, {useCallback, useState} from 'react';
import {Button, Icon} from 'src/components/Component';
import {Alert, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {Auth} from "aws-amplify";
import Axios from "axios";
import API_ROUTES from "src/config/api";
import { Link } from 'react-router-dom';
import AsyncSelect from "react-select/async-creatable";
import {useSelector} from "react-redux";

const CreditNoteAssignButton = ({ id, triggerReload }) => {
  const { organisation } = useSelector((state) => state);
  const [modalForm, setModalForm] = useState(false);
  const [modalFormProcessing, setModalFormProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [ formState, setFormState ] = useState({
    showSelectDropdown: false,
    asyncSelectLoaded: false,
  });
  const [ formValues, setFormValues ] = useState({
    bookingField: null,
    booking: '',
    booking_id: null,
  });

  const toggleForm = (override) => setModalForm(override === true ? override : !modalForm);

  const reassign = async () => {
    setErrors({});
    setModalFormProcessing(true);
    const user = await Auth.currentAuthenticatedUser();

    try {
      await Axios(
        API_ROUTES.agent['credit-note:assign'](id),
        {
          method: 'post',
          data: {
            booking_id: formValues.booking_id,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      setModalFormProcessing(false);

      triggerReload();
      toggleForm();
    } catch(error) {
      setModalFormProcessing(false);
      setErrors({
        booking: error.response.data.errors[0].message,
      });
    }
  };

  /**
   * Find bookings using the API
   * @type {function(*): *}
   */
  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFormState({...formState, asyncSelectLoaded: true});
    return result.data.data;
  }, []);

  /**
   * Populate the booking related fields on booking select
   * @param value
   * @returns {Promise<void>}
   */
  const populateBookingDetails = async (value) => {
    if (value !== null) {
      setFormState({ ...formState, asyncSelectLoaded: false });

      const valuesToSet = {
        ...formValues,
        bookingField: value,
        booking: value.booking_reference,
        booking_id: value.id,
      };

      setFormValues(valuesToSet);
    }
  }

  return (
    <React.Fragment>
      <Link to="#" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); toggleForm() }}>
        Assign to booking
      </Link>

      <Modal isOpen={modalForm} toggle={() => toggleForm()}>
        <ModalHeader
          toggle={toggleForm}
          className="bg-secondary text-white"
          close={
            <button className="close" onClick={() => toggleForm()}>
              <Icon name="cross" className="text-white" />
            </button>
          }
        >
          Assign Credit Note to Booking
        </ModalHeader>
        <ModalBody>
          {errors.booking && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> { errors.booking }
              </Alert>
            </div>
          )}
          <p>Please enter the booking reference that you wish to assign the credit note to</p>
          <FormGroup>
            <div className="form-control-wrap">
              <div className="form-control-wrap">
                <AsyncSelect
                  isClearable
                  isValidNewOption={() => false}
                  className={`form-control form-control-xl p-0 border-0 indicator-hidden`}
                  allowCreateWhileLoading={false}
                  value={ formValues.bookingField }
                  isMulti={false}
                  getOptionLabel={e => {
                    return `${e.booking_reference} | ${e.customer_name}`;
                  }}
                  getOptionValue={e => e.id}
                  loadOptions={findBookings}
                  onChange={populateBookingDetails}
                  menuIsOpen={formState.asyncSelectLoaded === true}
                  placeholder=""
                  classNamePrefix="react-select"
                  createOptionPosition="first"
                  isDisabled={modalFormProcessing}
                />
              </div>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button color="danger" type="submit" disabled={modalFormProcessing || formValues.booking_id === null} onClick={
              (ev) => {
                ev.preventDefault();
                reassign();
              }
            } size="lg">
              {modalFormProcessing ? <Spinner size="sm" color="light"> </Spinner> : 'Continue'}
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default CreditNoteAssignButton;
