import React from 'react';
import {Row, Col, FormGroup, Card, Form, Spinner, Alert} from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Content from 'src/layout/content/Content';
import Head from 'src/layout/head/Head';
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button, Icon,
} from 'src/components/Component';
import {Auth} from 'aws-amplify';
import Axios from 'axios';
import API_ROUTES from 'src/config/api';
import { useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";

const SupplierCreate = ({ ...props }) => {
  const history = useHistory();
  const { organisation } = useSelector((state) => state);

  return (
    <React.Fragment>
        <Head title="Form Layouts" />
        <Content>
            <Card className="card-bordered p-5">
                <BlockHead size="lg" wide="sm" className="p-0">
                    <BlockHeadContent>
                        <BlockTitle className="text-primary" page>Create a New Supplier</BlockTitle>
                    </BlockHeadContent>
                </BlockHead>

                <div className="nk-divider divider md pb-4" />

                <Block size="lg">
                    <Formik
                      initialValues={{
                          name: '',
                      }}
                      validationSchema={Yup.object().shape({
                          name: Yup.string()
                            .min(3)
                            .max(255)
                            .required('Supplier name is required')
                      })}
                      onSubmit={async (values, { setSubmitting, setErrors }) => {
                        const user = await Auth.currentAuthenticatedUser();

                        try {
                          await Axios(
                            API_ROUTES.agent['supplier:create'](),
                            {
                              method: 'post',
                              data: {
                                supplier_name: values.name,
                                organisation: organisation.organisation,
                              },
                              headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
                            }
                          );
                          setSubmitting(false);
                          history.push(`/suppliers`);
                        } catch(error) {
                          setErrors({ general: 'An error has occurred, a member of our team has been notified. Please try again later.' });
                        }
                      }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => {
                            return (
                              <Form className="gy-3"  onSubmit={handleSubmit}>
                                {errors.general && (
                                  <div className="mb-3">
                                    <Alert color="danger" className="alert-icon">
                                      {" "}
                                      <Icon name="alert-circle" /> { errors.general }
                                    </Alert>
                                  </div>
                                )}
                                  <Row className="g-3 align-center">
                                      <Col lg="5">
                                          <FormGroup>
                                              <label className="form-label">Supplier Name</label>
                                              <span className="form-note">The name of the supplier</span>
                                          </FormGroup>
                                      </Col>
                                      <Col lg="7">
                                          <FormGroup>
                                              <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={values.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control form-control-xl"
                                                  />
                                                  {touched.name && errors.name && <span className="invalid">{errors.name}</span>}
                                              </div>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  <Row className="g-3">
                                      <Col lg="7" className="offset-lg-5">
                                          <FormGroup>
                                              <Button
                                                type="submit"
                                                color="primary"
                                                size="lg"
                                                className="float-right bg-violet-800 text-white border-none"
                                                disabled={Object.keys(errors).length >= 1}
                                              >
                                                  {isSubmitting ? <Spinner size="sm" color="light"> </Spinner> : 'Submit'}
                                              </Button>
                                          </FormGroup>
                                      </Col>
                                  </Row>
                              </Form>
                            );
                        }}
                    </Formik>
                </Block>
            </Card>
        </Content>
    </React.Fragment>

  );
};

export default SupplierCreate;
