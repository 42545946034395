const TransactionMethodTransformer = (type) => {
  if (type === 'MOTO_OTHER' || type === 'MOTO_IN_PERSON') {
    return {
      icon: 'ni-user',
      label: 'Moto',
    };
  }

  if (type === 'BANK') {
    return {
      icon: 'ni-building',
      label: 'Bank transfer',
    }
  }

  if (type === 'ONLINE') {
    return {
      icon: 'ni-browser',
      label: 'Online',
    }
  }

  return {
    icon: 'ni-question',
    label: type
  };
}

export default TransactionMethodTransformer;
