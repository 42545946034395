import React, { useEffect, useState } from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  Icon,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import moment from "moment";
import { RSelect } from 'src/components/Component';
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { API_ROUTES } from 'src/config/api';
import DeleteModal from "./delete";

const OrganisationInformation = ({ organisation, parentLoading, getOrganisation }) => {
  const state = useSelector((state) => state);

  const [updating, setUpdating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newParent, setNewParent] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    const organisations = state.organisation.organisations;

    let options = organisations.map((item) => ({ value: item.id, label: `${item.name} | ${item.id}` }));
    options.sort((a, b) => a.label.localeCompare(b.label));

    options.forEach(item => {
      if (typeof organisation.parent !== 'undefined' && organisation.parent && item.value === organisation.parent.id) {
        setNewParent(item);
      }
    });

    setDropdownOptions(options)
  }, [state.organisation.organisations, state.organisation.organisation, organisation]);

  const changeParentOrganisation = async (value) => setNewParent(value);

  const saveParent = async (e) => {
    e.preventDefault();

    setUpdating(true);

    const user = await Auth.currentAuthenticatedUser();

    await Axios(
      API_ROUTES.user['organisation:update'](organisation.id),
      {
        method: 'post',
        data: {
          parent: newParent.value,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    getOrganisation();
    setUpdating(false);
    setEditing(false);
  };

  return (
    <React.Fragment>
      <div className="card-inner card-inner-lg">
        <Head title="Organisation Detail" />
        <Block>
          <div className="nk-data data-list data-list-s2">
            <div style={{ display: parentLoading === true ? 'block' : 'none' }} className="mt-3">
              <Skeleton count={3} height="50px" className="mb-4" style={{
                width: '100%',
              }} />
            </div>

            <div style={{ display: parentLoading === false ? 'block' : 'none' }}>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label"><strong>Organisation ID</strong></span>
                  <span className="data-value">{organisation.id}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more disable">
                    <Icon name="lock-alt" />
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label"><strong>Created On</strong></span>
                  <span className="data-value">{moment(organisation.created_at).format('Do MMMM YYYY')}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more disable">
                    <Icon name="lock-alt" />
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label"><strong>Parent Account</strong></span>
                  {
                    editing === false && (
                      <span className="data-value">{organisation.parent ? `${organisation.parent.name} | ${organisation.parent.id}` : 'NONE'}</span>
                    )
                  }
                  {
                    editing === true && (
                      <React.Fragment>
                        <RSelect
                          isClearable
                          name="parent"
                          id="parent"
                          className="form-control form-control-xl p-0 border-0 indicator-hidden"
                          value={newParent}
                          options={dropdownOptions}
                          onChange={changeParentOrganisation}
                          placeholder=""
                          classNamePrefix="react-select"
                        />
                      </React.Fragment>
                    )
                  }
                </div>
                <div className="data-col data-col-end">
                  {
                    editing === false && (
                      <React.Fragment>
                        <span className="data-more" onClick={() => { setEditing(true) }}>
                          <Icon name="pen2" />
                        </span>
                      </React.Fragment>
                    )
                  }
                  {
                    editing === true && (
                      <React.Fragment>
                        <Button onClick={saveParent} disabled={organisation?.parent?.id === newParent?.value || updating === true} color="primary">Save</Button>
                      </React.Fragment>
                    )
                  }
                </div>
              </div>
            </div>

          </div>
        </Block>
      </div>
      {parentLoading === false && organisation?.id !== 'X1234' && (
        <Block className="bg-danger text-white px-10 py-4">
          <span className="float-left"><strong className="text-xl">Archive Organisation</strong><br /> Archive the merchant account and prevent users from logging into the organisation</span>
          <span className="float-right pt-1.5">
            <DeleteModal organisation={organisation} />
          </span>
        </Block>
      )}
    </React.Fragment>
  );
};
export default OrganisationInformation;
