import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import applicationReducer from './applicationReducer';
import enumsReducer from './enumsReducer';
import organisationReducer from './organisationReducer';

export default combineReducers({
  account: accountReducer,
  application: applicationReducer,
  enums: enumsReducer,
  organisation: organisationReducer,
});
