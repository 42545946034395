export const LOAD = '@application/load';
export const SET_RUNNING_VERSION = '@application/set-running-version';
export const SET_LATEST_VERSION = '@application/set-latest-version';
export const CHECK_NEW_VERSION = '@application/check-new-version';
export const POLL_LATEST_VERSION = '@application/poll-latest-version';

export function triggerLoad() {
  return (dispatch) => dispatch({
    type: LOAD,
    payload: {}
  });
}

export function setRunningVersion(data) {
  return (dispatch) => dispatch({
    type: SET_RUNNING_VERSION,
    payload: data
  });
}

export function setLatestVersion(data) {
  return (dispatch) => dispatch({
    type: SET_LATEST_VERSION,
    payload: data
  });
}

export function checkNewVersion(data) {
  return (dispatch) => dispatch({
    type: CHECK_NEW_VERSION,
    payload: data
  });
}

export function pollLatestVersion() {
  return (dispatch) => dispatch({
    type: POLL_LATEST_VERSION,
    payload: {}
  });
}
