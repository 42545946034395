import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import AsyncSelect from 'react-select/async-creatable';
import Select from 'react-select';
import Validator from 'validator';
import { Row, Col, FormGroup, Card, Spinner, Label, Alert } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { camelCase, startCase } from 'lodash';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import Content from "src/layout/content/Content";
import Head from "src/layout/head/Head";
import {
  Block, BlockBetween, BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "src/components/Component";
import API_ROUTES from "../../../../../config/api";
import CurrencyFormat from "../../../../../transformer/currency-format";
import { convertNumberToPennies, roundDecimalMoney } from "../../../../../utils/money";
import { DEFAULT_CURRENCY, mapCurrency } from "../../../../../utils/currency";
import HasFeature from "../../../../../utils/has-feature";

const DEFAULT_PAYMENT_LINK_TYPE = { value: 'FULL_PAYMENT', label: 'Full Payment' };

const formDefaults = {
  amount: '',
  bookingField: null,
  booking: '',
  booking_id: null,
  name: '',
  email: '',
  departure_date: '',
  departure_date_pretty: '',
  return_date: '',
  return_date_pretty: '',
  description: '',
  expires_at: null,
  type: DEFAULT_PAYMENT_LINK_TYPE,
  advanced: {
    card_payments: true,
    open_banking: true,
    payment_reciept: true,
    authorise_only: false,
    currency: DEFAULT_CURRENCY,
  },
};

/**
 * Payment Link Create Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentLinkCreate = ({ ...props }) => {
  const { organisation } = useSelector((state) => state);
  const { enums } = useSelector((state) => state.enums);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [formValues, setFormValues] = useState(formDefaults);
  const [displayCurrency, setDisplayCurrency] = useState(mapCurrency(enums.currency[DEFAULT_CURRENCY]));

  const currencies = organisation.currencies.map(item => {
    const currency = enums.currency[item];
    return mapCurrency(currency);
  });

  const paymentLinkTypes = enums.payment_link_type.map(item => ({
    value: item,
    label: startCase(camelCase(item)),
  }));

  const history = useHistory();

  const [formState, setFormState] = useState({
    isTemporaryBooking: false,
    allowCustomerFieldsEntry: false,
    bookingFieldEnabled: false,
    showAdvancedOptions: false,
    showSelectDropdown: false,
    asyncSelectLoaded: false,
  });

  /**
   * Constantly check form field validity
   */
  useEffect(() => {
    checkFormValidity();
  }, [formValues]);

  /**
   * Update a form value
   * @type {updateFormField}
   */
  const updateFormField = ((e) => {
    let value = e.target.value;

    setTouched({ ...touched, [e.target.id]: true });

    if (e.target.id === 'amount' && value.toString().includes('.') && value.toString().split('.')[1].length > 2) {
      value = roundDecimalMoney(e.target.value);
    }

    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
  });

  /**
   * Update date fields
   * @param date
   * @param field
   */
  const updateDateField = (date, field) => {
    setTouched({
      ...touched,
      [field]: true,
    });

    setFormValues({
      ...formValues,
      [field]: new Date(date).toISOString().split('T')[0],
      [`${field}_pretty`]: new Date(date).toDateString(),
    });
  };

  /**
   * Set whether the booking is temporary
   * @param itemState
   */
  const setIsTemporary = (itemState) => {
    setFormValues({
      ...formDefaults,
      type: formValues.type,
      amount: formValues.amount,
      description: formValues.description,
      advanced: {
        ...formDefaults.advanced,
        currency: formValues.advanced.currency,
      }
    });

    setTouched({});

    setFormState({
      ...formState,
      isTemporaryBooking: itemState,
      allowCustomerFieldsEntry: itemState,
      bookingFieldEnabled: itemState === true,
    });
  };

  /**
   * Set the advanced options fields
   * @param e
   */
  const updateAdvanceOption = (e) => setFormValues({
    ...formValues,
    advanced: {
      ...formValues.advanced,
      [e.target.name]: e.target.checked,
    }
  });

  /**
   * Set currency (advanced option)
   * @param e
   */
  const setCurrency = (e) => {
    setDisplayCurrency(e);
    return setFormValues({
      ...formValues,
      advanced: {
        ...formValues.advanced,
        currency: e.id,
      }
    });
  };

  /**
   * Set whether advanced options should be shown
   * @param e
   */
  const showAdvancedOptions = (e) => {
    e.preventDefault();
    setFormState({ ...formState, showAdvancedOptions: true });
  };

  /**
   * Check the validity of the form and update state
   * @returns {{}}
   */
  const checkFormValidity = () => {
    const errorObj = {};

    if (formValues.amount === '') errorObj.amount = 'Amount cannot be empty';
    if (formValues.amount < 0.01 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be greater than zero';
    if (formValues.amount > 100000 && typeof errorObj.amount === 'undefined') errorObj.amount = 'Amount must be less than or equal to 100,000';

    if (formValues.name === '') errorObj.name = 'Name cannot be empty';

    if (formValues.email === '') errorObj.email = 'Email cannot be empty';

    if (!Validator.isEmail(formValues.email) && typeof errorObj.email === 'undefined') errorObj.email = 'Email is Invalid';

    if (formState.isTemporaryBooking === false) {
      if (formValues.booking === '') errorObj.booking = 'Booking cannot be empty';

      if (formValues.departure_date === '') errorObj.departure_date = 'Departure date cannot be empty';

      if (formValues.return_date === '') errorObj.return_date = 'Return date cannot be empty';

      if (formValues.departure_date !== ''
        && formValues.return_date !== ''
        && new Date(formValues.return_date).getTime() < new Date(formValues.departure_date).getTime()
      ) {
        errorObj.return_date = 'Return date must be greater than or equal to departure date';
      }
    }

    setErrors(errorObj);

    return errorObj;
  };

  /**
   * Submit the form
   * @param e
   * @returns {Promise<boolean>}
   */
  const formSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      ...touched,
      name: true,
      email: true,
      amount: true,
      booking: true,
      departure_date: true,
      return_date: true,
    });

    const errorCheck = checkFormValidity();

    if (Object.keys(errorCheck).length >= 1) {
      return false;
    }

    setIsSubmitting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();

      let bookingID = null

      // Create the booking if it is new
      if (formState.allowCustomerFieldsEntry === true && formState.isTemporaryBooking === false) {
        const bookingResponse = await Axios(
          API_ROUTES.agent['bookings:create'](),
          {
            method: 'put',
            data: {
              organisation: organisation.organisation,
              customer_name: formValues.name,
              email: formValues.email,
              booking_reference: formValues.booking,
              departure_date: formValues.departure_date !== '' ? formValues.departure_date : null,
              return_date: formValues.return_date !== '' ? formValues.return_date : null,
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
          }
        );

        bookingID = bookingResponse.data.data.id;
      }

      // Create the payment link
      const paymentLink = await Axios(
        API_ROUTES.agent['payment-links:put'](),
        {
          method: 'put',
          data: {
            customer_name: formValues.name,
            email: formValues.email,
            booking_id: bookingID !== null ? bookingID : formValues.booking_id,
            amount: convertNumberToPennies(formValues.amount),
            organisation: organisation.organisation,
            open_banking_enabled: formValues.advanced.open_banking,
            card_enabled: formValues.advanced.card_payments,
            currency: formValues.advanced.currency,
            description: formValues.description,
            expires_at: formValues.expires_at,
            authorisation_only: formValues.advanced.authorise_only,
            type_id: formValues.type.value,
          },
          headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        }
      );

      return history.push(`/payment-links/${paymentLink.data.data.id}`);
    } catch (error) {
      return history.push(`/errors/504`);
    }
  };

  /**
   * Find bookings using the API
   * @type {function(*): *}
   */
  const findBookings = useCallback(async (value) => {
    const user = await Auth.currentAuthenticatedUser();

    const result = await Axios(
      API_ROUTES.agent['bookings:get'](organisation.organisation),
      {
        method: 'post',
        data: {
          take: 10,
          keyword: value,
          skip: 0,
          organisation: organisation.organisation,
        },
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
      }
    );

    setFormState({ ...formState, asyncSelectLoaded: true });
    return result.data.data;
  }, []);

  /**
   * Populate the booking related fields on booking select
   * @param value
   * @returns {Promise<void>}
   */
  const populateBookingDetails = async (value) => {
    if (value !== null) {
      let newDuplicate = false;
      let data = { ...value };

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true) {
        const bookingCheck = await findBookings(value.value);

        for (const booking of bookingCheck) {
          if (booking.booking_reference === value.value) {
            newDuplicate = true;
            data = booking;
          }
        }
      }

      if (typeof value.__isNew__ !== 'undefined' && value.__isNew__ === true && newDuplicate === false) {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: true });

        setFormValues({
          ...formValues,
          bookingField: value,
          booking: value.value,
          booking_id: null,
          name: '',
          email: '',
          departure_date: '',
          departure_date_pretty: '',
          return_date: '',
          return_date_pretty: '',
        });
      } else {
        setFormState({ ...formState, asyncSelectLoaded: false, allowCustomerFieldsEntry: false });

        const valuesToSet = {
          ...formValues,
          bookingField: value,
          booking: data.booking_reference,
          booking_id: data.id,
          name: data.customer_name,
          email: data.email,
          departure_date: data.departure_date,
          return_date: data.return_date,
        };
        if (data.departure_date) valuesToSet.departure_date_pretty = new Date(data.departure_date).toDateString();
        if (data.return_date) valuesToSet.return_date_pretty = new Date(data.return_date).toDateString();

        setFormValues(valuesToSet);
      }
      if (alertMessage) {
        setAlertMessage(false);
      }
    }
  }

  // Pre-Selected booking.
  const urlParams = new URLSearchParams(history.location.search);
  const preSelectedBookingID = urlParams.get('booking');

  const geBookingData = useCallback(async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const result = await Axios(
      API_ROUTES.agent['booking:get'](preSelectedBookingID),
      {
        method: 'get',
        headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` },
        validateStatus: () => true,
      }
    );
    if (result.status === 200) {
      populateBookingDetails(result.data.data);
    } else {
      setAlertMessage(`Unable to set the pre-selected booking`);
    }
    setLoading(false);
  }, [preSelectedBookingID]);

  useEffect(() => {
    if (preSelectedBookingID) {
      geBookingData();
    }
  }, []);

  if (loading) {
    return (
      <React.Fragment>
        <Head title="New Payment Link" />
        <Content>

          <Card className="card-bordered p-5 rounded-xl shadow my-4">
            <BlockHead size="sm" className="mb-2">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
                    Create a Payment Link
                  </BlockTitle>
                  <BlockDes>Enter your customers payment details to take a payment via link, in person or over the phone</BlockDes>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block size="lg">
              <Skeleton count={5} height="60px" style={{ marginTop: "1em" }} />
            </Block>
          </Card>
        </Content>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="New Payment Link" />
      <Content>

        <Card className="card-bordered px-4 py-4 rounded-xl my-4">
          <BlockHead size="sm" className="pb-0">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle className="text-4xl bg-gradient-to-r from-purple-900 via-purple-800 to-purple-700 inline-block text-transparent bg-clip-text pb-0 font-normal" page>
                  Create a Payment Link
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3 pr-1">
                      <Link to={'/payments/payment-links'}>
                        <Button color="dark" outline className="border border-violet-800 text-violet-800 hover:bg-slate-700 hover:text-white rounded-md" id="button__back">
                          <ArrowLeftIcon className='h-3.5 w-3.5 inline mr-1' />
                          <span>Payment Links</span>
                        </Button>
                      </Link>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
            {alertMessage && <><br /><Alert className="alert-icon" color="info"> {alertMessage} </Alert></>}
          </BlockHead>

          <div className="nk-divider divider md pb-2" />

          <Block size="lg">

            <form className="gy-3">
              <Row className="g-3 align-center">
                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Payment Amount<small className="text-danger pl-1 pb-3">required</small>
                    </Label>
                    <div className="form-control-wrap input-group">
                      <div className="input-group-addon pl-3 pr-3">{CurrencyFormat(enums.currency, formValues.advanced.currency).getCurrencySymbol()}</div>
                      <input
                        type="number"
                        id="amount"
                        disabled={isSubmitting}
                        value={formValues.amount}
                        onChange={updateFormField}
                        className={`form-control form-control-xl ${typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' ? 'error' : ''}`}
                        min={0}
                        step={0.01}
                        placeholder="e.g. 200.00"
                      />
                    </div>
                    {typeof errors.amount !== 'undefined' && typeof touched.amount !== 'undefined' && (
                      <small className="text-danger">{errors.amount}</small>
                    )}
                    {(typeof errors.amount === 'undefined' || typeof touched.amount === 'undefined') && (
                      <span className="form-note">The amount that the customer will pay</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Currency
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <Select
                          className="form-control form-control-xl p-0 border-0 indicator-hidden"
                          value={displayCurrency}
                          getOptionLabel={item => item.label}
                          getOptionValue={item => item.id}
                          options={currencies}
                          onChange={setCurrency}
                          isDisabled={currencies.length <= 1}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    {currencies.length <= 1 && (
                      <span className="form-note">Multi currency is not available, contact your account manager to enable.</span>
                    )}
                    {currencies.length > 1 && (
                      <span className="form-note">The currency that the customer will pay in</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Payment Link Type
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <Select
                          name='type'
                          onChange={(val) => setFormValues({ ...formValues, type: val })}
                          value={formValues.type}
                          className="form-control form-control-xl p-0 border-0 indicator-hidden"
                          options={paymentLinkTypes}
                          classNamePrefix="react-select"
                        />
                      </div>
                    </div>
                    <span className="form-note">Payment Link Type ((Full Payment / Deposit))</span>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="g-3 align-center">
                <Col sm="12">
                  <FormGroup>
                    <Label htmlFor="default-2" className="form-label text-primary">
                      Payment Link Description
                    </Label>
                    <div className="border">
                      <textarea
                        rows={2}
                        id="description"
                        name="description"
                        disabled={isSubmitting}
                        value={formValues.description}
                        onChange={updateFormField}
                        placeholder="e.g. Trip to Hawaii"
                        className={`w-100 p-2`}
                      />
                    </div>
                    <span className="form-note">The description of what the payment is for (this will be shown to the customer at payment)</span>
                  </FormGroup>
                </Col>
              </Row>

              {!preSelectedBookingID && (
                <React.Fragment>
                  <div className="nk-divider divider md pb-2" />

                  <Row className="g-3 align-center">
                    <Col lg="5">
                      <FormGroup>
                        <label className="form-label text-primary">Unknown Booking Reference</label>
                        <span className="form-note">If you don't know the booking reference, this will allow you to fill it in at a later stage.</span>
                      </FormGroup>
                    </Col>
                    <Col lg="7">
                      <FormGroup>
                        <div className="form-control-wrap">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              disabled={isSubmitting}
                              className="custom-control-input form-control"
                              checked={formState.isTemporaryBooking}
                              value={formValues.bookingID}
                              onChange={(e) => { setIsTemporary(e.target.checked) }}
                              id={`temp-booking-switch`}
                            />
                            <label className="custom-control-label" htmlFor={`temp-booking-switch`} />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {formState.bookingFieldEnabled === false && (
                    <Row className="g-3 align-center">
                      <Col lg="5">
                        <FormGroup>
                          <label className="form-label text-primary">Booking Reference</label>
                          <span className="form-note">The customers reference for their booking</span>
                        </FormGroup>
                      </Col>
                      <Col lg="7">
                        <FormGroup>
                          <div className="form-control-wrap">
                            <AsyncSelect
                              isClearable
                              className={`form-control form-control-xl p-0 border-0 indicator-hidden ${typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' ? 'error' : ''}`}
                              allowCreateWhileLoading={false}
                              value={formValues.bookingField}
                              isMulti={false}
                              getOptionLabel={e => {
                                if (e.__isNew__) {
                                  return e.label;
                                }

                                return `${e.booking_reference} | ${e.customer_name}`;
                              }}
                              getOptionValue={e => e.id}
                              loadOptions={findBookings}
                              onChange={populateBookingDetails}
                              menuIsOpen={formState.asyncSelectLoaded === true}
                              placeholder="e.g. TEST-123"
                              classNamePrefix="react-select"
                              createOptionPosition="first"
                              isDisabled={isSubmitting}
                              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                let response = false;

                                if (inputValue && inputValue.length >= 1) {
                                  response = true;
                                }

                                if (inputValue && inputValue.length > 0 && selectOptions && selectOptions.length > 0) {
                                  selectOptions.forEach((item) => {
                                    if (item.booking_reference.toLowerCase() === inputValue.toLowerCase()) response = false;
                                  })
                                }

                                return response;
                              }}
                            />
                          </div>
                          {typeof errors.booking !== 'undefined' && typeof touched.booking !== 'undefined' && (
                            <small className="text-danger">{errors.booking}</small>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}

              {
                (formState.allowCustomerFieldsEntry === true || formValues.bookingField) && (
                  <React.Fragment>

                    <div className="nk-divider divider md pb-2" />

                    <Row className="g-3 align-center">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Customer Name
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formValues.name}
                              onChange={updateFormField}
                              className={`form-control form-control-xl ${typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' ? 'error' : ''}`}
                              disabled={formState.allowCustomerFieldsEntry === false || isSubmitting === true}
                            />
                          </div>
                          {typeof errors.name !== 'undefined' && typeof touched.name !== 'undefined' && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                          {(typeof errors.name === 'undefined' || typeof touched.name === 'undefined') && (
                            <span className="form-note">The customers full name</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label text-primary">
                            Customer Email
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={formValues.email}
                              onChange={updateFormField}
                              className={`form-control form-control-xl ${typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' ? 'error' : ''}`}
                              disabled={formState.allowCustomerFieldsEntry === false || isSubmitting === true}
                            />
                          </div>
                          {typeof errors.email !== 'undefined' && typeof touched.email !== 'undefined' && (
                            <small className="text-danger">{errors.email}</small>
                          )}
                          {(typeof errors.email === 'undefined' || typeof touched.email === 'undefined') && (
                            <span className="form-note">The customers email address</span>
                          )}
                        </FormGroup>
                      </Col>
                      {
                        formState.isTemporaryBooking === false && (
                          <React.Fragment>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="default-2" className="form-label text-primary">
                                  Departure Date
                                </Label>
                                <div className="form-control-wrap">
                                  <DatePicker
                                    id="departure_date"
                                    name="departure_date"
                                    value={formValues.departure_date_pretty}
                                    onChange={(value) => updateDateField(value, 'departure_date')}
                                    disabled={formState.allowCustomerFieldsEntry === false}
                                    className={`form-control form-control-xl date-picker ${typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' ? 'error' : ''}`}
                                  />
                                </div>
                                {typeof errors.departure_date !== 'undefined' && typeof touched.departure_date !== 'undefined' && (
                                  <small className="text-danger">{errors.departure_date}</small>
                                )}
                                {(typeof errors.departure_date === 'undefined' || typeof touched.departure_date === 'undefined') && (
                                  <span className="form-note">The date that the customers holiday departs</span>
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="default-2" className="form-label text-primary">
                                  Return Date
                                </Label>
                                <div className="form-control-wrap">
                                  <DatePicker
                                    id="return_date"
                                    name="return_date"
                                    value={formValues.return_date_pretty}
                                    onChange={(value) => updateDateField(value, 'return_date')}
                                    disabled={formState.allowCustomerFieldsEntry === false}
                                    className={`form-control form-control-xl date-picker ${typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' ? 'error' : ''}`}
                                  />
                                </div>
                                {typeof errors.return_date !== 'undefined' && typeof touched.return_date !== 'undefined' && (
                                  <small className="text-danger">{errors.departure_date}</small>
                                )}
                                {(typeof errors.return_date === 'undefined' || typeof touched.return_date === 'undefined') && (
                                  <span className="form-note">The date that the customers holiday returns</span>
                                )}
                              </FormGroup>
                            </Col>
                          </React.Fragment>
                        )
                      }
                    </Row>
                  </React.Fragment>
                )
              }

              {formState.showAdvancedOptions && (
                <React.Fragment>
                  <div className="nk-divider divider md" />
                  <Row className="g-3 align-center pb-2">
                    <Col lg="6">
                      <Row className="g-3 align-center pb-2">
                        <Col xs="9" md="6">
                          <FormGroup>
                            <label className="form-label text-primary">Allow Card Payments</label>
                            <span className="form-note">Whether to allow the customer to pay using their card</span>
                          </FormGroup>
                        </Col>
                        <Col xs="3" md="5" className="text-right">
                          <FormGroup>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  checked={formValues.advanced.card_payments}
                                  onChange={updateAdvanceOption}
                                  disabled={isSubmitting}
                                  id="Card Payments"
                                  name="card_payments"
                                />
                                <label className="custom-control-label" htmlFor="Card Payments" />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <Row className="g-3 align-center">
                        <Col xs="9" md="6" className="pr-5">
                          <FormGroup>
                            <label className="form-label text-primary">Allow Open Banking</label>
                            <span className="form-note">Whether to allow the customer to pay using open banking</span>
                          </FormGroup>
                        </Col>
                        <Col xs="3" md="5" className="text-right">
                          <FormGroup>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-control-input form-control"
                                  checked={formValues.advanced.open_banking}
                                  onChange={updateAdvanceOption}
                                  disabled={isSubmitting}
                                  id="Open Banking"
                                  name="open_banking"
                                />
                                <label className="custom-control-label" htmlFor="Open Banking" />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <Row className="g-3 align-center">
                        <Col xs="9" md="6">
                          <FormGroup>
                            <label className="form-label text-primary">Send Payment Receipt</label>
                            <span className="form-note">Whether to send a payment receipt to the customer on payment completion</span>
                          </FormGroup>
                        </Col>
                        <Col xs="3" md="5" className="text-right">
                          <FormGroup>
                            <div className="form-control-wrap">
                              <div className="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  name="payment_reciept"
                                  className="custom-control-input form-control"
                                  checked={formValues.advanced.payment_reciept}
                                  onChange={updateAdvanceOption}
                                  disabled={isSubmitting}
                                  id="Payment Receipt"
                                />
                                <label className="custom-control-label" htmlFor="Payment Receipt" />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    {
                      HasFeature(organisation.features, ['pre-auth']) && (
                        <Col lg="6">
                          <Row className="g-3 align-center">
                            <Col xs="9" md="6">
                              <FormGroup>
                                <label className="form-label text-primary">Pre-Authorise</label>
                                <span className="form-note">Only authorise the transaction, not take payment (the transaction will need to be manually completed).</span>
                              </FormGroup>
                            </Col>
                            <Col xs="3" md="5" className="text-right">
                              <FormGroup>
                                <div className="form-control-wrap">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      name="authorise_only"
                                      className="custom-control-input form-control"
                                      checked={formValues.advanced.authorise_only}
                                      onChange={updateAdvanceOption}
                                      disabled={isSubmitting}
                                      id="Authorise Only"
                                    />
                                    <label className="custom-control-label" htmlFor="Authorise Only" />
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                  </Row>

                  <Row className="g-3 align-center pb-2">
                    <Col lg="6">
                      <Row className="g-3 align-center">
                        <Col md="6">
                          <FormGroup>
                            <label className="form-label text-primary">Expires At</label>
                            <span className="form-note">The date that the payment link will expire and become unusable</span>
                          </FormGroup>
                        </Col>
                        <Col md="1" className="pr-5" />
                        <Col md="4">
                          <FormGroup>
                            <div className="form-control-wrap">
                              <DatePicker
                                id="expires_at"
                                name="expires_at"
                                value={formValues.expires_at}
                                onChange={(value) => updateDateField(value, 'expires_at')}
                                className={`form-control form-control-xl date-picker ${typeof errors.expires_at !== 'undefined' && typeof touched.expires_at !== 'undefined' ? 'error' : ''}`}
                              />
                              {typeof errors.expires_at !== 'undefined' && typeof touched.expires_at !== 'undefined' && (
                                <small className="text-danger">{errors.expires_at}</small>
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </React.Fragment>
              )}
              <div className="nk-divider divider md" />
              <Row className="g-3">
                <Col lg="7" className="offset-lg-5">
                  <FormGroup>
                    <Button
                      id="payment_link__submit"
                      color="primary"
                      size="lg"
                      onClick={formSubmit}
                      disabled={isSubmitting}
                      className="float-right"
                    >
                      {isSubmitting === true ? (
                        <React.Fragment>
                          Generating Link <Spinner style={{ marginLeft: '1em' }} size="sm"> </Spinner>
                        </React.Fragment>
                      ) : (
                        <span>Create Payment Link</span>
                      )}
                    </Button>
                    <Button
                      style={{ display: formState.showAdvancedOptions === false ? 'initial' : 'none' }}
                      color="primary"
                      className="mr-4 btn-dim float-right"
                      size="lg"
                      disabled={isSubmitting}
                      onClick={showAdvancedOptions}>
                      Advanced Options
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </Block>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default PaymentLinkCreate;
