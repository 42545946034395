import React from "react";
import Icon from "../../../icon/Icon";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import API_ROUTES from "../../../../config/api";
import DataTable from "../../../data-table";
import TransactionMethodTransformer from "src/transformer/transaction-method";
import TransactionTypeTransformer from "src/transformer/transaction-type";
import TransactionStatusTransformer from "src/transformer/transaction-status";
import CurrencyFormat from 'src/transformer/currency-format';
import PiiObfuscator from "../../../pii-obfuscator";
import { convertPenniesToDecimals } from "src/utils/money";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const customerNameFormatter = (item) => {
  if (typeof item.booking !== 'undefined' && item.booking?.customer_name) {
    return <span>{item.booking.customer_name}</span>
  }

  if (item.payment_link && item.payment_link.id) {
    return <span>{item.payment_link.customer_name}</span>
  }

  if (item.metadata && item.metadata.customer_name) {
    return <span>{item.metadata.cardholder_name}</span>
  }

  return <span>-</span>;
}

const RowFormatter = ({ item, enums }) => {
  const total = CurrencyFormat(enums.currency, item.currency)
    .format(convertPenniesToDecimals(item.amount));

  const method = TransactionMethodTransformer(item.method);
  const type = TransactionTypeTransformer(item.type, item.metadata);
  const status = TransactionStatusTransformer(item.status);
  const history = useHistory();

  const rowClick = (event, url) => {
    if (event.target.tagName === 'TD') {
      history.push(url)
    }
  }

  return (
    <tr key={item.id} className="tb-tnx-item cursor-pointer hover:bg-gray-50" onClick={(event) => rowClick(event, `/transaction/${item.id}`)}>
      <td>
        <span className="title">{moment(item.created_at).format('Do MMM, HH:mm')}</span>
      </td>
      <td className="hidden lg:table-cell">
        {item.booking && (
          <Link to={`/booking/${item.booking.id}`} className="link__payment_link">{item.booking.booking_reference}</Link>
        )}
        {!item.booking && item?.payment_link?.id && (
          <React.Fragment>
            <strong>Unassigned</strong><br /><Link to={`/payment-links/${item.payment_link.id}/assign`} className="link__assign_booking">Assign to Booking</Link>
          </React.Fragment>
        )}
      </td>
      <td className="hidden lg:table-cell">
        <PiiObfuscator type="customer_name">{customerNameFormatter(item)}</PiiObfuscator>
      </td>
      <td className="text-center border-left">
        {total}
      </td>
      <td className="text-right border-left pr-0 hidden lg:table-cell">
        <div style={{ width: '38px', padding: '0 4px 0 6px', maxWidth: '38px', margin: '0 auto' }}>
          {
            typeof type.image !== 'undefined' && (
              <img src={type.image} className={type.imageClass} />
            )
          }
          {
            typeof type.image === 'undefined' && (
              <Icon name={`ni text-primary icon ${type.icon} pr-3`} style={{ fontSize: '21px' }} />
            )
          }
        </div>
      </td>
      <td className="text-left border-left w-100px pl-0 hidden lg:table-cell">
        <span className="date" style={{ verticalAlign: 'top' }}>{type.label}</span>
      </td>
      <td className="text-center w-150px hidden lg:table-cell">
        <Icon name={`ni text-primary icon ${method.icon} pr-1`} />
        <span className="date">{method.label}</span>
      </td>
      <td className="text-center w-150px">
        <span className={`inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-white ${status.color}`}>
          <Icon name={`ni text-white icon ${status.icon} pr-1`} />
          <span className="date">{status.label}</span>
        </span>
      </td>
    </tr>
  );
}

const TableHeaders = () => (
  <tr className="tb-tnx-head text-left">
    <th>
      <span>Date</span>
    </th>
    <th className="hidden lg:table-cell">
      <span>Booking Ref</span>
    </th>
    <th className="hidden lg:table-cell">
      <span>Customer Name</span>
    </th>
    <th className="text-center border-left">
      <span>Amount</span>
    </th>
    <th colSpan={2} className="text-center border-left hidden lg:table-cell">
      <span>Type</span>
    </th>
    <th className="text-center hidden lg:table-cell">
      <span>Method</span>
    </th>
    <th className="text-center">
      <span>Status</span>
    </th>
  </tr>
);

const TransactionTable = () => {
  const endpoint = API_ROUTES.agent['payments:get']();

  return (
    <React.Fragment>
      <div className="flex flex-col rounded-md border border-slate-200 animate-in fade-in-50 mb-4 bg-gray-50">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title w-100">
              <div className="float-right">
                <Link className="text-purple-900 text-sm" to={`/payments`}>
                  View more <ChevronRightIcon className="h-4 inline-block" />
                </Link>
              </div>
              <h2 className="text-xl font-normal text-purple-900">
                Recent <span className="font-bold">Payments</span>
              </h2>
            </div>
          </div>
        </div>
        <DataTable
          columnCount={10}
          limit={10}
          autoReload={false}
          TableHeaders={TableHeaders}
          RowFormatter={RowFormatter}
          endpoint={endpoint}
          forceShowCount={false}
          hideFilters={true}
        />
      </div>

    </React.Fragment>

  );
};

export default TransactionTable;
