import React from "react";
import Head from "src/layout/head/Head";
import {
  Block,
  Icon,
} from "src/components/Component";
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import moment from "moment";

import {STATUS_TYPES_OBJECT} from "src/constants/user";
import PiiObfuscator from "src/components/pii-obfuscator";

const UserDetail = ({ user, parentLoading }) => {
  return (
    <React.Fragment>
      <Head title={`${user.first_name} ${user.last_name}`} />
      <Block>
        <div className="nk-data data-list data-list-s2">
          <div style={{ display: parentLoading === true ? 'block' : 'none' }} className="mt-3">
                    <Skeleton count={5} height="50px" className="mb-4" style={{
                      width: '100%',
                    }}/>
          </div>

          <div style={{ display: parentLoading === false ? 'block' : 'none' }}>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label"><strong>User ID</strong></span>
                <span className="data-value">{ user.id }</span>
              </div>
              <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label"><strong>Created On</strong></span>
                <span className="data-value">{ moment(user.created_at).format('Do MMMM YYYY') }</span>
              </div>
              <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label"><strong>Email</strong></span>
                <PiiObfuscator className="data-value" type="email">{ user.email }</PiiObfuscator>
              </div>
              <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label"><strong>Status</strong></span>
                <span className="data-value">{STATUS_TYPES_OBJECT[user.status]}</span>
              </div>
              <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label"><strong>KYC</strong></span>
                <span className="data-value">Not Provided</span>
              </div>
              <div className="data-col data-col-end">
              <span className="data-more disable">
                <Icon name="lock-alt" />
              </span>
              </div>
            </div>
          </div>
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserDetail;
